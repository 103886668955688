<template>
  <SelectView
    @selectView="fetchViewById"
    :selectedViewId="Number(SavingViewsGetActiveViewId)"
    :selectedViewName="SavingViewsGetActiveViewName"
    :colour="$vuetify.theme.themes.light.ciOrange"
    :availableViews="SavingViewsGetAvailableViews"
    :errorMessage="errorMsg"
    :loading="loading"
    :menuToLeft="menuToLeft"
    :viewType="SavedViewTypes.MAP"
    :dataCy="dataCy"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getters, actions } from "@S/savingView/types";
import SelectView from "@C/savingView/SelectView/SelectView.vue";

import getViewById from "@/graphql/views/getViewById";

import SavedViewTypes from "@/constants/SavedViewTypes";
import { apolloClient } from "@/plugins/apolloPlugin";

export default {
  name: "SelectMapView",
  components: {
    SelectView
  },
  data: () => ({
    loading: false,
    errorMsg: "",
    SavedViewTypes
  }),
  props: {
    menuToLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    dataCy: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      getters.SavingViewsGetAvailableViews,
      getters.SavingViewsGetActiveViewName,
      getters.SavingViewsGetActiveViewId
    ])
  },
  methods: {
    ...mapActions([actions.SavingViewsSetActiveView]),
    fetchViewById(id) {
      if (id === this.SavingViewsGetActiveViewId) {
        return;
      }
      const loadingTimeout = setTimeout(() => {
        this.loading = true;
      }, 500);

      apolloClient
        .query({
          query: getViewById,
          variables: {
            id: id.toString()
          }
        })
        .then((response) => {
          if (response?.data?.view) {
            this.SavingViewsSetActiveView(response.data.view);
          }

          this.menu = false;
        })
        .catch((error) => {
          console.error("error fetching view by name: ", error);
          this.errorMsg =
            "Failed Fetching the View, if the problem persists, please contact Continuum Support";
        })
        .finally(() => {
          clearTimeout(loadingTimeout);
          this.loading = false;
        });
    }
  }
};
</script>
