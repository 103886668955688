export default {
  ADMIN: "admin",
  EXECUTE: "execute",
  CREATE_PROJECT: "create_project"
};

export const permissionTypesForProject = {
  READ: "read",
  WRITE: "write",
  ADMIN: "admin",
  EXECUTE: "execute"
};

export const getNestedPermissions = (permission) => {
  const permissions = [];
  const iterator = (permission, array) => {
    if (permission) {
      array.push(permission);
    }
    switch (permission) {
      case permissionTypesForProject.READ:
        return array;
      case permissionTypesForProject.WRITE:
        return iterator(permissionTypesForProject.READ, array);
      case permissionTypesForProject.EXECUTE:
        return iterator(permissionTypesForProject.WRITE, array);
      case permissionTypesForProject.ADMIN:
        return iterator(permissionTypesForProject.EXECUTE, array);
      default:
        throw "Unexpected permission type: " + permission;
    }
  };
  return iterator(permission, permissions);
};
