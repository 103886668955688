import SessionModule from "@/store/session/module";

export default {
  methods: {
    accessAllowed(asset, verb) {
      const accessAllowed = this.$store.getters[
        SessionModule.types.getters.SessionCheckAuthorised
      ](asset, verb);

      return accessAllowed;
    }
  }
};
