<script>
import { InteractionStates } from "@/constants/InteractionStates";
import { mapGetters } from "vuex";
import { NewOptionTypes } from "@S/option/types";
import { isEqual } from "lodash";

export default {
  name: "SavedView2OptionState",
  render() {
    return null;
  },
  emits: [
    "OptionsToggleInteractionState",
    "OptionsToggleVisibility",
    "SetAllOptionColours"
  ],
  props: {
    SavingViewsGetOptionStyles: {
      type: Object,
      required: true
    },
    OptionsGetActiveId: {
      type: [String, Number],
      required: false //if no option is active
    },
    OptionsGetSelectedIds: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters([NewOptionTypes.getters.GetOptionLoadingState])
  },
  methods: {
    OptionsToggleInteractionState(payload) {
      this.$emit("OptionsToggleInteractionState", payload);
    },
    OptionsToggleVisibility(payload) {
      this.$emit("OptionsToggleVisibility", payload);
    },
    SetAllOptionColours(payload) {
      this.$emit("SetAllOptionColours", payload);
    },
    updateOptionSelectionStateAsNeeded(
      newActiveOptionId,
      oldActiveOptionId,
      newSelectedOptionIds,
      oldSelectedOptionIds
    ) {
      const oldSelectedIds = new Set(
        oldSelectedOptionIds.map((n) => Number(n))
      );
      const newSelectedIds = new Set(
        newSelectedOptionIds.map((n) => Number(n))
      );

      const checkOptionExists = (optionId) => {
        // Use the existence of a loading state as a proxy for the option not having been deleted
        return Boolean(this.GetOptionLoadingState(optionId));
      };

      // Handle previously active option
      if (
        oldActiveOptionId &&
        oldActiveOptionId !== newActiveOptionId &&
        checkOptionExists(oldActiveOptionId)
      ) {
        this.OptionsToggleInteractionState({
          optionId: oldActiveOptionId,
          interactionState: newSelectedIds.has(oldActiveOptionId)
            ? InteractionStates.SELECTED
            : InteractionStates.NORMAL
        });
      }

      // Update states for other options
      const allOptionIds = new Set([
        ...oldSelectedIds,
        ...newSelectedIds,
        oldActiveOptionId,
        newActiveOptionId
      ]);
      allOptionIds.forEach((id) => {
        if (!checkOptionExists(id)) return;
        if (id === newActiveOptionId) {
          // Set new active option
          this.OptionsToggleInteractionState({
            optionId: id,
            interactionState: InteractionStates.ACTIVE
          });
        } else if (newSelectedIds.has(id) && id !== oldActiveOptionId) {
          // Set newly selected options
          this.OptionsToggleInteractionState({
            optionId: id,
            interactionState: InteractionStates.SELECTED
          });
        } else if (!newSelectedIds.has(id) && oldSelectedIds.has(id)) {
          // Reset options no longer selected
          this.OptionsToggleInteractionState({
            optionId: id,
            interactionState: InteractionStates.NORMAL
          });
        }
      });
    },

    updateOptionColoursAsNeeded(newValue, oldValue) {
      const newStyles = newValue.styles;
      const oldStyles = oldValue?.styles;

      if (isEqual(newStyles, oldStyles)) {
        return;
      }

      const newColourMap = newStyles.map((s) => {
        return {
          id: Number(s.assetId),
          colour: s.fillColour
        };
      });
      this.SetAllOptionColours(newColourMap);
    },
    updateOptionVisibilityAsNeeded(newValue, oldValue) {
      const newStyles = newValue.styles;
      const oldStyles = oldValue?.styles;
      if (isEqual(newStyles, oldStyles)) return;
      const visibleOptionIds = newStyles
        .filter((s) => s.isVisible)
        .map((s) => Number(s.assetId));
      this.OptionsToggleVisibility({
        optionIds: visibleOptionIds,
        setTo: true,
        overwrite: true
      });
    }
  },
  watch: {
    SavingViewsGetOptionStyles: {
      deep: true,
      handler: function (newValue, oldValue) {
        if (!Object.keys(newValue).length) return; //handles a case where the optionsState is not set on a view
        this.updateOptionColoursAsNeeded(newValue, oldValue);
        this.updateOptionSelectionStateAsNeeded(
          newValue.activeId,
          oldValue?.activeId,
          newValue.selected,
          oldValue?.selected
        );
        this.updateOptionVisibilityAsNeeded(newValue, oldValue);
      }
    }
  }
};
</script>
