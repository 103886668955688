<template>
  <v-btn @click="toggleSnow()" class="ml-5" color="primary" icon>
    <v-icon> mdi-snowflake </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "LetItSnow",
  methods: {
    toggleSnow() {
      window.toggleSnow();
    }
  }
};
</script>
