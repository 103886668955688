export const calculateTimeDiffWithConversionFactor = (
  time1,
  time2,
  scaleFactor
) => {
  const diff = time1 - time2;
  const diffInMins = Math.ceil(diff / scaleFactor);
  return diffInMins;
};

export const addMinutes = (baseTime, numberOfMinutes) => {
  const newTime = baseTime + numberOfMinutes * 60 * 1000;
  return newTime;
};
