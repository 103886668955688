<template>
  <SavedViewEditedMenu
    @saveView="saveView"
    @discardChanges="SavingViewsDiscardChanges"
    :selectedViewId="SavingViewsGetActiveViewId"
    :isWorkingVersionSaved="isWorkingVersionSaved"
    :colour="$vuetify.theme.themes.light.ciOrange"
    :viewType="SavedViewTypes.MAP"
    dataCy="saved-view-edited-menu"
  />
</template>
<script>
import { cloneDeep, isEqual } from "lodash";

import { mapGetters, mapActions } from "vuex";
import { getters, actions } from "@S/savingView/types";
import updateView from "@/graphql/views/updateView";
import { convertToViewInputWithId } from "@/hooks/convertToViewInput";
import {
  areViewportsDifferent,
  areSelectedOptionsDifferent
} from "@C/savingView/SaveViewMenu/helpers";

import SavedViewEditedMenu from "@C/savingView/SaveViewMenu/SavedViewEditedMenu.vue";

import SavedViewTypes from "@/constants/SavedViewTypes";
import { apolloClient } from "@/plugins/apolloPlugin";

export default {
  name: "SaveMapViewMenu",
  components: {
    SavedViewEditedMenu
  },
  data: () => ({
    isWorkingVersionSaved: false,
    SavedViewTypes
  }),

  computed: {
    ...mapGetters([
      getters.SavingViewsGetWorkingVersion,
      getters.SavingViewsGetSavedVersion,
      getters.SavingViewsGetActiveViewName,
      getters.SavingViewsGetActiveViewId
    ])
  },
  watch: {
    SavingViewsGetWorkingVersion: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        const working = cloneDeep(newValue);
        const saved = cloneDeep(this.SavingViewsGetSavedVersion);

        if (
          areSelectedOptionsDifferent(
            working.optionsState.selected,
            saved.optionsState.selected
          )
        ) {
          this.isWorkingVersionSaved = false;
          return;
        }

        if (
          areViewportsDifferent(
            working.mapStyle.viewPort,
            saved.mapStyle.viewPort
          )
        ) {
          this.isWorkingVersionSaved = false;
          return;
        }

        delete working.mapStyle.viewPort;
        delete working.optionsState.selected;

        delete saved.mapStyle.viewPort;
        delete saved.optionsState.selected;

        if (isEqual(working, saved)) {
          this.isWorkingVersionSaved = true;
          return;
        } else {
          this.isWorkingVersionSaved = false;
          return;
        }
      }
    },
    SavingViewsGetSavedVersion: {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        if (isEqual(newValue, this.SavingViewsGetWorkingVersion)) {
          this.isWorkingVersionSaved = true;
        } else {
          this.isWorkingVersionSaved = false;
        }
      }
    }
  },

  methods: {
    ...mapActions([
      actions.SavingViewsDiscardChanges,
      actions.SavingViewsSetActiveView
    ]),
    saveView() {
      const input = convertToViewInputWithId({
        id: this.SavingViewsGetActiveViewId,
        obj: this.SavingViewsGetWorkingVersion,
        name: this.SavingViewsGetActiveViewName
      });

      apolloClient
        .mutate({
          mutation: updateView,
          variables: {
            input: input
          }
        })
        .then((response) => {
          if (response.errors) {
            throw response.errors;
          }
          this.SavingViewsSetActiveView(response.data.updateView);
        })
        .catch((error) => {
          console.error(
            "update mutation failed with errors: ",
            error.graphQLErrors || error
          );
          console.error("input was: ", input);
          alert("Error saving view");
        });
    }
  }
};
</script>

<style></style>
