import gql from "graphql-tag";

export default (ragId) => {
  return {
    mutation: gql`
      mutation deleteRAG($input: ID!) {
        deleteRAG(input: $input)
      }
    `,
    variables: { input: ragId }
  };
};
