import { InteractionStates } from "@/constants/InteractionStates";
import { THEME_COLOURS } from "@/mixins/themeColours";
import { groupBy } from "lodash";
import { _axios } from "@/plugins/axios";

import { clone } from "lodash";

const optionPathUrl = "/V2/option/";

//gets the new interaction state for an option being toggled. it toggles in the following order: normal -> selected -> normal | active -> selected
function inferNewInteractionState(optionID, activeOptionId, selectedOptionIds) {
  optionID = Number(optionID);
  if (optionID === Number(activeOptionId)) {
    return InteractionStates.SELECTED;
  }
  if (selectedOptionIds.includes(optionID)) {
    return InteractionStates.NORMAL;
  }
  return InteractionStates.SELECTED;
}

function addItemWithFifoLengthEnforcement(array, item, maxLength) {
  // Adds an element to an array, removing the first element if the array is at maxLength
  // Will not add an item that's already present

  //copy array here to avoid mutating the original array which causes problems with newValue, oldValue in watchers
  const newArray = clone(array);
  let removedItem = null;
  if (newArray.includes(item)) {
    return newArray;
  }
  if (newArray.length >= maxLength) {
    removedItem = newArray.shift();
  }
  newArray.push(item);
  return { newArray, removedItem };
}

const getDefaultColoursAvailable = (state) => {
  const currentlyAssignedColours = Object.values(state.colourMap);
  const availableThemeColours = THEME_COLOURS.filter(
    (colour) => !currentlyAssignedColours.includes(colour)
  );
  return availableThemeColours;
};

const colourIsInThemeColours = (colour) => {
  return THEME_COLOURS.includes(colour);
};

function updateMetricForDisplay(metric) {
  // Destructively update the metric object for display:
  //  - use Imperial units if necessary (from the valueWithUnit field, set on the backend)
  //  - set a human-friendly `displayValue` field (actually a name)
  if ("valueWithUnit" in metric) {
    metric.value = metric?.valueWithUnit?.value || metric?.value;
    metric.unit = metric?.valueWithUnit?.unit || metric?.unit;
  }
  metric.displayValue = convertMetricNameToDisplayValue(
    metric.name,
    metric.unit
  );
}

function convertMetricNameToDisplayValue(name, unit) {
  // Exported only for testing purposes - call this via updateMetricForDisplay
  // to ensure the name contains the right units.
  const wordified = name
    ?.split("_")
    ?.map(
      (partOfWord) =>
        partOfWord?.charAt(0)?.toUpperCase() + partOfWord?.slice(1)
    )
    .join(" ");
  if (unit && unit != "unknown") {
    const unit_removed = wordified.replace(/ \[.*\]$/, "");
    return `${unit_removed} [${unit}]`;
  } else {
    return wordified;
  }
}

const deleteOption = async (optionId) => {
  const url = `${optionPathUrl}${optionId}`;
  try {
    await _axios.delete(url);
    return true;
  } catch (err) {
    console.error("Error deleting option");
    console.error(err);
    return false;
  }
};

const getOptionsWithMetricValues = (state) => {
  return state?.selectedOptionIds?.map((optionId) => ({
    id: optionId,
    value: state?.options[optionId]?.optionMetrics || []
  }));
};

const mapOptionsWithMetricValues = (optionsWithMetricValues) => {
  return optionsWithMetricValues.flatMap(
    ({ value: metricValues, id: optionId }) =>
      metricValues.map((metricValue) => ({ ...metricValue, optionId }))
  );
};

const groupMetricsByName = (metrics) => {
  return groupBy(metrics, "name");
};

const formatGroupedMetrics = (groupedMetrics) => {
  return Object.entries(groupedMetrics).map(([name, values]) => ({
    name,
    values,
    displayValue: values[0]?.displayValue
  }));
};

export {
  inferNewInteractionState,
  addItemWithFifoLengthEnforcement,
  getDefaultColoursAvailable,
  colourIsInThemeColours,
  updateMetricForDisplay,
  convertMetricNameToDisplayValue,
  deleteOption,
  getOptionsWithMetricValues,
  mapOptionsWithMetricValues,
  groupMetricsByName,
  formatGroupedMetrics
};
