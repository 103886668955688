/**
 * Interface for classes that represent a Deferred.
 * @typedef {Object} DeferredBase
 * @property {function(): void} resolve Resolve the base Promise.
 * @property {function(): void} reject Reject the base Promise.
 * @property {function(): void} reset Reset the base Promise.
 */

/**
 * @typedef {DeferredBase & Promise} Deferred
 */

/**
 * @returns {Deferred}
 */
export default function defer() {
  let res;
  let rej;

  let promise = {};

  /** @return {Deferred} */
  const reset = () => {
    promise = new Promise((resolve, reject) => {
      res = resolve;
      rej = reject;
    });
    promise.resolve = res;
    promise.reject = rej;
    promise.reset = reset;
    return promise;
  };

  return reset();
}
