import { LAYERS } from "@/behaviours/map/features/feature-names";
import MapboxStyles from "@/constants/MapboxStyles";

const newEmptyViewsState = () => {
  return {
    availableViews: [],
    recentViews: [],
    activeView: createViewObject({ name: "" }),
    savedVersionUpdatedAt: null
  };
};

const createDefaultVersion = (payload) => {
  return {
    mapStyle: {
      viewPort: {
        lat: payload?.currentViewport?.lat ?? 0,
        lng: payload?.currentViewport?.lng ?? 0,
        zoomLevel: payload?.currentViewport?.zoomLevel ?? 0,
        bearing: payload?.currentViewport?.bearing ?? 0,
        pitch: payload?.currentViewport?.pitch ?? 0
      },
      showLayers: payload?.showLayers ?? [],
      baseMapStyle: MapboxStyles.OUTDOORS,
      threeD: {
        active: false,
        exaggerationFactor: 1
      },
      groupBy: "DATASET",
      activeRasterDatasetID: null,
      datasetLayerStyles: [],
      routePointStyles: [],
      lineStyles: [],
      polygonStyles: []
    },
    polygonResultsState: {
      styles: []
    },
    optionsState: {
      selected: [],
      styles: []
    },
    heatmapsState: {
      styles: {}
    }
  };
};
// This function creates an object that can be used as "default" state for saving views module
const createViewObject = (payload) => {
  return {
    id: "",
    name: payload.name,
    isLoading: false,
    savedVersion: createDefaultVersion(payload),
    workingVersion: createDefaultVersion(payload)
  };
};
// This function creates an object that can be used to create a new view - it shapes it to the format that the backend expects
const createDefaultViewInputObject = ({ name, projectId, currentViewport }) => {
  const layersWithOnByDefaultValues = LAYERS.asArray().filter(
    (layer) => layer.onByDefault !== undefined && layer.viewName !== undefined
  );
  const payload = { showLayers: [], currentViewport };

  const tempArray = [];

  layersWithOnByDefaultValues.forEach((element) => {
    tempArray.push({
      layer: element.viewName,
      show: element.onByDefault
    });
  });

  payload.showLayers = tempArray;

  return {
    name: name,
    projectID: projectId,
    ...createDefaultVersion(payload)
  };
};

export {
  newEmptyViewsState,
  createDefaultVersion,
  createViewObject,
  createDefaultViewInputObject
};
