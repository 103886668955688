<template>
  <svg
    id="LOGO_Monochrome_03"
    data-name="LOGO Monochrome 03"
    xmlns="http://www.w3.org/2000/svg"
    width="
    350"
    height="130"
    viewBox="0 0 900 130"
  >
    <path
      id="Path_248"
      data-name="Path 248"
      d="M410,80V0H390.1V51.4L354.9,0H330.2V80h20V29.1l36,50.9Z"
      fill="#fff"
    />
    <path
      id="Path_249"
      data-name="Path 249"
      d="M90,60V90H40V53A12.974,12.974,0,0,1,53,40H70V0H40A39.989,39.989,0,0,0,0,40v90H90a39.989,39.989,0,0,0,40-40V60Z"
      fill="#fff"
    />
    <path id="Path_250" data-name="Path 250" d="M130,0H90V40h40Z" fill="#fff" />
    <path
      id="Path_251"
      data-name="Path 251"
      d="M300,0H260a20.059,20.059,0,0,0-20,20V60a20.059,20.059,0,0,0,20,20h40a20.059,20.059,0,0,0,20-20V20A20.059,20.059,0,0,0,300,0Zm-8,60H268a8.024,8.024,0,0,1-8-8V28a8.024,8.024,0,0,1,8-8h24a8.024,8.024,0,0,1,8,8V52A8.024,8.024,0,0,1,292,60Z"
      fill="#fff"
    />
    <path
      id="Path_252"
      data-name="Path 252"
      d="M520,0H500V80h20Z"
      fill="#fff"
    />
    <path
      id="Path_253"
      data-name="Path 253"
      d="M680,0V60H648a8.024,8.024,0,0,1-8-8V0H620V60a20.059,20.059,0,0,0,20,20h60V0Z"
      fill="#fff"
    />
    <path
      id="Path_254"
      data-name="Path 254"
      d="M900,0V80H880V28.5L860,80H840L820,28.5V80H800V0h29.9L850,45.7,870.1,0"
      fill="#fff"
    />
    <path
      id="Path_255"
      data-name="Path 255"
      d="M495,0H415V20h30V80h20V20h30Z"
      fill="#fff"
    />
    <path
      id="Path_256"
      data-name="Path 256"
      d="M610,80V0H590.1V51.4L554.9,0H530.2V80h20V29.1l36,50.9Z"
      fill="#fff"
    />
    <path
      id="Path_257"
      data-name="Path 257"
      d="M230,20V0H170a20.059,20.059,0,0,0-20,20V80h80V60H170V28a8.024,8.024,0,0,1,8-8Z"
      fill="#fff"
    />
    <path
      id="Path_258"
      data-name="Path 258"
      d="M804.3,114v-8H783V98h30V90H773v40h40v-8H783v-8Z"
      fill="#fff"
    />
    <path
      id="Path_259"
      data-name="Path 259"
      d="M724,90H714v40h10Z"
      fill="#fff"
    />
    <path
      id="Path_260"
      data-name="Path 260"
      d="M900,98V90H868a8.024,8.024,0,0,0-8,8v8a8.024,8.024,0,0,0,8,8h19a2.946,2.946,0,0,1,3,3v2a2.946,2.946,0,0,1-3,3H860v8h32a8.024,8.024,0,0,0,8-8v-8a8.024,8.024,0,0,0-8-8H873a2.946,2.946,0,0,1-3-3v-2a2.946,2.946,0,0,1,3-3Z"
      fill="#fff"
    />
    <path
      id="Path_261"
      data-name="Path 261"
      d="M251,130V90H241v25.7L223.4,90H211.1v40h10V104.6l18,25.4Z"
      fill="#fff"
    />
    <path
      id="Path_262"
      data-name="Path 262"
      d="M328.2,90h-30v40h30a10.029,10.029,0,0,0,10-10V100A10.029,10.029,0,0,0,328.2,90Zm-5,30h-15V100h15a4.951,4.951,0,0,1,5,5v10A5.015,5.015,0,0,1,323.2,120Z"
      fill="#fff"
    />
    <path
      id="Path_263"
      data-name="Path 263"
      d="M160,90H150v40h10Z"
      fill="#fff"
    />
    <path
      id="Path_264"
      data-name="Path 264"
      d="M411.3,90v30h-17a2.946,2.946,0,0,1-3-3V90h-10v30a10.029,10.029,0,0,0,10,10h30V90Z"
      fill="#fff"
    />
    <path
      id="Path_265"
      data-name="Path 265"
      d="M591.6,90h-40v10h15v30h10V100h15Z"
      fill="#fff"
    />
    <path
      id="Path_266"
      data-name="Path 266"
      d="M662.7,90h-30v40h10V115h10l10,15h10l-10-15a10.029,10.029,0,0,0,10-10v-5A10.029,10.029,0,0,0,662.7,90Zm-4,16.5h-16v-8h16a4,4,0,0,1,0,8Z"
      fill="#fff"
    />
    <path
      id="Path_267"
      data-name="Path 267"
      d="M507.4,98V90h-32a8.024,8.024,0,0,0-8,8v8a8.024,8.024,0,0,0,8,8h19a2.946,2.946,0,0,1,3,3v2a2.946,2.946,0,0,1-3,3h-27v8h32a8.024,8.024,0,0,0,8-8v-8a8.024,8.024,0,0,0-8-8h-19a2.946,2.946,0,0,1-3-3v-2a2.946,2.946,0,0,1,3-3Z"
      fill="#fff"
    />
    <path
      id="Path_268"
      data-name="Path 268"
      d="M769.7,0V60h-32a8.024,8.024,0,0,1-8-8V0h-20V60a20.059,20.059,0,0,0,20,20h60V0Z"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: "ContinuumLogoWhite"
};
</script>
