export default function getDescendents(listOfObjects, parentId) {
  const descendents = [];
  const parentProperty = listOfObjects?.find((param) => {
    return param?.id == parentId;
  });
  parentProperty.children?.map((childId) => {
    descendents.push(...getDescendents(listOfObjects, childId));
  });
  descendents.push(parentId);

  return descendents;
}
