import gql from "graphql-tag";

export default gql`
  mutation CreateNewMetricsViewReturnMetrics($input: CreateViewInput!) {
    createView(input: $input) {
      id
      projectID
      name
      createdAt
      updatedAt
      selectedOptionMetrics {
        name
        weight
      }
      optionComparisonView {
        verticalProfileView {
          selectedPPQs
          selectedChartType
          mapLinked
        }
      }
    }
  }
`;
