<template>
  <v-dialog :value="logoutWarningShowing" width="30%" persistent>
    <v-card data-cy="sessionTimeoutCard">
      <v-card-title> Session Timeout </v-card-title>
      <v-card-text>
        <span v-if="sessionExpiresBeforeIdleLogout">
          Your session will expire in
        </span>
        <span v-else> You will be logged out due to inactivity in </span>
        <span v-if="lessThanMinuteRemaining">
          {{ secondsBeforeLogout }} seconds
        </span>
        <span v-else> {{ minutesBeforeLogout }} minutes </span>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="signout"
          data-cy="sessionTimeoutLogoutButton"
          color="secondary"
          text
        >
          Logout
        </v-btn>
        <v-btn
          @click="stayLoggedIn"
          data-cy="stayLoggedInButton"
          color="primary"
          text
        >
          Stay logged in
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import attachWindowActivityHandlers from "@/helpers/attachWindowActivityHandlers";
import { getters, mutations } from "@S/session/types";
import {
  addMinutes,
  calculateTimeDiffWithConversionFactor
} from "@/helpers/dateTimeArithmetic";
import { mapGetters, mapMutations } from "vuex";
import {
  loginWithPopup,
  updateAccessTokenExpiryTime,
  logout
} from "@/plugins/authPlugin";

export default {
  name: "SessionTimeoutDialog",
  props: {
    idleTimeBeforeLogoutInMinutes: {
      required: false,
      type: Number,
      default: 120
    },
    timeFromWarningToLogoutInMinutes: {
      required: false,
      type: Number,
      default: 15
    }
  },
  data() {
    return {
      initialCreationTime: null,
      currentTime: null,
      currentTimeTrackerInterval: null
    };
  },
  async created() {
    this.initialCreationTime = Date.now();
    this.currentTime = Date.now();
    this.currentTimeTrackerInterval = setInterval(this.updateCurrentTime, 5000);
    attachWindowActivityHandlers(this.handleWindowActivity);
  },
  computed: {
    ...mapGetters([getters.GetSessionExpiry, getters.GetSessionAccessExpired]),
    sessionExpiresBeforeIdleLogout() {
      if (!this.GetSessionExpiry) {
        return false;
      }
      return this.GetSessionExpiry < this.idleLogoutTime;
    },
    idleLogoutTime() {
      if (typeof this.initialCreationTime !== "number") {
        return null;
      }
      const idleLogoutTime = addMinutes(
        this.initialCreationTime,
        this.idleTimeBeforeLogoutInMinutes
      );
      return idleLogoutTime;
    },
    logoutTime() {
      return this.sessionExpiresBeforeIdleLogout
        ? this.GetSessionExpiry
        : this.idleLogoutTime;
    },
    minutesBeforeLogout() {
      return calculateTimeDiffWithConversionFactor(
        this.logoutTime,
        this.currentTime,
        1000 * 60
      );
    },
    logoutWarningShowing() {
      return this.minutesBeforeLogout <= this.timeFromWarningToLogoutInMinutes;
    },
    lessThanMinuteRemaining() {
      return this.minutesBeforeLogout <= 1;
    },
    secondsBeforeLogout() {
      return calculateTimeDiffWithConversionFactor(
        this.logoutTime,
        this.currentTime,
        1000
      );
    }
  },
  beforeDestroy() {
    clearInterval(this.currentTimeTrackerInterval);
  },
  methods: {
    ...mapMutations([mutations.SetSessionAccessExpired]),
    updateCurrentTime() {
      this.currentTime = Date.now();
    },
    handleWindowActivity() {
      // Calculate the time diff at point of activity, in case the allotted time for showing the warning
      // Has elapsed, but user interacts before warning is shown
      const timeAtPointOfActivity = Date.now();
      const diffInMins = calculateTimeDiffWithConversionFactor(
        this.logoutTime,
        timeAtPointOfActivity,
        1000 * 60
      );
      if (
        this.logoutWarningShowing ||
        diffInMins <= this.timeFromWarningToLogoutInMinutes
      ) {
        return;
      }

      // If dialog not showing, any activity should reset the logout countdown
      this.resetCreationTime();
    },
    resetCreationTime() {
      this.initialCreationTime = Date.now();
    },
    async stayLoggedIn() {
      try {
        if (this.GetSessionAccessExpired) {
          console.log("session access expired, logging in with popup");
          await loginWithPopup();
        } else {
          console.log("session access valid, updating token expiry time");
          await updateAccessTokenExpiryTime();
        }

        console.log("resetting session access expired flag");
        this.SetSessionAccessExpired(false);
      } catch (err) {
        console.error("error staying logged in", err);
        return;
      }
      this.resetCreationTime();
    },
    signout() {
      logout({
        federated: true,
        returnTo: window.location.origin
      });
    }
  },
  watch: {
    async currentTime() {
      if (this.minutesBeforeLogout <= 0) {
        this.signout();
      } else if (
        this.sessionExpiresBeforeIdleLogout &&
        this.minutesBeforeLogout <= 20 &&
        !this.GetSessionAccessExpired
      ) {
        console.log("refreshing session token automatically");
        try {
          await updateAccessTokenExpiryTime();
        } catch (err) {
          console.error("error updating access token expiry", err);
        }
      }
    }
  }
};
</script>

<style></style>
