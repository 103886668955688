import { BaseTypes } from "@S/base_types";

const getters = {
  MapGetEditSpaceCollapsed: "MapGetEditSpaceCollapsed",
  MapGetClickOutsideProjectBoundries: "MapGetClickOutsideProjectBoundries",
  MapGetMoveOutsideProjectBoundries: "MapGetMoveOutsideProjectBoundries",
  MapGetSetupPointByName: "MapGetSetupPointByName",
  MapGetSetupPointById: "MapGetSetupPointById",
  MapGetSetupPoints: "MapGetSetupPoints",
  MapGetSetupPointsAsArray: "MapGetSetupPointsAsArray",

  MapGetEntities: "MapGetEntities",
  MapGetSelectedMapPoint: "MapGetSelectedMapPoint",
  MapGetSelectedCases: "MapGetSelectedCases",
  GetMapEventBus: "GetMapEventBus",

  MapGetPointLocationData: "MapGetPointLocationData",

  AreRoutePointLabelsVisible: "AreRoutePointLabelsVisible",
  AreLineLabelsVisible: "AreLineLabelsVisible",
  ArePolygonLabelsVisible: "ArePolygonLabelsVisible",

  IsMapBeingExported: "IsMapBeingExported",
  GetExportMapScaleBar: "GetExportMapScaleBar",
  GetMapExportViewportGeojson: "GetMapExportViewportGeojson",
  GetGoogleMaps3DTilesAttribution: "GetGoogleMaps3DTilesAttribution"
};

const mutations = {
  MapSetEditSpaceCollapsed: "MapSetEditSpaceCollapsed",
  MapCreateSetupPoint: "MapCreateSetupPoint",
  MapUpdateSetupPoint: "MapUpdateSetupPoint",
  MapUpdateSetupPointType: "MapUpdateSetupPointType",
  MapUpdateSetupPointArrayByDragging: "MapUpdateSetupPointArrayByDragging",
  MapRemoveSetupPointById: "MapRemoveSetupPointById",
  MapRemoveHorizontalProfilePoints: "MapRemoveHorizontalProfilePoints",
  ClearSetupPoints: "ClearSetupPoints",

  MapAddEntity: "MapAddEntity",
  MapUpdateEntity: "MapUpdateEntity",
  MapDeleteEntity: "MapDeleteEntity",
  MapUpdateSelectedMapPoint: "MapUpdateSelectedMapPoint",
  MapUpdateSelectedMapFeature: "MapUpdateSelectedMapFeature",
  MapSetSelectedCases: "MapSetSelectedCases",
  MapSetClickOutsideProjectBoundries: "MapSetClickOutsideProjectBoundries",
  MapSetMoveOutsideProjectBoundries: "MapSetMoveOutsideProjectBoundries",

  MapSetPointLocationData: "MapSetPointLocationData",

  SetRoutePointLabelsVis: "SetRoutePointLabelsVis",
  SetLineLabelsVis: "SetLineLabelsVis",
  SetPolygonLabelsVis: "SetPolygonLabelsVis",

  SetMapBeingExported: "SetMapBeingExported",
  SetMapExportScaleBar: "SetMapExportScaleBar",
  SetMapExportViewportGeojson: "SetMapExportViewportGeojson",
  SetGoogleMaps3DTilesAttribution: "SetGoogleMaps3DTilesAttribution"
};

const actions = {
  MapSetEditSpaceCollapsed: "MapSetEditSpaceCollapsed",
  MapAddSetupPoint: "MapAddSetupPoint",
  MapUpdateSetupPoint: "MapUpdateSetupPoint",
  MapUpdateSetupPointType: "MapUpdateSetupPointType",
  MapUpdateSetupPointArrayByDragging: "MapUpdateSetupPointArrayByDragging",
  MapRemoveSetupPointById: "MapRemoveSetupPointById",
  MapRemoveHorizontalProfilePoints: "MapRemoveHorizontalProfilePoints",
  ClearSetupPoints: "ClearSetupPoints",

  MapAddDrawnObject: "MapAddDrawnObject",
  MapUpdateDrawnObject: "MapUpdateDrawnObject",
  MapDeleteDrawnObjectById: "MapDeleteDrawnObjectById",
  MapUpdateSelectedMapPoint: "MapUpdateSelectedMapPoint",
  MapUpdateSelectedMapFeature: "MapUpdateSelectedMapFeature",
  MapSetSelectedCases: "MapSetSelectedCases",
  MapSetClickOutsideProjectBoundries: "MapSetClickOutsideProjectBoundries",
  MapSetMoveOutsideProjectBoundries: "MapSetMoveOutsideProjectBoundries",
  MapSetPointLocationData: "MapSetPointLocationData",
  SetGoogleMaps3DTilesAttribution: "SetGoogleMaps3DTilesAttribution"
};

/**
 * @augments BaseTypes
 */
export class MapTypes extends BaseTypes {}
MapTypes.getters = getters;
MapTypes.mutations = mutations;
MapTypes.actions = actions;

export { getters, mutations, actions };
