const getters = {
  SavingViewsGetGisPanelSwitches: "SavingViewsGetGisPanelSwitches",
  SavingViewsGetVisibleVectorLayers: "SavingViewsGetVisibleVectorLayers",
  SavingViewsGetGroupVectorLayersBy: "SavingViewsGetGroupVectorLayersBy",
  SavingViewsGetBasemapStyle: "SavingViewsGetBasemapStyle",
  SavingViewsGetIsThreeDimensional: "SavingViewsGetIsThreeDimensional",
  SavingViewsGetThreeDimensionalExaggerationFactor:
    "SavingViewsGetThreeDimensionalExaggerationFactor",
  SavingViewsGetActiveViewName: "SavingViewsGetActiveViewName",
  SavingViewsGetAvailableViews: "SavingViewsGetAvailableViews",
  SavingViewsGetWorkingVersion: "SavingViewsGetWorkingVersion",
  SavingViewsGetSavedVersion: "SavingViewsGetSavedVersion",
  SavingViewsGetActiveViewId: "SavingViewsGetActiveViewId",
  SavingViewsGetOptionStyles: "SavingViewsGetOptionStyles",
  SavingViewsGetDataLayers: "SavingViewsGetDataLayers",
  SavingViewsGetSavedDataLayers: "SavingViewsGetSavedDataLayers",
  SavingViewsGetViewPort: "SavingViewsGetViewPort",
  SavingViewsGetSavedViewPort: "SavingViewsGetSavedViewPort",
  SavingViewsGetRoutePointStyles: "SavingViewsGetRoutePointStyles",
  SavingViewsGetLineStyles: "SavingViewsGetLineStyles",
  SavingViewsGetSavedVersionUpdatedAt: "SavingViewsGetSavedVersionUpdatedAt",
  SavingViewsGetPolygonStyles: "SavingViewsGetPolygonStyles",
  SavingViewsGetSavedGisPanelSwitches: "SavingViewsGetSavedGisPanelSwitches",
  SavingViewsGetVisiblePointsIds: "SavingViewsGetVisiblePointsIds",
  SavingViewsGetVisibleLinesIds: "SavingViewsGetVisibleLinesIds",
  SavingViewsGetVisiblePolygonsIds: "SavingViewsGetVisiblePolygonsIds",
  SavingViewsGetWorkingVersionActiveRasterLayer:
    "SavingViewsGetWorkingVersionActiveRasterLayer",
  SavingViewsGetSavedVersionActiveRasterLayer:
    "SavingViewsGetSavedVersionActiveRasterLayer",
  SavingViewGetVisiblePolygonResultsIdSet:
    "SavingViewGetVisiblePolygonResultsIdSet",
  SavingViewGetHeatmapVisibility: "SavingViewGetHeatmapVisibility"
};

const mutations = {
  SavingViewsRenameView: "SavingViewsRenameView",
  SavingViewsSetActiveRasterLayer: "SavingViewsSetActiveRasterLayer",
  SavingViewsSetPolygonStyle: "SavingViewsSetPolygonStyle",
  SavingViewsSetPolygonVisibility: "SavingViewsSetPolygonVisibility",
  SavingViewsSetLinesStyle: "SavingViewsSetLinesStyle",
  SavingViewsSetLinesVisibility: "SavingViewsSetLinesVisibility",
  SavingViewsSetRoutePointStyle: "SavingViewsSetRoutePointStyle",
  SavingViewsSetRoutePointVisibility: "SavingViewsSetRoutePointVisibility",
  SavingViewsSetGisPanelSwitch: "SavingViewsSetGisPanelSwitch",
  SavingViewsSetGroupVectorLayersBy: "SavingViewsSetGroupVectorLayersBy",
  SavingViewsSetBasemapStyle: "SavingViewsSetBasemapStyle",
  SavingViewsSetIsThreeDimensional: "SavingViewsSetIsThreeDimensional",
  SavingViewsSetThreeDimensionalExaggerationFactor:
    "SavingViewsSetThreeDimensionalExaggerationFactor",
  SavingViewsResetState: "SavingViewsResetState",
  SavingViewsSetActiveViewName: "SavingViewsSetActiveViewName",
  SavingViewsSetAvailableViews: "SavingViewsSetAvailableViews",
  SavingViewsSetActiveView: "SavingViewsSetActiveView",
  SavingViewsAddAvailableView: "SavingViewsAddAvailableView",
  SavingViewsDiscardChanges: "SavingViewsDiscardChanges",
  SavingViewsRemoveAvailableView: "SavingViewsRemoveAvailableView",
  SavingViewsUpdateSavedVersion: "SavingViewsUpdateSavedVersion",
  SavingViewsSetViewPort: "SavingViewsSetViewPort",
  SavingViewsSetActiveOption: "SavingViewsSetActiveOption",
  SavingViewsSetOptionsState: "SavingViewsSetOptionsState",
  SavingViewSetPolygonResultVisibility: "SavingViewSetPolygonResultVisibility",
  SavingViewSetHeatmapVisibility: "SavingViewSetHeatmapVisibility"
};

const actions = {
  SavingViewsSetActiveRasterLayer: "SavingViewsSetActiveRasterLayer",
  SavingViewsSetPolygonStyle: "SavingViewsSetPolygonStyle",
  SavingViewsSetPolygonVisibility: "SavingViewsSetPolygonVisibility",
  SavingViewsSetLinesStyle: "SavingViewsSetLinesStyle",
  SavingViewsSetLinesVisibility: "SavingViewsSetLinesVisibility",
  SavingViewsSetRoutePointStyle: "SavingViewsSetRoutePointStyle",
  SavingViewsSetRoutePointVisibility: "SavingViewsSetRoutePointVisibility",
  SavingViewsSetGisPanelSwitch: "SavingViewsSetGisPanelSwitch",
  SavingViewsSetGroupVectorLayersBy: "SavingViewsSetGroupVectorLayersBy",
  SavingViewsSetBasemapStyle: "SavingViewsSetBasemapStyle",
  SavingViewsSetIsThreeDimensional: "SavingViewsSetIsThreeDimensional",
  SavingViewsSetThreeDimensionalExaggerationFactor:
    "SavingViewsSetThreeDimensionalExaggerationFactor",
  SavingViewsResetState: "SavingViewsResetState",
  SavingViewsSetActiveViewName: "SavingViewsSetActiveViewName",
  SavingViewsSetAvailableViews: "SavingViewsSetAvailableViews",
  SavingViewsSetActiveView: "SavingViewsSetActiveView",
  SavingViewsAddAvailableView: "SavingViewsAddAvailableView",
  SavingViewsDiscardChanges: "SavingViewsDiscardChanges",
  SavingViewsRemoveAvailableView: "SavingViewsRemoveAvailableView",
  SavingViewsUpdateSavedVersion: "SavingViewsUpdateSavedVersion",
  SavingViewsSetViewPort: "SavingViewsSetViewPort",
  SavingViewsSetOptionsState: "SavingViewsSetOptionsState",
  SavingViewSetPolygonResultVisibility: "SavingViewSetPolygonResultVisibility",
  SavingViewSetHeatmapVisibility: "SavingViewSetHeatmapVisibility"
};

export { getters, mutations, actions };
const types = { getters, mutations, actions };
export default types;
