// CONVERT HEXA ("#FF00FFFF") TO RGBA ("rgba(255, 0, 255, 1)") STRINGS

export const hexToRgba = (hexa) => {
  const r = parseInt(hexa.substring(1, 3), 16);
  const g = parseInt(hexa.substring(3, 5), 16);
  const b = parseInt(hexa.substring(5, 7), 16);
  let a = parseInt(hexa.substring(7, 9), 16) / 255;

  if (isNaN(a)) {
    a = 1;
  }

  const rgba = `rgba(${r}, ${g}, ${b}, ${a})`;

  return rgba;
}

export const rgba2hex = (rgba) =>
  `#${rgba
    .match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)
    .slice(1)
    .map((n, i) =>
      (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n))
        .toString(16)
        .padStart(2, "0")
        .replace("NaN", "")
    )
    .join("")}`;
