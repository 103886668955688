import { hashCode } from "@/hooks/hash-code";
import { InteractionStates } from "@/constants/InteractionStates";

export default class Polygon {
  id = null;
  name = null;
  categoryId = 0;
  categoryName = null;
  description = null;
  coords = null;
  interactionState = InteractionStates.NORMAL;
  createdBy = null;
  owner_nickname = null;
  created = null;
  updatedAt = null;
  type = null;
  activeColour = "";
  activeOutlineColour = "";
  activeOpacity = "";

  toGeoJsonFeature() {
    return {
      type: "Feature",
      id: hashCode(`${this.id}`),
      geometry: {
        type: "Polygon",
        coordinates: this.coords
          ? [this.coords?.map((coord) => [coord.lng, coord.lat])]
          : []
      },
      properties: {
        id: this.id,
        name: this.name,
        categoryId: this.categoryId,
        categoryName: this.categoryName,
        description: this.description,
        interactionState: this.interactionState,
        createdBy: this.createdBy,
        owner_nickname: this.owner_nickname,
        created: this.created,
        updatedAt: this.updatedAt,
        type: this.type,
        activeColour: this.activeColour,
        activeOutlineColour: this.activeOutlineColour,
        activeOpacity: this.activeOpacity
      }
    };
  }

  createGeoJsonForExport() {
    return {
      type: "Feature",
      id: hashCode(`${this.id}`),
      geometry: {
        type: "Polygon",
        coordinates: this.coords
          ? [this.coords?.map((coord) => [coord.lng, coord.lat])]
          : []
      },
      properties: {
        id: this.id,
        name: this.name,
        description: this.description,
        createdBy: this.createdBy,
        owner_nickname: this.owner_nickname,
        created: this.created,
        updatedAt: this.updatedAt,
        type: this.type
      }
    };
  }

  constructor(
    id,
    _name,
    categoryId,
    categoryName,
    description,
    coords,
    loadingState,
    interactionState,
    createdBy,
    owner_nickname,
    created,
    updatedAt,
    type,
    activeColour,
    activeOutlineColour,
    activeOpacity
  ) {
    this.id = id;
    this.name = _name;
    this.categoryId = categoryId;
    this.categoryName = categoryName;
    this.description = description;
    this.coords = coords;
    this.loadingState = loadingState;
    this.interactionState = interactionState;
    this.createdBy = createdBy;
    this.owner_nickname = owner_nickname;
    this.created = created;
    this.updatedAt = updatedAt;
    this.type = type;
    this.activeColour = activeColour;
    this.activeOutlineColour = activeOutlineColour;
    this.activeOpacity = activeOpacity;
  }
}
