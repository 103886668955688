<script>
import { debounce, isEqual } from "lodash";

export default {
  name: "OptionState2SavedView",
  render() {
    return null;
  },
  props: {
    OptionsGetVisibleIds: {
      type: Array,
      required: true
    },
    OptionsGetActiveId: {
      type: [String, Number],
      required: false //if no option is active
    },
    OptionsGetSelectedIds: {
      type: Array,
      required: true
    },
    GetOptionColour: {
      type: Function,
      required: true
    },
    OptionsGetAllColours: {
      type: Object,
      required: true
    }
  },
  emits: ["SavingViewsSetOptionsState"],
  methods: {
    SavingViewsSetOptionsState(payload) {
      this.$emit("SavingViewsSetOptionsState", payload);
    },
    updateWorkingVersionOfSavedViewIfNeeded: debounce(function () {
      //prevents the view from updating multiple times when active, selected, colouts are set at the same time
      const optionsState = this.createOptionsStateObject();
      this.SavingViewsSetOptionsState(optionsState);
    }, 100),
    createOptionsStateObject() {
      const allOptionsToSave = new Set(this.OptionsGetVisibleIds);
      Object.keys(this.OptionsGetAllColours).forEach((id) => {
        id = Number(id);
        if (id) {
          allOptionsToSave.add(id);
        }
      });

      const visibleIds = new Set(this.OptionsGetVisibleIds);
      const styles = Array.from(allOptionsToSave).map((id) => {
        return {
          assetId: Number(id),
          asset: "OPTION",
          fillColour: this.GetOptionColour(id),
          isVisible: visibleIds.has(id)
        };
      });

      const optionsState = {
        activeId: Number(this.OptionsGetActiveId),
        selected: this.OptionsGetSelectedIds,
        styles: styles
      };
      return optionsState;
    }
  },
  watch: {
    OptionsGetActiveId(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.updateWorkingVersionOfSavedViewIfNeeded();
    },
    OptionsGetSelectedIds: {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue === oldValue) return;
        this.updateWorkingVersionOfSavedViewIfNeeded();
      }
    },
    OptionsGetAllColours: {
      deep: true,
      handler: function () {
        if (!this.OptionsGetSelectedIds.length) return;
        this.updateWorkingVersionOfSavedViewIfNeeded();
      }
    },
    OptionsGetVisibleIds: {
      deep: true,
      handler: function (newValue, oldValue) {
        if (isEqual(newValue, oldValue)) return;
        this.updateWorkingVersionOfSavedViewIfNeeded();
      }
    }
  }
};
</script>

<style></style>
