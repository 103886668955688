class Types {
  static getters = {
    OptionVisGetSelectionGrouping: "OptionVisGetSelectionGrouping",

    OptionVisGetIconVisibility: "OptionVisGetIconVisibility",
    OptionVisGetVisibleIconCombinations: "OptionVisGetVisibleIconCombinations",

    OptionVisGetCounterVisibility: "OptionVisGetCounterVisibility",
    OptionVisGetVisibleCounterCombinations:
      "OptionVisGetVisibleCounterCombinations",

    OptionVisGetVisibleCountersByOptionId:
      "OptionVisGetVisibleCountersByOptionId",
    OptionVisGetPolygonVisibility: "OptionVisGetPolygonVisibility",
    OptionVisGetPolygonVisibilityCombinations:
      "OptionVisGetPolygonVisibilityCombinations"
  };
  static mutations = {
    OptionVisSetSelectionGrouping: "OptionVisSetSelectionGrouping",
    OptionVisSetIconVisibility: "OptionVisSetIconVisibility",
    OptionVisSetCounterVisibility: "OptionVisSetCounterVisibility",
    OptionVisSetPolygonVisibility: "OptionVisSetPolygonVisibility"
  };
  static actions = {
    OptionVisSetSelectionGrouping: "OptionVisSetSelectionGrouping",
    OptionVisSetCounterVisibility: "OptionVisSetCounterVisibility"
  };
}

export default Types;
export { Types as OptionVisualisationTypes };
