import { BaseTypes } from "@S/base_types";

const getters = {
  SessionWaitingForPermissions: "SessionWaitingForPermissions",
  SessionGetSelectedOptions: "SessionGetSelectedOptions",
  SessionGetActiveOption: "SessionGetActiveOption",
  SessionGetStatus: "SessionGetStatus",
  SessionGetProjectIds: "SessionGetProjectIds",
  SessionGetActiveProjectId: "SessionGetActiveProjectId",
  SessionHasActiveProject: "SessionHasActiveProject",
  SessionCheckAuthorised: "SessionCheckAuthorised",
  SessionGetVisibleVectorLayerIds: "SessionGetVisibleVectorLayerIds",
  SessionGetActiveRasterDatasets: "SessionGetActiveRasterDatasets",

  SessionGetUsers: "SessionGetUsers",
  SessionGetDatasetUploads: "SessionGetDatasetUploads",

  GetSessionExpiry: "GetSessionExpiry",
  GetSessionAccessExpired: "GetSessionAccessExpired",
  SessionShouldLoadAsset: "SessionShouldLoadAsset",
  SessionLowbandWithModeActive: "SessionLowbandWithModeActive"
};
const mutations = {
  SessionLoading: "SessionLoading",
  SessionLoaded: "SessionLoaded",
  SessionAddProjectIds: "SessionAddProjectIds",
  SessionDeleteProjectById: "SessionDeleteProjectById",
  SessionSelectProjectById: "SessionSelectProjectById",
  SessionSetPermissionsForUser: "SessionSetPermissionsForUser",
  SessionSetVisibleVectorLayerIds: "SessionSetVisibleVectorLayerIds",
  SessionSetVectorLayerVisibility: "SessionSetVectorLayerVisibility",
  SessionSetActiveRasterDatasets: "SessionSetActiveRasterDatasets",

  SessionAddOnProjectExecution: "SessionAddOnProjectExecution",
  SessionUserUpdate: "SessionUserUpdate",
  SessionSetDatasetUploads: "SessionSetDatasetUploads",
  SessionRemoveDatasetUpload: "SessionRemoveDatasetUpload",

  SetSessionExpiry: "SetSessionExpiry",
  SetSessionAccessExpired: "SetSessionAccessExpired",

  SessionSetLowBandwidthMode: "SessionSetLowBandwidthMode",
  SessionSetLoadAsset: "SessionSetLoadAsset"
};
const actions = {
  SessionSetSelectedOptionsMetrics: "SessionSetSelectedOptionsMetrics",
  SessionCreateProject: "SessionCreateProject",
  SessionDeleteProjectById: "SessionDeleteProjectById",
  SessionSelectProjectById: "SessionSelectProjectById",
  SessionFetchProjectIdsForUser: "SessionFetchProjectIdsForUser",
  SessionSetVisibleVectorLayerIds: "SessionSetVisibleVectorLayerIds",
  SessionSetActiveRasterDatasets: "SessionSetActiveRasterDatasets",

  SessionExecuteWhenProjectIsActive: "SessionExecuteWhenProjectIsActive",
  SessionAddUserDetails: "SessionAddUserDetails",
  SessionFetchUserDetails: "SessionFetchUserDetails",
  SessionSetDatasetUploads: "SessionSetDatasetUploads",
  SessionRemoveDatasetUpload: "SessionRemoveDatasetUpload",

  SessionSetLowBandwidthMode: "SessionSetLowBandwidthMode",
  SessionSetLoadAsset: "SessionSetLoadAsset"
};

/**
 * @summary The Session modules Types class.
 * @description This holds all the definitions for the types used for getters, mutations and actions for the Session module.
 * @augments BaseTypes
 */
export default class SessionTypes extends BaseTypes {}
SessionTypes.getters = getters;
SessionTypes.mutations = mutations;
SessionTypes.actions = actions;

export { getters, mutations, actions };
