const names = {
  None: "None",
  RoutePointPlacementTool: "RoutePointPlacementTool",
  HorizontalProfilePlacementTool: "HorizontalProfilePlacementTool",
  SetupPointPlacementTool: "SetupPointPlacementTool",
  MeasureTool: "MeasureTool",
  CorridorsTool: "CorridorsTool",
  CommentPlacementTool: "CommentPlacementTool",
  ConfigSelectionTool: "ConfigSelectionTool",
  ConfigComparisonTool: "ConfigComparisonTool",
  PolygonPlacementTool: "PolygonPlacementTool",
  OptionFlyoverTool: "OptionFlyoverTool"
};

export default Object.freeze(names);
