import convertComponentSettings from "@/hooks/convertComponentSettings";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { checkKeyValueListIsDirty } from "./helpers";

function setComponentSettings(state, configId, componentSettings) {
  if (!componentSettings) {
    return;
  }
  const converted = convertComponentSettings.toObject(componentSettings);

  if (getDirtyComponentsForConfig(state.configs[configId]).length == 0) {
    Vue.set(
      state.configs[configId],
      "componentSettingsEditedMapping",
      cloneDeep(converted)
    );
  }
  Vue.set(state.configs[configId], "componentSettingsMapping", cloneDeep(converted));

  if (componentSettings?.design_rules?.length > 0) {
    Vue.set(state.configs[configId], "newParametersSchema", true);
  }
}

function getDirtyComponentsForConfig(config) {
  if (!config || !config.componentSettingsMapping) {
    return [];
  }
  const dirtyComponents = [];
  Object.keys(config.componentSettingsMapping).forEach((componentName) => {
    let parametersHaveChanged = false;
    //if parameters are found to have been changed, check if the changes are only due to the key_value_list
    const parameters =
      config?.componentSettingsMapping[componentName]?.parameters;
    const editedParameters =
      config?.componentSettingsEditedMapping[componentName]?.parameters;

    for (const key of Object.keys(parameters)) {
      if (parameters[key].input_type == "key_value_list") {
        if (checkKeyValueListIsDirty(parameters[key], editedParameters[key])) {
          parametersHaveChanged = true;
          break;
        }
      } else if (!isEqual(parameters[key].value, editedParameters[key].value)) {
        parametersHaveChanged = true;
        break;
      }
    }

    const activeHasChanged =
      config?.componentSettingsMapping[componentName]?.active !==
      config?.componentSettingsEditedMapping[componentName]?.active;
    if (parametersHaveChanged || activeHasChanged) {
      dirtyComponents.push({
        ...config.componentSettingsMapping[componentName],
        isDirty: true
      });
    }
  });

  return dirtyComponents;
}

export { setComponentSettings, getDirtyComponentsForConfig };
