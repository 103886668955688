import { actions, mutations, getters as CommentGetters } from "./types";
import { InteractionStates } from "@/constants/InteractionStates";
import LoadingStates from "@/constants/LoadingStates";
import SessionTypes from "@S/session/types";

import { getMostRecentReplyDateAndCount } from "@/hooks/updateCommentsReplyCount";
import { status } from "@C/comments/attachments/consts";
import { sorters as commentSorters } from "@/constants/CommentConsts";

const convertStringsToNumbers = (stringArray) =>
  stringArray.map((entry) => Number(entry));

const getDefaultSort = () => ({
  direction: commentSorters?.directions?.ascending,
  name: commentSorters?.fields?.dateRecentComment?.name
});
export default {
  state: {
    comments: {
      // Check README.md for the structure of the comments object}
    },
    attachmentUploads: {
      //used to keep track of the files that are in the process of being uploaded.
      // structure:
      // attachmentID: { matches the one from the backend
      //uploadStatus: //set to error if the S3 upload fails
      //uploadPercentage: keeps track of how far the upload has come.
      //}
    },
    categories: {
      /*
      categoryId : {
        name: String,
        colour: String,
        loadingState: String
      }
      */
    },
    //Attachments which are displayed on the map (images. Only 1 per comment)
    attachmentsOnMap: [],
    paginatedCommentsAreLoading: false, //used to keep track of whether the paginated comments are loading - used to show a loading spinner on project home page,
    openCommentId: null,
    commentSort: getDefaultSort(),
    editingCommentId: null
  },
  getters: {
    [CommentGetters.PaginatedCommentsAreLoading](state) {
      return state.paginatedCommentsAreLoading;
    },
    [CommentGetters.CommentGetAllAsObject](state) {
      return state.comments;
    },
    [CommentGetters.CommentGetAll](state) {
      return Object.values(state.comments);
    },
    [CommentGetters.CommentGetAttachmentsOnMap](state) {
      return state.attachmentsOnMap;
    },
    [CommentGetters.CommentGetComment]: (state) => (commentId) => {
      return state.comments[commentId];
    },
    [CommentGetters.CommentGetNumberOfComments]: (state) => {
      return Object.keys(state.comments).length;
    },
    [CommentGetters.CommentIsLoading]: (state) => (commentId) => {
      return [LoadingStates.INITIALISED, LoadingStates.LOADING].includes(
        state.comments[commentId]?.loadingState
      );
    },
    [CommentGetters.CommentErrored]: (state) => (commentId) => {
      return state.comments[commentId]?.loadingState === LoadingStates.ERRORED;
    },
    [CommentGetters.CommentGetNumberOfCommentsLoading]: (state, getters) => {
      const loadingComments = getters[CommentGetters.CommentGetAll].filter(
        (comment) => comment?.loadingState === LoadingStates.LOADING
      );
      return loadingComments.length;
    },
    [CommentGetters.CommentGetReplyCount]: (state) => (commentId) => {
      return state.comments[commentId]?.replyCount;
    },
    [CommentGetters.CommentGetMessage]: (state) => (commentId) => {
      return state.comments[commentId]?.message;
    },
    [CommentGetters.CommentGetCreatedBy]: (state) => (commentId) => {
      return state.comments[commentId]?.createdBy || "";
    },
    [CommentGetters.CommentGetCreatedDate]: (state) => (commentId) => {
      return state.comments[commentId]?.createdAt || new Date(0);
    },
    [CommentGetters.CommentGetEditedDate]: (state) => (commentId) => {
      return state.comments[commentId]?.updatedAt;
    },
    [CommentGetters.CommentGetCoords]: (state) => (commentId) => {
      return state.comments[commentId]?.coords;
    },
    [CommentGetters.CommentGetTempCoords]: (state) => (commentId) => {
      return state.comments[commentId]?.tempCoords;
    },
    [CommentGetters.CommentGetParentId]: (state) => (commentId) => {
      return state.comments[commentId]?.parentID;
    },
    [CommentGetters.CommentGetUser]:
      (state, getters, rootState, rootGetters) => (commentId) => {
        return rootGetters[SessionTypes.getters.SessionGetUsers][
          getters[CommentGetters.CommentGetCreatedBy](commentId)
        ];
      },
    [CommentGetters.CommentGetOptionIds]: (state) => (commentId) => {
      return state.comments[commentId]?.optionIds;
    },
    [CommentGetters.CommentGetPointIds]: (state) => (commentId) => {
      return state.comments[commentId]?.pointIds;
    },
    [CommentGetters.CommentGetLineIds]: (state) => (commentId) => {
      return state.comments[commentId]?.lineIds;
    },
    [CommentGetters.CommentGetPolygonIds]: (state) => (commentId) => {
      return state.comments[commentId]?.polygonIds;
    },
    [CommentGetters.CommentGetAttachments]: (state) => (commentId) => {
      let linkedAttachments =
        state.comments[commentId]?.linkedAttachments || [];
      linkedAttachments = linkedAttachments.map((attachment) => {
        const retVal = attachment; // joins together backend updates with status updates from axios as it's uploading files to S3
        if (attachment.uploadStatus === status.PENDING) {
          retVal["uploadPercentage"] =
            state.attachmentUploads[attachment.id]?.uploadPercentage;
        }
        return retVal;
      });
      return linkedAttachments;
    },
    [CommentGetters.GetCategoryIdForComment]: (state) => (commentId) => {
      return state.comments[commentId]?.categoryId || 0;
    },
    [CommentGetters.GetCommentCategory]: (state) => (categoryId) => {
      return state.categories[categoryId];
    },
    [CommentGetters.GetCommentColour]: (state) => (commentId) => {
      const categoryId = state.comments[commentId]?.categoryId || 0;
      return state.categories[categoryId]?.colour;
    },
    [CommentGetters.GetCommentCategories]: (state) => {
      return state.categories;
    },
    [CommentGetters.GetOpenCommentId](state) {
      return state.openCommentId;
    },
    [CommentGetters.GetCommentSort]: (state) => {
      return state.commentSort;
    },
    [CommentGetters.GetEditingCommentId]: (state) => {
      return state.editingCommentId;
    }
  },
  actions: {
    [actions.CommentSetPagginatedCommentsAreLoading]: ({ commit }, payload) => {
      commit(mutations.CommentSetPagginatedCommentsAreLoading, payload);
    },
    [actions.CommentAddAttachmentToMap]: ({ commit }, attachment) => {
      commit(mutations.CommentAddAttachmentToMap, attachment);
    },
    [actions.CommentRemoveAttachmentFromMap]: ({ commit }, attachmentId) => {
      commit(mutations.CommentRemoveAttachmentFromMap, attachmentId);
    },
    [actions.CommentRemoveAllAttachmentsFromMap]: ({ commit }) => {
      commit(mutations.CommentRemoveAllAttachmentsFromMap);
    },
    [actions.CommentResetState]: ({ commit }) => {
      commit(mutations.CommentResetState);
    },
    [actions.CommentDeleteComment]: ({ commit }, comment) => {
      commit(mutations.CommentDeleteComment, comment);
    },
    [actions.CommentAddComment]: ({ commit, dispatch }, comment) => {
      commit(mutations.CommentAddComment, comment);
      // Initiate fetching of user details in session module
      if (comment?.createdBy) {
        dispatch(
          SessionTypes.actions.SessionAddUserDetails,
          comment?.createdBy
        );
      }
    },
    [actions.CommentUpdateComment]: ({ commit }, comment) => {
      commit(mutations.CommentUpdateComment, comment);
    },
    [actions.CommentUpdateLoadingState]: ({ commit }, payload) => {
      commit(mutations.CommentSetLoadingState, payload);
    },
    [actions.CommentSetInteractionState]: ({ commit }, payload) => {
      commit(mutations.CommentSetInteractionState, payload);
    },
    [actions.CommentSetMostRecentReplyDate]: ({ commit }, payload) => {
      commit(mutations.CommentSetMostRecentReplyDate, payload);
    },
    [actions.CommentSetCoords]: ({ commit }, payload) => {
      commit(mutations.CommentSetCoords, payload);
    },
    [actions.CommentSetTempCoords]: ({ commit }, payload) => {
      commit(mutations.CommentSetTempCoords, payload);
    },
    [actions.CommentSetAttachmentUploadState]: ({ commit }, payload) => {
      commit(mutations.CommentSetAttachmentUploadState, payload);
    }
  },
  mutations: {
    [mutations.CommentSetPagginatedCommentsAreLoading]: (state, payload) => {
      Vue.set(state, "paginatedCommentsAreLoading", payload);
    },
    [mutations.CommentAddAttachmentToMap]: (state, attachment) => {
      state.attachmentsOnMap = [...state.attachmentsOnMap, attachment];
    },
    [mutations.CommentRemoveAttachmentFromMap]: (state, attachmentId) => {
      state.attachmentsOnMap = state.attachmentsOnMap.filter(
        (attachment) => attachment.attachmentId !== attachmentId
      );
    },
    [mutations.CommentRemoveAllAttachmentsFromMap]: (state) => {
      state.attachmentsOnMap = [];
    },
    [mutations.CommentSetTempCoords]: (state, payload) => {
      state.comments[payload.id].tempCoords = payload.tempCoords;
    },
    [mutations.CommentSetCoords]: (state, payload) => {
      state.comments[payload.id].coords = payload.coords;
    },
    [mutations.CommentSetMostRecentReplyDate]: (state, payload) => {
      state.comments[payload.id].mostRecentReplyDate =
        payload.mostRecentReplyDate;
    },
    [mutations.CommentSetInteractionState]: (state, payload) => {
      Vue.set(state.comments, payload.id, {
        ...state.comments?.[payload.id],
        interactionState: payload.interactionState
      });
    },
    [mutations.CommentSetLoadingState]: (state, payload) => {
      Vue.set(state.comments, payload.id, {
        ...state.comments?.[payload.id],
        loadingState: payload.loadingState
      });
    },
    [mutations.CommentDeleteComment]: (state, commentId) => {
      if (!commentId) {
        console.error("Comment ID is required to delete a comment");
        return;
      }

      const parentId = state.comments[commentId]?.parentID;

      Vue.delete(state.comments, commentId);

      // If comment had a parent, update the parent's reply count
      if (parentId) {
        const parentComment = state.comments[parentId];
        const { replyCount, mostRecentReplyDate } =
          getMostRecentReplyDateAndCount(state.comments, parentComment);
        Vue.set(state.comments, parentId, {
          ...state.comments?.[parentId],
          replyCount,
          mostRecentReplyDate
        });
      }
    },
    [mutations.CommentAddComment]: (state, newComment) => {
      if (!newComment?.id) {
        return;
      }

      // Add entry for new comment
      Vue.set(state.comments, newComment?.id, {
        ...newComment,
        optionIds: convertStringsToNumbers(newComment.linkedOptions),
        pointIds: convertStringsToNumbers(newComment.linkedPoints),
        lineIds: convertStringsToNumbers(newComment.linkedLines),
        polygonIds: convertStringsToNumbers(newComment.linkedPolygons),
        interactionState: InteractionStates.NORMAL,
        loadingState: LoadingStates.LOADED,
        replyCount: 0,
        categoryId: Number(newComment?.categoryID)
      });

      const parentId = Number(newComment?.parentID);
      const parentComment = state.comments?.[parentId];
      // If comment has a loaded parent, update the parent's reply count
      if (
        parentId &&
        parentComment &&
        parentComment?.loadingState === LoadingStates.LOADED
      ) {
        const { replyCount, mostRecentReplyDate } =
          getMostRecentReplyDateAndCount(state.comments, parentComment);
        Vue.set(state.comments, parentId, {
          ...state.comments?.[parentId],
          replyCount,
          mostRecentReplyDate
        });
        return;
      }

      // If comment has no parent, and therefore IS a parent, update its reply count and date
      if (!parentId) {
        const { replyCount, mostRecentReplyDate } =
          getMostRecentReplyDateAndCount(state.comments, newComment);
        Vue.set(state.comments, newComment?.id, {
          ...state.comments[newComment?.id],
          replyCount,
          mostRecentReplyDate
        });
        return;
      }
    },
    [mutations.CommentUpdateComment]: (state, newComment) => {
      if (!newComment?.id) {
        console.error("Comment ID is required to update a comment");
        return;
      }
      Vue.set(state.comments, newComment.id, {
        ...state.comments[newComment.id],
        ...newComment,
        optionIds: convertStringsToNumbers(newComment.linkedOptions),
        pointIds: convertStringsToNumbers(newComment.linkedPoints),
        lineIds: convertStringsToNumbers(newComment.linkedLines),
        polygonIds: convertStringsToNumbers(newComment.linkedPolygons),
        loadingState: LoadingStates.LOADED,
        // Only update the category ID if it's been returned in the update
        // otherwise keep the existing one
        ...(newComment?.categoryID !== undefined && {
          categoryId: Number(newComment?.categoryID)
        })
      });
    },
    [mutations.CommentResetState]: (state) => {
      Vue.set(state, "comments", {});
      Vue.set(state, "categories", {});
      Vue.set(state, "attachmentsOnMap", []);
      Vue.set(state, "paginatedCommentsAreLoading", false);
      Vue.set(state, "openCommentId", null);
      Vue.set(state, "editingCommentId", null);
      Vue.set(state, "commentSort", getDefaultSort());
    },
    [mutations.CommentSetAttachmentUploadState]: (state, payload) => {
      const attachmentID = payload?.attachmentID;
      if (attachmentID === undefined) {
        return;
      }
      Vue.set(state.attachmentUploads, attachmentID, payload);
    },

    [mutations.SetCommentCategory]: (state, newCategory) => {
      const categoryId = newCategory?.id;
      if (!categoryId) {
        console.error(
          "Category with ID is required to update a comment category"
        );
        return;
      }
      try {
        Vue.set(state.categories, categoryId, {
          ...newCategory,
          loadingState: LoadingStates.LOADED
        });
      } catch (err) {
        console.errror(err);
      }
    },
    [mutations.AddCommentCategories]: (state, newCategories) => {
      const newCategoriesObject = {};
      newCategories.forEach((category) => {
        const id = category?.id;
        if (!id) {
          return;
        }
        newCategoriesObject[id] = {
          id,
          name: category?.name,
          colour: category?.colour
        };
      });
      Vue.set(state, "categories", {
        ...state.categories,
        ...newCategoriesObject
      });
    },
    [mutations.UpdateCommentCategoryLoading]: (
      state,
      { categoryId, loadingState }
    ) => {
      if (!categoryId) {
        console.error(
          "Category with ID is required to update a comment category's loading state"
        );
        return;
      }
      Vue.set(state.categories, categoryId, {
        ...state.categories[categoryId],
        loadingState
      });
    },
    [mutations.DeleteCommentCategory]: (state, categoryId) => {
      if (!categoryId) {
        console.error("Category ID is required to delete a comment category");
        return;
      }
      Vue.delete(state.categories, categoryId);
    },
    [mutations.SetOpenCommentId]: (state, id) => {
      Vue.set(state, "openCommentId", id);
    },
    [mutations.SetCommentSort]: (state, payload) => {
      state.commentSort = payload;
    },
    [mutations.SetEditingCommentId]: (state, id) => {
      Vue.set(state, "editingCommentId", id);
    }
  }
};
