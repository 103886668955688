import LoadingStates from "@/constants/LoadingStates";

export const getDateSortedChildComments = (commentsMap, parentId) => {
  const childComments = Object.values(commentsMap).filter((comment) => {
    return (
      comment.loadingState === LoadingStates.LOADED &&
      comment.parentID == parentId
    );
  });

  const sortedChildComments = childComments.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  return sortedChildComments;
};

export const getMostRecentReplyDate = (parentComment, sortedChildComments) => {
  let mostRecentReplyDate;
  if (sortedChildComments?.length) {
    mostRecentReplyDate = sortedChildComments[0]?.createdAt
      ? new Date(sortedChildComments[0]?.createdAt).getTime()
      : new Date(parentComment?.createdAt).getTime();
  } else {
    mostRecentReplyDate = new Date(parentComment?.createdAt).getTime();
  }
  return mostRecentReplyDate;
};

export const getMostRecentReplyDateAndCount = (commentsMap, parentComment) => {
  const replies = getDateSortedChildComments(commentsMap, parentComment?.id);
  const replyCount = replies?.length;
  const mostRecentReplyDate = getMostRecentReplyDate(parentComment, replies);
  return { replyCount, mostRecentReplyDate };
};
