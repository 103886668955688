import { POLYGON_RESULT_ID_PREFIX } from "@/constants/Prefixes";
import {
  getters as polygonResultGetters,
  mutations as polygonResultMutations,
  actions as polygonResultActions
} from "@S/polygonResult/types";
import { getters as savingViewGetters } from "@S/savingView/types";
import { getters as caseGetters } from "@S/case/types";

import { usingLocalBackend } from "@/hooks/usingLocalBackend";
import { IndexedDB, Tables } from "@/indexdb/core";
import ResultTypes from "@/constants/ResultTypes";
import { _axios } from "@/plugins/axios";

const polygonResultsDB = new IndexedDB(
  Tables.OPPORTUNITY_MAPS,
  usingLocalBackend()
);

const defaultState = {
  metadata: {},
  caseIdMap: {},
  geojsonMap: {}
};

const stripPrefix = (id) => {
  return String(id).replace(POLYGON_RESULT_ID_PREFIX, "");
};
const addPrefix = (id) => {
  return POLYGON_RESULT_ID_PREFIX + id;
};

export default {
  state: {
    ...defaultState
  },
  getters: {
    [polygonResultGetters.GetPolygonResultSummary]:
      (state, getters) => (id) => {
        const unprefixedId = stripPrefix(id);
        const prefixedId = addPrefix(unprefixedId);
        const caseId =
          getters[polygonResultGetters.GetPolygonResultCaseId](unprefixedId);
        const caseName = getters[caseGetters.CaseGetName](caseId);
        const visible =
          getters[polygonResultGetters.GetPolygonResultVisibility](prefixedId);

        return {
          id: prefixedId,
          visible: visible,
          displayValue: `${caseName} - #${prefixedId}`,
          resultType: ResultTypes.POLYGON_RESULT.key,
          caseName: caseName,
          caseId: caseId
        };
      },
    [polygonResultGetters.GetPolygonResultCaseId]:
      (state) => (polygonResultId) => {
        if (!polygonResultId) {
          console.error(
            "GetPolygonResultCaseId called without polygonResultId"
          );
          return;
        }
        const unprefixedId = stripPrefix(polygonResultId);
        return state?.caseIdMap?.[unprefixedId];
      },
    [polygonResultGetters.GetPolygonResultMetadata]:
      (state) => (polygonResultId) => {
        const unprefixedId = stripPrefix(polygonResultId);
        const resultMetadata = state?.metadata?.[unprefixedId];
        if (!resultMetadata) {
          return [];
        }
        const metadata = Object.keys(resultMetadata).flatMap((key) => {
          return [
            {
              name: key,
              displayValue: key,
              value: resultMetadata?.[key]
            }
          ];
        });
        return metadata;
      },
    [polygonResultGetters.GetPolygonResultGeojson]: (state) => (id) => {
      const unprefixedId = stripPrefix(id);
      return state.geojsonMap?.[unprefixedId];
    },
    [polygonResultGetters.GetPolygonResultVisibility]: (_, getters) => (id) => {
      const unprefixedId = stripPrefix(id);
      const prefixedId = addPrefix(unprefixedId);
      const result =
        getters[savingViewGetters.SavingViewGetVisiblePolygonResultsIdSet]?.has(
          prefixedId
        );
      return result;
    },
    [polygonResultGetters.AllVisiblePolygonResults]: (state, getters) => {
      let geojsonFeatures = [];

      Object.entries(state.geojsonMap)?.forEach(([id, geojson]) => {
        const visible =
          getters?.[polygonResultGetters.GetPolygonResultVisibility](id);
        if (!visible) {
          return;
        }
        geojsonFeatures = geojsonFeatures.concat(geojson?.features);
      });
      return geojsonFeatures?.filter((geojson) => Boolean(geojson));
    }
  },
  mutations: {
    [polygonResultMutations.SetPolygonResultMetadata]: (
      state,
      { polygonResultId, objectivesData, caseId }
    ) => {
      if (!polygonResultId) {
        console.error(
          "SetPolygonResultMetadata called without polygonResultId"
        );
        return;
      }
      if (!objectivesData) {
        console.error("SetPolygonResultMetadata called without objectivesData");
        return;
      }

      const unprefixedId = stripPrefix(polygonResultId);
      if (caseId) {
        Vue.set(state.caseIdMap, unprefixedId, caseId);
      }
      Vue.set(state.metadata, unprefixedId, { ...objectivesData });
    },
    [polygonResultMutations.SetPolygonResultGeojson]: (
      state,
      { geojson, id }
    ) => {
      Vue.set(state.geojsonMap, id, geojson);
    },
    [polygonResultMutations.ResetPolygonResults]: (state) => {
      Vue.set(state, "metadata", {});
      Vue.set(state, "caseIdMap", {});
      Vue.set(state, "geojsonMap", {});
    }
  },
  actions: {
    [polygonResultActions.FetchPolygonResult]: async (
      { commit },
      polygonResultId
    ) => {
      const unprefixedId = stripPrefix(polygonResultId);
      const prefixedId = addPrefix(unprefixedId);
      const url = `/V2/opportunityMap/${unprefixedId}`;
      let polygonResultData = null;
      polygonResultData = await polygonResultsDB.get(unprefixedId);
      if (!polygonResultData) {
        let polygonResultDataRaw;
        await _axios
          .get(url)
          .then(async function (response) {
            polygonResultDataRaw = response.data.opportunityMap;
          })
          .catch(function err(err) {
            console.error(err);
            return;
          });
        const convertedGeoJsonString = JSON.parse(
          polygonResultDataRaw?.geojsonString
        );
        convertedGeoJsonString?.features?.forEach((feature) => {
          feature.properties = {
            id: prefixedId,
            name: prefixedId, //TODO: Change this to the actual name when we fetch the rest of the polygon result data
            ...feature.properties
          };
        });
        if (convertedGeoJsonString?.features?.length > 0) {
          polygonResultData = {
            geojson_string: convertedGeoJsonString
          };
          await polygonResultsDB.set(unprefixedId, polygonResultData);
        }
      }
      commit(polygonResultMutations.SetPolygonResultGeojson, {
        geojson: polygonResultData?.geojson_string,
        id: unprefixedId
      });
    }
  }
};
