<template>
  <v-menu nudge-bottom="46" bottom dense eager>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="{ ...menu }"
        :disabled="!isAuthenticated"
        color="primary"
        data-cy="user-menu-button"
        icon
      >
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-btn>
    </template>
    <v-card
      style="background-color: var(--ciBackgroundGray)"
      class="pa-1"
      width="400px"
    >
      <v-card-title class="pa-0 ma-0">
        <v-row
          class="pa-1 pt-3 ma-0"
          justify="space-between"
          align-content="space-between"
          no-gutters
        >
          <v-col cols="2" class="pa-0 ma-0"
            ><v-avatar
              color="grey"
              size="40"
              class="d-flex mx-auto"
              elevation="2"
              outlined
            >
              <img :src="picture" :alt="nickname" />
            </v-avatar>
          </v-col>

          <v-col cols="10" class="pa-0 ma-0 mt-n2"
            ><h4>{{ nickname }}</h4>
            <v-subheader class="pa-0 ma-0 mt-n3">
              {{ email }}
            </v-subheader>
          </v-col>
        </v-row>
      </v-card-title>
      <v-spacer />

      <v-card style="background-color: white" class="pa-1" flat>
        <v-list>
          <LogoutButton />

          <v-divider v-if="projectId" class="list-divider" />
          <span v-show="projectId">
            <TeamMgmtModal :projectName="activeProjectName" />
          </span>
        </v-list>
      </v-card>
    </v-card>
  </v-menu>
</template>

<script>
import LogoutButton from "@/components/auth/LogoutButton.vue";
import TeamMgmtModal from "@C/userManagement/TeamMgmtModal.vue";
import { getters } from "@/store/project/types";
import { mapGetters } from "vuex";
import { authState } from "@/plugins/authPlugin";

export default {
  name: "DropDownMenu",
  components: { LogoutButton, TeamMgmtModal },
  props: { projectId: {} },
  computed: {
    ...mapGetters([getters.ProjectGetName]),
    activeProjectName() {
      return this.projectId ? this.ProjectGetName(this.projectId) : "";
    },
    user() {
      return authState?.value?.user;
    },
    nickname() {
      return this.user?.nickname;
    },
    picture() {
      return this.user?.picture;
    },
    email() {
      return this.user?.email;
    },
    isAuthenticated() {
      return authState?.value?.isAuthenticated;
    }
  }
};
</script>

<style scoped>
.list-divider {
  border-width: 0.75px;
  border-color: var(--ciGray);
}
</style>
