import { actions, mutations, getters } from "@S/projectFeatureCategories/types";

const isNullOrUndefined = (input) => {
  return input === null || input === undefined;
};

export default {
  state: {
    categories: {}
  },
  getters: {
    [getters.GetAllCategories]: (state) => {
      return state.categories;
    },
    [getters.GetCategoryById]: (state) => (categoryId) => {
      return state.categories[categoryId];
    }
  },
  mutations: {
    [mutations.CategoryAddCategory]: (state, payload) => {
      const id = payload?.id;
      if (!payload || isNullOrUndefined(id)) {
        return;
      }

      //Re-map masterOpacity to masterOpacityPercentage
      const formatted = {
        ...payload,
        masterOpacityPercentage: payload.masterOpacity
      };

      delete formatted.masterOpacity;

      Vue.set(state.categories, payload.id, formatted);
    },
    [mutations.DeleteCategory]: (state, categoryId) => {
      const tempState = { ...state.categories };
      delete tempState[categoryId];
      Vue.set(state, "categories", tempState);
    },
    [mutations.UpdateCategory]: (state, newCategory) => {
      const id = newCategory.id;

      Vue.set(state.categories[id], "name", newCategory.name);
      Vue.set(state.categories[id], "description", newCategory.description);
      Vue.set(
        state.categories[id],
        "masterOpacityPercentage",
        newCategory.masterOpacity
      );
      Vue.set(state.categories[id], "primaryColour", newCategory.primaryColour);
      Vue.set(
        state.categories[id],
        "secondaryColour",
        newCategory.secondaryColour
      );
    },
    [mutations.ResetCategories]: (state) => {
      Vue.set(state, "categories", {});
    }
  },
  actions: {
    [actions.CategoryAddCategory]: ({ commit }, payload) => {
      commit(mutations.CategoryAddCategory, payload);
    },
    [actions.ResetCategories]: ({ commit }) => {
      commit(mutations.ResetCategories);
    },
    [actions.DeleteCategory]: ({ commit }, categoryId) => {
      commit(mutations.DeleteCategory, categoryId);
    },
    [actions.UpdateCategory]: ({ commit }, payload) => {
      commit(mutations.UpdateCategory, payload);
    }
  }
};
