import { _axios } from "@/plugins/axios";

const featureFlagUrl = "/V2/feature-flag";

const featureFlags = {};

export const getFeatureFlag = async (featureFlag, projectId) => {
  const tag = `${featureFlag}:${projectId}`;

  if (featureFlags[tag]) {
    return featureFlags[tag];
  }

  const params = {};
  if (projectId) {
    params.projectId = projectId;
  }
  featureFlags[tag] = _axios
    ?.get(`${featureFlagUrl}/${featureFlag}`, {
      params: params
    })
    .then((response) => {
      const flagValue = !!response.data.featureActive;
      console.log(
        `Responding with feature flag: ${featureFlag} for project: ${projectId}, active status is ${flagValue}`
      );
      return flagValue;
    })
    .catch((error) => {
      console.error(error);
    });

  return featureFlags[tag];
};
