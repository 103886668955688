import SessionTypes, { getters as sessionGetters } from "./types";
import { NewOptionTypes as OptionTypes } from "@S/option/types";

import * as alertModuleTypes from "@/store/alert/types";
import defer from "@/helpers/defer";
import LoadingGroups from "@/constants/LoadingGroups";
import { _axios } from "@/plugins/axios";

import store from "@/store";
import LoadingStates from "@/constants/LoadingStates";
import FILE_UPLOAD_STATES from "@/constants/FileUploadStates";
import { getNestedPermissions } from "@/constants/PermissionsTypes";
import { isIdFromAuth0, usingCognito } from "@/plugins/authHelpers";
const { actions, mutations } = SessionTypes;

const projectUrl = "/V2/project/";

const projectGetIdsUrl = "V2/user/projects/";
const userGetPermissionsUrl = "V2/user/permissions/";
const userDetailsUrl = "/V2/users/";

const createUserDetailsObject = ({
  id,
  nickname,
  email,
  picture_url,
  loadingState
}) => {
  return {
    id,
    nickname,
    email,
    picture_url,
    loadingState
  };
};

const createInitialIngestObject = ({
  ingestOperationId,
  datasetName,
  uploadSource,
  projectId
}) => {
  return {
    ingestOperationId,
    datasetName,
    uploadState: FILE_UPLOAD_STATES.IN_PROGRESS,
    errors: [],
    projectId,
    uploadSource
  };
};

export default {
  types: SessionTypes,
  state: {
    waitingForPermissions: true,
    sessionExpiryTime: null,
    sessionAccessExpired: false,
    status: "loading",
    projectIds: [],
    activeProjectId: null,
    userPermissions: {
      // "project_id": set("read", "write", "execute", "admin")
      //"create_project": false | true
    },
    datasetLayerColours: {},
    visibleVectorLayerIds: [],
    VectorMapSettings: {
      layerEnabled: true,
      layerOutlineEnabled: true,
      displayMode: "Dataset"
    },
    activeRasterDatasets: [],

    users: {},
    datasetUploadTracker: {},

    executeOnProjectSelect: {}, // Internal variable to exectute an action when a project is selected
    hoveredPortfolioProject: null,
    portfolioProjectsInViewPort: new Set(),
    portfolioSearch: "",

    lowBandwidthMode: true, //Setting it to true by default to avoid loading assets before checking in local store
    assetsToLoad: []
  },
  getters: {
    [sessionGetters.SessionShouldLoadAsset]: (state, getters) => (asset) => {
      if (!LoadingGroups[asset]) {
        throw new Error(`requested asset: ${asset} not in loading groups`);
      }
      if (!getters[sessionGetters.SessionLowbandWithModeActive]) {
        return true;
      }
      return state.assetsToLoad.includes(asset);
    },
    [sessionGetters.SessionLowbandWithModeActive]: (state) => {
      return state.lowBandwidthMode;
    },
    [sessionGetters.SessionGetSelectedOptions](_, getters) {
      return getters[OptionTypes.getters.OptionsGetSelectedIds];
    },
    [sessionGetters.SessionGetActiveOption](_, getters) {
      return getters[OptionTypes.getters.OptionsGetActiveId];
    },
    [sessionGetters.SessionGetActiveRasterDatasets](state) {
      return state.activeRasterDatasets;
    },
    [sessionGetters.SessionGetVisibleVectorLayerIds](state) {
      return state.visibleVectorLayerIds;
    },
    [sessionGetters.SessionGetStatus](state) {
      return state.status;
    },
    [sessionGetters.SessionGetProjectIds](state) {
      return state.projectIds;
    },
    [sessionGetters.SessionHasActiveProject](state) {
      return state.activeProjectId != null && state.activeProjectId.length > 0;
    },
    [sessionGetters.SessionGetActiveProjectId](state) {
      return state.activeProjectId;
    },
    [sessionGetters.SessionWaitingForPermissions](state) {
      return state.waitingForPermissions;
    },
    [sessionGetters.SessionCheckAuthorised](state) {
      return (asset, verb) => {
        if (!asset) {
          return state.userPermissions[verb] === true;
        }
        if (
          state.userPermissions[asset] &&
          state.userPermissions[asset].has(verb)
        ) {
          return true;
        }
        return false;
      };
    },
    [sessionGetters.SessionGetUsers]: (state) => {
      return state.users;
    },
    [sessionGetters.SessionGetDatasetUploads]: (state, getters) => {
      if (!getters[sessionGetters.SessionGetActiveProjectId]) {
        return state.datasetUploadTracker;
      }
      const projectIngests = Object.values(state.datasetUploadTracker).filter(
        (ingest) =>
          ingest.projectId === getters[sessionGetters.SessionGetActiveProjectId]
      );
      const projectIngestsObject = {};
      projectIngests.forEach((ingest) => {
        projectIngestsObject[ingest.ingestOperationId] = ingest;
      });
      return projectIngestsObject;
    },
    [sessionGetters.GetSessionExpiry]: (state) => {
      return state.sessionExpiryTime;
    },
    [sessionGetters.GetSessionAccessExpired]: (state) => {
      return state.sessionAccessExpired;
    }
  },
  mutations: {
    [mutations.SessionSetLowBandwidthMode]: (state, payload) => {
      state.lowBandwidthMode = payload;
    },
    [mutations.SessionSetLoadAsset]: (state, payload) => {
      state.assetsToLoad.push(payload);
    },
    [mutations.SessionLoading]: (state) => {
      state.status = "loading";
    },
    [mutations.SessionLoaded]: (state) => {
      state.status = "loaded";
    },
    [mutations.SessionAddProjectIds]: (state, projectIds) => {
      projectIds?.forEach((projectId) => {
        if (state.projectIds?.lastIndexOf(projectId) === -1) {
          Vue.set(state, "projectIds", [...state.projectIds, projectId]);
        }
      });
    },
    [mutations.SessionSetPermissionsForUser]: (state, permissions) => {
      permissions.forEach((permission) => {
        const split = permission?.split(":");
        if (split.length == 1) {
          state.userPermissions[split[0]] = true;
          return;
        }
        const projectId = split[0];
        const projectPermission = split[1];
        if (!state.userPermissions[projectId]) {
          state.userPermissions[projectId] = new Set();
        }
        getNestedPermissions(projectPermission).forEach((perm) => {
          state.userPermissions[projectId].add(perm);
        });
      });
      state.waitingForPermissions = false;
    },
    [mutations.SessionDeleteProjectById]: (state, projectId) => {
      const newIds = state.projectIds?.filter((id) => id !== projectId);
      Vue.set(state, "projectIds", newIds);

      if (state.activeProjectId == projectId) {
        state.activeProjectId = null;
      }
    },
    [mutations.SessionSelectProjectById]: (state, projectId) => {
      state.activeProjectId = projectId;
    },
    [mutations.SessionSetVisibleVectorLayerIds]: (state, payload) => {
      state.visibleVectorLayerIds = payload.map((id) => Number(id));
    },
    [mutations.SessionSetVectorLayerVisibility]: (
      state,
      { layerId, visibility }
    ) => {
      if (
        visibility === true &&
        !state.visibleVectorLayerIds.includes(layerId)
      ) {
        state.visibleVectorLayerIds.push(layerId);
      } else if (
        visibility === false &&
        state.visibleVectorLayerIds.includes(layerId)
      ) {
        state.visibleVectorLayerIds = state.visibleVectorLayerIds.filter(
          (id) => id !== layerId
        );
      } else {
        console.warn("Unexpected visibility value:", visibility);
      }
    },
    [mutations.SessionSetActiveRasterDatasets]: (state, payload) => {
      state.activeRasterDatasets = payload;
    },
    [mutations.SessionAddOnProjectExecution]: (state, payload) => {
      const projectId = payload.projectId;
      const callback = payload.callback;

      const deferAction = defer();
      deferAction.then(callback);

      const existingDefers = state.executeOnProjectSelect[projectId] || [];
      existingDefers.push(deferAction);
      state.executeOnProjectSelect[projectId] = existingDefers;
    },
    [mutations.SessionUserUpdate]: (state, payload) => {
      const { userId, nickname, email, picture_url } = payload;

      if (userId) {
        state.users = {
          ...state.users,
          [userId]: {
            ...createUserDetailsObject({
              id: userId,
              nickname,
              email,
              picture_url,
              loadingState: LoadingStates.LOADED
            })
          }
        };
      }
    },
    [mutations.SessionSetDatasetUploads]: (state, payload) => {
      state.datasetUploadTracker = payload;
    },
    [mutations.SetSessionExpiry]: (state, payload) => {
      state.sessionExpiryTime = payload;
    },
    [mutations.SetSessionAccessExpired]: (state, payload) => {
      state.sessionAccessExpired = payload;
    }
  },
  actions: {
    [actions.SessionSetLowBandwidthMode]: ({ commit }, payload) => {
      commit(mutations.SessionSetLowBandwidthMode, payload);
    },
    [actions.SessionSetLoadAsset]: ({ commit }, payload) => {
      commit(mutations.SessionSetLoadAsset, payload);
    },
    [actions.SessionSetActiveRasterDatasets]: ({ commit }, payload) => {
      commit(mutations.SessionSetActiveRasterDatasets, payload);
    },
    [actions.SessionSetVisibleVectorLayerIds]: ({ commit }, payload) => {
      commit(mutations.SessionSetVisibleVectorLayerIds, payload);
    },
    [actions.SessionCreateProject]: ({ commit }, payload) => {
      commit(mutations.SessionAddProjectIds, [payload]);
    },
    [actions.SessionFetchProjectIdsForUser]: async ({ commit }) => {
      commit(mutations.SessionLoading);
      _axios
        .get(projectGetIdsUrl)
        .then(
          function (response) {
            const ids = response?.data?.ids;
            commit(mutations.SessionAddProjectIds, ids);
            _axios.get(userGetPermissionsUrl).then(
              function (response) {
                commit(
                  mutations.SessionSetPermissionsForUser,
                  response.data.permissions
                );
              },
              function (reason) {
                store.dispatch(alertModuleTypes.mutations.AlertUpdate, {
                  message:
                    "Something went wrong, please contact us via Intercom below",
                  type: "error"
                });
                console.error(reason);
              }
            );
          },
          function (reason) {
            store.dispatch(alertModuleTypes.mutations.AlertUpdate, {
              message:
                "Something went wrong, please contact us via Intercom below",
              type: "error"
            });
            console.error(reason);
          }
        )
        .then(function () {
          setTimeout(() => {
            commit(SessionTypes.mutations.SessionLoaded);
          }, 250);
        });
    },
    [actions.SessionDeleteProjectById]: ({ commit }, projectId) => {
      return _axios.delete(`${projectUrl}${projectId}`).then(
        function (response) {
          commit(
            SessionTypes.mutations.SessionDeleteProjectById,
            response?.data?.id
          );
        },
        function (reason) {
          store.dispatch(alertModuleTypes.mutations.AlertUpdate, {
            message:
              "Something went wrong, please contact us via Intercom below",
            type: "error"
          });
          console.error(reason);
        }
      );
    },
    [actions.SessionSelectProjectById]: ({ state, commit }, projectId) => {
      commit(SessionTypes.mutations.SessionSelectProjectById, projectId);
      resolveDefersForActiveProject(state);
    },
    [actions.SessionExecuteWhenProjectIsActive]: (
      { state, commit },
      payload
    ) => {
      const callback = payload.callback;
      const projectId = payload.projectId;

      commit(mutations.SessionAddOnProjectExecution, { callback, projectId });

      resolveDefersForActiveProject(state);
    },
    [actions.SessionAddUserDetails]: ({ state }, newUserId) => {
      // Don't fetch user details if we're not querying for an actual id
      // I.e calling this action with a nickname
      const idFromAuth0 = isIdFromAuth0(newUserId);
      const onCognito = usingCognito();
      if (!(idFromAuth0 || onCognito)) {
        return;
      }

      const userIds = Object.keys(state.users);
      // Only fetch user details if it doesn't already exist in session
      if (newUserId && !userIds.includes(newUserId)) {
        store.dispatch(actions.SessionFetchUserDetails, newUserId);
      }
    },
    [actions.SessionFetchUserDetails]: async ({ commit, state }, userId) => {
      const url = `${userDetailsUrl}${userId}/details`;

      // Initialise user so that repeat fetches don't get called while
      // GET is in progress
      if (userId) {
        state.users = {
          ...state.users,
          [userId]: {
            ...createUserDetailsObject({
              id: userId,
              loadingState: LoadingStates.INITIALISED
            })
          }
        };
      }

      try {
        const response = await _axios.get(url);

        const payload = {
          userId,
          nickname: response.data.nickname,
          email: response.data.email,
          picture_url: response.data.picture_url
        };

        commit(mutations.SessionUserUpdate, payload);
      } catch (error) {
        console.log("Error fetching user details: ", error);
      }
    },
    [actions.SessionSetDatasetUploads]: (
      { state, commit },
      {
        ingestOperationId,
        datasetName,
        uploadState,
        errors,
        uploadSource,
        intervalToken
      }
    ) => {
      const currentEntry = state.datasetUploadTracker?.[ingestOperationId];
      let newIngestState = null;

      if (currentEntry) {
        // Only update the supplied values, all others remain the same
        newIngestState = {
          ...state.datasetUploadTracker,
          [ingestOperationId]: {
            ...currentEntry,
            ...(uploadState && { uploadState }),
            ...(errors && { errors }),
            ...(intervalToken && { intervalToken }),
            ...(datasetName && { datasetName })
          }
        };
      } else {
        newIngestState = {
          ...state.datasetUploadTracker,
          [ingestOperationId]: createInitialIngestObject({
            ingestOperationId,
            datasetName,
            projectId: state.activeProjectId,
            uploadSource
          })
        };
      }

      commit(mutations.SessionSetDatasetUploads, newIngestState);
    },
    [actions.SessionRemoveDatasetUpload]: (
      { state, commit },
      { ingestOperationId }
    ) => {
      if (!ingestOperationId) {
        console.warn("SessionRemoveDatasetUpload called without an ingest id");
        return;
      }
      const currentEntry = state.datasetUploadTracker?.[ingestOperationId];

      if (currentEntry) {
        // Only update the supplied values, all others remain the same
        const newIngestState = {
          ...state.datasetUploadTracker
        };
        delete newIngestState[ingestOperationId];
        commit(mutations.SessionSetDatasetUploads, newIngestState);
      }
    }
  }
};

function resolveDefersForActiveProject(state) {
  const activeProjectId =
    store.getters[sessionGetters.SessionGetActiveProjectId];
  const actionsToExecute = state.executeOnProjectSelect[activeProjectId] || [];
  actionsToExecute.forEach((promise) => {
    promise.resolve();
    console.debug("resolved defer for project:", activeProjectId);
  });
  state.executeOnProjectSelect[activeProjectId] = [];
}

export function executeWhenProjectIsActive(projectId, callback) {
  store.dispatch(SessionTypes.actions.SessionExecuteWhenProjectIsActive, {
    projectId: projectId,
    callback: callback
  });
}
