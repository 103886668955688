<template>
  <v-list-item
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
    @click="emitClick"
    :value="id"
    :color="colour"
    :data-cy="dataCy"
    value-input="id"
  >
    <v-list-item-title> {{ title }}</v-list-item-title>
    <v-list-item-action class="ma-0">
      <SavedViewListItemMenu
        @refreshMetricsViewList="$emit('refreshMetricsViewList')"
        :hover="hovered"
        :title="title"
        :id="id"
        :viewType="viewType"
        :availableViews="availableViews"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import SavedViewListItemMenu from "@/components/savingView/views/SavedViewListItemMenu.vue";

import SavedViewTypes from "@/constants/SavedViewTypes";

export default {
  name: "SavedViewListItem",
  emits: ["click", "refreshMetricsViewList"], 
  props: {
    title: { type: String, required: true },
    id: { type: Number, required: true },
    colour: { type: String, required: true },
    viewType: {
      type: String,
      required: true
    },
    availableViews: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SavedViewListItemMenu
  },
  data: () => ({
    hovered: false
  }),
  computed: {
    dataCy() {
      if (this.viewType === SavedViewTypes.MAP) {
        return "save-map-view-list-item";
      } else {
        return "save-metrics-view-list-item";
      }
    }
  },
  methods: {
    emitClick() {
      this.$emit("click");
    }
  }
};
</script>
