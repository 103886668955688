const getters = {
  // Map
  GetHoveredPortfolioProjectId: "GetHoveredPortfolioProjectId",
  GetPortfolioProjectsInViewportSet: "GetPortfolioProjectsInViewportSet",
  GetPortfolioSearch: "GetPortfolioSearch",
  GetPortfolioStatusFilters: "GetPortfolioStatusFilters",
  GetPortfolioMilestoneNameFilters: "GetPortfolioMilestoneNameFilters",
  GetPortfolioDeliverableNameFilters: "GetPortfolioDeliverableNameFilters",
  GetProjectIdsThatMatchSearch: "GetProjectIdsThatMatchSearch",
  GetProjectIdsThatMatchFilters: "GetProjectIdsThatMatchFilters",
  GetProjectIdsMatchingFiltersAndSearch:
    "GetProjectIdsMatchingFiltersAndSearch",
  GetAllMatchedProjectIds: "GetAllMatchedProjectIds",
  GetPortfolioOptionGeojson: "GetPortfolioOptionGeojson",
  GetPortfolioOptions: "GetPortfolioOptions",
  GetPortfolioOptionFields: "GetPortfolioOptionFields",

  // General milestones
  GetPortfolioMilestonesMap: "GetPortfolioMilestonesMap",
  GetPortfolioDeliverablesMap: "GetPortfolioDeliverablesMap",
  GetPortfolioAttachmentsMap: "GetPortfolioAttachmentsMap",
  GetPromotedMetricAttachments: "GetPromotedMetricAttachments",
  GetAllMilestoneNames: "GetAllMilestoneNames",
  GetAllDeliverableNames: "GetAllDeliverableNames",

  // Dashboards
  GetDashboardWidgetMapping: "GetDashboardWidgetMapping",
  GetDashboardLayouts: "GetDashboardLayouts",
  GetGroupedDeliverableData: "GetGroupedDeliverableData",
  GetWidgetDatasetName: "GetWidgetDatasetName"
};
const mutations = {
  // Map
  SetHoveredPortfolioProjectId: "SetHoveredPortfolioProjectId",
  SetPortfolioProjectsInViewport: "SetPortfolioProjectsInViewport",
  SetPortfolioSearch: "SetPortfolioSearch",
  SetPortfolioStatusFilters: "SetPortfolioStatusFilters",
  SetPortfolioMilestoneNameFilters: "SetPortfolioMilestoneNameFilters",
  SetPortfolioDeliverableNameFilters: "SetPortfolioDeliverableNameFilters",
  AddPortfolioOption: "AddPortfolioOption",
  AddPortfolioMilestones: "AddPortfolioMilestones",
  ResetPortfolioMilestonesState: "ResetPortfolioMilestonesState",

  // Dashboard
  UpdateDashboardWidgetProp: "UpdateDashboardWidgetProp",
  AddWidgetDataset: "AddWidgetDataset",
  UpdateWidgetDataset: "UpdateWidgetDataset",
  DeleteWidgetDataset: "DeleteWidgetDataset",
  UpdateDashboardWidgetComponent: "UpdateDashboardWidgetComponent",
  SetDashboardWidgetMapping: "SetDashboardWidgetMapping",
  AddBlankDashboardWidget: "AddBlankDashboardWidget",
  RemoveDashboardWidget: "RemoveDashboardWidget",
  UpdateDashboardLayout: "UpdateDashboardLayout"
};
const actions = {};

export { getters, mutations, actions };
