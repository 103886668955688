const getters = {
  LayersGetAllSummaries: "LayersGetAllSummaries",
  LayerGetStyle: "LayerGetStyle",
  LayerGetOpacity: "LayerGetOpacity",
  LayerGetFillColour: "LayerGetFillColour",
  LayerGetOutlineColour: "LayerGetOutlineColour",
  LayerGetInfo: "LayerGetInfo",
  LayerGetProperties: "LayerGetProperties",
  LayerGetPropertiesClean: "LayerGetPropertiesClean",
  LayerGetIngestPropertyKeys: "LayerGetIngestPropertyKeys",
  LayersGetAllUniqueProperties: "LayersGetAllUniqueProperties",
  LayersGetAllIds: "LayersGetAllIds"
};
const mutations = {
  LayerSetStyles: "LayerSetStyles",
  LayerSetInfo: "LayerSetInfo",
  LayerSetProperties: "LayerSetProperties",
  LayerAddProperty: "LayerAddProperty",
  LayerEditProperty: "LayerEditProperty",
  LayerDeleteProperty: "LayerDeleteProperty"
};
const actions = {
  LayerSetStyles: "LayerSetStyles",
  LayerSetInfo: "LayerSetInfo",
  LayerUpdateInfo: "LayerUpdateInfo",
  LayerSetProperties: "LayerSetProperties",
  LayerAddProperty: "LayerAddProperty",
  LayerEditProperty: "LayerEditProperty",
  LayerDeleteProperty: "LayerDeleteProperty"
};

export { getters, mutations, actions };

export default { getters, mutations, actions };
