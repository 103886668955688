<template>
  <fragment>
    <v-tooltip left>
      <template #activator="{ on }">
        <v-btn v-on="on" @click="toggleConfetti" color="primary" icon>
          <v-icon>cake</v-icon>
        </v-btn>
      </template>
      <span>
        Get the confetti popping! <br />
        Continuum Industries is turning 5 this week!
      </span>
    </v-tooltip>
  </fragment>
</template>

<script>
import { confetti } from "tsparticles-confetti";

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

export default {
  name: "MainNavBar",
  methods: {
    async toggleConfetti() {
      const duration = 3 * 1000;
      const animationEnd = Date.now() + duration;
      const defaults = {
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        zIndex: 1000
      };

      const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);

        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
          })
        );
      }, 250);
    }
  }
};
</script>

<style lang="scss"></style>
