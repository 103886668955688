<template>
  <portal v-if="showSnackbar" :to="portalTarget">
    <v-snackbar
      @input="$emit('close')"
      :value="showSnackbar"
      data-cy="errorSnackbar"
      color="error"
      timeout="5000"
      width="2000"
      style="margin-top: 6vh"
      top
    >
      {{ error }}
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="onClose"
          data-cy="closeErrorSnackbarButton"
          text
        >
          Cancel
        </v-btn>
      </template>
    </v-snackbar>
  </portal>
</template>

<script>
export default {
  name: "ErrorSnackbar",
  emits: ["close"],
  props: {
    error: {
      type: String,
      default: ""
    },
    portalTarget: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      manualClose: false
    };
  },
  computed: {
    showSnackbar() {
      return !!this.error && !this.manualClose;
    }
  },

  methods: {
    onClose() {
      this.manualClose = true;
      this.$emit("close");
    }
  },
  watch: {
    error() {
      this.manualClose = false;
    }
  }
};
</script>

<style></style>
