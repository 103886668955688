import Vuex from "vuex";
import { createStore } from "vuex-extensions";

import session from "@S/session/module";
import map from "@S/map/module";
import newCaseModule from "@S/case/newModule";
import polygonResultModule from "@S/polygonResult/module";
import comment from "@S/comment/module";
import featureCategoriesModule from "@S/projectFeatureCategories/module";
import alert from "@S/alert/module";
import configurationParameter from "@S/configurationParameter/module";
import layer from "@S/layer/module";
import resultsTable from "@S/resultsTable/module";
import mapChart from "@S/mapChart/module";
import savingView from "@S/savingView/module";
import savedMetricsView from "@S/savedMetricsView/module";
import collection from "@S/collection/module";
import setupPage from "@S/setupPage/module";
import newOptionModule from "@S/option/newModule";
import newDatasetModule from "@S/dataset/newModule";
import OptionVisualisationModule from "@S/optionVisualisation/module";
import routePoints from "@S/routePoints/module";
import derivedMetric from "@S/derivedMetric/module";
import newPolygonModule from "@S/polygon/newModule";
import lineModule from "@S/line/module";
import linkAnythingEverywhereModule from "@S/linkAnythingEverywhere/module";
import portfolioModule from "@S/portfolio/module";
import newConfigModule from "@S/config/newModule";
import newProjectModule from "@S/project/newModule";
import polygonAnalysisModule from "@S/polygonAnalysis/module";
import multiPolygonAnalysisModule from "@S/multiPolygonAnalysis/module";
import layersTreeModule from "@S/layersTree/module";
import analysisReport from "@S/analysisReport/module";
import relativeRAGModule from "@S/relativeRAG/module";

Vue.use(Vuex);

const thisStore = createStore(Vuex.Store, {
  modules: {
    alert,
    session,
    map,
    comment,
    configurationParameter,
    layer,
    resultsTable,
    mapChart,
    savingView,
    savedMetricsView,
    collection,
    setupPage,
    featureCategoriesModule,
    newOptionModule,
    newDatasetModule,
    newCaseModule,
    newPolygonModule,
    lineModule,
    OptionVisualisationModule,
    routePoints,
    derivedMetric,
    linkAnythingEverywhereModule,
    portfolioModule,
    newConfigModule,
    polygonResultModule,
    newProjectModule,
    polygonAnalysisModule,
    multiPolygonAnalysisModule,
    layersTreeModule,
    analysisReport,
    relativeRAGModule
  }

  //plugins: [vuexLocal.plugin]
});

export default thisStore;
