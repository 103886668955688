import { getters as CommentGetters } from "@S/comment/types";

export const keys = {
  createdAt: "createdAt",
  username: "username",
  dateRecentComment: "dateRecentComment",
  numberOfReplies: "numberOfReplies"
};

export const sorters = {
  directions: {
    ascending: "ascending",
    descending: "descending"
  },
  fields: {
    [keys.createdAt]: {
      name: keys.createdAt,
      label: "Date",
      sortFunc: (a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
    },
    [keys.username]: {
      name: keys.username,
      label: "Username",
      sortFunc: (a, b) => {
        if (a?.user?.nickname && b?.user?.nickname)
          return b?.user?.nickname?.localeCompare(a?.user?.nickname);
      }
    },
    [keys.dateRecentComment]: {
      name: keys.dateRecentComment,
      label: "Date of last reply",
      sortFunc: (a, b) => {
        return (
          new Date(b.mostRecentReplyDate) - new Date(a.mostRecentReplyDate)
        );
      }
    },
    [keys.numberOfReplies]: {
      name: keys.numberOfReplies,
      label: "Number of replies",
      sortFunc: (a, b) => {
        return b.replyCount - a.replyCount;
      }
    }
  }
};

export const linkTypes = {
  option: "Option",
  line: "Line",
  point: "Point",
  polygon: "Polygon"
};

const linkTypesPropValidator = (propValue) =>
  [
    linkTypes.option,
    linkTypes.point,
    linkTypes.line,
    linkTypes.polygon
  ].includes(propValue);

export const featureTypePropTemplate = {
  required: true,
  type: String,
  validator: linkTypesPropValidator
};

export const linkIdsMap = {
  [linkTypes.option]: {
    getter: CommentGetters.CommentGetOptionIds,
    stateKey: "optionIds"
  },
  [linkTypes.polygon]: {
    getter: CommentGetters.CommentGetPolygonIds,
    stateKey: "polygonIds"
  },
  [linkTypes.line]: {
    getter: CommentGetters.CommentGetLineIds,
    stateKey: "lineIds"
  },
  [linkTypes.point]: {
    getter: CommentGetters.CommentGetPointIds,
    stateKey: "pointIds"
  }
};
