const getters = {
  MapChartGetPaneExpanded: "MapChartGetPaneExpanded",
  GetActiveOptionDiscreteItems: "GetActiveOptionDiscreteItems",
  GetWorkingOptionComparisonView: "GetWorkingOptionComparisonView",
  GetSavedOptionComparisonView: "GetSavedOptionComparisonView",
  IsOptionComparisonViewDirty: "IsOptionComparisonViewDirty",

  GetSelectedMapChartTypeId: "GetSelectedMapChartTypeId",
  GetVerticalProfileSelectedQuantities: "GetVerticalProfileSelectedQuantities",
  IsVerticalProfileLinkedToMap: "IsVerticalProfileLinkedToMap"
};

const mutations = {
  MapChartSetPaneExpanded: "MapChartSetPaneExpanded",
  MapChartSetSelectedMapChartTypeId: "MapChartSetSelectedMapChartTypeId",
  SetActiveOptionDiscreteItems: "SetActiveOptionDiscreteItems",
  SetVerticalProfileSelectedQuantities: "SetVerticalProfileSelectedQuantities",
  SetVerticalProfileMapLink: "SetVerticalProfileMapLink",
  SetComparisonView: "SetComparisonView"
};

const actions = {
  MapChartSetPaneExpanded: "MapChartSetPaneExpanded",
  FetchActiveOptionDiscreteItems: "FetchActiveOptionDiscreteItems"
};

export { getters, mutations, actions };

export default { getters, mutations, actions };
