const getters = {
  GetCategoryById: "GetCategoryById",
  GetAllCategories: "GetAllCategories"
};
const mutations = {
  CategoryAddCategory: "CategoryAddCategory",
  ResetCategories: "ResetCategories",
  DeleteCategory: "DeleteCategory",
  UpdateCategory: "UpdateCategory"
};
const actions = {
  CategoryAddCategory: "CategoryAddCategory",
  ResetCategories: "ResetCategories",
  DeleteCategory: "DeleteCategory",
  UpdateCategory: "UpdateCategory"
};

export { getters, mutations, actions };
