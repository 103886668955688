import { toDiscreteIconImage } from "@/helpers/discreteItemsAndIcons";
import VerticalProfileChartTypes from "@/constants/VerticalProfileChartTypes";

/**
 *
 * @param {d3} svg
 * @param {*} chartType
 * @param {*} xScale
 * @param {*} elevationScale
 * @param {*} chartData
 * @param {*} dimensions
 */
export function plotDiscreteItems(
  svg,
  chartType,
  xScale,
  elevationScale,
  discreteItems,
  dimensions
) {
  svg
    .selectAll(".discrete-items")
    .data([null])
    .join("g")
    .attr("class", "discrete-items")
    .selectAll(`.discrete-item`)
    .data(discreteItems)
    .join("image")
    .attr("class", () => {
      return "discrete-item";
    })
    .attr("xlink:href", (d) => {
      return toDiscreteIconImage(d["discreteItems"]);
    })
    .attr("width", 24)
    .attr("height", 24)
    .attr("fill", "#434343")
    .attr(
      "transform",
      /** Place discrete item along x axis if chartType != 'vertical-profile'*/
      (d) => {
        const iconFilePath = toDiscreteIconImage(d["discreteItems"]);

        //These are the icon files we want to sit above (touching) the line so it does not intersect the line on the graph.
        const vertYOffset =
          iconFilePath.includes("currency-mnt.svg") ||
          iconFilePath.includes("tower-terminal.svg") ||
          iconFilePath.includes("currency-kzt.svg") ||
          iconFilePath.includes("waves.svg")
            ? 22
            : 12;

        return chartType === VerticalProfileChartTypes?.vProfile?.id
          ? //if the chart type is vertical profile
            `translate(${xScale(d["chainage"]) - 12},${
              elevationScale(d["centerline_elevation"]) - vertYOffset
            })`
          : //Otherwise if the chart type is composition
          chartType === VerticalProfileChartTypes?.composition?.id
          ? `translate(${xScale(d?.chainage) - 12 + xScale?.bandwidth() / 2},${
              dimensions[0] - 24
            })`
          : //otherwise not vertical profile or composition
            `translate(${xScale(d["chainage"]) - 12},${dimensions[0] - 24})`;
      }
    );
}
