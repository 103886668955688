<template>
  <v-menu bottom dense eager left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-show="hover"
        v-bind="attrs"
        v-on="on"
        :class="hover ? 'd-inline-flex flex-row ' : 'd-none'"
        data-cy="saved-views-list-item-menu-button"
        icon
        light
      >
        <v-icon
          data-cy="saved-view-menu-icon"
          class="saved-view-menu-icon"
          light
          >mdi-dots-horizontal</v-icon
        >
      </v-btn>
    </template>

    <v-list data-cy="saved-view-list-item-menu-options-list" dense>
      <PermissionGuard :permission="VERB.WRITE">
        <template v-slot:default="permissionSlot">
          <DeleteDialog
            @deletionConfirmed="deleteSavedView"
            :key="`delete_${title}_${id}`"
            :display-name="title"
            :item-id="`${id}`"
            :disabled="id === selectedViewId || !permissionSlot.hasAccess"
            activatorType="listItem"
            display-type="saved view"
          />
        </template>
      </PermissionGuard>
      <PermissionGuard :permission="VERB.WRITE">
        <template v-slot:default="permissionSlot">
          <RenameSavedViewItemDialog
            @refreshMetricsViewList="$emit('refreshMetricsViewList')"
            :title="title"
            :id="id"
            :isMapView="isMapView"
            :availableViews="availableViewsBasedOnType"
            :disabled="!permissionSlot.hasAccess"
          />
        </template>
      </PermissionGuard>
    </v-list>
  </v-menu>
</template>

<script>
import RenameSavedViewItemDialog from "@/components/savingView/RenameSavedViewItemDialog.vue";
import DeleteDialog from "@C/DeleteDialog.vue";
import deleteView from "@/graphql/views/deleteView";
import { mapActions, mapGetters } from "vuex";
import {
  actions as mapViewActions,
  getters as mapViewGetters
} from "@S/savingView/types";
import { getters as metricsViewGetters } from "@S/savedMetricsView/types";

import SavedViewTypes from "@/constants/SavedViewTypes";

import PermissionGuard from "@C/PermissionGuard.vue";
import { apolloClient } from "@/plugins/apolloPlugin";

export default {
  name: "SavedViewListItemMenu",
  components: {
    DeleteDialog,
    PermissionGuard,
    RenameSavedViewItemDialog
  },
  emits: ["refreshMetricsViewList"], 
  props: {
    hover: {
      type: Boolean,
      required: true
    },
    title: { type: String, required: true },
    id: { type: Number, required: true },
    viewType: {
      type: String,
      required: true
    },
    availableViews: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters([
      mapViewGetters.SavingViewsGetAvailableViews,
      mapViewGetters.SavingViewsGetActiveViewId,
      metricsViewGetters.MetricsViewGetId
    ]),
    selectedViewId() {
      if (this.viewType === SavedViewTypes.MAP) {
        return Number(this.SavingViewsGetActiveViewId);
      }
      if (this.viewType === SavedViewTypes.METRICS) {
        return Number(this.MetricsViewGetId);
      }
      return null;
    },
    isMapView() {
      return this.viewType === SavedViewTypes.MAP;
    },
    availableViewsBasedOnType() {
      if (this.viewType === SavedViewTypes.MAP) {
        return this.SavingViewsGetAvailableViews;
      }
      if (this.viewType === SavedViewTypes.METRICS) {
        return this.availableViews;
      }
      return [];
    }
  },
  methods: {
    ...mapActions([mapViewActions.SavingViewsRemoveAvailableView]),
    deleteSavedView() {
      apolloClient
        .mutate({
          mutation: deleteView,
          variables: { input: this.id }
        })
        .then((response) => {
          if (response.errors) {
            console.error("error", response.errors);
            return;
          }

          if (this.viewType === SavedViewTypes.MAP) {
            this.SavingViewsRemoveAvailableView(this.id);
          }

          if (this.viewType === SavedViewTypes.METRICS) {
            this.$emit("refreshMetricsViewList");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  }
};
</script>

<style></style>
