import types from "./types";
import ProjectFeaturesTypes from "@/constants/ProjectFeaturesTypes";

export function createEmptyState() {
  return {
    //Sidebar project features
    polygonIdsForAnalysis: [],
    pointIdsForAnalysis: [],
    lineIdsForAnalysis: [],

    //Sidebar layers tab
    layerIdsForAnalysis: [],

    //Sidebar buffers tab
    buffers: [], //In meters

    //Layers table selected row
    layerRowSelected: [], //Is an array because vuetify sends an array of selected rows. even though it is a  single-select table

    //The selected project feature ids (regardless of the type of the project feature)
    activeFeatureSelection: [],

    //The type of the project feature/or the tab that is active
    selectedFeatureTab: ProjectFeaturesTypes.POINT,

    searchText: "",
    layerFeatureSearchText: "",

    //Selected parameters (columns) for each layer.
    /*
    {
      layerId: [selectedParameterId1, selectedParameterId2, ...], ...
    }
    */
    selectedFeatureParameters: {},
    //Selected group by for each layer.
    /*
    {
      layerId: selectedGroupBy, ...
    }
    */
    selectedGroupBy: {}
  };
}

const featureTypesToStates = {
  [ProjectFeaturesTypes.POLYGON]: "polygonIdsForAnalysis",
  [ProjectFeaturesTypes.POINT]: "pointIdsForAnalysis",
  [ProjectFeaturesTypes.LINE_STRING]: "lineIdsForAnalysis"
};

const featureTypeInfos = {
  [ProjectFeaturesTypes.POLYGON]: buildProjectFeatureTabInfo(
    ProjectFeaturesTypes.POLYGON,
    "Polygon",
    true
  ),
  [ProjectFeaturesTypes.POINT]: buildProjectFeatureTabInfo(
    ProjectFeaturesTypes.POINT,
    "Point",
    false
  ),
  [ProjectFeaturesTypes.LINE_STRING]: buildProjectFeatureTabInfo(
    ProjectFeaturesTypes.LINE_STRING,
    "Line",
    false
  )
};

//Experimental maximum number of polygons that can be selected for analysis
//TODO: Rename to MAX_PROJECT_FEATURES
const MAX_POLYGONS = Number.MAX_SAFE_INTEGER;

const MAX_BUFFERS = 4;

export const DEFAULT_POLYGON_BUFFER = 0;
export const DEFAULT_POINT_LINE_BUFFER = 10;

export default {
  state: createEmptyState(),
  getters: {
    //TODO: Rename to MultiFeatureAnalysisGetMaxProjectFeatures
    [types.getters.MultiPolygonAnalysisGetMaxPolygons]: () => {
      return MAX_POLYGONS;
    },
    [types.getters.MultiPolygonAnalysisGetPolygonIds]: (state) => {
      return state.polygonIdsForAnalysis;
    },
    [types.getters.MultiPolygonAnalysisGetPointIds]: (state) => {
      return state.pointIdsForAnalysis;
    },
    [types.getters.MultiPolygonAnalysisGetLineIds]: (state) => {
      return state.lineIdsForAnalysis;
    },
    [types.getters.MultiPolygonAnalysisGetLayerIds]: (state) => {
      return state.layerIdsForAnalysis;
    },
    [types.getters.MultiPolygonAnalysisGetLayerRowSelected]: (state) => {
      return state.layerRowSelected;
    },
    [types.getters.MultiPolygonAnalysisGetActiveFeatureSelection]: (state) => {
      return state.activeFeatureSelection;
    },
    // deprecated: use MultiFeatureAnalysisGetSelectedFeatureTabInfo
    [types.getters.MultiFeatureAnalysisGetSelectedFeatureTab]: (state) => {
      return state.selectedFeatureTab;
    },
    [types.getters.MultiFeatureAnalysisGetSelectedFeatureTabInfo]: (state) => {
      return featureTypeInfos[state.selectedFeatureTab];
    },
    [types.getters.MultiPolygonAnalysisGetBuffers]: (state) => {
      return state.buffers;
    },
    [types.getters.MultiPolygonAnalysisGetMaxBuffers]: () => {
      return MAX_BUFFERS;
    },
    [types.getters.MultiPolygonAnalysisGetSearchText]: (state) => {
      return state.searchText;
    },
    [types.getters.MultiPolygonAnalysisGetLayerFeatureSearchText]: (state) => {
      return state.layerFeatureSearchText;
    },
    [types.getters.MultiPolygonAnalysisGetSelectedFeatureParameters]:
      (state) => (layerId) => {
        return state.selectedFeatureParameters[layerId] || [];
      },
    [types.getters.MultiPolygonAnalysisGetAllFeatureParameters](state) {
      return state.selectedFeatureParameters;
    },
    [types.getters.MultiPolygonAnalysisGetSelectedGroupBy]:
      (state) => (layerId) => {
        return state.selectedGroupBy[layerId] || null;
      }
  },
  mutations: {
    [types.mutations.MultiPolygonAnalysisToggleFeatureId]: (state, payload) => {
      toggleFeatureSelectionState(state, payload);
    },
    [types.mutations.MultiFeatureAnalysisSetSelectedFeatureTab]: (
      state,
      featureName
    ) => {
      setSelectedFeatureTab(state, featureName);
    },
    [types.mutations.MultiPolygonAnalysisSetBuffers](state, buffers) {
      Vue.set(state, "buffers", buffers);
    },
    [types.mutations.MultiPolygonAnalysisResetState](state) {
      Object.assign(state, createEmptyState());
    },
    [types.mutations.MultiPolygonAnalysisSetLayerIds](state, layerIds) {
      Vue.set(state, "layerIdsForAnalysis", layerIds);
    },
    [types.mutations.MultiPolygonAnalysisSetLayerRowSelected](state, row) {
      Vue.set(state, "layerRowSelected", row);
    },
    [types.mutations.MultiPolygonAnalysisSetSearchText](state, searchText) {
      Vue.set(state, "searchText", searchText);
    },
    [types.mutations.MultiPolygonAnalysisSetLayerFeatureSearchText](
      state,
      layerFeatureSearchText
    ) {
      Vue.set(state, "layerFeatureSearchText", layerFeatureSearchText);
    },
    [types.mutations.MultiPolygonAnalysisSetSelectedFeatureParameters](
      state,
      layerIdAndParameters
    ) {
      if (layerIdAndParameters.parameters?.length <= 0) {
        Vue.delete(
          state.selectedFeatureParameters,
          layerIdAndParameters.layerId
        );
        return;
      }

      Vue.set(
        state.selectedFeatureParameters,
        layerIdAndParameters.layerId,
        layerIdAndParameters.parameters
      );
    },
    [types.mutations.MultiPolygonAnalysisSetSelectedGroupBy](
      state,
      layerIdAndGroupBy
    ) {
      //If the group by is null, remove the layer from the selected group by
      if (layerIdAndGroupBy.groupBy === null) {
        Vue.delete(state.selectedGroupBy, layerIdAndGroupBy.layerId);
        return;
      }

      //Otherwise, set the group by
      Vue.set(
        state.selectedGroupBy,
        layerIdAndGroupBy.layerId,
        layerIdAndGroupBy.groupBy
      );
    },

    [types.mutations.MultiPolygonAnalysisOverwriteFeatureParameters](
      state,
      payload
    ) {
      Vue.set(state, "selectedFeatureParameters", payload);
    }
  },
  actions: {
    [types.actions.MultiPolygonAnalysisSetAnalysisReportProperties](
      { commit },
      { layerIDs, buffers, layerParams }
    ) {
      commit(types.mutations.MultiPolygonAnalysisSetLayerIds, layerIDs);
      commit(types.mutations.MultiPolygonAnalysisSetBuffers, buffers);
      commit(types.mutations.MultiPolygonAnalysisOverwriteFeatureParameters, {
        ...layerParams
      });
    },
    [types.actions.MultiPolygonAnalysisToggleFeatureId]({ commit }, payload) {
      commit(types.mutations.MultiPolygonAnalysisToggleFeatureId, payload);
    },
    [types.actions.MultiPolygonAnalysisSetLayerIds]({ commit }, layerIds) {
      commit(types.mutations.MultiPolygonAnalysisSetLayerIds, layerIds);
    },
    [types.actions.MultiPolygonAnalysisSetLayerRowSelected]({ commit }, row) {
      commit(types.mutations.MultiPolygonAnalysisSetLayerRowSelected, row);
    },
    [types.actions.MultiPolygonAnalysisSetBuffers]({ commit }, buffers) {
      commit(types.mutations.MultiPolygonAnalysisSetBuffers, buffers);
    },
    [types.actions.MultiPolygonAnalysisResetState]({ commit }) {
      commit(types.mutations.MultiPolygonAnalysisResetState);
    },
    [types.actions.MultiFeatureAnalysisSetSelectedFeatureTab](
      { commit },
      featureName
    ) {
      commit(
        types.mutations.MultiFeatureAnalysisSetSelectedFeatureTab,
        featureName
      );
    },
    [types.actions.MultiPolygonAnalysisSetSearchText]({ commit }, searchText) {
      commit(types.mutations.MultiPolygonAnalysisSetSearchText, searchText);
    },
    [types.actions.MultiPolygonAnalysisSetLayerFeatureSearchText](
      { commit },
      layerFeatureSearchText
    ) {
      commit(
        types.mutations.MultiPolygonAnalysisSetLayerFeatureSearchText,
        layerFeatureSearchText
      );
    },
    [types.actions.MultiPolygonAnalysisSetSelectedFeatureParameters](
      { commit },
      layerIdAndParameters
    ) {
      commit(
        types.mutations.MultiPolygonAnalysisSetSelectedFeatureParameters,
        layerIdAndParameters
      );
    },
    [types.actions.MultiPolygonAnalysisSetSelectedGroupBy](
      { commit },
      layerIdAndGroupBy
    ) {
      commit(
        types.mutations.MultiPolygonAnalysisSetSelectedGroupBy,
        layerIdAndGroupBy
      );
    }
  }
};

function setSelectedFeatureTab(state, featureName) {
  // Update the current feature type selection
  Vue.set(state, "selectedFeatureTab", featureName);

  // When a feature tab is selected, reset the selections for
  // the other feature types
  const featureTypes = Object.values(ProjectFeaturesTypes);
  featureTypes.forEach((featureType) => {
    if (featureType !== featureName) {
      const featureTypeState = featureTypesToStates[featureType];
      Vue.set(state, featureTypeState, []);
    }
  });

  // Update the current active feature item selection based on the updated
  // feature type
  Vue.set(state, "activeFeatureSelection", getCurrentSelectedIds(state));

  setDefaultBufferValue(state, featureName);
}

function toggleFeatureSelectionState(state, { featureId, featureType }) {
  const featureTypeState = featureTypesToStates[featureType];
  if (!state[featureTypeState].includes(featureId)) {
    Vue.set(state, featureTypeState, [...state[featureTypeState], featureId]);
  } else {
    const newIds = state[featureTypeState]?.filter((id) => id !== featureId);
    Vue.set(state, featureTypeState, newIds);
  }
  Vue.set(state, "activeFeatureSelection", getCurrentSelectedIds(state));
}

function getCurrentSelectedIds(state) {
  const featureTypeState = featureTypesToStates[state.selectedFeatureTab];
  return state[featureTypeState];
}

function addBuffer(state, bufferValue) {
  const updatedBuffers = [...state.buffers, bufferValue];
  Vue.set(state, "buffers", updatedBuffers);
}

function setDefaultBufferValue(state) {
  if (state.buffers.length === 0) {
    // If there are no buffers defined, add a default based on Feature Type
    if (state.selectedFeatureTab === ProjectFeaturesTypes.POLYGON) {
      addBuffer(state, DEFAULT_POLYGON_BUFFER);
    } else {
      addBuffer(state, DEFAULT_POINT_LINE_BUFFER);
    }
  }
}

function buildProjectFeatureTabInfo(id, displayName, supportsZeroValueBuffer) {
  return {
    id: id,
    displayName: displayName,
    supportsZeroValueBuffer: supportsZeroValueBuffer
  };
}
