import { HttpLink } from "@apollo/client";
import { ApolloClient } from "@apollo/client/core";

import { WebSocketLink } from "apollo-link-ws";
import { InMemoryCache } from "apollo-cache-inmemory";

import { getTokenSilently } from "@/plugins/authPlugin";

import { Config } from "./config-importer";
import { from, split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { RetryLink } from "@apollo/client/link/retry";

const getHeaders = async () => {
  const headers = {};
  try {
    const token = await getTokenSilently();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
  } catch (error) {
    console.error("error grabbing access token for apollo link\n", error);
  }
  headers["Content-Type"] = "application/json";
  return headers;
};

// Create an http link:
const httpLink = new HttpLink({
  uri: `${Config.apiBaseAddress}/V2/graphql`,
  fetch: async (uri, options) => {
    options.headers = await getHeaders();
    return fetch(uri, options);
  }
});

// Create the subscription websocket link
const wsLink = new WebSocketLink({
  uri: `${Config.apiBaseAddress}/V2/graphql`.replace("http", "ws"), //This also replaces https with wss

  options: {
    reconnect: true,
    connectionParams: async () => {
      return await getHeaders();
    }
  }
});

// RetryLink takes an options object. It implements a retry back off, so leaves longer gaps between each retry
// Docs here https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: (error) => {
      // Retry if there's any error returned
      // These can just be error messages from apollo, not necessarily status codes
      return !!error;
    }
  }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  // Using from should ensure that both retryLink AND then httpLink should be run
  from([retryLink, httpLink])
);

export let apolloClient;
// Create the apollo client
export const instantiateApolloClient = () => {
  apolloClient = new ApolloClient({
    // typeDefs,
    // resolvers: {}, //As you can see, we've added also an empty resolvers object here: if we don't assign it to the Apollo client options, it won't recognize the queries to local state and will try to send a request to remote URL instead.
    cache: new InMemoryCache({
      addTypename: false
    }),
    link: link,
    connectToDevTools: true,
    defaultOptions: {
      // errorPolicy is set to default which is "none"
      // Meaning that partial data will not be returned if there is an error
      query: {
        fetchPolicy: "no-cache"
        // fetchPolicy: "network-only", // Used for first execution
        // nextFetchPolicy: "cache-first" // Used for subsequent executions
      },
      mutate: {
        fetchPolicy: "no-cache"
      }
    }
  });
};
