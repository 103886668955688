class LayerDefinition {
  name = null;
  title = null;
  toggleable = true;
  featureFlag = null;
  onByDefault = false;
  fillName = null;
  viewName = null;
  constructor({
    name,
    title,
    toggleable,
    featureFlag,
    onByDefault,
    fillName,
    viewName
  }) {
    this.name = name;
    this.title = title;
    this.toggleable = toggleable === undefined ? this.toggleable : toggleable;
    this.featureFlag = featureFlag;
    this.onByDefault = onByDefault;
    this.fillName = fillName;
    this.viewName = viewName;

    return {
      name: this.name,
      title: this.title,
      toggleable: this.toggleable,
      featureFlag: this.featureFlag,
      onByDefault: this.onByDefault,
      fillName: this.fillName,
      viewName: this.viewName
    };
  }

  toString() {
    return this.name;
  }
}

export const OS_50K_Basemap_Name = "OS_50K_Basemap";
export const OS_50K_Basemap_Url = "mapbox://emilhansen.bcrmirmm";

export const OS_10K_Basemap_Name = "OS_10K_Basemap";
export const OS_10K_Basemap_Url =
  "https://api.ellipsis-drive.com/v3/path/c9c68216-aacc-4611-a97c-4e5dc795b2fa/raster/timestamp/59d50e08-4770-430a-b23f-dc47ec1504e0/tile/{z}/{x}/{y}?style=56e28a7d%2d79be%2d49ba%2d8cce%2db7c3141868b1&token=epat_V8RAuhcVTwrK9UK9nzGJMBysFn350WScLkXvuG7FSXHhq2Ih8xWnS5qMy5AQJahV";

export const B2P_AERIAL_IMAGERY_NAME = "Aerial Imagery Sep 2023";
export const B2P_AERIAL_IMAGERY_URL =
  "https://api.ellipsis-drive.com/v3/path/b02bd877-4287-485a-b670-11923540c4f9/raster/timestamp/994e0d01-ee22-458e-9a55-359ad756adb8/tile/{z}/{x}/{y}?style=82050a29%2dd2cb%2d45e2%2db64c%2d94f341fe6d9b&token=epat_EUwVJwwSab6ExkyeFOf8jIdgWjMRAWTTvIkUljJtCV56Z8PCRfY1IEQpvTDxPCzj";

export const zIndexLayers = {
  lazilyLoadedOptionsZIndex: "lazily-loaded-options-z-index",
  iconographyZIndex: "iconography-z-index",
  casesZIndex: "cases-z-index",
  oppsmapsZIndex: "oppsmaps-z-index",
  heatmapsZIndex: "heatmaps-z-index",
  corridorsZIndex: "corridors-z-index",
  projectBoundsZIndex: "project-bounds-z-index",
  vectorsZIndex: "vectors-z-index",
  contourZIndex: "contour-z-index",
  rastersZIndex: "rasters-z-index"
};

export const LAYERS = {
  DATASETS_POLYGONS_LAYER: new LayerDefinition({
    name: "datasets-polygons-layer",
    title: "Datasets Polygons",
    toggleable: false
  }),
  OPTION_POLYGON_OUTPUTS_LAYER: new LayerDefinition({
    name: "option-polygon-outputs-layer",
    title: "Option Metrics Polygons",
    toggleable: true
  }),
  DATASETS_POLYGONS_OUTLINES_LAYER: new LayerDefinition({
    name: "datasets-polygons-outlines-layer",
    title: "Datasets Polygons Outlines",
    toggleable: false
  }),
  DATASETS_LINES_LAYER: new LayerDefinition({
    name: "datasets-lines-layer",
    title: "Datasets Lines",
    toggleable: false
  }),
  DATASETS_HIGHLIGHTS_LAYER: new LayerDefinition({
    name: "datasets-highlights-layer",
    title: "Datasets Feature Highlights",
    toggleable: false
  }),
  ROUTE_POINTS_LAYER: new LayerDefinition({
    name: "route-points-layer",
    title: "Points",
    onByDefault: true,
    viewName: "ROUTE_POINTS_LAYER"
  }),
  ROUTE_POINTS_LABELS: new LayerDefinition({
    name: "route-points-labels-layer",
    title: "Points",
    onByDefault: true
  }),
  LINE_LABELS: new LayerDefinition({
    name: "line-labels-layer",
    title: "Lines",
    onByDefault: true
  }),
  POLYGON_LABELS: new LayerDefinition({
    name: "polygon-labels-layer",
    title: "Polygons",
    onByDefault: true
  }),
  CONFIG_POINTS_LAYER: new LayerDefinition({
    name: "config-points-layer",
    title: "Config Points",
    toggleable: false
  }),
  WAYPOINTS_LAYER: new LayerDefinition({
    name: "waypoints-layer",
    title: "Waypoints",
    toggleable: false
  }),
  LAZILY_LOADED_OPTIONS_LAYER: new LayerDefinition({
    name: "lazily-loaded-options-layer",
    title: "Lazily Loaded Options",
    toggleable: false
  }),
  OPTIONS_LAYER: new LayerDefinition({
    name: "options-layer",
    title: "Options",
    toggleable: false,
    viewName: "OPTIONS_LAYER",
    onByDefault: true
  }),
  OPTIONS_ACTIVE_LAYER: new LayerDefinition({
    name: "options-active-layer",
    title: "Active Options",
    toggleable: false
  }),
  OPTIONS_SELECTED_LAYER: new LayerDefinition({
    name: "options-selected-layer",
    title: "Selected Options",
    toggleable: false
  }),
  OPTIONS_ICONS_LAYER: new LayerDefinition({
    name: "options-icons-layer",
    title: "Options Iconography",
    toggleable: false
  }),
  HORIZONTAL_PROFILES_LAYER: new LayerDefinition({
    name: "horizontal-profiles-layer",
    title: "Lines",
    onByDefault: true,
    viewName: "HORIZONTAL_PROFILES_LAYER"
  }),
  CORRIDORS_LAYER: new LayerDefinition({
    name: "corridors-layer",
    fillName: "corridors-layer-fill",
    title: "Polygons",
    onByDefault: true,
    viewName: "POLYGONS_LAYER"
  }),
  HEATMAPS_LAYER: new LayerDefinition({
    name: "heatmaps-layer",
    title: "Heatmaps",
    toggleable: true,
    onByDefault: true,
    viewName: "HEATMAPS_LAYER"
  }),
  OPPORTUNITY_MAPS_LAYER: new LayerDefinition({
    name: "opportunity-maps-layer",
    fillName: "opportunity-maps-fill",
    title: "Polygon Results",
    toggleable: true,
    onByDefault: true,
    viewName: "OPPMAPS_LAYER"
  }),
  COMMENTS_LAYER: new LayerDefinition({
    name: "comments-layer",
    title: "Comments",
    onByDefault: true,
    toggleable: true,
    viewName: "COMMENTS_LAYER"
  }),
  PROJECT_BOUNDARIES_LAYER: new LayerDefinition({
    name: "project-boundary-layer",
    title: "Project Boundaries",
    toggleable: false
  }),
  WORLD_BOUNDARIES_LAYER: new LayerDefinition({
    name: "world-boundaries-layer",
    title: "World Boundaries",
    toggleable: false
  }),
  ROUTE_ICONS_COUNTER_LAYER: new LayerDefinition({
    name: "route-icons-counter-layer",
    title: "Route Icons Counter",
    toggleable: false
  }),
  GOOGLE_3D_TILES_LAYER: new LayerDefinition({
    name: "google-3d-tiles-layer",
    title: "Expiremental Google 3D Tiles",
    toggleable: true,
    onByDefault: false
  }),

  asArray() {
    const obj = new Array();
    for (const key of Object.keys(this)) {
      if (key == "asArray") {
        continue;
      }
      obj.push(this[key]);
    }
    return obj;
  }
};
export const SOURCES = {
  OPTION_METRICS_POLYGON_SOURCE: "option-metrics-polygon-source",
  WORLD_BOUNDARIES_SOURCE: "world-boundaries-source",
  PROJECT_BOUNDARIES_SOURCE: "project-boundaries-source",
  WAYPOINTS_SOURCE: "waypoints-source",
  CONFIG_POINTS_SOURCE: "config-points-source",
  HEATMAPS_SOURCE: "heatmaps-source",
  OPPORTUNITY_MAPS_SOURCE: "opportunity-maps-source",
  OPPORTUNITY_MAPS_OUTLINE_SOURCE: "opportunity-maps-outline-source",
  LAZILY_LOADED_OPTIONS_SOURCE: "lazily-loaded-options-source",
  OPTIONS_SOURCE: "options-source",
  ROUTE_POINTS_SOURCE: "route-points-source",
  COMMENTS_SOURCE: "comments-source",
  HORIZONTAL_PROFILES_SOURCE: "horizontal-profiles-source",
  CORRIDORS_SOURCE: "corridors-source",
  VECTOR_DATASETS_SOURCE: "vector-datasets-source",
  VECTOR_DATASETS_FEATURE_HIGHLIGHTS_SOURCE:
    "vector-datasets-feature-highlights-source",
  VECTOR_DATASETS_LINES_SOURCE: "vector-datasets-line-source"
};
