import gql from "graphql-tag";

export default (projectId) => {
  return {
    query: gql`
      query getUsersByProjectId($input: ListUsersInput!) {
        users(input: $input) {
          users {
            MFARequired
            id
            nickname
            pictureURL
            role
            email
          }
        }
      }
    `,
    variables: { input: { filters: { projectID: projectId } } },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first"
  };
};
