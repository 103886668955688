import Options from "@/constants/Options";

const sortOptions = ({ items, optionSort, optionsAreGroupedByCase }) => {
  const sortField = optionSort.sortField;

  const isAlphabeticalSort =
    sortField === Options.sorters.sortFields.alphabetical.field;
  const isDescending =
    optionSort.direction === Options.sorters.directions.descending;

  if (shouldSortAlphabetically({ sortField, optionsAreGroupedByCase })) {
    sortByAlphabet(items, isAlphabeticalSort);
  } else {
    sortByField(items, sortField);
  }

  if (isDescending) {
    items.reverse();
  }

  return items;
};

const shouldSortAlphabetically = ({ sortField, optionsAreGroupedByCase }) => {
  return (
    sortField === Options.sorters.sortFields.dateCreated.field ||
    optionsAreGroupedByCase
  );
};

const sortByAlphabet = (items, isAlphabeticalSort) => {
  const alphabetField = Options.sorters.sortFields.alphabetical.field;

  items.sort((a, b) => {
    const aValue = a[alphabetField] ? a[alphabetField].toString() : "";
    const bValue = b[alphabetField] ? b[alphabetField].toString() : "";

    return isAlphabeticalSort
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });
};

const sortByField = (items, sortField) => {
  items.sort((a, b) => a[sortField] - b[sortField]);
};

export default sortOptions;
