import { InteractionStates } from "@/constants/InteractionStates";
import {
  inferNewInteractionState,
  addItemWithFifoLengthEnforcement,
  getDefaultColoursAvailable,
  colourIsInThemeColours,
  updateMetricForDisplay,
  getOptionsWithMetricValues,
  mapOptionsWithMetricValues,
  groupMetricsByName,
  formatGroupedMetrics
} from "@/store/option/helperfunctions";
import newEmptyOptionState from "@/store/option/emptyState";
import { getters as savedMetricsViewGetters } from "@S/savedMetricsView/types";

import { NewOptionTypes as types } from "@S/option/types";
import generateRandomRgbaString from "@/helpers/generateRandomRgba";
import sortOptions from "@/helpers/sortOptions";
import { OptionSelectionGrouping } from "@S/optionVisualisation/module";

import Options from "@/constants/Options";
import ResultTypes from "@/constants/ResultTypes";
import LoadingStates from "@/constants/LoadingStates";
import { CaseTypes } from "@S/case/types";
import { OptionVisualisationTypes } from "@S/optionVisualisation/types";

import { findKey, uniqBy } from "lodash";

export default {
  types: types,
  newEmptyOptionState, //also included here to be able to use it in tests
  state: newEmptyOptionState(),
  getters: {
    "options/getAll": (state) => {
      return state.options;
    },

    [types.getters.GetArchivedOptions](state) {
      return state.archivedOptions;
    },
    [types.getters.GetLazilyLoadedActiveOptionGeojson](state) {
      return state.lazilyLoadedActiveOptionGeojson;
    },
    [types.getters.GetLazilyLoadedHoveredOptionGeojson](state) {
      return state.lazilyLoadedHoveredOptionGeojson;
    },
    [types.getters.GetAmountOfOptionsUserCanSelect]() {
      return Options.maxNOptionsSelected;
    },
    [types.getters.GetOptionDateSort](state, getters) {
      return (
        getters[types.getters.GetOptionSort].sortField ===
        Options.sorters.sortFields.dateCreated.field
      );
    },
    [types.getters.GetSummariesOfSelectedOptions](state, getters) {
      const optionSummaries = state.selectedOptionIds.reduce(
        (accumulatorOfOptionSummaries, element) => {
          accumulatorOfOptionSummaries.push(
            getters[types.getters.GetOptionSummaryById](String(element))
          );
          return accumulatorOfOptionSummaries;
        },
        []
      );
      return optionSummaries;
    },
    [types.getters.GetActiveOptionSummary](state, getters) {
      return getters[types.getters.GetOptionSummaryById](
        String(state.activeOptionId)
      );
    },
    [types.getters.GetAllOptionsSummariesSorted](state, getters) {
      return sortOptions({
        items: getters[types.getters.GetAllOptionSummaries],
        optionSort: getters[types.getters.GetOptionSort],
        optionsAreGroupedByCase:
          getters[types.getters.GetOptionsAreGroupedByCase]
      });
    },
    [types.getters.GetSortedSelectedOptionsForAutocompleteList](
      state,
      getters
    ) {
      const optionsSummaries =
        getters[types.getters.GetSummariesOfSelectedOptions];

      return sortOptions({
        items: optionsSummaries,
        optionSort: getters[types.getters.GetOptionSort],
        optionsAreGroupedByCase:
          getters[types.getters.GetOptionsAreGroupedByCase]
      });
    },
    [types.getters.GetOptionsAreGroupedByCase](
      state,
      getters,
      rootState,
      rootGetters
    ) {
      return (
        rootGetters[
          OptionVisualisationTypes.getters.OptionVisGetSelectionGrouping
        ] === OptionSelectionGrouping.CASE
      );
    },
    [types.getters.GetOptionSort](state) {
      return state.optionSort;
    },
    [types.getters.GetRouteIconsCountersVisibility](state) {
      return state.routeIconsCounters;
    },
    [types.getters.OptionGetAllIds](state) {
      return Object.keys(state.options);
    },
    [types.getters.GetAllOptionSummaries](state, getters) {
      return Object.keys(state.options).map((id) => {
        return getters[types.getters.GetOptionSummaryById](id);
      });
    },
    [types.getters.AnyOptionsLoading](state) {
      return Object.values(state.loadingMap).some(
        (loadingState) => loadingState === LoadingStates.LOADING
      );
    },
    [types.getters.GetOptionLoadingState]: (state) => (optionId) => {
      return state.loadingMap?.[optionId];
    },
    [types.getters.GetOptionDisplayValue]: (state, getters) => (optionId) => {
      const caseName = getters[types.getters.OptionGetCaseName](optionId);
      const fallbackName = caseName
        ? `${caseName} - #${optionId}`
        : "Option #" + optionId;

      const displayValue =
        getters[types.getters.OptionGetAlias](optionId) || fallbackName;
      return displayValue;
    },
    [types.getters.GetOptionSummaryById]: (state, getters) => (optionId) => {
      if (!optionId) {
        return null;
      }
      const caseName = getters[types.getters.OptionGetCaseName](optionId);
      const alias = getters[types.getters.OptionGetAlias](optionId);
      const displayValue = alias || `${caseName} - #${optionId}`;

      return {
        id: optionId.toString(),
        displayValue,
        alias,
        caseName: caseName,
        caseId: getters[types.getters.OptionGetCaseId](optionId),
        resultType: ResultTypes.OPTION.key
      };
    },
    [types.getters.OptionGetLength]: (state) => (optionId) => {
      const result =
        state?.options?.[optionId]?.geojsonLength ||
        state.options?.[optionId]?.optionMetrics.find((element) => {
          return (
            element.name == "total_length" || element.name == "total_length_2D"
          );
        })?.value;
      return result;
    },
    [types.getters.OptionGetCaseId]: (state) => (optionId) => {
      return state.caseIdsForOptions?.[optionId]?.toString() || null;
    },
    [types.getters.OptionGetCaseName]: (state, getters) => (optionId) => {
      const caseId = getters[types.getters.OptionGetCaseId](optionId);
      if (!caseId) {
        return null;
      }
      return getters[CaseTypes.getters.CaseGetName](caseId);
    },
    [types.getters.OptionGetAlias]: (state) => (optionId) => {
      optionId = Number(optionId);
      return state.options[optionId]?.alias || "";
    },
    [types.getters.OptionGetScenarios]: (state) => (optionId) => {
      optionId = Number(optionId);
      return state.options[optionId]?.scenarios || [];
    },
    [types.getters.OptionsGetSelectedIds]: (state) => {
      return state.selectedOptionIds;
    },
    [types.getters.OptionsGetActiveId]: (state) => {
      return state.activeOptionId;
    },
    [types.getters.OptionsGetHovered]: (state) => {
      return state.hoveredOption;
    },
    [types.getters.OptionsGetVisibleIds]: (state) => {
      return state.visibleOptionIds || [];
    },
    [types.getters.OptionsGetVisibleIdsAsSet]: (_, getters) => {
      return new Set(getters[types.getters.OptionsGetVisibleIds]);
    },
    [types.getters.OptionsGetAllColours]: (state) => {
      return state.colourMap;
    },
    [types.getters.OptionsGetVisiblePolygonOutputNames]: (state) => {
      return state.visiblePolygonOutputNames;
    },
    [types.getters.GetPolygonOutputsForOption]: (state) => (optionId) => {
      optionId = Number(optionId);
      return state.options?.[optionId]?.polygonOutputs || [];
    },
    [types.getters.OptionsGetPolygonOutputsForAllOptions]: (state) => {
      return Object.fromEntries(
        Object.entries(state.options).map(([id, option]) => [
          id,
          option.polygonOutputs
        ])
      );
    },

    [types.getters.GetSelectedOptionsMetricItems]: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      const workingMetricNames =
        rootGetters[savedMetricsViewGetters.MetricsViewGetWorkingMetricNames];

      const metricItemsOfSelectedOptions =
        getters[types.getters.GetGroupedMetricItemsOfSelectedOptions];

      const filteredMetrics = metricItemsOfSelectedOptions.filter((item) =>
        workingMetricNames.includes(item?.name)
      );

      //Selected metrics can have weights, so fetch the weight from the saved metrics view module and add it to the metric item
      const filteredMetricsWithWeights = filteredMetrics.map((metric) => {
        const weight =
          rootGetters[savedMetricsViewGetters.MetricsViewGetMetricsByName]()[
            metric.name
          ]?.weight;

        return {
          ...metric,
          weight
        };
      });

      return filteredMetricsWithWeights;
    },
    [types.getters.GetGroupedMetricItemsOfSelectedOptions]: (state) => {
      const optionsWithMetricValues = getOptionsWithMetricValues(state);
      const optionsWithMetricValuesMapped = mapOptionsWithMetricValues(
        optionsWithMetricValues
      );
      const groupedMetrics = groupMetricsByName(optionsWithMetricValuesMapped);
      return formatGroupedMetrics(groupedMetrics);
    },
    [types.getters.OptionGetOptionMetrics]: (state) => (optionId) => {
      optionId = Number(optionId);

      return state.options[optionId]?.optionMetrics || [];
    },
    [types.getters.GetOptionColour]: (state) => (optionId) => {
      optionId = Number(optionId);
      const optionColour = state.colourMap[optionId] || null;
      return optionColour;
    },
    [types.getters.OptionGetDiscreteItems]: (state) => (optionId) => {
      return state.options?.[optionId]?.discreteItems || [];
    },
    [types.getters.OptionGetObjectives]: (state) => (optionId) => {
      if (state.options?.[optionId]?.constraintViolation > 0) {
        return [
          {
            name: "CONSTRAINT_VIOLATION",
            value: state.options?.[optionId]?.constraintViolation
          }
        ];
      }
      return state.options?.[optionId]?.objectiveMetrics || [];
    },
    [types.getters.OptionGetTopObjective]: (state, getters) => (optionId) => {
      //Get the Top objective type for an option. e.g. BALANCED, LOWEST_IMPACT, null, etc.

      if (!state.options[optionId]) {
        return null;
      }

      const caseId = getters[types.getters.OptionGetCaseId](optionId);
      const caseTopObjectiveOptions =
        getters[CaseTypes.getters.CaseGetTopObjectiveOptions](caseId);

      const found = findKey(
        caseTopObjectiveOptions,
        (topOptionId) => topOptionId === Number(optionId)
      );
      return found || null;
    },
    [types.getters.GetOptionConstraintViolation]: (state) => (optionId) => {
      return state.options?.[optionId]?.constraintViolation || 0;
    }
  },
  actions: {
    [types.actions.OptionSetHover]: ({ dispatch }, payload) => {
      const { event, option } = payload;

      const optionId = option?.id || option; //if option is an object, it will have an id property, if it's just an id, it will be the option itself

      switch (event?.type) {
        case "mouseenter":
          dispatch(types.actions.OptionToggleHover, { optionId, setTo: true });
          break;
        case "mouseleave":
          dispatch(types.actions.OptionToggleHover, { optionId, setTo: false });
          break;
      }
    },
    [types.actions.SetLazilyLoadedActiveOptionGeojson]: (
      { commit },
      payload
    ) => {
      commit(types.mutations.SetLazilyLoadedActiveOptionGeojson, payload);
    },
    [types.actions.SetLazilyLoadedHoveredOptionGeojson]: (
      { commit },
      payload
    ) => {
      commit(types.mutations.SetLazilyLoadedHoveredOptionGeojson, payload);
    },
    [types.actions.SetOptionSort]: ({ commit }, payload) => {
      commit(types.mutations.SetOptionSort, payload);
    },
    [types.actions.SetRouteIconsCountersVisibility]: ({ commit }, payload) => {
      commit(types.mutations.SetRouteIconsCountersVisibility, payload);
    },
    [types.actions.OptionsSetCaseId]: ({ commit }, payload) => {
      commit(types.mutations.OptionsSetCaseId, payload);
    },

    [types.actions.OptionsResetState]: ({ commit }) => {
      commit(types.mutations.OptionsResetState);
    },
    [types.actions.OptionsSetPolygonOutputVis]: ({ commit }, payload) => {
      commit(types.mutations.OptionsSetPolygonOutputVis, payload);
    },
    [types.actions.OptionSetPolygonOutputsForOption]: ({ commit }, payload) => {
      commit(types.mutations.OptionSetPolygonOutputsForOption, payload);
    },
    [types.actions.OptionsAdd]: ({ commit, getters }, option) => {
      const polygonOutputs = [];
      option?.polygonOutputs?.forEach((metric) => {
        //TODO: load the actual value from indexed db if it's there. Otherwise leave it empty

        polygonOutputs.push({
          name: metric.name,
          value: null,
          title: metric.title,
          description: metric.description
        });
      });
      option.polygonOutputs = polygonOutputs;

      option?.optionMetrics?.forEach(updateMetricForDisplay);

      //We need to rename the "NORMALISED_DISTANCE" objective to "EXISTING_RIGHTS_OF_WAY" if it exists
      const mappedMetrics = option?.objectiveMetrics?.map((objectiveMetric) => {
        if (objectiveMetric.name === "NORMALISED_DISTANCE") {
          objectiveMetric.name = "EXISTING_RIGHTS_OF_WAY";
        }
        return objectiveMetric;
      });

      option["objectiveMetrics"] = mappedMetrics;

      commit(types.mutations.OptionsAdd, { option });

      let caseId = null;
      if (option?.caseId) {
        caseId = option?.caseId;
      } else {
        const caseIdAndName = getters[CaseTypes.getters.GetOptionCaseNameAndId](
          option.id
        );
        if (caseIdAndName) {
          caseId = caseIdAndName?.id;
        }
      }

      // Retrieve case info from the case module and commit it
      commit(types.mutations.OptionsSetCaseId, {
        caseId: caseId,
        optionId: option.id
      });

      commit(types.mutations.SetOptionLoadingState, {
        optionId: option?.id,
        loadingState: LoadingStates.LOADED
      });
    },
    [types.actions.OptionsUpdateOptionMetrics]: (
      { commit },
      { optionId, newMetrics }
    ) => {
      commit(types.mutations.OptionsUpdateOptionMetrics, {
        optionId,
        newMetrics
      });
    },
    [types.actions.OptionUpdate]: (
      { commit },
      { optionId, alias, scenarios }
    ) => {
      commit(types.mutations.OptionUpdate, { optionId, alias, scenarios });
    },
    [types.actions.RemoveOption]: ({ commit, getters }, optionId) => {
      const caseId = getters[types.getters.OptionGetCaseId](optionId);
      commit(types.mutations.RemoveOption, optionId);
      if (caseId) {
        commit(CaseTypes.mutations.CaseDeleteOption, { caseId, optionId });
      }
    },
    [types.actions.OptionToggleHover]: ({ commit }, { optionId, setTo }) => {
      commit(types.mutations.OptionToggleHover, { optionId, setTo });
    },
    [types.actions.OptionsToggleInteractionState]: (
      { commit },
      { optionId, interactionState }
    ) => {
      commit(types.mutations.OptionsToggleInteractionState, {
        optionId,
        interactionState
      });
    },
    [types.actions.OptionsToggleVisibility]: (
      { commit },
      { optionIds, setTo, overwrite }
    ) => {
      //optionIds can be either an array or a single optionId
      //setTo is optional boolean, if true, option will be visible, if false, option will be hidden
      if (optionIds === undefined) {
        throw new Error("optionIds is required");
      }
      commit(types.mutations.OptionsToggleVisibility, {
        optionIds,
        setTo,
        overwrite
      });
    },
    [types.actions.SetOptionColour]: ({ commit }, { optionId, rgbaColour }) => {
      commit(types.mutations.SetOptionColour, { optionId, rgbaColour });
    },
    [types.actions.SetAllOptionColours]: (
      { commit },
      arrayOfColoursWithIds
    ) => {
      if (!arrayOfColoursWithIds || !Array.isArray(arrayOfColoursWithIds)) {
        console.error(
          types.actions.SetAllOptionColours,
          "called with no array of colours"
        );
        return;
      }
      let missingIds = false;
      const missingColours = [];
      const newColourMap = {};
      // Translate array into colour map for use in module
      arrayOfColoursWithIds.forEach((colourIdPair) => {
        const id = colourIdPair?.id;
        if (!id) {
          missingIds = true;
          return;
        }
        const colour = colourIdPair?.colour;
        if (!colour) {
          missingColours.push(id);
          return;
        }
        newColourMap[id] = colour;
      });

      if (missingIds) {
        console.error(
          types.actions.SetAllOptionColours,
          "Some colour entries were missing option ids"
        );
      }
      if (missingColours.length) {
        console.error(
          types.actions.SetAllOptionColours,
          "These options were missing colours",
          missingColours
        );
      }

      commit(types.mutations.SetAllOptionColours, newColourMap);
    }
  },
  mutations: {
    [types.mutations.SetArchivedOptions]: (state, options) => {
      // THIS MUTATION IS USED TO ARCHIVE AND UNARCHIVE OPTIONS IN THE ARCHIVE TABLE - DONT USE IT FOR ANYTHING WEIRD
      Vue.set(state, "archivedOptions", options);
    },
    [types.mutations.SetLazilyLoadedActiveOptionGeojson]: (state, payload) => {
      Vue.set(state, "lazilyLoadedActiveOptionGeojson", payload);
    },
    [types.mutations.SetLazilyLoadedHoveredOptionGeojson]: (state, payload) => {
      Vue.set(state, "lazilyLoadedHoveredOptionGeojson", payload);
    },
    [types.mutations.SetOptionSort]: (state, payload) => {
      Vue.set(state.optionSort, "direction", payload.direction);
      Vue.set(state.optionSort, "sortField", payload.sortField);
    },
    [types.mutations.SetRouteIconsCountersVisibility]: (state, payload) => {
      Vue.set(state.routeIconsCounters, payload.routeIconName, payload.value);
    },
    [types.mutations.OptionsUpdateOptionMetrics]: (
      state,
      { optionId, newMetrics }
    ) => {
      if (!optionId) {
        throw new Error("optionId is required");
      }
      optionId = Number(optionId);

      newMetrics.forEach(updateMetricForDisplay);
      const existingMetrics = state.options?.[optionId]?.optionMetrics || [];
      const totalMetrics = existingMetrics.concat(newMetrics);
      const deduplicatedMetrics = uniqBy(totalMetrics, "name");

      if (!state.options[optionId]) {
        Vue.set(state.options, optionId, {});
      }
      Vue.set(state.options[optionId], "optionMetrics", deduplicatedMetrics);
    },
    [types.mutations.OptionSetPolygonOutputsForOption]: (
      state,
      { optionID, polygonOutputs }
    ) => {
      if (!optionID) {
        throw new Error("optionID is required");
      }
      const optionPolygonOutputs = [];
      polygonOutputs.forEach((metric) => {
        if (!metric.value) return;

        if (typeof metric.value === "string") {
          metric.value = JSON.parse(metric.value);
        }
        const geojson = metric.value;

        geojson?.features?.forEach((feature) => {
          const existingProperties = feature?.properties || {};
          feature.properties = {
            optionId: parseInt(optionID),
            name: metric.name,
            ...existingProperties
          };
        });

        optionPolygonOutputs.push({
          name: metric.name,
          value: geojson,
          title: metric.title,
          description: metric.description
        });
      });
      if (!state.options[optionID]) {
        Vue.set(state.options, optionID, {});
      }
      Vue.set(state.options[optionID], "polygonOutputs", optionPolygonOutputs);
    },
    [types.mutations.OptionsSetCaseId]: (state, payload) => {
      state.caseIdsForOptions = {
        ...state.caseIdsForOptions,
        [payload.optionId]: payload.caseId
      };
    },

    [types.mutations.OptionsResetState]: (state) => {
      Object.assign(state, newEmptyOptionState());
    },
    [types.mutations.OptionsSetPolygonOutputVis]: (
      state,
      { metric, value }
    ) => {
      const visiblePolygonOutputNames = new Set(
        state.visiblePolygonOutputNames.values()
      );
      if (value) {
        visiblePolygonOutputNames.add(metric);
      } else if (visiblePolygonOutputNames.has(metric)) {
        visiblePolygonOutputNames.delete(metric);
      }
      Vue.set(state, "visiblePolygonOutputNames", visiblePolygonOutputNames);
    },
    [types.mutations.OptionsAdd]: (state, { option }) => {
      if (!option.id) {
        console.error("no id provided to OptionsAdd");
        return;
      }

      if (!state?.options?.[option?.id]) {
        Vue.set(state.options, option.id, option);
      } else {
        Vue.set(state.options, option.id, {
          ...state?.options?.[option?.id],
          ...option
        });
      }
    },
    [types.mutations.OptionUpdate]: (state, { optionId, alias, scenarios }) => {
      if (optionId === undefined) {
        throw new Error("optionId is required");
      }
      optionId = Number(optionId);
      if (alias !== undefined) {
        Vue.set(state.options[optionId], "alias", alias);
      }
      if (scenarios !== undefined) {
        Vue.set(state.options[optionId], "scenarios", scenarios);
      }
    },
    [types.mutations.RemoveOption]: (state, optionId) => {
      Vue.delete(state.options, optionId);
      Vue.delete(state.colourMap, optionId);
      Vue.delete(state.caseIdsForOptions, optionId);
      Vue.delete(state.loadingMap, optionId);
      state.selectedOptionIds = state.selectedOptionIds.filter(
        (id) => id != optionId
      );
      state.visibleOptionIds = state.visibleOptionIds.filter(
        (id) => id != optionId
      );
      if (state.activeOptionId === optionId) {
        state.activeOptionId = null;
      }
      if (state.hoveredOption.id === optionId) {
        state.hoveredOption = {};
      }
    },
    [types.mutations.OptionToggleHover]: (state, { optionId, setTo }) => {
      if (optionId === undefined) {
        throw new Error("optionId is required");
      }
      if (setTo === undefined) {
        if (optionId !== state.hoveredOption?.id) {
          setTo = true;
        } else {
          setTo = !state.hoveredOption.hoverIsActive;
        }
      }
      state.hoveredOption = {
        id: optionId,
        hoverIsActive: setTo
      };
    },
    [types.mutations.OptionsToggleVisibility]: (
      state,
      { optionIds, setTo, overwrite }
    ) => {
      if (!Array.isArray(optionIds)) {
        optionIds = [optionIds];
      }
      const filteredNumericIds = [];
      optionIds.forEach((id) => {
        if (id) {
          filteredNumericIds.push(Number(id));
        }
      });

      if (setTo === true && overwrite === true) {
        Vue.set(state, "visibleOptionIds", filteredNumericIds);
        return;
      }
      let tempVisibleOptionIds = [...state.visibleOptionIds];
      const showOption = (optionId) => {
        if (!state.visibleOptionIds.includes(Number(optionId))) {
          tempVisibleOptionIds.push(Number(optionId));
        }
      };
      const hideOption = (optionId) => {
        tempVisibleOptionIds = tempVisibleOptionIds.filter(
          (id) => id !== Number(optionId)
        );
      };
      const setOptionVisibility = (optionId, setTo) => {
        if (setTo === true) {
          showOption(optionId);
        } else if (setTo === false) {
          hideOption(optionId);
        } else {
          throw new Error("setTo must be a boolean");
        }
      };
      const isVisible = (optionId) => {
        return tempVisibleOptionIds.includes(Number(optionId));
      };

      if (setTo === undefined) {
        const allOptionsVisible = filteredNumericIds.every((optionId) =>
          isVisible(optionId)
        );
        setTo = !allOptionsVisible;
      }
      filteredNumericIds.forEach((optionId) =>
        setOptionVisibility(optionId, setTo)
      );

      Vue.set(state, "visibleOptionIds", tempVisibleOptionIds);
    },
    //also asigns a colour to the option
    [types.mutations.OptionsToggleInteractionState]: (
      state,
      { optionId, interactionState }
    ) => {
      optionId = Number(optionId); //there is some ambiguity about whether optionId is a string or a number
      if (!optionId) {
        return;
      }

      const newInteractionState =
        interactionState ||
        inferNewInteractionState(
          optionId,
          state.activeOptionId,
          state.selectedOptionIds.map((id) => Number(id))
        );
      const addOptionToSelected = (optionId) => {
        const results = addItemWithFifoLengthEnforcement(
          state.selectedOptionIds.map((id) => Number(id)),
          optionId,
          Options.maxNOptionsSelected
        );

        const newSelectedIds = results?.newArray;
        const removedOptionId = results?.removedItem;

        // Update the selected option ids now that max length has been enforced
        Vue.set(state, "selectedOptionIds", newSelectedIds);
        if (
          removedOptionId &&
          colourIsInThemeColours(state.colourMap[removedOptionId])
        ) {
          // If an option was removed, any non-chosen colour associated with it should be cleared
          Vue.delete(state.colourMap, removedOptionId);
        }

        //ensure that the new option is visible
        if (!state.visibleOptionIds.includes(Number(optionId))) {
          state.visibleOptionIds.push(Number(optionId));
        }

        //assign a colour to the option if it doesn't have one already
        const currentColour = state.colourMap[optionId];
        if (currentColour) {
          return;
        }

        const availableThemeColours = getDefaultColoursAvailable(state);
        // If there are no available theme colours, assign a random colour
        if (!availableThemeColours.length) {
          const randomColour = generateRandomRgbaString();
          Vue.set(state.colourMap, optionId, randomColour);
          return;
        }
        //assign the first available theme colour to the option
        Vue.set(state.colourMap, optionId, availableThemeColours[0]);
      };

      if (newInteractionState === InteractionStates.SELECTED) {
        if (
          !state.selectedOptionIds.map((id) => Number(id)).includes(optionId)
        ) {
          addOptionToSelected(optionId);
        }
        //if there is no "active" option, make the selected one active, otherwise, if the option is "demoted" from active to selected, make the active option null.
        if (!state.activeOptionId) {
          state.activeOptionId = optionId;
        } else if (state.activeOptionId == optionId) {
          state.activeOptionId = null;
        }
      } else if (newInteractionState === InteractionStates.ACTIVE) {
        state.activeOptionId = optionId;
        if (
          !state.selectedOptionIds.map((id) => Number(id)).includes(optionId)
        ) {
          addOptionToSelected(optionId);
        }
      } else if (newInteractionState === InteractionStates.NORMAL) {
        // Remove from selection and remove any default colour
        state.selectedOptionIds = state.selectedOptionIds.filter(
          (id) => id !== optionId
        );
        // Remove any associated default colour
        if (colourIsInThemeColours(state.colourMap[optionId])) {
          Vue.delete(state.colourMap, optionId);
        }
        if (state.visibleOptionIds.includes(Number(optionId))) {
          state.visibleOptionIds = state.visibleOptionIds.filter(
            (id) => id !== Number(optionId)
          );
        }

        if (state.activeOptionId === optionId) {
          state.activeOptionId = null;
        }
      } else {
        throw new Error(
          `interactionState: ${newInteractionState} not supported`
        );
      }
    },
    [types.mutations.SetOptionColour]: (state, { optionId, rgbaColour }) => {
      if (!optionId || !rgbaColour) {
        console.error(
          "SetOptionColour called without optionId or rgbaColour",
          "optionId",
          optionId,
          "rgbaColour",
          rgbaColour
        );
        return;
      }
      Vue.set(state.colourMap, optionId, rgbaColour);
    },
    [types.mutations.SetAllOptionColours]: (state, newColourMap) => {
      if (!newColourMap) {
        console.error("SetAllOptionColours called without newColourMap");
        return;
      }
      Vue.set(state, "colourMap", newColourMap || {});
    },
    [types.mutations.SetOptionLoadingState]: (
      state,
      { optionId, loadingState }
    ) => {
      if (!optionId || !loadingState) {
        console.error(
          "SetOptionLoadingState called without optionId or loadingState",
          "optionId",
          optionId,
          "loadingState",
          loadingState
        );
        return;
      }

      Vue.set(state.loadingMap, optionId, loadingState);
    },
    [types.mutations.OptionSetLength]: (state, { optionId, length }) => {
      if (!optionId || !length) {
        console.error(
          "OptionSetLength called without optionId or length",
          "optionId",
          optionId,
          "length",
          length
        );
        return;
      }
      if (!state.options[optionId]) {
        Vue.set(state.options, optionId, {});
      }
      Vue.set(state.options[optionId], "geojsonLength", length);
    }
  }
};
