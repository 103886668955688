<template>
  <CreateSavedViewDialogue
    @save="save"
    :newViewMethod="newViewMethod"
    :viewType="SavedViewTypes.METRICS"
    :saving="saving"
  />
</template>

<script>
import createMetricsViewReturnMetrics from "@/graphql/views/createMetricsViewReturnMetrics";
import { mapActions, mapGetters } from "vuex";
import { actions, getters } from "@S/savedMetricsView/types";
import { getters as mapChartGetters } from "@S/mapChart/types";

import SavingViewsNewViewMethod from "@/constants/SavingViewsNewViewMethod";

import CreateSavedViewDialogue from "@C/savingView/CreateViewDialogue/CreateSavedViewDialogue.vue";

import SavedViewTypes from "@/constants/SavedViewTypes";
import { apolloClient } from "@/plugins/apolloPlugin";
import { convertOptionComparisonToGraphQLInput } from "@S/mapChart/module";

export default {
  name: "CreateMetricsViewDialogue",
  components: {
    CreateSavedViewDialogue
  },
  data() {
    return {
      saving: false,
      SavedViewTypes
    };
  },
  emits: ["refreshMetricsViewList"], 
  props: {
    newViewMethod: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      getters.MetricsViewGetWorkingMetrics,
      mapChartGetters.GetWorkingOptionComparisonView
    ])
  },
  methods: {
    ...mapActions([actions.SaveMetricView]),
    save(savedViewName) {
      this.saving = true;

      let metricsForInput = [];
      if (
        [
          SavingViewsNewViewMethod.NEW_FROM_BUTTON,
          SavingViewsNewViewMethod.NEW_FROM_EXISTING_VIEW
        ].includes(this.newViewMethod)
      ) {
        metricsForInput = this.MetricsViewGetWorkingMetrics;
      }

      const input = {
        name: savedViewName,
        projectID: this.$route.params.projectId,
        selectedOptionMetrics: metricsForInput,
        optionComparisonView: convertOptionComparisonToGraphQLInput(
          this.GetWorkingOptionComparisonView
        )
      };

      apolloClient
        .mutate({
          mutation: createMetricsViewReturnMetrics,
          variables: {
            input: input
          }
        })
        .then((response) => {
          if (response.errors) {
            console.error("error", response.errors);
            throw new Error("failed to create view");
          }

          this.$emit("refreshMetricsViewList");
          this.SaveMetricView(response.data.createView);
        })
        .catch((error) => {
          console.error("error", error);
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
};
</script>

<style></style>
