import types, { actions, mutations, getters } from "@S/layer/types";
import { _axios } from "@/plugins/axios";

export default {
  types: types,
  state: {
    layerSummaries: {},
    /* {
      layerId : {
        style : {
          opacity: 1,
          fillColour: "#FFFFFFFF",
          outlineColour: "#FFFFFFFF"
        },
        info: {
          name: "",
          alias: "",
          description: "",
          createdAt: timestamp,
          createdBy: "",
          layerType: "POLYGON/LINESTRING"
        }
      },
      ...}
    */
    layerProperties: {}
    // {
    //     <layerId>: [
    //         {
    //           "mutable": true,
    //           "key": "layer_name",
    //           "value": "Sample layer property",
    //         }
    //       ]
    //     },...
    // }
  },

  getters: {
    //Get all styles for all layers
    [getters.LayersGetAllIds](state) {
      return Object.keys(state.layerSummaries);
    },
    [getters.LayersGetAllSummaries](state) {
      return state.layerSummaries;
    },
    [getters.LayerGetInfo]: (state) => (layerId) => {
      const found = state.layerSummaries?.[layerId]?.info;
      return found;
    },
    //Get all styles for the specified layer
    [getters.LayerGetStyle]: (state) => (layerId) => {
      const found = state.layerSummaries?.[layerId]?.style;
      return found;
    },
    [getters.LayerGetProperties]: (state) => (layerId) => {
      const found = state.layerProperties?.[layerId];
      return found;
    },
    //Get a map with the layer's properties as keys, and values as values, without mutable
    [getters.LayerGetPropertiesClean]: (state) => (layerId) => {
      const found = state.layerProperties?.[layerId];
      if (!layerId) {
        return {};
      }

      const layerPropertyMap = {};
      if (!found) {
        return layerPropertyMap;
      }
      found.forEach((layerProperty) => {
        layerPropertyMap[layerProperty.key] = layerProperty.value;
      });
      return layerPropertyMap;
    },
    [getters.LayerGetIngestPropertyKeys]: (state) => (layerId) => {
      const found = state.layerProperties?.[layerId];

      if (!layerId) {
        return [];
      }

      //Create list of properties that are from ingest
      const immutableLayerPropertyObjects = found.filter(
        (propertyObject) => !propertyObject.mutable
      );

      const immutableLayerPropertyKeys = immutableLayerPropertyObjects.map(
        (layerProperty) => layerProperty.key
      );
      return immutableLayerPropertyKeys;
    },
    //Get the opacity property for the specified layerid
    [getters.LayerGetOpacity]: (state, getters) => (layerId) => {
      const layerStyle = getters.LayerGetStyle(layerId);
      return layerStyle?.opacity;
    },
    [getters.LayerGetFillColour]: (state, getters) => (layerId) => {
      const layerStyle = getters.LayerGetStyle(layerId);
      return layerStyle?.fillColour;
    },
    [getters.LayerGetOutlineColour]: (state, getters) => (layerId) => {
      const layerStyle = getters.LayerGetStyle(layerId);
      return layerStyle?.outlineColour;
    },
    [getters.LayersGetAllUniqueProperties](state) {
      const properties = state.layerProperties;

      const layerIds = Object.keys(properties);
      const uniqueProperties = new Set();

      layerIds.forEach((layerId) => {
        const layerPropertiesList = properties[layerId];

        layerPropertiesList.forEach((layerPropertyObject) => {
          uniqueProperties.add(layerPropertyObject.key);
        });
      });

      return [...uniqueProperties];
    }
  },
  mutations: {
    [mutations.LayerSetStyles]: (
      state,
      { layerId, opacity, fillColour, outlineColour }
    ) => {
      if (opacity > 1) {
        opacity = opacity / 100; //opacticy should be a fraction
      }
      layerId = Number(layerId);
      const styleObject = {
        opacity,
        fillColour,
        outlineColour
      };
      if (layerId) {
        Vue.set(state.layerSummaries, layerId, {
          ...state.layerSummaries[layerId],
          style: {
            ...styleObject
          }
        });
      }
    },
    [mutations.LayerSetInfo]: (state, infoObject) => {
      const layerId = infoObject?.layerId;
      delete infoObject.layerId;

      const currentInfo = state.layerSummaries?.[layerId]?.info;

      if (layerId) {
        Vue.set(state.layerSummaries, layerId, {
          ...state.layerSummaries[layerId],
          info: {
            ...currentInfo,
            ...infoObject
          }
        });
      }
    },
    [mutations.LayerSetProperties]: (state, propertiesObject) => {
      const layerId = propertiesObject?.id;
      Vue.set(state.layerProperties, layerId, propertiesObject?.properties);
    },
    [mutations.LayerAddProperty]: (state, propertyObject) => {
      // propertyObject: {
      //   id: ...,
      //   property: {
      //     key: ...,
      //     value: ...,
      //     mutable: true/false
      //   }
      // }

      const layerId = propertyObject.id;

      let propertiesForLayer = state.layerProperties[layerId] || [];

      propertiesForLayer = [...propertiesForLayer, propertyObject.property];

      Vue.set(state.layerProperties, layerId, propertiesForLayer);
    },
    [mutations.LayerEditProperty]: (state, propertyObject) => {
      // propertyObject: {
      // id: ...,
      // editedPropertyKey: ...,
      // property: {
      //     key: ...,
      //     value: ...,
      //     mutable: true/false
      //   }

      if (!propertyObject.property.mutable) {
        throw "Property is from ingest and cannot be edited";
      }

      const layerId = propertyObject?.id;

      const propertiesForLayer = state.layerProperties?.[layerId];
      if (!propertiesForLayer) {
        console.warn("Layer does not have any properties");
        return;
      }

      const indexOfItem = propertiesForLayer.findIndex(
        (property) => property.key === propertyObject.editedPropertyKey
      );

      propertiesForLayer.splice(indexOfItem, 1, propertyObject.property);

      Vue.set(state.layerProperties, layerId, propertiesForLayer);
    },
    [mutations.LayerDeleteProperty]: (state, propertyObject) => {
      //propertyObject: {
      //   id: ...,
      //   propertyKey: ...
      // }

      const layerId = propertyObject?.id;
      let propertiesForLayer = state.layerProperties?.[layerId];

      propertiesForLayer = propertiesForLayer.filter(
        (property) => property.key !== propertyObject?.propertyKey
      );
      Vue.set(state.layerProperties, layerId, propertiesForLayer);
    }
  },
  actions: {
    [actions.LayerSetStyles]: ({ commit }, payload) => {
      commit(mutations.LayerSetStyles, payload);
    },
    [actions.LayerSetInfo]: ({ commit }, payload) => {
      commit(mutations.LayerSetInfo, payload);
    },
    [actions.LayerUpdateInfo]: async ({ commit }, payload) => {
      const layerId = payload?.layerId;
      const newAlias = payload?.alias;
      const newDescription = payload?.description;

      const url = `/V2/dataset-layer/${layerId}`;

      const objectWithOnlyNewFields = {
        ...(newAlias && { alias: newAlias }),
        ...(newDescription && { description: newDescription })
      };

      try {
        const res = await _axios.patch(url, objectWithOnlyNewFields);
        if (res.status == 200) {
          commit(mutations.LayerSetInfo, {
            layerId,
            ...objectWithOnlyNewFields
          });
        }
      } catch (err) {
        console.error("error updating layer info", err);
      }
    },
    [actions.LayerSetProperties]: ({ commit }, payload) => {
      commit(mutations.LayerSetProperties, payload);
    },
    [actions.LayerAddProperty]: ({ commit }, payload) => {
      commit(mutations.LayerAddProperty, payload);
    },
    [actions.LayerEditProperty]: ({ commit }, payload) => {
      commit(mutations.LayerEditProperty, payload);
    },
    [actions.LayerDeleteProperty]: ({ commit }, payload) => {
      commit(mutations.LayerDeleteProperty, payload);
    }
  }
};
