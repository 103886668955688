<template>
  <div
    :class="spaceBetween ? 'd-flex justify-space-between' : ''"
    :style="spaceBetween ? 'width: 100%' : ''"
  >
    <span v-if="!hideTitle" class="text-overline mt-0 mr-1">
      {{ title }}
    </span>
    <v-btn-toggle
      @change="change"
      :key="items.map((i) => i.value).join('-')"
      :value="valueToIndex(value)"
      :mandatory="mandatory"
    >
      <v-tooltip v-for="item in items" :key="item.value" top>
        <template v-slot:activator="{ props, on }">
          <v-btn
            v-on="on"
            v-bind="props"
            :data-cy="getDataCy(item.value)"
            small
          >
            <v-icon small>{{ item.icon }}</v-icon>
          </v-btn>
        </template>
        {{ item.tooltip }}
      </v-tooltip>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name: "SelectByIcon",
  model: {
    prop: "value",
    event: "change"
  },
  emits: ["change"], 
  props: {
    value: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      validator: (items) => {
        for (const item of items) {
          if (!item.value || !item.tooltip || !item.icon) {
            console.error(
              "SelectByIcon: items must have a value, tooltip, and icon"
            );
            return false;
          }
        }
        return true;
      },
      required: true
    },
    title: {
      type: String,
      required: true
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    mandatory: {
      type: Boolean,
      default: true
    },
    spaceBetween: {
      type: Boolean,
      default: false,
      description:
        "Adds variable space between the label and buttons to fill the parent container width"
    }
  },
  computed: {
    disabled() {
      return this.items.length <= 1;
    }
  },
  watch: {
    items: {
      deep: true,
      handler: function (newValue) {
        //if the value no longer exists in the list, set it to be the first item
        if (!newValue.find((item) => item.value === this.value)) {
          this.change(0);
        }
      }
    }
  },
  methods: {
    change(index) {
      const emit = this.indexToObject(index)?.value;
      this.$emit("change", emit);
    },
    getDataCy(value) {
      return `select-${this.title.toLowerCase().split(" ").join("-")}-${value}`;
    },
    valueToIndex(value) {
      return this.items.findIndex((item) => item.value === value);
    },
    objectToIndex(object) {
      return this.items.findIndex((item) => item.value === object.value);
    },
    indexToObject(index) {
      return this.items[index];
    }
  }
};
</script>

<style></style>
