import LoadingStates from "@/constants/LoadingStates";
import { actions, mutations, getters as datasetGetters } from "./types";
import { DATASET_TYPE } from "@/constants/Datasets";
import { DatasetConverter } from "./converter";
import { IndexedDB, Tables } from "@/indexdb/core";
import { usingLocalBackend } from "@/hooks/usingLocalBackend";
import { _axios } from "@/plugins/axios";

import stringToColor from "string-to-color";
import {
  actions as LayerActions,
  getters as LayerGetters
} from "@S/layer/types";
import { hexToRgba } from "@/helpers/hexToRgba";
import { IsDatasetType } from "@/hooks/declarative-filters";

const useLocalBackend = usingLocalBackend();
const datasetIDB = new IndexedDB(Tables.DATASETS, useLocalBackend);

const fetchDatasetGeoJSON = async ({ id, state }) => {
  if (state?.datasets?.[id]?.type !== "VECTOR") {
    return;
  }
  let dataset_json;
  await _axios.get(`/V2/dataset/${id}/dataset`).then((response) => {
    dataset_json = response?.data;
  });
  return dataset_json;
};

const defaultState = {
  datasets: {
    // Indexed by dataset ID
    /*
      {
      id: identifier,
      name: "",
      dataset: null,
      type: null,
      errorMessages: [],
      loadingState: LoadingStates.INITIALISED,
      // Defaulting to null so that default is different to a received false value
      layerNameIds: []
      };
  */
  },
  tiled: true,
  allDatasetsGeojson: null,
  rasterLegends: {
    /*
    [id]: [
      {rgba: "rgba(0,0,0,0)", value: 0},
      ...
    ]
    */
  }
};

export default {
  state: {
    ...defaultState
  },
  getters: {
    [datasetGetters.GetDatasetsAsArray]: (state) => {
      return Object.values(state.datasets);
    },
    [datasetGetters.GetDatasetSummaries]: (state) => {
      const datasetSummaries = {};
      Object.keys(state.datasets).forEach((datasetId) => {
        datasetSummaries[datasetId] = {
          name: state.datasets[datasetId]?.name,
          type: state.datasets[datasetId]?.type,
          subtype: state.datasets[datasetId]?.subtype
        };
      });
      return datasetSummaries;
    },
    [datasetGetters.DatasetGetAllIds]: (state) => {
      return Object.keys(state.datasets);
    },
    [datasetGetters.DatasetGetById]: (state) => (datasetId) => {
      return state.datasets[datasetId];
    },
    [datasetGetters.DatasetGetName]: (state) => (datasetId) => {
      return state.datasets[datasetId]?.name;
    },
    [datasetGetters.DatasetGetType]: (state) => (datasetId) => {
      return state.datasets[datasetId]?.type;
    },
    [datasetGetters.DatasetGetLayerNamesAndIds]: (state) => (datasetId) => {
      return state.datasets[datasetId]?.layerNameIds || [];
    },
    [datasetGetters.DatasetGetIsLoaded]: (state) => (datasetId) => {
      return state.datasets[datasetId]?.loadingState === LoadingStates.LOADED;
    },
    [datasetGetters.AnyDatasetsLoading]: (state) => {
      return Object.values(state.datasets).some(
        (dataset) => dataset?.loadingState === LoadingStates.LOADING
      );
    },

    [datasetGetters.DatasetGetAsGeoJson]: (state, getters) => (datasetId) => {
      const dataset =
        getters[datasetGetters.DatasetGetById](datasetId)?.datasetJson;
      if (!dataset?.features?.length) {
        return;
      }

      if (!getters[datasetGetters.DatasetGetIsLoaded](datasetId)) {
        return;
      }
      if (
        getters[datasetGetters.DatasetGetType](datasetId) ===
        DATASET_TYPE.VECTOR
      ) {
        return dataset;
      }

      console.warn("Tried to get GeoJSON for an incompatible dataset");
    },
    [datasetGetters.GetAllDatasetsAsGeojson]: (state) => {
      return (
        state.allDatasetsGeojson || {
          type: "FeatureCollection",
          crs: { type: "name", properties: { name: "EPSG:4326" } },
          features: []
        }
      );
    },
    [datasetGetters.GetDatasetIdsForInitialFetch]: (state) => {
      return state.datasetIdsForInitialFetch || [];
    },
    [datasetGetters.GetRasterLegendById]: (state) => (id) => {
      return state.rasterLegends?.[id];
    }
  },
  mutations: {
    [mutations.UpdateDatasetsGeojson]: (state, payload) => {
      state.allDatasetsGeojson = payload;
    },
    // Loading state setter usage has been updated - tick!
    [mutations.DatasetSetLoadingState]: (state, payload) => {
      if (!payload?.id || !payload?.loadingState) {
        console.error(
          "DatasetSetLoadingState called without id or loadingState"
        );
        return;
      }

      Vue.set(state.datasets, payload?.id, {
        ...state.datasets[payload?.id],
        loadingState: payload?.loadingState
      });
    },
    [mutations.DatasetSetData]: (state, payload) => {
      if (!payload?.id || !payload?.dataset) {
        console.error("DatasetSetData called without id or dataset");
        return;
      }
      Vue.set(state.datasets[payload?.id], "datasetJson", payload?.dataset);
    },
    [mutations.DatasetSetName]: (state, payload) => {
      const id = payload?.id;
      if (!id || !payload?.name) {
        console.error("DatasetSetName called without id or name");
        return;
      }

      Vue.set(state.datasets, id, {
        ...state.datasets[id],
        name: payload?.name
      });
      Vue.set(state.datasets[payload?.id], "id", payload?.id);
    },
    [mutations.DatasetSetType]: (state, payload) => {
      const datasetId = payload?.id;
      const type = payload?.type;

      if (!datasetId || !type) {
        console.error("DatasetSetType called without id");
        return;
      }

      Vue.set(state.datasets[datasetId], "type", type);
    },
    [mutations.DatasetSetLayerNameIds]: (state, payload) => {
      const datasetId = payload?.id;
      if (!datasetId) {
        console.error("DatasetSetLayerNameIds called without id");
        return;
      }
      const layerNameIdMap = payload?.layerNameIdMap || {};
      const layerNameSet = Object.keys(layerNameIdMap)?.map((layerName) => ({
        id: Number(layerNameIdMap[layerName].layerId),
        name: layerName,
        layerType: layerNameIdMap[layerName].layerType
      }));
      Vue.set(state.datasets[datasetId], "layerNameIds", layerNameSet);
    },
    [mutations.DatasetSetVectorTiledStatus]: (state, { tiled }) => {
      Vue.set(state, "tiled", tiled);
    },
    [mutations.DatasetDelete]: (state, id) => {
      if (!id) {
        console.error("DatasetDelete called without id");
        return;
      }
      Vue.delete(state.datasets, id);
    },
    [mutations.SetDatasetIdsForInitialFetch]: (state, datasetIds) => {
      Vue.set(state, "datasetIdsForInitialFetch", datasetIds || []);
    },
    [mutations.ResetDatasets]: (state) => {
      Vue.set(state, "datasets", {});
      Vue.set(state, "tiled", true);
      Vue.set(state, "allDatasetsGeojson", null);
    },
    [mutations.SetRasterLegend]: (state, { id, legend }) => {
      if (!id || !legend?.length) {
        console.error(
          "SetRasterLegend called without id or legend",
          "\nid: ",
          id,
          "\nlegend: ",
          legend
        );
        return;
      }

      Vue.set(state.rasterLegends, id, legend);
    }
  },
  actions: {
    // Datasetupdate done
    [actions.DatasetUpdate]: async ({ commit, dispatch, getters }, payload) => {
      try {
        const datasetId = payload?.id;
        const datasetName = payload?.name;
        const datasetType = payload?.type?.toUpperCase();

        if (!datasetId) {
          console.error("DatasetUpdate called without id");
          return;
        }

        commit(mutations.DatasetSetName, {
          id: datasetId,
          name: datasetName
        });
        commit(mutations.DatasetSetType, {
          id: datasetId,
          type: datasetType
        });

        const layerNameIdMap = {};
        const layers = payload?.layers;

        if (layers) {
          // Initialise styles and descriptive fields for each layer when layer customisation enabled
          const createdAt = payload.created_at;
          const createdBy = payload.created_by;

          layers.forEach((layer) => {
            const layerId = layer?.id;
            const layerName = layer?.name;

            //Layer name ID map
            if (layerName && layerId) {
              layerNameIdMap[layerName] = {
                layerId: layerId,
                layerType: layer.geomType,
                layerName: layerName
              };
            }

            // If there is not a style entry in the store for this layer, then add it
            if (!getters[LayerGetters.LayerGetStyle](layer.id)) {
              const fillColourHex = stringToColor(layer.name);
              const outlineColourHex = stringToColor(layer.name);

              const fillColour = hexToRgba(fillColourHex);
              const outlineColour = hexToRgba(outlineColourHex);
              const defaultFillOpacity = 0.5;

              // add default styles to the store
              dispatch(LayerActions.LayerSetStyles, {
                layerId: layer.id,
                opacity: defaultFillOpacity,
                fillColour: fillColour,
                outlineColour: outlineColour
              });

              const description = layer.description;
              const alias = layer.alias;
              const name = layer.name;

              dispatch(LayerActions.LayerSetInfo, {
                layerId: layer.id,
                description,
                alias,
                createdAt,
                createdBy,
                name,
                layerType: layer.geomType
              });
            }

            dispatch(LayerActions.LayerSetProperties, {
              id: layer?.id,
              properties: layer?.properties
            });
          });
        }

        commit(mutations.DatasetSetLayerNameIds, {
          id: datasetId,
          layerNameIdMap
        });

        commit(mutations.DatasetSetLoadingState, {
          id: datasetId,
          loadingState: LoadingStates.LOADED
        });
      } catch (err) {
        console.error(err);
      }
    },
    [actions.DatasetDelete]: ({ commit }, id) => {
      if (!id) {
        console.error("DatasetDelete called without id");
        return;
      }
      const url = `/V2/dataset/${id}`;
      console.debug(`calling dataset delete for ${id}`);
      return _axios
        .delete(url)
        .then(() => {
          commit(mutations.DatasetDelete, id);
          return true;
        })
        .catch((err) => {
          console.error("Error deleting dataset");
          console.error(err);
          commit(mutations.DatasetSetLoadingState, {
            id,
            loadingState: LoadingStates.ERRORED
          });
          return;
        });
    },
    [actions.FetchGeoJSON]: async (
      { commit, dispatch, state, getters },
      { datasetId }
    ) => {
      if (!datasetId) {
        console.error("Dataset fetch called without id");
        return;
      }
      commit(mutations.DatasetSetLoadingState, {
        id: datasetId,
        loadingState: LoadingStates.LOADING
      });

      let dataset = await datasetIDB.get(datasetId);
      if (!dataset) {
        const dataset_json = await fetchDatasetGeoJSON({
          id: datasetId,
          state
        });
        const sharedProperties = {
          datasetId: datasetId,
          datasetName: getters[datasetGetters.DatasetGetName](datasetId),
          datasetType: DATASET_TYPE.VECTOR
        };
        const layerNameIdMap = getters[
          datasetGetters.DatasetGetLayerNamesAndIds
        ](datasetId).map((layer) => ({ [layer.name]: layer.id }));
        dataset = DatasetConverter.convertDataset(
          dataset_json,
          sharedProperties,
          layerNameIdMap
        );

        if (!useLocalBackend) {
          await datasetIDB.set(datasetId, dataset);
        }
      }

      commit(mutations.DatasetSetData, { id: datasetId, dataset });
      commit(mutations.DatasetSetLoadingState, {
        id: datasetId,
        loadingState: LoadingStates.LOADED
      });
      dispatch(actions.UpdateDatasetsGeojson);
    },
    [actions.UpdateDatasetsGeojson]: ({ state, getters, commit }) => {
      //Re-generates the geojson for datasets
      if (state.tiled) {
        return;
      }
      const datasets = getters[datasetGetters.GetDatasetsAsArray]
        .filter(IsDatasetType(DATASET_TYPE.VECTOR))
        ?.map((dataset) => {
          return getters[datasetGetters.DatasetGetAsGeoJson](dataset?.id);
        });

      const firstBaseCollection = {
        type: "FeatureCollection",
        crs: datasets[0]?.crs
      };
      const datasetCollection = DatasetConverter.combineDatasets(
        datasets,
        firstBaseCollection
      );

      commit(mutations.UpdateDatasetsGeojson, datasetCollection);
    }
  }
};
