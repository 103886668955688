const updateOrAddProjectFeatureStyle = (
  state,
  payload,
  updateCallback,
  projectFeaturesType
) => {
  try {
    if (!payload) {
      throw new Error("Invalid payload: null or undefined.");
    }

    const index = state.activeView.workingVersion.mapStyle[
      projectFeaturesType
    ].findIndex((item) => item.assetId == payload.id);

    if (index === -1) {
      Vue.set(state.activeView.workingVersion.mapStyle, projectFeaturesType, [
        ...state.activeView.workingVersion.mapStyle[projectFeaturesType],
        updateCallback(payload, {
          fillColour: payload?.fillColour,
          outlineColour: payload?.outlineColour,
          opacity: payload?.opacity,
          assetId: Number(payload?.id),
          isVisible: payload?.isVisible ?? false
        })
      ]);
    } else {
      const updatedItem = updateCallback(
        payload,
        state.activeView.workingVersion.mapStyle[projectFeaturesType][index]
      );

      Vue.set(
        state.activeView.workingVersion.mapStyle[projectFeaturesType],
        index,
        updatedItem
      );
    }
  } catch (error) {
    console.error("updateOrAddProjectFeatureStyle", error);
  }
};

export default updateOrAddProjectFeatureStyle;
