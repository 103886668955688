export default {
  maxNOptionsSelected: 25,
  sorters: {
    directions: {
      ascending: "ascending",
      descending: "descending"
    },
    sortFields: {
      alphabetical: {
        field: "id",
        label: "Alphabetical",
        activeWhenGrouping: true
      },
      dateCreated: {
        field: "dateCreated",
        label: "Date Created",
        activeWhenGrouping: true
      }
    }
  }
};

export const defaultOptionColourOnMap = "#888";
