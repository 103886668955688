import { BaseTypes } from "@S/base_types";

const getters = {
  ConfigsAreLoading: "ConfigsAreLoading",
  GetConfigSort: "GetConfigSort",
  GetAllConfigIds: "GetAllConfigIds",
  GetEditedDesignRulesWithName: "GetEditedDesignRulesWithName",
  GetConfigIsValidForSetupMode: "GetConfigIsValidForSetupMode",
  AllConfigNames: "AllConfigNames",
  AllConfigSummaries: "AllConfigSummaries",
  GetConfigCategoriesById: "GetConfigCategoriesById",
  GetConfigLoadingStateById: "GetConfigLoadingStateById",
  GetConfigEditedDesignRulesWithNameById:
    "GetConfigEditedDesignRulesWithNameById",
  GetConfigSummaryById: "GetConfigSummaryById",
  GetConfigAssetTypeById: "GetConfigAssetTypeById",
  GetConfigIsUsingNewParametersSchemaById:
    "GetConfigIsUsingNewParametersSchemaById",
  GetConfigIsReadOnlyById: "GetConfigIsReadOnlyById",
  GetConfigDatasetSettingsById: "GetConfigDatasetSettingsById",
  GetConfigNameById: "GetConfigNameById",
  GetConfigDescriptionById: "GetConfigDescriptionById",
  GetConfigEditedDescriptionById: "GetConfigEditedDescriptionById",
  GetConfigDateCreatedById: "GetConfigDateCreatedById",
  GetConfigDateModifiedById: "GetConfigDateModifiedById",
  GetConfigModelSettingsById: "GetConfigModelSettingsById",
  GetConfigDesignRuleSettingsById: "GetConfigDesignRuleSettingsById",
  GetConfigComponentSettingsById: "GetConfigComponentSettingsById",
  GetConfigDirtyComponentSettingsById: "GetConfigDirtyComponentSettingsById",
  GetConfigDirtyDesignRulesById: "GetConfigDirtyDesignRulesById",
  GetConfigDesignRuleModelIsDirtyById: "GetConfigDesignRuleModelIsDirtyById",
  GetConfigDesignRuleParameterIsDirtyById:
    "GetConfigDesignRuleParameterIsDirtyById",
  GetConfigDirtyModelsById: "GetConfigDirtyModelsById",
  GetConfigModelParameterIsDirtyById: "GetConfigModelParameterIsDirtyById",
  GetConfigModelSettingsEditedById: "GetConfigModelSettingsEditedById",
  GetConfigComponentSettingsEditedById: "GetConfigComponentSettingsEditedById",
  GetConfigDesignRuleSettingsEditedById:
    "GetConfigDesignRuleSettingsEditedById",
  GetConfigEditedDesignRuleSettingParametersById:
    "GetConfigEditedDesignRuleSettingParametersById",
  GetConfigDatasetSettingsEditedById: "GetConfigDatasetSettingsEditedById",
  GetConfigDirtyDatasetsById: "GetConfigDirtyDatasetsById",
  GetConfigDatasetErrorsById: "GetConfigDatasetErrorsById",
  GetConfigDesignRuleErrorsById: "GetConfigDesignRuleErrorsById",
  GetConfigDesignRuleErrorsByNameById: "GetConfigDesignRuleErrorsByNameById",
  GetConfigDesignRuleParameterErrorsById:
    "GetConfigDesignRuleParameterErrorsById",
  GetConfigIsValidById: "GetConfigIsValidById",
  GetConfigValidationStateById: "GetConfigValidationStateById"
};
const mutations = {
  SetConfigSort: "SetConfigSort",
  RemoveConfigById: "RemoveConfigById",
  ResetConfigsState: "ResetConfigsState",
  SetConfigById: "SetConfigById",
  SetConfigLoadingStateById: "SetConfigLoadingStateById",
  SetConfigDescriptionEditedById: "SetConfigDescriptionEditedById",
  SetConfigValidationStateById: "SetConfigValidationStateById",
  SetConfigDesignRulesActiveStateById: "SetConfigDesignRulesActiveStateById",
  SetConfigComponentSettingsItemById: "SetConfigComponentSettingsItemById",
  SetConfigMultipleComponentSettingsItemsById:
    "SetConfigMultipleComponentSettingsItemsById",
  SetConfigDesignRuleParameterById: "SetConfigDesignRuleParameterById",
  SetConfigModelParameterById: "SetConfigModelParameterById",
  SetConfigRefetchedSettingsById: "SetConfigRefetchedSettingsById",
  SetConfigDatasetById: "SetConfigDatasetById",
  SetConfigDesingRuleModelById: "SetConfigDesingRuleModelById",
  SetConfigDesignRuleParameterErrorById:
    "SetConfigDesignRuleParameterErrorById",
  AddEmptyConfig: "AddEmptyConfig"
};
const actions = {
  SetConfigSort: "SetConfigSort",
  RemoveConfigById: "RemoveConfigById",
  ResetConfigsState: "ResetConfigsState",
  SetConfigById: "SetConfigById",
  SetConfigLoadingStateById: "SetConfigLoadingStateById",
  SetConfigDescriptionEditedById: "SetConfigDescriptionEditedById",
  SetConfigValidationStateById: "SetConfigValidationStateById",
  SetConfigDesignRulesActiveStateById: "SetConfigDesignRulesActiveStateById",
  SetConfigComponentSettingsItemById: "SetConfigComponentSettingsItemById",
  SetConfigMultipleComponentSettingsItemsById:
    "SetConfigMultipleComponentSettingsItemsById",
  SetConfigDesignRuleParameterById: "SetConfigDesignRuleParameterById",
  SetConfigModelParameterById: "SetConfigModelParameterById",
  SetConfigRefetchedSettingsById: "SetConfigRefetchedSettingsById",
  SetConfigDatasetById: "SetConfigDatasetById",
  SetConfigDesingRuleModelById: "SetConfigDesingRuleModelById",
  SetConfigDesignRuleParameterErrorById:
    "SetConfigDesignRuleParameterErrorById",
  AddEmptyConfig: "AddEmptyConfig",
  AddConfigs: "AddConfigs"
};

class ConfigTypes extends BaseTypes {
  static getters = getters;
  static mutations = mutations;
  static actions = actions;
}

export { ConfigTypes };

export { getters, mutations, actions };
