<template>
  <div>
    <OptionStateController />
    <ErrorSnackbar :error="errorMsg" portalTarget="saving-views-error" />
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      :offset-y="!menuToLeft"
      :offset-x="menuToLeft"
      :left="menuToLeft"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          @mouseenter="hover = true"
          @mouseleave="hover = false"
          @click="menu = !menu"
          :style="`color: ${colour}; background-color: ${colourOpaque}; border: 1px solid ${colour}`"
          :data-cy="dataCy"
          class="view-text-field"
        >
          <div :style="`color: ${colour}`" class="view-text-field-title">
            View:
          </div>
          <v-col
            class="align-center overflow-hidden"
            style="white-space: nowrap"
            justify="center"
            cols="10"
          >
            <div
              :data-cy="`saved-${viewType}-view-name-container`"
              class="view-text-field-content"
            >
              {{ truncate(viewNameToBeShown, 20, "...") }}
            </div>
          </v-col>
          <v-col cols="2"
            ><v-icon
              :color="colour"
              data-cy="saving-views-selection-menu-icon"
              large
              left
            >
              {{ menuIcon }}
            </v-icon></v-col
          >
        </div>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                v-model="search"
                label="Search Views"
                append-icon="mdi-magnify"
                data-cy="viewsSearch"
                outlined
                dense
                hide-details
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="pl-4">
          <SelectByIcon
            v-model="sortBy"
            :items="sortByOptions"
            title="ORDER BY"
          />
          <SortOrderToggle v-model="sortByDescending" />
        </div>

        <v-list
          data-cy="saved-views-list"
          style="height: 240px; overflow-y: scroll"
        >
          <v-list-item-group
            v-if="!loading"
            :value="selectedViewId"
            :key="sortedViews.join(',')"
          >
            <SavedViewListItem
              v-for="savedView in sortedViews"
              @click="
                handlerAssetOnClick(
                  $router,
                  $route.name,
                  savedView.id,
                  selectView,
                  routeConsts.analyze
                )
              "
              @refreshMetricsViewList="$emit('refreshMetricsViewList')"
              :key="savedView.name + savedView.id"
              :title="savedView.name"
              :id="Number(savedView.id)"
              :colour="colour"
              :viewType="viewType"
              :availableViews="availableViews"
            />
          </v-list-item-group>
          <v-container v-if="loading" fluid fill-height>
            <v-row align="center" justify="center">
              <v-progress-circular :size="94" :width="8" indeterminate />
            </v-row>
          </v-container>
        </v-list>

        <v-card-actions>
          <CreateMapViewDialogue
            v-if="viewType === SavedViewTypes.MAP"
            :newViewMethod="SavingViewsNewViewMethod.NEW_FROM_MENU"
          />
          <CreateMetricsViewDialogue
            v-else-if="viewType === SavedViewTypes.METRICS"
            @refreshMetricsViewList="$emit('refreshMetricsViewList')"
            :newViewMethod="SavingViewsNewViewMethod.NEW_FROM_MENU"
          />
          <v-spacer v-if="viewType === SavedViewTypes.MAP" />
          <portal-target
            v-if="viewType === SavedViewTypes.MAP"
            name="mapExporter"
          />
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import SavedViewListItem from "@/components/savingView/views/SavedViewListItem.vue";
import CreateMapViewDialogue from "@/components/savingView/CreateViewDialogue/CreateMapViewDialogue.vue";
import CreateMetricsViewDialogue from "@/components/savingView/CreateViewDialogue/CreateMetricsViewDialogue.vue";
import SavingViewsNewViewMethod from "@/constants/SavingViewsNewViewMethod";
import OptionStateController from "@/components/savingView/controllers/OptionStateController.vue";
import ErrorSnackbar from "@C/ciBaseComponents/errors/ErrorSnackbar.vue";

import SelectByIcon from "@C/ciBaseComponents/select/SelectByIcon.vue";
import SortOrderToggle from "@C/ciBaseComponents/buttons/SortOrderToggle.vue";
import { truncate } from "@/helpers/truncate";
import SavedViewTypes from "@/constants/SavedViewTypes";
import handlerAssetOnClick from "@/helpers/handleNavigationByClickingOnAsset";
import { routes as routeConsts } from "@/constants/routes";

export default {
  name: "SelectView",
  components: {
    SavedViewListItem,
    CreateMapViewDialogue,
    CreateMetricsViewDialogue,
    OptionStateController,
    ErrorSnackbar,
    SelectByIcon,
    SortOrderToggle
  },
  data: () => ({
    routeConsts,
    hover: false,
    menu: false,
    search: "",
    SavingViewsNewViewMethod,
    errorMsg: "",
    sortByOptions: [
      {
        value: "name",
        icon: "mdi-alphabetical",
        tooltip: "Order alphabetically"
      },
      {
        value: "createdAt",
        icon: "mdi-calendar",
        tooltip: "Order by date created"
      },
      {
        value: "updatedAt",
        icon: "mdi-update",
        tooltip: "Order by updated date"
      }
    ],
    sortBy: "name",
    sortByDescending: false,
    SavedViewTypes
  }),
  emits: ["menuOpened", "refreshMetricsViewList", "selectView"], 
  props: {
    selectedViewId: {
      type: [String, Number],
      default: ""
    },
    selectedViewName: {
      type: String,
      default: ""
    },
    colour: {
      type: String,
      required: true
    },
    availableViews: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    menuToLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    viewType: {
      type: String,
      required: true
    },
    dataCy: {
      type: String,
      required: true
    }
  },
  computed: {
    filteredViews() {
      return this.availableViews.filter((view) =>
        view.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    sortedViews() {
      return this.sortViews(
        this.filteredViews,
        this.sortBy,
        this.sortByDescending
      );
    },
    menuIcon() {
      return this.menu ? "mdi-menu-up" : "mdi-menu-down";
    },
    viewNameToBeShown() {
      return this.selectedViewName?.length
        ? this.selectedViewName
        : "[Unnamed View]";
    },
    mandatoryListGroup() {
      return (
        this.selectedViewId !== null &&
        this.sortedViews.some((view) => view.id === this.selectedViewId)
      );
    },
    colourOpaque() {
      //Append a 15% opacity in the alpha channel. 26 is it's hex equivalent.
      return `${this.colour}26`;
    }
  },
  methods: {
    handlerAssetOnClick,
    truncate,
    selectView(viewId) {
      if (viewId === this.selectedViewId) {
        return;
      }
      this.$emit("selectView", viewId);
    },

    sortViews(views, sortBy, desc) {
      views.sort((a, b) => {
        return a[sortBy].localeCompare(b[sortBy]);
      });

      if (desc) {
        views.reverse();
      }

      return views;
    },
    resetError() {
      this.errorMsg = "";
    }
  },
  watch: {
    menu(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$emit("menuOpened");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.view-text-field {
  width: 200px;
  display: flex;
  flex-direction: row;
  height: 42px;
  border-radius: 4px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  padding-right: 4px;
  position: relative;
  /*The following styles are to reset to default, as they are different in v-card-title.*/
  line-height: 1.5 !important;
  letter-spacing: normal !important;
}

.view-text-field-content {
  margin-top: 4px;
  margin-left: 4px;
}

.view-text-field-title {
  position: absolute;
  margin-top: -2px;
  font-size: 10px;
  left: 8px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
