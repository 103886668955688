import LoadingStates from "@/constants/LoadingStates";
import {
  getters as caseGetters,
  mutations as caseMutations,
  actions as caseActions
} from "@S/case/types";
import store from "@/store";
import { getters as polygonResultGetters } from "@S/polygonResult/types";
import { POLYGON_RESULT_ID_PREFIX } from "@/constants/Prefixes";
import { NewOptionTypes as OptionTypes } from "@S/option/types";

import sortedCases from "@/helpers/sortCases";
import { getters as savingViewGetters } from "@S/savingView/types";
import { actions as savingViewActions } from "@S/savingView/types";

const defaultState = {
  cases: {
    /*
    {
    caseId: {
        configId: null,
        name: null,
        description: null,
        status: null,
        failureReason: null, // may be populated with human readable error message for failed case
        lastEdited: new Date(0),
        created: new Date(0),
        createdBy: null, // will be user id,
        opportunityMapIds: [],
        optionIds: [],
        labels: [],
        optionsLazilyLoaded: [],
        parentConfigId,
        },
    }
    */
  },
  heatmaps: {
    /*
    {
    caseId: {
        heatmapStuff: {},
        },
    }
    */
  },
  caseLoadingMap: {
    /*
    {
    caseId: loadingState,
    }
    */
  },

  caseOptionsObjectives: {
    /* caseOptionsObjectives: {
     *    [caseId]: {
     *      [optionId]: [{name: "cost", value: 100}, {name: "penalty", value: 200}],
     *      [optionId]: [{name: "cost", value: 200}, {name: "penalty", value: 100}]
     *    }, ...
     * }
     */
  },

  caseTopOptionObjectives: {
    /*
    caseTopOptionObjectives: {
      [caseId]: {
        ENVIRONMENTAL: null,
        ECONOMICAL: null,
        BALANCED: null,
        LOWEST_IMPACT: null,
        SHORTEST: null
      }, ...
    }
    */
  }
};

const getHeatmapSize = (heatmap) => {
  return heatmap?.mags?.length;
};

export default {
  state: {
    ...defaultState
  },
  getters: {
    [caseGetters.AnyCasesLoading]: (state) => {
      return Object.values(state.caseLoadingMap)?.some((loadingState) =>
        [LoadingStates.INITIALISED, LoadingStates.LOADING].includes(
          loadingState
        )
      );
    },
    [caseGetters.GetSortedCasesWithSummariesOfSelectedOptions]: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      rootGetters[OptionTypes.getters.GetOptionSummaryById];
      getters[caseGetters.CaseGetDetails];

      return sortedCases({
        GetSummariesOfSelectedOptions:
          rootGetters[OptionTypes.getters.GetSummariesOfSelectedOptions],
        CaseGetName: getters[caseGetters.CaseGetName],
        CaseGetDateCreated: getters[caseGetters.CaseGetDateCreated],
        GetOptionsAreGroupedByCase:
          rootGetters[OptionTypes.getters.GetOptionsAreGroupedByCase],
        GetOptionDateSort: rootGetters[OptionTypes.getters.GetOptionDateSort],
        GetOptionSort: rootGetters[OptionTypes.getters.GetOptionSort]
      });
    },

    [caseGetters.CaseGetLazilyLoadedOptions]: (state) => (caseId) => {
      return state.cases?.[caseId]?.optionsLazilyLoaded || [];
    },
    [caseGetters.GetAllCases]: (state) => {
      return Object.values(state.cases);
    },
    [caseGetters.GetAllCaseIds]: (state) => {
      return Object.keys(state.cases);
    },
    [caseGetters.GetAllCaseLabels]: (state, getters) => {
      const allLabels = new Set();
      getters[caseGetters.GetAllCases].forEach((caseObj) => {
        if (!caseObj?.labels) {
          return;
        }
        caseObj?.labels.forEach((label) => {
          allLabels.add(label);
        });
      });
      return [...allLabels];
    },

    // General case info
    [caseGetters.CaseGetName]: (state) => (caseId) => {
      return state.cases?.[caseId]?.name;
    },
    [caseGetters.CaseGetDateCreated]: (state) => (caseId) => {
      return state.cases?.[caseId]?.created;
    },
    [caseGetters.CaseGetDetails]: (state) => (caseId) => {
      return state.cases?.[caseId] || {};
    },
    [caseGetters.CaseGetFailureReason]: (state) => (caseId) => {
      if (
        state.cases?.[caseId]?.failureReason == null ||
        state.cases?.[caseId]?.failureReason == ""
      ) {
        return null;
      }
      return state.cases?.[caseId]?.failureReason;
    },
    [caseGetters.GetLabelsByCaseId]: (state) => (caseId) => {
      return state.cases?.[caseId]?.labels || [];
    },

    // Options and polygon results
    [caseGetters.GetAllProjectResults](state, getters, _, rootGetters) {
      const resultSummaries = [];
      Object.keys(state.cases)?.forEach((caseId) => {
        const polygonResults =
          getters[caseGetters.CaseGetPolygonResultSummaries](caseId);
        if (polygonResults?.length) {
          resultSummaries.push(...polygonResults);
        }
        const caseDetails = getters[caseGetters.CaseGetDetails](caseId);
        const optionIds = caseDetails?.optionIds;
        if (optionIds?.length) {
          const options = optionIds.map((optionId) =>
            rootGetters[OptionTypes.getters.GetOptionSummaryById](optionId)
          );
          resultSummaries.push(...options);
        }
      });
      return resultSummaries;
    },

    [caseGetters.CaseGetAllOptionsIds]: (state) => (caseId) => {
      const optionIdsInCase = state?.cases?.[caseId]?.optionIds;
      return optionIdsInCase || [];
    },
    [caseGetters.IsOptionOrPolygonResultVisible]:
      (state, getters) => (resultId) => {
        const allVisibleOptionIds =
          getters[OptionTypes.getters.OptionsGetVisibleIdsAsSet];
        if (allVisibleOptionIds.has(Number(resultId))) {
          return true;
        }
        const polygonResultVisible =
          getters[
            savingViewGetters.SavingViewGetVisiblePolygonResultsIdSet
          ]?.has(resultId);
        return polygonResultVisible;
      },
    [caseGetters.CaseGetAllVisibleOptionsIds]: (state, getters) => (caseId) => {
      const allVisibleOptionIds =
        getters[OptionTypes.getters.OptionsGetVisibleIdsAsSet];
      const caseVisibleOptionIds = state?.cases?.[caseId]?.optionIds?.filter(
        (id) => allVisibleOptionIds.has(Number(id))
      );
      return caseVisibleOptionIds || [];
    },

    [caseGetters.GetOptionCaseNameAndId]: (state) => (optionId) => {
      const optionCase = Object.values(state.cases).find((caseObj) => {
        if (!caseObj?.optionIds?.length) {
          return;
        }
        if (caseObj?.optionIds?.includes(Number(optionId))) {
          return true;
        }
      });

      return { id: optionCase?.id, name: optionCase?.name };
    },

    [caseGetters.CaseGetOptionsWithObjective]: (state, getters) => (caseId) => {
      const optionIdsInCase = state?.cases?.[caseId]?.optionIds;
      const optionsObjectives = {};
      optionIdsInCase.forEach((optionId) => {
        optionsObjectives[optionId] =
          getters[OptionTypes.getters.OptionGetObjectives](optionId);
      });

      return optionsObjectives;
    },

    [caseGetters.CaseGetOptionsObjectives]: (state) => (caseId) => {
      return state.caseOptionsObjectives[caseId] || {};
    },
    [caseGetters.CaseGetTopObjectiveOptions]: (state) => (caseId) => {
      return state.caseTopOptionObjectives[caseId] || {};
    },

    [caseGetters.CaseHasVisibleResults]: (state, getters) => (caseId) => {
      //returns true if anything is visible in the case
      const visibleOptionIds =
        getters[caseGetters.CaseGetAllVisibleOptionsIds](caseId);
      const casePolygonResultIds = state.cases?.[caseId]?.opportunityMapIds;
      const visiblePolygonResultIds =
        getters[savingViewGetters.SavingViewGetVisiblePolygonResultsIdSet] ||
        new Set();
      const visiblePolygonResultsInCase = casePolygonResultIds?.filter((id) =>
        visiblePolygonResultIds?.has(id)
      );
      return visibleOptionIds?.length || visiblePolygonResultsInCase?.length;
    },

    [caseGetters.CaseGetPolygonResultSummaries]:
      (state, getters) => (caseId) => {
        const polygonResultIds = state.cases?.[caseId]?.opportunityMapIds;
        return polygonResultIds?.map((id) => {
          const polygonResultSummary =
            getters?.[polygonResultGetters.GetPolygonResultSummary](id);
          return polygonResultSummary;
        });
      },
    [caseGetters.CaseGetResultPropertiesById]:
      (state, getters) => (caseId, resultId) => {
        //Cast the result id to a number version too for options as graphql returns the format as number
        const resultIdAsNumber = Number(resultId);
        let resultProperties = [];

        const optionIds = state.cases?.[caseId]?.optionIds;
        const polygonResultIds = state.cases?.[caseId]?.opportunityMapIds;
        if (optionIds.includes(resultIdAsNumber)) {
          resultProperties =
            getters[OptionTypes.getters.OptionGetOptionMetrics](
              resultIdAsNumber
            );
        } else if (polygonResultIds.includes(resultId)) {
          resultProperties =
            getters?.[polygonResultGetters.GetPolygonResultMetadata](resultId);
        }

        return resultProperties;
      },

    // Heatmaps
    [caseGetters.AllVisibleHeatmaps]: (state, getters) => {
      let aggregateHeatmapLats = [];
      let aggregateHeatmapLngs = [];
      let aggregateHeatmapMags = [];
      let numberOfheatmaps = 0;

      Object.entries(state.heatmaps).forEach(([caseId, heatmapEntry]) => {
        if (!getters[caseGetters.CaseGetHeatmapVisibility](caseId)) {
          return;
        }
        const heatmap = heatmapEntry?.heatmap;
        if (!heatmap || !getHeatmapSize(heatmap)) {
          return;
        }

        aggregateHeatmapLats = aggregateHeatmapLats.concat(heatmap?.lats);
        aggregateHeatmapLngs = aggregateHeatmapLngs.concat(heatmap?.lngs);
        aggregateHeatmapMags = aggregateHeatmapMags.concat(heatmap?.mags);
        numberOfheatmaps += 1;
      });

      return {
        lats: aggregateHeatmapLats,
        lngs: aggregateHeatmapLngs,
        mags: aggregateHeatmapMags,
        amountOfHeatmaps: numberOfheatmaps
      };
    },
    [caseGetters.CaseGetHeatmap]: (state) => (caseId) => {
      return state.heatmaps?.[caseId]?.heatmap || null;
    },
    [caseGetters.CaseGetHeatmapSize]: (state) => (caseId) => {
      const heatmap = state.heatmaps?.[caseId]?.heatmap;
      return getHeatmapSize(heatmap) || null;
    },
    [caseGetters.CaseHasHeatmap]: (state) => (caseId) => {
      const heatmap = state.heatmaps?.[caseId]?.heatmap;
      return getHeatmapSize(heatmap) ? true : false;
    },
    [caseGetters.CaseGetHeatmapVisibility]: (state) => (caseId) => {
      if (!state.heatmaps?.[caseId]) return false;
      return store.getters[savingViewGetters.SavingViewGetHeatmapVisibility](
        caseId
      );
    }
  },
  mutations: {
    [caseMutations.CaseSetLazilyLoadedOptions]: (
      state,
      { optionsLazilyLoaded, caseId }
    ) => {
      if (!caseId) {
        console.warn(
          "case update 'CaseSetLazilyLoadedOptions' payload missing caseId"
        );
      }

      Vue.set(
        state.cases?.[caseId],
        "optionsLazilyLoaded",
        optionsLazilyLoaded
      );
    },
    [caseMutations.CaseSetLoadingState]: (state, payload) => {
      const caseId = payload?.caseId;
      const loadingState = payload?.loadingState;
      if (!caseId || !loadingState) {
        return;
      }
      Vue.set(state.caseLoadingMap, caseId, payload?.loadingState);
    },
    [caseMutations.AddCase]: async (state, payload) => {
      const caseId = payload?.caseId;
      if (!caseId) {
        console.warn("case update payload missing caseId", payload);
      }

      const newCase = {};
      newCase.id = caseId;

      if (payload?.configId) {
        newCase.configId = payload?.configId;
      }
      if (payload?.name) {
        newCase.name = payload?.name;
      }
      if (payload?.description) {
        newCase.description = payload?.description;
      }
      if (payload?.state?.status) {
        newCase.status = payload?.state?.status;
        newCase.failureReason = payload?.state?.failureReason;
      }
      if (payload?.created) {
        newCase.created = new Date(payload?.created);
      }
      if (payload?.lastEdited) {
        newCase.lastEdited = new Date(payload?.lastEdited);
      }
      if (payload?.createdBy) {
        newCase.createdBy = payload?.createdBy;
      }
      if (payload?.labels) {
        newCase.labels = payload?.labels;
      }
      if (payload?.opportunityMapIds) {
        newCase.opportunityMapIds = payload?.opportunityMapIds?.map((id) => {
          const identifier = `${POLYGON_RESULT_ID_PREFIX}${id}`;
          return identifier;
        });
      }
      if (payload?.options) {
        //For every option returned in the case payload
        newCase.optionIds = payload?.options?.map((option) =>
          parseInt(option.id)
        );
      }

      if (payload?.heatmap) {
        const heatmap = payload.heatmap;

        Vue.set(state.heatmaps, caseId, {
          ...state.heatmaps?.[caseId],
          heatmap
        });
      }

      if (payload?.optionsLazilyLoaded) {
        newCase.optionsLazilyLoaded = payload.optionsLazilyLoaded;
      }

      if (payload?.parentConfigId) {
        newCase.parentConfigId = payload.parentConfigId;
      }

      Vue.set(state.cases, caseId, { ...newCase });
    },

    [caseMutations.RemoveHeatmapByCaseId]: (state, caseId) => {
      if (!caseId) {
        console.error("RemoveHeatmapByCaseId mutation missing caseId");
        return;
      }
      if (state.heatmaps?.[caseId]) {
        Vue.delete(state.heatmaps, caseId);
      }
    },
    [caseMutations.RemoveCase]: (state, caseId) => {
      if (!caseId) {
        console.error("RemoveCase mutation missing caseId");
        return;
      }
      if (state.cases?.[caseId]) {
        Vue.delete(state.cases, caseId);
      }
      if (state.heatmaps?.[caseId]) {
        Vue.delete(state.heatmaps, caseId);
      }
      if (state.caseLoadingMap?.[caseId]) {
        Vue.delete(state.caseLoadingMap, caseId);
      }
      if (state.caseOptionsObjectives?.[caseId]) {
        Vue.delete(state.caseOptionsObjectives, caseId);
      }
      if (state.caseTopOptionObjectives?.[caseId]) {
        Vue.delete(state.caseTopOptionObjectives, caseId);
      }
    },

    [caseMutations.RemovePolygonResult]: (
      state,
      { caseId, polygonResultId }
    ) => {
      if (!caseId || !polygonResultId) {
        console.error(
          "RemovePolygonResult mutation missing caseId or polygonResultId"
        );
        return;
      }
      const polygonResultIds = state.cases?.[caseId]?.opportunityMapIds.filter(
        (id) => id !== polygonResultId
      );
      Vue.set(state.cases, caseId, {
        ...state.cases?.[caseId],
        opportunityMapIds: polygonResultIds
      });
    },

    [caseMutations.CaseAddOption]: (state, { optionId, caseId }) => {
      if (!optionId || !caseId) {
        console.error(
          "CaseAddOption mutation missing optionId or caseId. caseId: ",
          caseId,
          "optionId: ",
          optionId
        );
        return;
      }

      const caseOptions = state?.cases?.[caseId]?.optionIds;

      if (caseOptions && !caseOptions.includes(optionId)) {
        Vue.set(state?.cases?.[caseId], "optionIds", [
          ...caseOptions,
          optionId
        ]);
      }
    },
    [caseMutations.CaseDeleteOption]: (state, { optionId, caseId }) => {
      if (!optionId || !caseId) {
        console.error(
          "CaseDeleteOption mutation missing optionId or caseId. caseId: ",
          caseId,
          "optionId: ",
          optionId
        );
        return;
      }
      if (!state?.cases?.[caseId]?.optionIds?.length) {
        return;
      }
      Vue.set(
        state?.cases?.[caseId],
        "optionIds",
        state?.cases?.[caseId]?.optionIds?.filter((id) => id != optionId)
      );
    },
    [caseMutations.ResetCasesState]: (state) => {
      Vue.set(state, "cases", {});
      Vue.set(state, "heatmaps", {});
      Vue.set(state, "labels", {});
      Vue.set(state, "caseLoadingMap", {});
      Vue.set(state, "caseOptionsObjectives", {});
      Vue.set(state, "caseTopOptionObjectives", {});
    },
    [caseMutations.CaseSetOptionsObjectives]: (
      state,
      { caseId, objectives }
    ) => {
      Vue.set(state.caseOptionsObjectives, caseId, objectives);
    },
    [caseMutations.CaseSetTopObjectiveOptions]: (
      state,
      { caseId, topObjectiveOptions }
    ) => {
      Vue.set(state.caseTopOptionObjectives, caseId, topObjectiveOptions);
    }
  },
  actions: {
    [caseActions.CaseSetLazilyLoadedOptions]: (
      { commit },
      { optionsLazilyLoaded, caseId }
    ) => {
      commit(caseMutations.CaseSetLazilyLoadedOptions, {
        optionsLazilyLoaded,
        caseId
      });
    },
    [caseActions.CaseAddOption]: ({ commit }, { optionId, caseId }) => {
      commit(caseMutations.CaseAddOption, { optionId, caseId });
    },
    [caseActions.CaseDeleteOption]: ({ commit }, { optionId, caseId }) => {
      try {
        commit(caseMutations.CaseDeleteOption, { optionId, caseId });
      } catch (error) {
        console.error("CaseDeleteOption error", error);
      }
    },

    [caseActions.CaseToggleHeatmapVisibility]: (
      { state, getters, dispatch },
      caseId
    ) => {
      if (state.heatmaps?.[caseId]) {
        const newVisible =
          !getters[caseGetters.CaseGetHeatmapVisibility](caseId);
        dispatch(savingViewActions.SavingViewSetHeatmapVisibility, {
          caseID: caseId,
          isVisible: newVisible
        });
      }
    },
    [caseActions.CaseSetResultVisibility]: (
      { state, dispatch },
      { resultId, caseId, setTo }
    ) => {
      if (!resultId || !caseId) {
        console.error(
          "CaseSetResultVisibility called without resultId or caseId. resultId: ",
          resultId,
          "caseId: ",
          caseId
        );
        return;
      }
      const optionIdsInCase = state.cases?.[caseId]?.optionIds;
      const polygonResultIdsInCase = state.cases?.[caseId]?.opportunityMapIds;
      const boolSetTo = Boolean(setTo);

      const resultIdAsNumber = Number(resultId);
      if (optionIdsInCase.includes(resultIdAsNumber)) {
        dispatch(OptionTypes.actions.OptionsToggleVisibility, {
          optionIds: resultIdAsNumber,
          setTo: boolSetTo
        });
      }
      if (polygonResultIdsInCase.includes(resultId)) {
        dispatch(savingViewActions.SavingViewSetPolygonResultVisibility, {
          polygonResultId: resultId,
          isVisible: boolSetTo
        });
      }
    },
    [caseActions.CaseSetAllResultsVisibility]: (
      { state, dispatch },
      { caseId, setTo }
    ) => {
      if (!caseId) {
        console.error("CaseSetAllResultsVisibility called without caseId");
        return;
      }
      const optionIdsInCase = state.cases?.[caseId]?.optionIds;
      const polygonResultIdsInCase =
        state.cases?.[caseId]?.opportunityMapIds || [];

      const boolSetTo = Boolean(setTo);
      dispatch(OptionTypes.actions.OptionsToggleVisibility, {
        optionIds: optionIdsInCase,
        setTo: boolSetTo
      });
      polygonResultIdsInCase.forEach((id) => {
        dispatch(savingViewActions.SavingViewSetPolygonResultVisibility, {
          polygonResultId: id,
          isVisible: boolSetTo
        });
      });
    },
    [caseActions.AddCase]({ commit }, payload) {
      commit(caseMutations.AddCase, payload);
    },
    [caseActions.RemoveCase]: ({ commit, getters, dispatch }, caseId) => {
      if (!caseId) {
        console.error("RemoveCase action missing caseId");
        return;
      }
      const optionsInCase = getters[caseGetters.CaseGetAllOptionsIds](caseId);
      if (optionsInCase?.length) {
        optionsInCase.forEach((optionId) => {
          dispatch(OptionTypes.actions.RemoveOption, optionId);
        });
      }
      commit(caseMutations.RemoveCase, caseId);
    },
    [caseActions.RemovePolygonResult]: (
      { commit },
      { polygonResultId, caseId }
    ) => {
      commit(caseMutations.RemovePolygonResult, { caseId, polygonResultId });
    },
    [caseActions.CaseSetOptionsObjectives]: ({ commit }, payload) => {
      commit(caseMutations.CaseSetOptionsObjectives, payload);
    },
    [caseActions.CaseSetTopObjectiveOptions]: ({ commit }, payload) => {
      commit(caseMutations.CaseSetTopObjectiveOptions, payload);
    }
  }
};
