//A store to keep track of the user's current layers tree so that it is in-sync over multiple components

import types from "./types";
import VectorDatasetsDisplayMode from "@/constants/VectorDatasetsDisplayMode";

export function createEmptyState() {
  return {
    displayMode: VectorDatasetsDisplayMode.DATASET,
    groupByProperty: null, //The property to group by in custom mode
    showProperty: null, //The property to show in the list items in group mode
    searchFilter: "",
    showAliasOrName: "alias"
  };
}
export default {
  state: createEmptyState(),
  getters: {
    [types.getters.LayersTreeGetDisplayMode]: (state) => {
      return state.displayMode;
    },
    [types.getters.LayersTreeGetGroupByProperty]: (state) => {
      return state.groupByProperty;
    },
    [types.getters.LayersTreeGetShowProperty]: (state) => {
      return state.showProperty;
    },
    [types.getters.LayersTreeGetSearchFilter]: (state) => {
      return state.searchFilter;
    },
    [types.getters.LayersTreeGetShowAliasOrName]: (state) => {
      return state.showAliasOrName;
    }
  },
  mutations: {
    [types.mutations.LayersTreeSetDisplayMode]: (state, mode) => {
      Vue.set(state, "displayMode", mode);
    },
    [types.mutations.LayersTreeSetGroupByProperty]: (
      state,
      groupByProperty
    ) => {
      Vue.set(state, "groupByProperty", groupByProperty);
    },
    [types.mutations.LayersTreeSetShowProperty]: (state, propertyToShow) => {
      Vue.set(state, "showProperty", propertyToShow);
    },
    [types.mutations.LayersTreeSetSearchFilter]: (state, searchFilter) => {
      Vue.set(state, "searchFilter", searchFilter);
    },
    [types.mutations.LayersTreeSetShowAliasOrName]: (
      state,
      showNameOrAlias
    ) => {
      Vue.set(state, "showAliasOrName", showNameOrAlias);
    },
    [types.mutations.LayersTreeResetState](state) {
      Object.assign(state, createEmptyState());
    }
  },
  actions: {
    [types.actions.LayersTreeSetDisplayMode]({ commit }, mode) {
      commit(types.mutations.LayersTreeSetDisplayMode, mode);
    },
    [types.actions.LayersTreeSetGroupByProperty]({ commit }, groupByProperty) {
      commit(types.mutations.LayersTreeSetGroupByProperty, groupByProperty);
    },
    [types.actions.LayersTreeSetShowProperty]({ commit }, propertyToShow) {
      commit(types.mutations.LayersTreeSetShowProperty, propertyToShow);
    },
    [types.actions.LayersTreeSetSearchFilter]({ commit }, searchFilter) {
      commit(types.mutations.LayersTreeSetSearchFilter, searchFilter);
    },
    [types.actions.LayersTreeSetShowAliasOrName]({ commit }, showNameOrAlias) {
      commit(types.mutations.LayersTreeSetShowAliasOrName, showNameOrAlias);
    },
    [types.actions.LayersTreeResetState]({ commit }) {
      commit(types.mutations.LayersTreeResetState);
    }
  }
};
