const getFeatureOpacity = (
  nonCategoryOpacity,
  categoryOpacity,
  defaultOpacity
) => {
  if (
    (!nonCategoryOpacity || nonCategoryOpacity === defaultOpacity) &&
    categoryOpacity
  ) {
    return categoryOpacity / 100;
  } else if (nonCategoryOpacity && nonCategoryOpacity !== defaultOpacity) {
    return nonCategoryOpacity;
  } else {
    return defaultOpacity;
  }
};

const getFeatureColour = (nonCategoryColour, categoryColour, defaultColour) => {
  if (
    (!nonCategoryColour || nonCategoryColour === defaultColour) &&
    categoryColour
  ) {
    return categoryColour;
  } else if (nonCategoryColour && nonCategoryColour !== defaultColour) {
    return nonCategoryColour;
  } else {
    return defaultColour;
  }
};

const getFeatureOwnerName = (createdBy, users) => {
  let name;
  if (users?.[createdBy]?.nickname) {
    name = users?.[createdBy]?.nickname;
  } else {
    name = createdBy;
  }
  return name || "Unknown";
};

export { getFeatureOpacity, getFeatureColour, getFeatureOwnerName };
