export const routes = {
  analyze: "analyze",
  data: "data",
  setup: "setup",
  parameters: "parameters",
  mapExport: "mapExport",
  projectDetails: "projectDetails",
  portfolio: "portfolio",
  portfolioMap: "map",
  portfolioDashboard: "dashboard"
};
