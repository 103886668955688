export default {
  vProfile: {
    id: "vertical-profile",
    label: "Vertical Profile",
    viewId: "LONG_SECTION"
  },
  composition: {
    id: "composition",
    label: "Composition",
    viewId: "COMPOSITION"
  },
  schedule: { id: "schedule", label: "Schedule", viewId: "SCHEDULE" }
};
