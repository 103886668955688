export const LineTypes = {
  getters: {
    GetAllLines: "GetAllLines",
    AllLineSets: "AllLineSets",
    LineGetSet: "LineGetSet",
    AnyLineIsLoading: "AnyLineIsLoading",
    LineGetLoadingState: "LineGetLoadingState",
    LineGetInteractionState: "LineGetInteractionState",
    LineGetName: "LineGetName"
  },
  mutations: {
    LineAddLine: "LineAddLine",
    LineUpdate: "LineUpdate",
    LineDeleteLine: "LineDeleteLine",
    LineSetLoadingState: "LineSetLoadingState",
    LineSetInteractionState: "LineSetInteractionState",
    LineResetState: "LineResetState"
  },
  actions: {
    LineAddLine: "LineAddLine",
    LineUpdate: "LineUpdate",
    LineDeleteLine: "LineDeleteLine",
    LineSetLoadingState: "LineSetLoadingState",
    LineSetInteractionState: "LineSetInteractionState",
    LineResetState: "LineResetState"
  }
};

const getters = LineTypes.getters;

export { getters };
