import gql from "graphql-tag";

/*
input EvaluatedBoundsRequest {
  projectID: ProjectID!
  option_ids: [ID!]!
  metric_names: [String!]!
}
*/
export default (input) => {
  return {
    query: gql`
      query evaluateRAGBounds(
        $evaluatedBoundsRequest: EvaluatedBoundsRequest!
      ) {
        evaluateRAGBounds(evaluatedBoundsRequest: $evaluatedBoundsRequest) {
          bounds {
            metricName
            greenBound
            redBound
            baseValue
          }
        }
      }
    `,
    variables: { evaluatedBoundsRequest: input }
  };
};
