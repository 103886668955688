<template>
  <v-tooltip v-model="tooltip" :disabled="hasAccess" top>
    <template v-slot:activator="permissionGuardTooltip">
      <div v-on="permissionGuardTooltip.on">
        <slot v-bind:hasAccess="hasAccess"></slot>
      </div>
    </template>
    <span>
      This action requires {{ permission }} permissions, which can be granted
      by:
    </span>
    <ul>
      <li v-for="user in admins" :key="user.id">{{ user.email }}</li>
    </ul>
  </v-tooltip>
</template>

<script>
import fetchUsers from "@C/userManagement/helpers/fetchUsersByProjectId";
import permissions from "@/mixins/permissions";
import { permissionTypesForProject } from "@/constants/PermissionsTypes.js";

export default {
  name: "PermissionGuard",
  emits: ["hasAccess"],
  mixins: [permissions],
  data() {
    return {
      tooltip: false,
      admins: []
    };
  },
  props: {
    permission: {
      type: String,
      required: true,
      validator: (value) => {
        return Object.values(permissionTypesForProject).includes(value);
      }
    }
  },
  computed: {
    hasAccess() {
      return this.userCan(this.permission);
    }
  },
  watch: {
    hasAccess: {
      immediate: true,
      handler(newValue) {
        this.$emit("hasAccess", newValue);
      }
    },
    async tooltip(newValue) {
      if (newValue && !this.admins.length) {
        const users = await fetchUsers(this.$route.params.projectId);
        this.admins = users.filter(
          (user) => user.role?.toUpperCase() === "ADMIN"
        );
      }
    }
  }
};
</script>
