import Options from "@/constants/Options";

const newEmptyOptionState = () => {
  return {
    options: {
      //id: {geojson: {}, discreteItems: [], polygonOutputs: [], optionMetrics: [],
      // alias: String, objectiveMetrics: [], scenarios: [], constraintViolation: float}
      // scenarios: [{id: int, name: String, colour: String}]
      //}
    },
    visiblePolygonOutputNames: new Set(), // polygon metric names that are visible.
    visibleOptionIds: [],
    selectedOptionIds: [],
    activeOptionId: null,
    hoveredOption: {
      //id: String
      //hoverIsActive: Boolean -> used because some places care about the most recently hovered option, even if it is not currently hovered over
    },
    allOptionsGeojson: {
      type: "FeatureCollection",
      features: []
    },
    colourMap: {}, // {optionId: String },
    caseIdsForOptions: {}, // {optionId: caseId},
    loadingMap: {}, // {optionId: loadingState},
    routeIconsCounters: {}, // {['route icon name']: Bool},
    optionSort: {
      direction: Options.sorters.directions.descending,
      sortField: Options.sorters.sortFields.alphabetical.field
    }, //{sortField: field, direction: direction}
    lazilyLoadedHoveredOptionGeojson: null, // Same format as normal option geojson, but only contains the geojson for the lazily loaded option which is hovered over.
    lazilyLoadedActiveOptionGeojson: null, // Same format as normal option geojson, but only contains the geojson for the lazily loaded option which is active.,
    archivedOptions: []
  };
};

export default newEmptyOptionState;
