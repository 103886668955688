import gql from "graphql-tag";

export default (projectId, userId) => {
  return {
    mutation: gql`
      mutation RemoveUserFromProject($projectId: ProjectID!, $userId: UserID!) {
        removeUserFromProject(projectID: $projectId, userID: $userId) {
          message
        }
      }
    `,
    variables: { projectId: projectId, userId: userId }
  };
};
