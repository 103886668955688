import gql from "graphql-tag";

export default (projectID) => {
  return {
    query: gql`
      query getAllRAGs($projectID: ProjectID!) {
        rags(projectID: $projectID) {
          id
          createdAt
          updatedAt
          createdBy
          updatedBy
          metricName
          expressionType
          greenBound
          redBound
        }
      }
    `,
    variables: { projectID: projectID }
  };
};