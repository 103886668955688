import { cloneDeep } from "lodash";
import { isEqual } from "lodash";
import Vue from "vue";

import {
  findParameterById,
  checkKeyValueListIsDirty,
  updateDatasetSettings,
  updateDesignRuleSettings
} from "./helpers";
import { setCategories } from "./categories";
import {
  setComponentSettings,
  getDirtyComponentsForConfig
} from "./components";
import { ConfigTypes as types } from "./types";
import convertComponentSettings from "@/hooks/convertComponentSettings";
import getDescendents from "@/helpers/getDescendents";
import { getters as datasetGetters } from "@S/dataset/types";
import { getters as setupGetters } from "@S/setupPage/types";
import LoadingStates from "@/constants/LoadingStates";
import { configAssetTypes } from "@/constants/AssetType";
import SetupModes from "@/constants/SetupModes";
import ConfigConsts from "@/constants/ConfigConsts";

const assetsToSetupModesMap = {
  [configAssetTypes.TRANSMISSION_LINE]: new Set([
    SetupModes.EVALUATION,
    SetupModes.GENERATION,
    SetupModes.CORRIDOR_GENERATION,
    SetupModes.NONE
  ]),
  [configAssetTypes.OFFSHORE_PIPELINE]: new Set([
    SetupModes.EVALUATION,
    SetupModes.GENERATION,
    SetupModes.CORRIDOR_GENERATION,
    SetupModes.NONE
  ]),
  [configAssetTypes.OFFSHORE_CABLE]: new Set([
    SetupModes.EVALUATION,
    SetupModes.GENERATION,
    SetupModes.CORRIDOR_GENERATION,
    SetupModes.NONE
  ]),
  [configAssetTypes.ONSHORE_CABLE]: new Set([
    SetupModes.EVALUATION,
    SetupModes.GENERATION,
    SetupModes.CORRIDOR_GENERATION,
    SetupModes.NONE
  ]),
  [configAssetTypes.ONSHORE_PIPELINE]: new Set([
    SetupModes.EVALUATION,
    SetupModes.GENERATION,
    SetupModes.CORRIDOR_GENERATION,
    SetupModes.NONE
  ]),
  [configAssetTypes.WATER_PIPELINE]: new Set([
    SetupModes.EVALUATION,
    SetupModes.GENERATION,
    SetupModes.CORRIDOR_GENERATION,
    SetupModes.NONE
  ]),
  [configAssetTypes.ELECTRICITY]: new Set([
    SetupModes.EVALUATION,
    SetupModes.GENERATION,
    SetupModes.CORRIDOR_GENERATION,
    SetupModes.NONE
  ]),
  [configAssetTypes.SITE_SELECTION]: new Set([
    SetupModes.SITE_SELECTION,
    SetupModes.NONE
  ]),
  [configAssetTypes.ACCESS_TRACK]: new Set([
    SetupModes.EVALUATION,
    SetupModes.NONE
  ]),
  [configAssetTypes.ONSHORE_PIPELINE_2]: new Set([
    SetupModes.EVALUATION,
    SetupModes.GENERATION,
    SetupModes.CORRIDOR_GENERATION,
    SetupModes.NONE
  ])
};

const emptyConfig = {
  name: null,
  description: null,
  descriptionEdited: null,
  designRuleSettings: null,
  designRuleSettingsEdited: null,
  designRuleParameterErrors: {}, // variable for manually setting parameters for a particular design rule
  datasetSettings: null,
  datasetSettingsEdited: null,
  componentSettingsMapping: null,
  componentSettingsEditedMapping: null,
  newParametersSchema: false,
  modelSettings: null,
  modelSettingsEdited: null,
  isReadOnly: false,
  loadingState: LoadingStates.INITIALISED,
  configValidationState: {
    succeeded: true,
    validationErrors: []
  },
  assetType: null,
  dateCreated: null,
  dateModified: null
};

const createDefaultSort = () => ({
  direction: ConfigConsts.sorters.directions.descending,
  sortField: ConfigConsts.sorters.sortFields.alphabetical.field
});

export default {
  state: {
    // ...
    configs: {
      // id: {
      // name: null,
      // description: null,
      // descriptionEdited: null,
      // designRuleSettings: null,
      // designRuleSettingsEdited: null,
      // designRuleParameterErrors: {}, // variable for manually setting parameters for a particular design rule
      // datasetSettings: null,
      // datasetSettingsEdited: null,
      // componentSettingsMapping: null,
      // componentSettingsEditedMapping: null,
      // newParametersSchema: false,
      // modelSettings: null,
      // modelSettingsEdited: null,
      // isReadOnly: false,
      // loadingState: LoadingStates.INITIALISED,
      // configValidationState: {
      //   succeeded: true,
      //   validationErrors: []
      // },
      // assetType: null,
      // dateCreated: null,
      // dateModified: null
      // }
    },
    configSort: createDefaultSort()
  },
  getters: {
    [types.getters.ConfigsAreLoading]: (state) => {
      const configIds = Object.keys(state.configs);
      return configIds.some((configId) => {
        return (
          state.configs[configId].loadingState == LoadingStates.LOADING ||
          state.configs[configId].loadingState == LoadingStates.INITIALISED
        );
      });
    },
    [types.getters.GetConfigSort]: (state) => {
      return state.configSort;
    },
    [types.getters.GetAllConfigIds]: (state) => {
      return Object.keys(state.configs);
    },
    [types.getters.GetEditedDesignRulesWithName]: (state, getters) => {
      const configIds = Object.keys(state.configs);
      const configEditedDRsWithNames = new Array();
      configIds.forEach((configId) => {
        const editedDesignRules =
          getters[types.getters.GetConfigEditedDesignRulesWithNameById](
            configId
          );
        if (!editedDesignRules) return;
        configEditedDRsWithNames.push(editedDesignRules);
      });
      return configEditedDRsWithNames;
    },
    [types.getters.AllConfigNames]: (state) => {
      const configIds = Object.keys(state.configs);
      const configNames = new Array();
      configIds.forEach((configId) => {
        const name = state.configs[configId].name;
        configNames.push(name);
      });
      return configNames;
    },
    [types.getters.AllConfigSummaries]: (state, getters) => {
      const configIds = Object.keys(state.configs);
      const configSummaries = new Array();
      configIds.forEach((configId) => {
        const summary = getters[types.getters.GetConfigSummaryById](configId);
        configSummaries.push(summary);
      });
      return configSummaries;
    },
    [types.getters.GetConfigCategoriesById]: (state) => (id) => {
      return state.configs[id]?.categoriesForConfig;
    },
    [types.getters.GetConfigLoadingStateById]: (state) => (id) => {
      return state.configs[id]?.loadingState;
    },
    [types.getters.GetConfigEditedDesignRulesWithNameById]: (state) => (id) => {
      return {
        id: id,
        name: state.configs[id].name,
        designRuleSettings: state.configs[id].newParametersSchema
          ? convertComponentSettings.toArray(
              state.configs[id]?.componentSettingsEditedMapping
            )
          : state.configs[id].designRuleSettingsEdited
      };
    },
    [types.getters.GetConfigSummaryById]: (state, getters) => (id) => {
      return {
        id: id,
        name: state.configs[id].name,
        description: state.configs[id].description,
        designRuleSettings: state.configs[id].designRuleSettings,
        datasetSettings: state.configs[id].datasetSettings,
        isReadOnly: state.configs[id].isReadOnly,
        loadingState: state.configs[id].loadingState,
        categoriesForConfig: state.configs[id].categoriesForConfig,
        isValid: getters[types.getters.GetConfigIsValidById](id)
      };
    },
    [types.getters.GetConfigAssetTypeById]: (state) => (id) => {
      return state.configs[id]?.assetType;
    },
    [types.getters.GetConfigIsUsingNewParametersSchemaById]:
      (state) => (id) => {
        return state.configs[id]?.newParametersSchema;
      },
    [types.getters.GetConfigIsReadOnlyById]: (state) => (id) => {
      return state.configs[id]?.isReadOnly;
    },
    [types.getters.GetConfigDatasetSettingsById]: (state) => (id) => {
      return state.configs[id]?.datasetSettings;
    },
    [types.getters.GetConfigNameById]: (state) => (id) => {
      return state.configs[id]?.name;
    },
    [types.getters.GetConfigDescriptionById]: (state) => (id) => {
      return state.configs[id]?.description;
    },
    [types.getters.GetConfigEditedDescriptionById]: (state) => (id) => {
      return state.configs[id]?.descriptionEdited;
    },
    [types.getters.GetConfigDateCreatedById]: (state) => (id) => {
      return state.configs[id]?.dateCreated;
    },
    [types.getters.GetConfigDateModifiedById]: (state) => (id) => {
      return state.configs[id]?.dateModified;
    },
    [types.getters.GetConfigModelSettingsById]: (state) => (id) => {
      return state.configs[id]?.modelSettings;
    },
    [types.getters.GetConfigDesignRuleSettingsById]: (state) => (id) => {
      return state.configs[id]?.designRuleSettings;
    },
    [types.getters.GetConfigComponentSettingsById]: (state) => (id) => {
      return convertComponentSettings.toArray(
        state.configs[id].componentSettingsMapping
      );
    },
    [types.getters.GetConfigDirtyComponentSettingsById]: (state) => (id) => {
      return getDirtyComponentsForConfig(state.configs[id]);
    },
    [types.getters.GetConfigDesignRuleParameterIsDirtyById]:
      (state) => (id, parameterId) => {
        const original = findParameterById(
          state.configs[id].designRuleSettings,
          parameterId
        );
        const current = findParameterById(
          state.configs[id].designRuleSettingsEdited,
          parameterId
        );

        const inputType = original.input_type;

        if (inputType == "key_value_list") {
          return checkKeyValueListIsDirty(original, current);
        } else {
          return !isEqual(current, original);
        }
      },

    [types.getters.GetConfigDesignRuleModelIsDirtyById]:
      (state) => (id, parameterId) => {
        const original = state.configs[
          id
        ].designRuleSettings?.design_rules?.find((param) => {
          return parameterId == param?.id;
        });
        const current = state.configs[
          id
        ]?.designRuleSettingsEdited?.design_rules?.find((param) => {
          return parameterId == param?.id;
        });
        const isDirty = !isEqual(current.models, original.models);
        return isDirty;
      },
    [types.getters.GetConfigDirtyDesignRulesById]: (state, getters) => (id) => {
      const isDirty = [];

      state.configs[id]?.designRuleSettings?.design_rules?.forEach(
        (designRule) => {
          const isDirtyDesignRule = { id: designRule?.id, isDirty: false };

          if (
            getters[types.getters.GetConfigDesignRuleModelIsDirtyById](
              id,
              designRule?.id
            )
          ) {
            isDirtyDesignRule.isDirty = true;
          }
          const editedDesignRule = state.configs[
            id
          ]?.designRuleSettingsEdited?.design_rules?.find(
            (editedDesignRule) => designRule?.id == editedDesignRule?.id
          );
          if (editedDesignRule?.active != designRule.active) {
            isDirtyDesignRule.isDirty = true;
          }

          designRule.children.forEach((childId) => {
            const childIds = getDescendents(
              state.configs[id].designRuleSettingsEdited.parameters,
              childId
            );
            childIds?.forEach((idOfChildId) => {
              if (
                getters[types.getters.GetConfigDesignRuleParameterIsDirtyById](
                  id,
                  idOfChildId
                )
              ) {
                isDirtyDesignRule.isDirty = true;
              }
            });
          });
          isDirty.push(isDirtyDesignRule);
        }
      );
      return isDirty;
    },
    [types.getters.GetConfigModelParameterIsDirtyById]:
      (state) => (id, parameterId) => {
        const original = state.configs[id].modelSettings.parameters?.find(
          (param) => {
            return parameterId == param?.id;
          }
        );
        const current = state.configs[id].modelSettingsEdited.parameters?.find(
          (param) => {
            return parameterId == param?.id;
          }
        );
        const isDirty = !isEqual(current, original);
        return isDirty;
      },
    [types.getters.GetConfigDirtyModelsById]: (state, getters) => (id) => {
      return state.configs[id]?.modelSettings?.models?.map((model) => {
        const isDirtyModel = { id: model?.id, isDirty: false };
        model.children.forEach((childId) => {
          const childIds = getDescendents(
            state.configs[id]?.modelSettingsEdited?.parameters,
            childId
          );
          childIds.forEach((idOfChild) => {
            if (
              //NOTE: This getter is a O(n) operation, making this function at least an O(n^3) operation
              getters[types.getters.GetConfigModelParameterIsDirtyById](
                id,
                idOfChild
              )
            ) {
              isDirtyModel.isDirty = true;
            }
          });
        });
        return isDirtyModel;
      });
    },
    [types.getters.GetConfigModelSettingsEditedById]: (state) => (id) => {
      return state.configs[id]?.modelSettingsEdited;
    },
    [types.getters.GetConfigComponentSettingsEditedById]: (state) => (id) => {
      return convertComponentSettings.toArray(
        state.configs[id]?.componentSettingsEditedMapping
      );
    },
    [types.getters.GetConfigDesignRuleSettingsEditedById]: (state) => (id) => {
      return state.configs[id]?.designRuleSettingsEdited;
    },
    [types.getters.GetConfigEditedDesignRuleSettingParametersById]:
      (state) => (id) => {
        return state.configs[id]?.designRuleSettingsEdited?.parameters;
      },
    [types.getters.GetConfigDatasetSettingsEditedById]: (state) => (id) => {
      return state.configs[id]?.datasetSettingsEdited;
    },
    [types.getters.GetConfigDirtyDatasetsById]: (state) => (id) => {
      const dirtyDatasets = [];

      state.configs[id].datasetSettingsEdited?.forEach((datasetEdited) => {
        const dataset = state.configs[id]?.datasetSettings?.find(
          (ds) => datasetEdited?.name == ds?.name
        );
        dirtyDatasets.push({
          name: datasetEdited?.name,
          isDirty: !isEqual(datasetEdited, dataset)
        });
      });
      return dirtyDatasets;
    },
    [types.getters.GetConfigDatasetErrorsById]:
      (state, getters, _, rootGetters) => (id) => {
        const datasetErrors = [];
        if (rootGetters[datasetGetters.AnyDatasetsLoading]) {
          return datasetErrors;
        }

        state.configs[id].datasetSettingsEdited?.forEach((dataset) => {
          if (!dataset?.active) {
            return;
          }
          if (
            (!dataset.properties.dataset_id ||
              dataset?.properties?.dataset_id === null) &&
            (!dataset.properties.collection_id ||
              dataset.properties.collection_id === null)
          ) {
            datasetErrors.push({
              datasetName: dataset.name,
              errorMessage: "Dataset or Collection must be selected."
            });
          }

          if (
            dataset?.properties?.dataset_id &&
            dataset?.properties?.dataset_id !== null &&
            !rootGetters[datasetGetters.DatasetGetAllIds].find(
              (id) => id == dataset.properties.dataset_id
            )
          ) {
            datasetErrors.push({
              datasetName: dataset?.name,
              errorMessage:
                "Unavailable Dataset: Please select a valid dataset."
            });
          }
        });
        return datasetErrors;
      },
    [types.getters.GetConfigDesignRuleErrorsById]: (state, getters) => (id) => {
      {
        const errors = [];

        state.configs[id].designRuleSettings?.design_rules?.forEach(
          (designRule) => {
            if (designRule.required_geodata?.some((x) => x == "unknown")) {
              errors.push({
                designRuleId: designRule.id,
                errorMessage:
                  "Template is missing or damaged for this design rule, please contact Continuum Industries"
              });
            }

            const descendantIds = [];
            designRule?.children?.forEach((childId) => {
              descendantIds.push(
                ...getDescendents(
                  state.configs[id].designRuleSettingsEdited.parameters,
                  childId
                )
              );
            });
            descendantIds?.forEach((childId) => {
              getters[types.getters.GetConfigDesignRuleParameterErrorsById](
                id
              )?.forEach((parameterError) => {
                if (parameterError?.parameterId == childId) {
                  errors.push({
                    parameterId: parameterError?.parameterId,
                    designRuleId: designRule?.id,
                    errorMessage: parameterError?.errorMessage
                  });
                }
              });
            });
          }
        );

        return errors;
      }
    },
    [types.getters.GetConfigDesignRuleErrorsByNameById]:
      (state, getters, _, rootGetters) => (id, designRuleName) => {
        const errors = [];

        const designRuleToBeCheckForErrors =
          state.configs[id]?.componentSettingsEditedMapping[designRuleName];

        if (
          designRuleToBeCheckForErrors.required_geodata?.some(
            (x) => x == "unknown"
          )
        ) {
          errors.push({
            designRuleName: designRuleToBeCheckForErrors?.name,
            errorMessage:
              "Template is missing or damaged for this design rule, please contact Continuum Industries"
          });
        }

        Object.values(designRuleToBeCheckForErrors?.parameters)?.forEach(
          (parentParameterItem) => {
            parentParameterItem.children?.forEach((child) => {
              if (child?.input_type == "key_value_list") {
                const requestedDataset = state.configs[
                  id
                ].datasetSettingsEdited?.find(
                  (dataset) => dataset?.name == child?.key_source
                );
                if (requestedDataset === undefined) {
                  errors.push({
                    parameterId: child?.id,
                    errorMessage: `The design rule requires the dataset: ${child?.key_source} but it is not present in the dataset settings`
                  });
                  return;
                }
                const geodataId = rootGetters[
                  datasetGetters.DatasetGetAllIds
                ]?.find((id) => id == requestedDataset?.properties?.dataset_id);
                if (!geodataId) {
                  return;
                }
                if (
                  !rootGetters[datasetGetters.DatasetGetIsLoaded](geodataId?.id)
                ) {
                  return;
                }
                const datasetLayerNameIds = rootGetters[
                  datasetGetters.DatasetGetLayerNamesAndIds
                ](geodataId?.id);

                child?.value?.forEach((value) => {
                  if (
                    !datasetLayerNameIds?.some(
                      (layerNameId) => layerNameId?.name == value[0]
                    )
                  ) {
                    errors.push({
                      designRuleName,
                      parameterId: child?.id,
                      errorMessage: `The layer ${value[0]} does not exist in the chosen dataset`
                    });
                  }
                });
              }
            });
          }
        );
        return errors;
      },
    [types.getters.GetConfigDesignRuleParameterErrorsById]: (state) => (id) => {
      const parameterErrors = [];
      for (const [parameterId, errorMessages] of Object.entries(
        state.configs[id].designRuleParameterErrors
      )) {
        errorMessages.forEach((errorMessage) => {
          parameterErrors.push({ parameterId, errorMessage });
        });
      }
      return parameterErrors;
    },
    [types.getters.GetConfigIsValidForSetupMode]:
      (state, getters, _, rootGetters) => (id) => {
        const setupMode = rootGetters[setupGetters.SetupGetSetupMode];
        const assetType = state.configs[id]?.assetType;

        const validSetupModes = assetsToSetupModesMap[assetType];
        if (!validSetupModes)
          return {
            isValid: false,
            errorMessage: `No valid setup modes for this asset type (${assetType}).`
          };
        const isValid = validSetupModes.has(setupMode);

        let errorMessage;
        if (isValid) {
          errorMessage = null;
        } else {
          errorMessage = `The current setup mode (${setupMode}) is not valid for this asset type (${assetType})`;
        }
        return { isValid: isValid, errorMessage: errorMessage };
      },
    [types.getters.GetConfigIsValidById]: (state, getters) => (id) => {
      const validationState = state.configs[id].configValidationState.succeeded;
      const datasetErrors = getters.GetConfigDatasetErrorsById(id).length == 0;
      const validForSetupMode = getters.GetConfigIsValidForSetupMode(id);
      const isValidForSetupMode = validForSetupMode.isValid;

      let errorMessage;
      if (!isValidForSetupMode) {
        errorMessage = validForSetupMode.errorMessage;
      } else {
        errorMessage = "Validation Errors. Review in Parameters page.";
      }

      const isValidValue =
        validationState && datasetErrors && isValidForSetupMode;
      const isValid = {
        value: isValidValue,
        message: isValidValue ? "" : errorMessage
      };
      return isValid;
    },
    [types.getters.GetConfigValidationStateById]: (state) => (id) => {
      return state.configs[id].configValidationState;
    }
  },
  mutations: {
    [types.mutations.SetConfigSort]: (state, payload) => {
      state.configSort = payload;
    },
    [types.mutations.RemoveConfigById]: (state, { id }) => {
      Vue.delete(state.configs, id);
    },
    [types.mutations.ResetConfigsState]: (state) => {
      Vue.set(state, "configs", {});
      Vue.set(state, "configSort", createDefaultSort());
    },

    [types.mutations.SetConfigById]: (state, { id, payload }) => {
      //TODO: Consider whether to move much of this logic into the action instead as that seems to be the recommended pattern
      if (!state?.configs?.[id]) {
        Vue.set(state.configs, id, cloneDeep(emptyConfig));
      }
      if (payload?.name) {
        Vue.set(state.configs[id], "name", payload?.name);
      }
      if (payload?.description) {
        Vue.set(state.configs[id], "description", payload?.description);
        Vue.set(state.configs[id], "descriptionEdited", payload?.description);
      }
      if (payload?.userSettings) {
        Vue.set(
          state.configs[id],
          "userSettings",
          JSON.parse(payload?.userSettings)
        );
      }
      if (payload?.modelSettings && !state.configs[id].modelSettings) {
        Vue.set(
          state.configs[id],
          "modelSettingsEdited",
          cloneDeep(JSON.parse(payload?.modelSettings))
        );
      }
      if (payload?.modelSettings) {
        Vue.set(
          state.configs[id],
          "modelSettings",
          JSON.parse(payload?.modelSettings)
        );
      }
      const tempComponentSettings = payload?.componentSettings
        ? JSON.parse(payload.componentSettings)
        : null;

      setCategories(state, id, tempComponentSettings);
      setComponentSettings(state, id, tempComponentSettings);

      updateDesignRuleSettings(state, id, payload);
      updateDatasetSettings(state, id, payload);
      if (payload?.isReadOnly) {
        Vue.set(state.configs[id], "isReadOnly", payload?.isReadOnly);
      }
      if (payload?.assetType) {
        Vue.set(state.configs[id], "assetType", payload?.assetType);
      }

      if (payload?.createdAt) {
        Vue.set(state.configs[id], "dateCreated", payload?.createdAt);
      }
      if (payload?.lastModified) {
        Vue.set(state.configs[id], "dateModified", payload?.lastModified);
      }
    },
    [types.mutations.SetConfigLoadingStateById]: (
      state,
      { id, loadingState }
    ) => {
      if (!state.configs[id]) return;
      Vue.set(state.configs[id], "loadingState", loadingState);
    },
    [types.mutations.SetConfigDescriptionEditedById]: (
      state,
      { id, descriptionEdited }
    ) => {
      Vue.set(state.configs[id], "descriptionEdited", descriptionEdited);
    },
    [types.mutations.SetConfigValidationStateById]: (
      state,
      { id, configValidationState }
    ) => {
      // configValidationState: {
      //   succeeded: true,
      //   validationErrors: []
      // }
      let validationErrors = configValidationState?.validationErrors;
      validationErrors = validationErrors.map((validationError) => {
        validationError.componentNames = validationError.componentNames || [];
        return validationError;
      });
      configValidationState.validationErrors = validationErrors;
      Vue.set(
        state.configs[id],
        "configValidationState",
        configValidationState
      );
    },
    [types.mutations.SetConfigDesignRulesActiveStateById]: (
      state,
      { id, payload }
    ) => {
      // We need to have two path for this until we migrate
      // all of our configs in all project to new parameters schema
      // i.e. Evaluator2.0
      if (state.configs[id].newParametersSchema) {
        Vue.set(
          state.configs[id].componentSettingsEditedMapping[
            payload.designRuleName
          ],
          "active",
          payload.activeState
        );
      } else {
        const designRuleId = payload?.designRuleId;
        const activeState = payload?.activeState;
        const index = state.configs[
          id
        ].designRuleSettingsEdited.design_rules.findIndex(
          (designRule) => designRule.id == designRuleId
        );
        Vue.set(
          state.configs[id].designRuleSettingsEdited.design_rules[index],
          "active",
          activeState
        );
      }
    },
    [types.mutations.SetConfigComponentSettingsItemById]: (
      state,
      { id, designRuleName, designRuleParameterName, updatedParameter }
    ) => {
      if (!state.configs[id]) return;
      Vue.set(
        state.configs[id].componentSettingsEditedMapping[designRuleName]
          .parameters,
        designRuleParameterName,
        updatedParameter
      );
    },
    [types.mutations.SetConfigMultipleComponentSettingsItemsById]: (
      state,
      { id, designRuleName, arrayOfParamsToBeUpdated }
    ) => {
      arrayOfParamsToBeUpdated.forEach((element) => {
        Vue.set(
          state.configs[id].componentSettingsEditedMapping[designRuleName]
            .parameters,
          element.name,
          element
        );
      });
    },
    [types.mutations.SetConfigDesignRuleParameterById]: (
      state,
      { id, payload }
    ) => {
      const designRuleEditedIndex = state.configs[
        id
      ]?.designRuleSettingsEdited?.parameters?.findIndex((item) => {
        return payload?.id == item?.id;
      });
      if (designRuleEditedIndex == -1) {
        Vue.set(
          state.configs[id]?.designRuleSettingsEdited.parameters,
          state.configs[id]?.designRuleSettingsEdited.parameters.length,
          payload
        );
      } else {
        Vue.set(
          state.configs[id]?.designRuleSettingsEdited?.parameters,
          designRuleEditedIndex,
          payload
        );
      }
    },
    [types.mutations.SetConfigModelParameterById]: (state, { id, payload }) => {
      const modelEditedIndex = state.configs[
        id
      ].modelSettingsEdited?.parameters.findIndex((item) => {
        return payload?.id == item?.id;
      });
      if (modelEditedIndex == -1) {
        Vue.set(
          state.configs[id].modelSettingsEdited.parameters,
          state.configs[id].modelSettingsEdited.parameters.length,
          payload
        );
      } else {
        Vue.set(
          state.configs[id].modelSettingsEdited.parameters,
          modelEditedIndex,
          payload
        );
      }
    },
    [types.mutations.SetConfigRefetchedSettingsById]: (
      state,
      { id, payload }
    ) => {
      if (payload.model_settings) {
        state.configs[id].modelSettingsEdited = JSON.parse(
          payload.model_settings
        );
      }

      if (payload.dataset_settings) {
        state.configs[id].datasetSettingsEdited = JSON.parse(
          payload.dataset_settings
        );
      }
    },
    [types.mutations.SetConfigDatasetById]: (state, { id, payload }) => {
      const datasetEditedIndex = state.configs[
        id
      ].datasetSettingsEdited?.findIndex(
        (dataset) => dataset?.name == payload?.name
      );
      if (datasetEditedIndex == -1) {
        Vue.set(
          state.configs[id].datasetSettingsEdited,
          state.configs[id].datasetSettingsEdited.length,
          payload
        );
      } else {
        Vue.set(
          state.configs[id].datasetSettingsEdited,
          datasetEditedIndex,
          payload
        );
      }
    },
    [types.mutations.SetConfigDesingRuleModelById]: (
      state,
      { id, payload }
    ) => {
      const designRuleEditedIndex = state.configs[
        id
      ]?.designRuleSettingsEdited?.design_rules?.findIndex(
        (designRule) => designRule?.id == payload?.id
      );

      if (designRuleEditedIndex) {
        state.configs[id].designRuleSettingsEdited.design_rules[
          designRuleEditedIndex
        ].models[payload?.modelName] = payload?.modelValue;

        const updatedDesignRule =
          state.configs[id]?.designRuleSettingsEdited?.design_rules[
            designRuleEditedIndex
          ];

        Vue.set(
          state.configs[id].designRuleSettingsEdited.design_rules,
          designRuleEditedIndex,
          updatedDesignRule
        );
      }
    },
    [types.mutations.SetConfigDesignRuleParameterErrorById]: (
      state,
      { id, payload }
    ) => {
      Vue.set(
        state.configs[id].designRuleParameterErrors,
        payload.parameterId,
        payload.errorMessages
      );
    },
    [types.mutations.AddEmptyConfig]: (state, { id }) => {
      Vue.set(state.configs, id, { ...emptyConfig });
    }
  },
  actions: {
    [types.actions.SetConfigSort]: ({ commit }, payload) => {
      commit(types.mutations.SetConfigSort, payload);
    },
    [types.actions.RemoveConfigById]: ({ commit }, { id }) => {
      commit(types.mutations.RemoveConfigById, { id });
    },
    [types.actions.ResetConfigsState]: ({ commit }) => {
      commit(types.mutations.ResetConfigsState);
    },
    [types.actions.SetConfigById]: ({ commit }, { id, payload }) => {
      commit(types.mutations.SetConfigById, { id, payload });
    },
    [types.actions.SetConfigLoadingStateById]: (
      { commit },
      { id, loadingState }
    ) => {
      commit(types.mutations.SetConfigLoadingStateById, { id, loadingState });
    },
    [types.actions.SetConfigDescriptionEditedById]: (
      { commit },
      { id, descriptionEdited }
    ) => {
      commit(types.mutations.SetConfigDescriptionEditedById, {
        id,
        descriptionEdited
      });
    },
    [types.actions.SetConfigValidationStateById]: (
      { commit },
      { id, configValidationState }
    ) => {
      commit(types.mutations.SetConfigValidationStateById, {
        id,
        configValidationState
      });
    },
    [types.actions.SetConfigDesignRulesActiveStateById]: (
      { commit },
      { id, payload }
    ) => {
      commit(types.mutations.SetConfigDesignRulesActiveStateById, {
        id,
        payload
      });
    },
    [types.actions.SetConfigComponentSettingsItemById]: (
      { commit },
      { id, designRuleName, designRuleParameterName, updatedParameter }
    ) => {
      commit(types.mutations.SetConfigComponentSettingsItemById, {
        id,
        designRuleName,
        designRuleParameterName,
        updatedParameter
      });
    },
    [types.actions.SetConfigMultipleComponentSettingsItemsById]: (
      { commit },
      { id, designRuleName, arrayOfParamsToBeUpdated }
    ) => {
      commit(types.mutations.SetConfigMultipleComponentSettingsItemsById, {
        id,
        designRuleName,
        arrayOfParamsToBeUpdated
      });
    },
    [types.actions.SetConfigDesignRuleParameterById]: (
      { commit },
      { id, payload }
    ) => {
      commit(types.mutations.SetConfigDesignRuleParameterById, {
        id,
        payload
      });
    },
    [types.actions.SetConfigModelParameterById]: (
      { commit },
      { id, payload }
    ) => {
      commit(types.mutations.SetConfigModelParameterById, {
        id,
        payload
      });
    },
    [types.actions.SetConfigRefetchedSettingsById]: (
      { commit },
      { id, payload }
    ) => {
      commit(types.mutations.SetConfigRefetchedSettingsById, { id, payload });
    },
    [types.actions.SetConfigDatasetById]: ({ commit }, { id, payload }) => {
      commit(types.mutations.SetConfigDatasetById, { id, payload });
    },
    [types.actions.SetConfigDesingRuleModelById]: (
      { commit },
      { id, payload }
    ) => {
      commit(types.mutations.SetConfigDesingRuleModelById, { id, payload });
    },
    [types.actions.SetConfigDesignRuleParameterErrorById]: (
      { commit },
      { id, payload }
    ) => {
      commit(types.mutations.SetConfigDesignRuleParameterErrorById, {
        id,
        payload
      });
    },
    [types.actions.AddEmptyConfig]: ({ commit }, { id }) => {
      commit(types.mutations.AddEmptyConfig, {
        id
      });
    },
    [types.actions.AddConfigs]: ({ commit }, { configs }) => {
      configs.forEach((payload) => {
        const configId = payload.id;
        commit(types.mutations.SetConfigById, { id: configId, payload });
        commit(types.mutations.SetConfigLoadingStateById, {
          loadingState: LoadingStates.LOADED,
          id: configId
        });

        const validation_errors = payload?.validationErrors;
        if (validation_errors?.length > 0) {
          commit(types.mutations.SetConfigValidationStateById, {
            configValidationState: {
              succeeded: false,
              validationErrors: validation_errors
            },
            id: configId
          });
        }
      });
    }
  }
};
