<template>
  <fragment />
</template>

<script>
import FILE_UPLOAD_STATES from "@/constants/FileUploadStates";
import {
  getters as gettersFromSession,
  actions as actionsFromSession
} from "@S/session/types";
import { mapGetters, mapActions } from "vuex";
import { _axios } from "@/plugins/axios";

export default {
  name: "DatasetUploadTracker",
  data() {
    return {
      pollingTracker: {}
    };
  },
  computed: {
    ...mapGetters([gettersFromSession.SessionGetDatasetUploads])
  },
  beforeDestroy() {
    Object.keys(this.pollingTracker).forEach((ingestId) => {
      this.stopPolling(ingestId);
    });
  },
  methods: {
    ...mapActions([actionsFromSession.SessionSetDatasetUploads]),
    startPolling(ingestOperationId) {
      this.pollingTracker[ingestOperationId] = setInterval(() => {
        _axios
          .get(`V2/${ingestOperationId}`)
          .then((resp) => {
            this.processPollingResponse(resp.data, ingestOperationId);
          })
          .catch((error) => {
            console.warn("Polling datasets ingest operation failed: ", error);
          });
      }, 1000);
    },
    stopPolling(ingestOperationId) {
      if (this.pollingTracker?.[ingestOperationId]) {
        clearInterval(this.pollingTracker?.[ingestOperationId]);
      }
    },

    processPollingResponse(response, ingestOperationId) {
      const datasetName = response?.metadata?.value?.dataset_name;
      if (!response) {
        this.SessionSetDatasetUploads({
          ingestOperationId,
          uploadState: FILE_UPLOAD_STATES.IDLE,
          intervalToken: this.pollingTracker[ingestOperationId],
          datasetName: datasetName
        });
        return;
      }
      if (!response.done) {
        this.SessionSetDatasetUploads({
          ingestOperationId,
          uploadState: FILE_UPLOAD_STATES.IN_PROGRESS,
          intervalToken: this.pollingTracker[ingestOperationId],
          datasetName: datasetName
        });
      } else {
        this.stopPolling(ingestOperationId);
        if (response?.error) {
          const errorMessages = response?.error?.details?.map(
            (detail) => detail.value
          );
          this.SessionSetDatasetUploads({
            ingestOperationId,
            uploadState: FILE_UPLOAD_STATES.FAILED,
            errors: errorMessages,
            intervalToken: this.pollingTracker[ingestOperationId],
            datasetName: datasetName
          });
        } else {
          this.SessionSetDatasetUploads({
            ingestOperationId,
            uploadState: FILE_UPLOAD_STATES.COMPLETED,
            intervalToken: this.pollingTracker[ingestOperationId],
            datasetName: datasetName
          });
        }
      }
    }
  },
  watch: {
    SessionGetDatasetUploads: {
      handler: function (uploadTrackerObject) {
        const currentlyPolledIngests = Object.keys(this.pollingTracker);
        Object.keys(uploadTrackerObject).forEach((ingestId) => {
          // Don't start polling if this ingest is already being tracked
          const uploadIntervalToken =
            uploadTrackerObject[ingestId]?.intervalToken;
          if (
            !currentlyPolledIngests.includes(ingestId) &&
            !uploadIntervalToken
          ) {
            this.startPolling(ingestId);
          }
        });
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
