import { actions, mutations, getters } from "./types";

export default {
  state: {
    alert: null
  },
  getters: {
    [getters.AlertExists](state) {
      return !!state.alert;
    },
    [getters.AlertGetType](state, getters) {
      return getters.AlertExists ? state.alert.type : null;
    },
    [getters.AlertGetMessage](state, getters) {
      return getters.AlertExists ? state.alert.message : null;
    }
  },
  mutations: {
    [mutations.AlertUpdate]: (state, payload) => {
      if (payload === null) {
        state.alert = null;
        return;
      }
      if (!("message" in payload)) {
        throw new Error("Alert message is required");
      }
      if (!("type" in payload)) {
        throw new Error("Alert type is required");
      }
      if (
        ![null, "success", "error", "warning", "info"].includes(payload.type)
      ) {
        throw new Error("Invalid alert type");
      }
      state.alert = payload;
    }
  },
  actions: {
    [actions.AlertUpdate]: ({ commit }, payload) => {
      commit(mutations.AlertUpdate, payload);
    }
  }
};
