//On removal of feature flag 'RELATIVE_RAG', uncomment the commented code below
export const ResultsLibraryAnalysisModes = {
  DEFAULT: "DEFAULT",
  ANALYSIS: "ANALYSIS"
  //RAG: "RAG"
};

export const ResultsLibraryAnalysisModeNames = {
  DEFAULT: "None",
  ANALYSIS: "Weighted Ranking"
  //RAG: "Red-Amber-Green"
};
