export const checkMilestonePassesFilters = ({
  milestone,
  statusFilters,
  milestoneNameFilters,
  deliverableNameFilters,
  deliverablesGetter
}) => {
  const status = milestone?.status;
  if (statusFilters?.length && !statusFilters?.includes(status)) {
    return;
  }

  const name = milestone?.name;
  if (milestoneNameFilters?.length && !milestoneNameFilters?.includes(name)) {
    return;
  }

  let anyMatchingDeliverable = true;
  if (deliverableNameFilters?.length) {
    const deliverables = deliverablesGetter(milestone);
    anyMatchingDeliverable = deliverables?.some((d) => {
      const deliverableName = d?.name;
      return deliverableNameFilters?.includes(deliverableName);
    });
  }
  if (!anyMatchingDeliverable) {
    return;
  }
  return true;
};

export const checkMilestoneMatchesSearch = ({
  milestone,
  deliverablesGetter,
  search
}) => {
  if (!search) {
    return true;
  }
  const name = milestone?.name;
  if (name?.toLowerCase()?.includes(search)) {
    return true;
  }

  const deliverables = deliverablesGetter(milestone);
  const anyMatchingDeliverable = deliverables?.some((d) => {
    const deliverableName = d?.name;
    return deliverableName?.toLowerCase()?.includes(search);
  });

  if (anyMatchingDeliverable) {
    return true;
  }
  return false;
};
