import gql from "graphql-tag";

/*
input CreateRAGInput {
  projectID: ProjectID!
  metricName: String!
  BaseExpressionType: BaseExpressionType!
  greenBound: String!
  redBound: String!
}
*/
export default (input) => {
  return {
    mutation: gql`
    mutation createRAG($input: CreateRAGInput!) {
      createRAG(input: $input) {
        id
        createdAt
        updatedAt
        createdBy
        updatedBy
        metricName
        expressionType
        greenBound
        redBound
      }
    }
    `,
    variables: { input: input }
  };
};