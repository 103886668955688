import types from "./types";
import {
  getAllRAGs,
  createRAG,
  updateRAG,
  createEvaluateRAGBoundsPayload,
  evaluateRAGBounds,
  deleteRAG
} from "@S/relativeRAG/apiRelativeRAG";
import { flow, map, keyBy } from "lodash/fp";
import { pick, keyBy as plainKeyBy, merge } from "lodash";

import {
  RAGAnalysisFunctionTypes,
  RAGAnalysisStatuses
} from "@/constants/RAGAnalysisConstants";

export const RAGEvaluationStatus = {
  EVALUATING: "EVALUATING",
  OK: "OK"
};

export function createEmptyState() {
  return {
    RAGStates: {},
    RAGBoundsEvaluation: {},
    initialisationState: null,
    RAGEvaluationLoadStatus: null
  };
}

export default {
  state: createEmptyState(),
  getters: {
    [types.getters.GetRAGs]: (state) => (metricNames) => {
      return metricNames ? pick(state.RAGStates, metricNames) : state.RAGStates;
    },
    [types.getters.GetInitialisationState]: (state) => {
      return state.initialisationState;
    },
    [types.getters.GetRAGEvaluationLoadStatus]: (state) => {
      return state.RAGEvaluationLoadStatus;
    },
    [types.getters.GetRAGEvaluation]: (state) => (metricName) => {
      return state.RAGBoundsEvaluation[metricName];
    }
  },
  mutations: {
    [types.mutations.SetRAGs]: (state, RAGs) => {
      Vue.set(state, "RAGStates", RAGs);
    },
    [types.mutations.SetInitialisationState]: (state, loadingState) => {
      Vue.set(state, "initialisationState", loadingState);
    },
    [types.mutations.SetRAGState]: (state, updatedRAG) => {
      const updatedRAGStates = {
        ...state.RAGStates,
        [updatedRAG.metricName]: updatedRAG
      };
      Vue.set(state, "RAGStates", updatedRAGStates);
    },
    [types.mutations.RemoveRAGState]: (state, RAGToRemove) => {
      const updatedRAGStates = { ...state.RAGStates };
      delete updatedRAGStates[RAGToRemove.metricName];
      Vue.set(state, "RAGStates", updatedRAGStates);

      const evalState = { ...state.RAGBoundsEvaluation };
      delete evalState[RAGToRemove.metricName];
      Vue.set(state, "RAGBoundsEvaluation", evalState);
    },
    [types.mutations.SetRAGEvaluationLoadStatus]: (state, evalState) => {
      Vue.set(state, "RAGEvaluationLoadStatus", evalState);
    },
    [types.mutations.SetRAGEvaluationResults]: (state, results) => {
      // Merge the results with the existing state based on lodash merge semantics
      const mergedResults = merge({ ...state.RAGBoundsEvaluation }, results);
      Vue.set(state, "RAGBoundsEvaluation", mergedResults);
    }
  },
  actions: {
    async [types.actions.LoadRAGs]({ commit }, payload) {
      await actionLoadRAGs(commit, payload);
    },
    async [types.actions.CreateRAG]({ commit }, payload) {
      await actionCreateRAG(commit, payload);
    },
    async [types.actions.UpdateRAG]({ commit, state }, payload) {
      await actionUpdateRAG(commit, state, payload);
    },
    async [types.actions.EvaluateRAGBounds]({ commit }, payload) {
      await actionEvaluateRAGBounds(commit, payload);
    }
  }
};

async function actionLoadRAGs(commit, { projectId }) {
  commit(types.mutations.SetInitialisationState, RAGAnalysisStatuses.LOADING);

  const loadedRAGs = await getAllRAGs(projectId);

  const { result, error } = loadedRAGs;

  if (result) {
    const RAGsWithLoadStatus = flow(
      map((RAG) => initialiseRAGLoadStatus(RAG, RAGAnalysisStatuses.OK)),
      keyBy("metricName")
    )(result);
    commit(types.mutations.SetRAGs, RAGsWithLoadStatus);
    commit(types.mutations.SetInitialisationState, RAGAnalysisStatuses.OK);
  } else if (error) {
    commit(types.mutations.SetInitialisationState, RAGAnalysisStatuses.ERROR);
  }
}

async function actionCreateRAG(commit, { projectID, RAG }) {
  commitRAGWithLoadStatus(commit, RAG, RAGAnalysisStatuses.CREATING);

  const RAGCreatePayload = {
    projectID: projectID,
    metricName: RAG.metricName,
    BaseExpressionType: RAG.BaseExpressionType,
    greenBound: RAG.greenBound,
    redBound: RAG.redBound
  };
  const { result, error } = await createRAG(RAGCreatePayload);

  if (result) {
    commitRAGWithLoadStatus(commit, result, RAGAnalysisStatuses.OK);
  } else if (error) {
    // In the event of an error, create a placeholder with the
    // error info so we can track it.
    const placeHolderRAG = { metricName: RAG.metricName };
    commitRAGWithErrorStatus(commit, placeHolderRAG, error);
  }
}

async function actionUpdateRAG(commit, state, { projectID, RAG }) {
  if (RAG.expressionType === RAGAnalysisFunctionTypes.TYPE_UNSET.value) {
    // Deletion is triggered when user saves a RAG with UNSET state.
    await doDeleteRAG(commit, state, RAG);
  } else {
    await doUpdateRAG(commit, state, projectID, RAG);
  }
}

async function actionEvaluateRAGBounds(
  commit,
  { projectID, optionIds, metricNames }
) {
  commit(
    types.mutations.SetRAGEvaluationLoadStatus,
    RAGEvaluationStatus.EVALUATING
  );

  const evalPayload = createEvaluateRAGBoundsPayload(
    projectID,
    optionIds,
    metricNames
  );
  const { result, error } = await evaluateRAGBounds(evalPayload);

  if (result) {
    const evaluatedBoundsByMetric = plainKeyBy(result, "metricName");
    commit(types.mutations.SetRAGEvaluationResults, evaluatedBoundsByMetric);
    commit(types.mutations.SetRAGEvaluationLoadStatus, RAGEvaluationStatus.OK);
  } else if (error) {
    console.log(`error: ${error}`); //todo - store this in state
    commit(
      types.mutations.SetRAGEvaluationLoadStatus,
      RAGEvaluationStatus.ERROR
    );
  }
}

function commitRAGWithLoadStatus(commit, RAG, loadStatus) {
  // Assuming this is called in succes case, so we remove
  // any lingering error info.
  const updatedRAG = { ...RAG, loadStatus };
  delete updatedRAG.error;

  commit(types.mutations.SetRAGState, updatedRAG);
}

function commitRAGWithErrorStatus(commit, RAG, error) {
  const updatedRAG = {
    ...RAG,
    loadStatus: RAGAnalysisStatuses.ERROR,
    error: error
  };
  commit(types.mutations.SetRAGState, updatedRAG);
}

async function doUpdateRAG(commit, state, projectID, RAG) {
  commitRAGWithLoadStatus(commit, RAG, RAGAnalysisStatuses.UPDATING);

  const RAGUpdatePayload = {
    projectID: projectID,
    id: RAG.id,
    BaseExpressionType: RAG.expressionType,
    greenBound: RAG.greenBound,
    redBound: RAG.redBound
  };
  const { result, error } = await updateRAG(RAGUpdatePayload);

  if (result) {
    commitRAGWithLoadStatus(commit, result, RAGAnalysisStatuses.OK);
  } else if (error) {
    commitRAGWithErrorStatus(commit, RAG, error);
  }
}

async function doDeleteRAG(commit, state, RAG) {
  const ragToDelete = state.RAGStates[RAG.metricName];

  commitRAGWithLoadStatus(commit, ragToDelete, RAGAnalysisStatuses.DELETING);

  const { result, error } = await deleteRAG(ragToDelete.id);

  if (result) {
    commit(types.mutations.RemoveRAGState, ragToDelete);
  } else if (error) {
    commitRAGWithErrorStatus(commit, ragToDelete, error);
  }
}

function initialiseRAGLoadStatus(RAG, loadStatus) {
  return { ...RAG, loadStatus };
}
