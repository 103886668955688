export const toArray = (componentSettingsMapping) => {
  const tempComponentSettings = { design_rules: [] };
  if (!componentSettingsMapping) return tempComponentSettings;
  Object.values(componentSettingsMapping).forEach((element) => {
    const arrayOfChildrenToExclude = [];

    const tempElement = element;
    let tempParams = [];
    if (tempElement?.parentParameters) {
      for (const value of Object.values(tempElement?.parentParameters)) {
        if (value.children) {
          const tempChildren = [];
          value.children.forEach((child) => {
            arrayOfChildrenToExclude.push(child);
            tempChildren.push(tempElement.parameters[child]);
          });
          tempParams.push({
            ...value,
            children: tempChildren
          });
        } else {
          tempParams.push({ ...value, children: [] });
        }
      }
    }

    Object.values(tempElement?.parameters).forEach((param) => {
      if (!arrayOfChildrenToExclude.find((item) => item === param.name)) {
        tempParams.push(param);
      }
    });

    tempParams = [...new Set(tempParams)];

    tempComponentSettings.design_rules.push({
      ...element,
      parameters: tempParams
    });
  });

  return tempComponentSettings;
};

export const toObject = (componentSettings) => {
  let tempObject = {};
  componentSettings.design_rules?.forEach((element) => {
    tempObject = {
      ...tempObject,
      [`${element.name}`]: {
        ...element,
        parameters: {},
        parentParameters: {}
      }
    };
    element.parameters.forEach((item) => {
      if (item.children?.length > 0) {
        tempObject[`${element.name}`].parentParameters[`${item.id}`] = {
          ...item,
          children: item.children.map((child) => child.name)
        };
        item.children.forEach((ele) => {
          tempObject[`${element.name}`].parameters[`${ele.name}`] = ele;
        });
      } else {
        tempObject[`${element.name}`].parameters[`${item.name}`] = item;
      }
    });
  });

  return tempObject;
};

export default { toArray, toObject };
