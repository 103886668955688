import types, { actions, mutations, getters } from "./types";

export default {
  types: types,
  state: {
    ragAnalysisNameObject: {}
  },
  getters: {
    [getters.DerivedMetricGetRagAnalysisNameObject](state) {
      return state.ragAnalysisNameObject;
    }
  },
  mutations: {
    [mutations.DerivedMetricSetRagAnalysisNameObject](state, payload) {
      Vue.set(state.ragAnalysisNameObject, payload.name, payload.value);
    },
    [mutations.DerivedMetricResetRagAnalysisNameObject](state) {
      Vue.set(state, "ragAnalysisNameObject", {});
    }
  },
  actions: {
    [actions.DerivedMetricSetRagAnalysisNameObject]({ commit }, payload) {
      commit(mutations.DerivedMetricSetRagAnalysisNameObject, payload);
    },
    [actions.DerivedMetricResetRagAnalysisNameObject]({ commit }) {
      commit(mutations.DerivedMetricResetRagAnalysisNameObject);
    }
  }
};
