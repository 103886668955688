const getters = {
  GetRAGs: "GetRAGs",
  GetInitialisationState: "GetInitialisationState",
  GetRAGEvaluationLoadStatus: "GetRAGEvaluationLoadStatus",
  GetRAGEvaluation: "GetRAGEvaluation"
};

const mutations = {
  SetRAGs: "SetRAGs",
  SetInitialisationState: "SetInitialisationState",
  SetRAGState: "SetRAGState",
  RemoveRAGState: "RemoveRAGState",
  SetRAGEvaluationLoadStatus: "SetRAGEvaluationLoadStatus",
  SetRAGEvaluationResults: "SetRAGEvaluationResults"
};

const actions = {
  LoadRAGs: "LoadRAGs",
  CreateRAG: "CreateRAG",
  UpdateRAG: "UpdateRAG",
  EvaluateRAGBounds: "EvaluateRAGBounds"
};

export { getters, mutations, actions };
const types = { getters, mutations, actions };
export default types;
