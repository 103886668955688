<template>
  <div class="pa-5">
    <v-subheader class="body-2 font-weight-bold mb-4">
      Invite Project Members
    </v-subheader>
    <v-col cols="12">
      <v-row>
        <v-subheader class="body-3 font-weight-bold">
          Invite by email address
        </v-subheader>
      </v-row>
      <v-row class="mb-5 ml-2">
        <v-col cols="6">
          <v-text-field
            v-model="email"
            :append-icon="iconForTextfield"
            :error="requestSent && anyErrors && !loading"
            :success="requestSent && !anyErrors && !loading"
            :error-messages="errorMessages"
            :success-messages="successMessages"
            :loading="loading"
            :disabled="loading"
            data-cy="invite-project-member-email-textfield"
            outlined
            dense
          />
        </v-col>

        <div class="d-inline-flex">
          <PermissionGuard :permission="VERB.ADMIN">
            <template v-slot:default="permissionSlot">
              <v-tooltip
                :disabled="!permissionSlot.hasAccess"
                content-class="tooltip"
                top
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      @click="sendInvite"
                      :disabled="!email.length || !permissionSlot.hasAccess"
                      :loading="loading"
                      data-cy="invite-project-member-button"
                      class="mt-1"
                      color="primary"
                      label="Email"
                      outlined
                    >
                      Send
                    </v-btn>
                  </div>
                </template>

                <span v-if="email.length > 0"> Send invite </span>
                <span v-else>Please enter an email</span>
              </v-tooltip>
            </template>
          </PermissionGuard>

          <FeatureFlag
            :projectId="SessionGetActiveProjectId"
            featureFlag="SSO_INVITE"
          >
            <PermissionGuard :permission="VERB.ADMIN">
              <template v-slot:default="permissionSlot">
                <v-tooltip
                  :disabled="!permissionSlot.hasAccess"
                  content-class="tooltip"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        @click="sendSSOInvite"
                        :disabled="!email.length || !permissionSlot.hasAccess"
                        :loading="loading"
                        data-cy="invite-project-member-sso-button"
                        class="mt-1 ml-2"
                        color="primary"
                        label="SSOEmail"
                        outlined
                      >
                        Invite via SSO
                      </v-btn>
                    </span>
                  </template>

                  <span v-if="email.length > 0"> Send invite via SSO </span>
                  <span v-else>Please enter an email</span>
                </v-tooltip>
              </template>
            </PermissionGuard>
          </FeatureFlag>
        </div>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getters as gettersFromSession } from "@S/session/types";
import FeatureFlag from "@C/FeatureFlag.vue";
import PermissionGuard from "@C/PermissionGuard.vue";
import { _axios } from "@/plugins/axios";

export default {
  name: "InviteProjectMembers",
  components: {
    FeatureFlag,
    PermissionGuard
  },
  data() {
    return {
      email: "",
      loading: false,
      requestSent: false,
      errorMessages: [],
      successMessages: []
    };
  },
  computed: {
    ...mapGetters([gettersFromSession.SessionGetActiveProjectId]),
    iconForTextfield() {
      if (!this.requestSent || this.loading) {
        return "";
      }
      if (this.error) {
        return "mdi-alert-circle";
      }
      return "mdi-check-circle";
    },
    anyErrors() {
      return this.errorMessages?.length > 0;
    }
  },
  methods: {
    clearMessages() {
      this.errorMessages = [];
      this.successMessages = [];
    },
    async sendInvite() {
      this.clearMessages();
      this.requestSent = true;
      this.loading = true;

      const emailCopy = this.email?.slice();

      try {
        await _axios.post("V2/user/", {
          project_id: this.SessionGetActiveProjectId,
          user_email: this.email
        });

        this.successMessages.push(`Invite sent successfully to ${emailCopy}`);
      } catch (error) {
        this.errorMessages.push(`Error sending invite to ${emailCopy}`);
        console.error("Error sending invite:\n", error);
      }

      this.loading = false;
    },
    async sendSSOInvite() {
      this.clearMessages();
      this.requestSent = true;
      this.loading = true;

      const emailCopy = this.email?.slice();

      try {
        await _axios.post("V2/user/", {
          project_id: this.SessionGetActiveProjectId,
          user_email: this.email,
          invite_by_sso: true
        });

        this.successMessages.push(`Invite sent successfully to ${emailCopy}`);
      } catch (error) {
        this.errorMessages.push(`Error sending invite to ${emailCopy}`);
        console.error("Error sending invite:\n", error);
      }

      this.loading = false;
    }
  },
  watch: {
    email(newValue, oldValue) {
      if (newValue.length !== oldValue) {
        this.requestSent = false;
        this.clearMessages();
      }
    }
  }
};
</script>

<style></style>
