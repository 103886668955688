<template>
  <!-- There is no fallback slot, and no slot is specified-->
  <fragment>
    <template v-if="featureEnabled">
      <slot></slot>
    </template>

    <template v-else-if="!featureEnabled">
      <slot name="flagDisabled"></slot>
    </template>
  </fragment>
</template>

<script>
import { getFeatureFlag } from "@/hooks/feature-flag-hook";

export default {
  name: "FeatureFlag",
  props: {
    featureFlag: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      featureEnabled: false
    };
  },
  async mounted() {
    this.featureEnabled = await getFeatureFlag(
      this.featureFlag,
      this.projectId
    );
  }
};
</script>
