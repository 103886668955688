import { authReady } from "@/plugins/authPlugin";
import LoadingStates from "@/constants/LoadingStates";
import { _axios } from "@/plugins/axios";

const projectPathUrl = "/V2/project/";

const fetchProjectDataById = async ({
  projectId,
  setLoadingState,
  setDataWasFetched,
  setProjectData
}) => {
  const url = `${projectPathUrl}${projectId}`;
  if (!projectId) {
    // We do not want to fetch project if id is null 🤯
    return;
  }

  setLoadingState({ projectId, loadingState: LoadingStates.LOADING });
  await authReady();
  return _axios.get(url).then(
    (response) => {
      const projectData = {
        ...response.data,
        project_data: {
          config_ids: response.data.config_ids,
          option_ids: response.data.option_ids,
          case_ids: response.data.case_ids,
          datasetIdsForFetching: response.data.dataset_ids,
          comment_ids: response.data.comment_ids,
          drawn_asset_category_ids: response.data.drawn_asset_category_ids
        }
      };

      if (projectData && projectData !== null) {
        setDataWasFetched({ projectId, dataWasFetched: true });
      }

      setProjectData({ projectId, projectData });

      setLoadingState({ projectId, loadingState: LoadingStates.LOADED });
    },
    (reason) => {
      console.error(reason);
      setLoadingState({ projectId, loadingState: LoadingStates.ERRORED });
    }
  );
};

export { fetchProjectDataById };
