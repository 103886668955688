import { openDB } from "idb/with-async-ittr.js";

const Tables = {
  DATASETS: "datasets",
  OPTIONS: "options",
  HEATMAPS: "heatmaps",
  OPPORTUNITY_MAPS: "opportunityMaps"
};

class IndexedDB {
  DB_VERSION = 16;
  USING_LOCAL_BACKEND = false;

  constructor(table, usingLocalBackend) {
    this.USING_LOCAL_BACKEND = usingLocalBackend;

    if (this.USING_LOCAL_BACKEND) {
      console.debug("Using local backend, skipping cache interactions");
    }
    this.tableName = table;
  }

  async getDbPromise() {
    const objectStoreName = this.tableName;
    if (Object.values(Tables)?.indexOf(objectStoreName) == -1) {
      throw new Error("Table must be set by the Tables enum");
    }
    const dbVersion = this.DB_VERSION;
    return openDB(`Optioneer`, dbVersion, {
      upgrade(db) {
        console.info(`Upgrading DB to version ${db.version}`);
        for (const table in Tables) {
          if (db.objectStoreNames.contains(Tables[table])) {
            console.warn(
              "Deleting and recreating the object stores:",
              Tables[table]
            );
            db.deleteObjectStore(Tables[table]);
          }
          db.createObjectStore(Tables[table]);
        }
      }
    });
  }

  async get(key) {
    if (this.USING_LOCAL_BACKEND) {
      return undefined;
    }
    return (await this.getDbPromise()).get(this.tableName, key);
  }
  async set(key, val) {
    if (this.USING_LOCAL_BACKEND) {
      return;
    }
    return (await this.getDbPromise()).put(this.tableName, val, key);
  }
  async del(key) {
    if (this.USING_LOCAL_BACKEND) {
      return;
    }
    return (await this.getDbPromise()).delete(this.tableName, key);
  }
  async clear() {
    if (this.USING_LOCAL_BACKEND) {
      return;
    }
    return (await this.getDbPromise()).clear(this.tableName);
  }
  async keys() {
    if (this.USING_LOCAL_BACKEND) {
      return [];
    }
    return (await this.getDbPromise()).getAllKeys(this.tableName);
  }
}

export { IndexedDB, Tables };
