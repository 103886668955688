const getters = {
  CollectionGetCollections: "CollectionGetCollections"
};

const mutations = {
  CollectionSetCollections: "CollectionSetCollections"
};

const actions = {
  CollectionFetchCollections: "CollectionFetchCollections"
};

export { getters, mutations, actions };
