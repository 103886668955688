export default {
  HOVERED_OVER_COLOUR: "white",
  LINES_COLOUR: "rgba(255, 170, 170, 1)",
  LINES_DEFAULT_OPACITY: 1,
  POINTS_DEFAULT_FILL_COLOUR: "rgba(221, 34, 34, 1)",
  POINTS_DEFAULT_OPACITY: 0.75,
  POINTS_DEFAULT_OUTLINE_COLOUR: "rgba(0, 0, 0, 1)",
  HOVERED_OVER_LINE_WIDTH: 10,
  CORRIDORS_LINE_WIDTH: 3,
  CORRIDORS_COLOUR: "rgba(62, 12, 186, 0.15)",
  CORRIDORS_OUTLINE_COLOUR: "rgba(62, 12, 186, 1)",
  CORRIDORS_FILL_OPACITY: 1,
  LAYER_ID_DELIMITER: "__,,__"
};
