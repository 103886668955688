const getters = {
  GetPolygonResultSummary: "GetPolygonResultSummary",
  GetPolygonResultGeojson: "GetPolygonResultGeojson",
  GetPolygonResultVisibility: "GetPolygonResultVisibility",
  GetPolygonResultMetadata: "GetPolygonResultMetadata",
  GetPolygonResultCaseId: "GetPolygonResultCaseId",
  AllVisiblePolygonResults: "AllVisiblePolygonResults"
};

const mutations = {
  SetPolygonResultGeojson: "SetPolygonResultGeojson",
  SetPolygonResultMetadata: "SetPolygonResultMetadata",
  ResetPolygonResults: "ResetPolygonResults"
};

const actions = {
  FetchPolygonResult: "FetchPolygonResult"
};

export { getters, mutations, actions };
