import { BaseTypes } from "@S/base_types";

const cases = {
  getters: {
    ProjectGetCaseIdsForFetching: "ProjectGetCaseIdsForFetching"
  },
  actions: {
    ProjectCreateCase: "ProjectCreateCase"
  }
};

const configs = {
  getters: {
    ProjectGetConfigIds: "ProjectGetConfigIds"
  }
};

const getters = {
  ProjectGetProjectSummary: "ProjectGetProjectSummary",
  ProjectGetName: "ProjectGetName",
  ProjectGetDescription: "ProjectGetDescription",
  ProjectGetNotice: "ProjectGetNotice",
  ProjectGetAreaName: "ProjectGetAreaName",
  ProjectGetBoundaries: "ProjectGetBoundaries",
  ProjectGetBoundariesAsGeoJson: "ProjectGetBoundariesAsGeoJson",
  ProjectGetAssetTypes: "ProjectGetAssetTypes",
  ProjectGetStage: "ProjectGetStage",

  ProjectGetActiveMapLegend: "ProjectGetActiveMapLegend",


  ProjectGetActiveTool: "ProjectGetActiveTool",

  ProjectGetLoadingState: "ProjectGetLoadingState",
  ProjectIsLoading: "ProjectIsLoading",

  GetProjectDataWasFetched: "GetProjectDataWasFetched",

  ProjectGetCategoryIds: "ProjectGetCategoryIds",

  ...cases.getters,
  ...configs.getters
};

const mutations = {
  ProjectUpdate: "ProjectUpdate",

  ProjectSetCategoryIds: "ProjectSetCategoryIds",
  ProjectRemoveCategoryId: "ProjectRemoveCategoryId",

  ProjectSetActiveMapLegend: "ProjectSetActiveMapLegend",
  ProjectSetActiveTool: "ProjectSetActiveTool",

  ProjectSetMapRef: "ProjectSetMapRef",

  ProjectSetLoadingState: "ProjectSetLoadingState",
  ProjectRemoveChildId: "ProjectRemoveChildId",

  ProjectSetProjectDataWasFetched: "ProjectSetProjectDataWasFetched",
  ChangeActiveProject: "ChangeActiveProject"
};

const actions = {
  ProjectFetchProjectDataById: "ProjectFetchProjectDataById",
  UpdateProjectChildIds: "UpdateProjectChildIds",

  ProjectUpdate: "ProjectUpdate",

  ProjectCreateGeometry: "ProjectCreateGeometry",

  ProjectSetActiveMapLegend: "ProjectSetActiveMapLegend",
  ProjectSetActiveTool: "ProjectSetActiveTool",

  ProjectSetMapRef: "ProjectSetMapRef",

  ProjectRemoveChildId: "ProjectRemoveChildId",

  ProjectRemoveCategoryId: "ProjectRemoveCategoryId",

  ...cases.actions
};

/**
 * @augments BaseTypes
 */
export class ProjectTypes extends BaseTypes {
  static getters = getters;
  static mutations = mutations;
  static actions = actions;
}

export default ProjectTypes;

export { getters, mutations, actions };
