const ResultTypes = {
  OPTION: { key: "OPTION", text: "Option" },
  POLYGON_RESULT: { key: "POLYGON_RESULT", text: "Polygon Result" },
  HEATMAP: { key: "HEATMAP", text: "Heatmap" }
};

export const resultNameColumn = {
  text: "Result Name",
  value: "displayValue",
  width: "220px",
  draggable: false
};

export const caseIdColumn = {
  //Only used so we can group by this attribute
  displayValue: "Case ID",
  text: "Case ID",
  name: "caseId",
  value: "caseId",
  sortable: false,
  align: " d-none", //Hide the column, (the space before d-none is needed)
  draggable: false,
  hideFromTableCsvExport: true
};

export const mandatoryResultsTableHeaders = Object.freeze([
  {
    text: "Visible",
    icon: "mdi-eye-outline",
    sortable: false,
    value: "visible",
    draggable: false,
    hideFromTableCsvExport: true,
    width: "50px"
  },
  resultNameColumn,
  caseIdColumn
]);

export const rankColumn = Object.freeze({
  displayValue: "Rank",
  text: "Rank",
  name: "rank",
  value: "rankValue",
  draggable: false
});

export const resultCreatorColumn = Object.freeze({
  displayValue: "Creator",
  text: "Creator",
  name: "creator",
  value: "creator",
  draggable: true
});

export const resultCreatedDateColumn = Object.freeze({
  displayValue: "Created",
  text: "Created",
  name: "created",
  value: "created",
  draggable: true
});

export const defaultSelectedResultTableColumns = Object.freeze({
  objective: {
    displayValue: "Objective",
    text: "Objective",
    name: "objective",
    value: "objective",
    width: "80px",
    draggable: true
  },
  creator: resultCreatorColumn,
  created: resultCreatedDateColumn,
  scenario: {
    displayValue: "Scenario",
    text: "Scenario",
    name: "scenario",
    value: "scenario",
    width: "220px",
    draggable: true
  }
});

export const defaultAvailableResultTableColumns = Object.freeze({
  id: {
    text: "ID",
    align: "start",
    value: "id",
    draggable: true
  },
  ...defaultSelectedResultTableColumns
});

export default ResultTypes;
