const fullscreenMixin = {
  data() {
    return {
      isFullScreen: false
    };
  },
  created() {
    this.addFullScreenEventListeners();
  },
  beforeDestroy() {
    this.removeFullScreenEventListeners();
  },
  methods: {
    updateFullScreenStatus() {
      this.isFullScreen = !!document.fullscreenElement;
    },
    addFullScreenEventListeners() {
      document.addEventListener(
        "fullscreenchange",
        this.updateFullScreenStatus
      );
      document.addEventListener(
        "webkitfullscreenchange",
        this.updateFullScreenStatus
      ); // For Safari
      document.addEventListener(
        "mozfullscreenchange",
        this.updateFullScreenStatus
      ); // For Firefox
      document.addEventListener(
        "MSFullscreenChange",
        this.updateFullScreenStatus
      ); // For IE/Edge
    },
    removeFullScreenEventListeners() {
      document.removeEventListener(
        "fullscreenchange",
        this.updateFullScreenStatus
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        this.updateFullScreenStatus
      ); // For Safari
      document.removeEventListener(
        "mozfullscreenchange",
        this.updateFullScreenStatus
      ); // For Firefox
      document.removeEventListener(
        "MSFullscreenChange",
        this.updateFullScreenStatus
      ); // For IE/Edge
    }
  }
};

export default fullscreenMixin;
