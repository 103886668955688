const attachWindowActivityHandlers = (handler) => {
  window.onload = handler;
  window.onmousemove = handler;
  window.onmousedown = handler; // catches touchscreen presses as well
  window.ontouchstart = handler; // catches touchscreen swipes as well
  window.ontouchmove = handler; // required by some devices
  window.onclick = handler; // catches touchpad clicks as well
  window.onkeydown = handler;
  window.addEventListener("scroll", handler, true);
};

export default attachWindowActivityHandlers;
