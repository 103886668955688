const getters = {
  IsMentionListOpen: "IsMentionListOpen",
  ConfigSelectedInComment: "ConfigSelectedInComment"
};

const mutations = {
  SetIsMentionListOpen: "SetIsMentionListOpen",
  SetConfigSelectedInComment: "SetConfigSelectedInComment"
};

const actions = {
  SetIsMentionListOpen: "SetIsMentionListOpen",
  SetConfigSelectedInComment: "SetConfigSelectedInComment"
};

export { getters, mutations, actions };

export default { getters, mutations, actions };
