import { isEqual } from "lodash";

const findParameterById = (settings, parameterId) => {
  return settings?.parameters?.find((param) => parameterId == param?.id);
};

//This function is used to check if a key_value_list has been edited
//It is used to determine if a component is dirty
//It needs to run this logic instead of a simple isEqual because the key_value_lists are automatically filled with zeros for new layers and we don't want to count these towards the "edited" state
const checkKeyValueListIsDirty = (original, current) => {
  const originalEntries = original.value;
  const currentEntries = current.value;

  if (!originalEntries?.length && !currentEntries?.length) {
    //if they are both empty, we can assume they are the same
    return false;
  }

  const originalKeyValues = originalEntries?.reduce(
    (o, listItem) => Object.assign(o, { [listItem[0]]: listItem[1] }),
    {}
  );
  let currentKeyValues = currentEntries?.reduce(
    (o, listItem) => Object.assign(o, { [listItem[0]]: listItem[1] }),
    {}
  );

  currentKeyValues = filterNewDefaultValues(
    currentKeyValues,
    originalKeyValues
  );

  //if the length is different, we can assume they are different
  if (
    Object.keys(currentKeyValues).length !==
    Object.keys(originalKeyValues).length
  ) {
    return true;
  }

  for (const layerName of Object.keys(originalKeyValues)) {
    if (currentKeyValues[layerName] !== originalKeyValues[layerName]) {
      return true;
    }
  }

  //if the length is the same, and each key has the same value, we can assume they are the same
  return false;
};

function filterNewDefaultValues(currentKeyValue, originalKeyValue) {
  //if a value is in "current" but not in "original" and it is it's current value
  //then it is a default value and we don't want to trigger and "edited" state from it

  for (const [key, value] of Object.entries(currentKeyValue)) {
    if (originalKeyValue[key] === undefined && value === 0) {
      delete currentKeyValue[key];
    }
  }
  return currentKeyValue;
}

function updateDesignRuleSettings(state, configId, payload) {
  if (payload?.designRuleSettings) {
    //If a user is editing a config, don't overwrite the design rule settings when an update is coming in
    if (
      !state.configs[configId]?.designRuleSettings ||
      isEqual(
        state.configs[configId]?.designRuleSettings,
        state.configs[configId]?.designRuleSettingsEdited
      )
    ) {
      Vue.set(
        state.configs[configId],
        "designRuleSettingsEdited",
        JSON.parse(payload?.designRuleSettings)
      );
    }

    Vue.set(
      state.configs[configId],
      "designRuleSettings",
      JSON.parse(payload?.designRuleSettings)
    );
  }
}

function updateDatasetSettings(state, configId, payload) {
  if (payload?.datasetSettings) {
    //If a user is editing a config, don't overwrite the dataset settings when an update is coming in
    if (
      !state.configs[configId]?.datasetSettings ||
      isEqual(
        state.configs[configId]?.datasetSettings,
        state.configs[configId]?.datasetSettingsEdited
      )
    ) {
      Vue.set(
        state.configs[configId],
        "datasetSettingsEdited",
        JSON.parse(payload?.datasetSettings)
      );
    }

    Vue.set(
      state.configs[configId],
      "datasetSettings",
      JSON.parse(payload?.datasetSettings)
    );
  }
}

export {
  findParameterById,
  checkKeyValueListIsDirty,
  updateDatasetSettings,
  updateDesignRuleSettings
};
