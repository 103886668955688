<template>
  <v-divider inset vertical />
</template>
<script>
export default {
  name: "DefaultDivider"
};
</script>
<style scoped>
.v-divider--vertical {
  border: 1px solid white !important;
  opacity: 1 !important;
  height: 27px;
  margin-top: 15px !important;
}
</style>
