import { LineTypes as types } from "@S/line/types";
import LoadingStates from "@/constants/LoadingStates";
import { InteractionStates } from "@/constants/InteractionStates";

import { getters as savingViewGetters } from "@S/savingView/types";
import { getters as categoryGetters } from "@S/projectFeatureCategories/types";
import SessionTypes from "@S/session/types";

import {
  getFeatureColour,
  getFeatureOpacity,
  getFeatureOwnerName
} from "@S/helpers/featureGetterHelpers";

import Line from "@S/line/Line.js";
import LayersProperties from "@/constants/LayersProperties";

export default {
  state: {
    lines: {},
    loadingStates: {},
    interactionStates: {}
  },

  getters: {
    [types.getters.LineGetName]: (state) => (id) => {
      return state.lines[id]?.name;
    },
    [types.getters.GetAllLines](state) {
      return Object.values(state.lines);
    },
    [types.getters.AllLineSets](state, getters) {
      const lineIds = Object.keys(state.lines);

      if (!lineIds?.length) {
        return [];
      }

      const lineSets = [];

      lineIds.forEach((lineId) => {
        lineSets.push(getters[types.getters.LineGetSet](Number(lineId)));
      });
      return lineSets;
    },
    [types.getters.LineGetSet]: (state, getters, _, rootGetters) => (id) => {
      if (!state.lines?.[id]) {
        return null;
      }

      //Line style from saved view
      const lineStyle = getters[
        savingViewGetters.SavingViewsGetLineStyles
      ].find((style) => style.assetId == Number(id));

      const category = getters[categoryGetters.GetCategoryById](
        state.lines[id]?.categoryId
      );

      const primaryColour = getFeatureColour(
        lineStyle?.outlineColour,
        category?.primaryColour,
        LayersProperties.LINES_COLOUR
      );

      const opacity = getFeatureOpacity(
        lineStyle?.opacity,
        category?.masterOpacityPercentage,
        LayersProperties.CORRIDORS_FILL_OPACITY
      );

      //Resolve owner id to nickname
      const createdBy = state.lines?.[id]?.createdBy;
      const users = rootGetters[SessionTypes.getters.SessionGetUsers];
      const ownerNickname = getFeatureOwnerName(createdBy, users);

      const line = new Line(
        state.lines[id]?.id.toString(),
        state.lines[id]?.name,
        state.lines[id]?.categoryId,
        category?.name || null,
        state.lines[id]?.description,
        state.lines[id]?.coords,
        state.loadingStates[id],
        state.interactionStates[id] || InteractionStates.NORMAL,
        state.lines[id]?.createdBy,
        ownerNickname,
        state.lines[id]?.created,
        primaryColour,
        opacity
      );

      return line;
    },
    [types.getters.AnyLineIsLoading]: (state) => {
      return Object.values(state.loadingStates).includes(
        LoadingStates.INITIALISED || LoadingStates.LOADING
      );
    },

    [types.getters.LineGetLoadingState]: (state) => (id) => {
      return state.loadingStates[Number(id)];
    },

    [types.getters.LineGetInteractionState]: (state) => (id) => {
      return state.interactionStates[Number(id)] || InteractionStates.NORMAL;
    }
  },

  mutations: {
    [types.mutations.LineAddLine]: (state, payload) => {
      //convert categoryID property to categoryId
      const formatted = { ...payload, categoryId: payload.categoryID };
      delete formatted.categoryID;

      Vue.set(state.lines, payload.id, formatted);
    },
    [types.mutations.LineUpdate]: (state, payload) => {
      const id = payload.id;
      Vue.set(state.lines[id], "name", payload.name);
      Vue.set(state.lines[id], "description", payload.description);
      Vue.set(state.lines[id], "categoryId", payload.categoryID);
      Vue.set(state.lines[id], "coords", payload.coords);
    },
    [types.mutations.LineDeleteLine]: (state, payload) => {
      Vue.delete(state.lines, payload);
    },
    [types.mutations.LineSetLoadingState]: (state, payload) => {
      //{id, loadingState}
      Vue.set(state.loadingStates, Number(payload.id), payload.loadingState);
    },
    [types.mutations.LineSetInteractionState]: (state, payload) => {
      //{id, interactionState}
      Vue.set(
        state.interactionStates,
        Number(payload.id),
        payload.interactionState
      );
    },
    [types.mutations.LineResetState]: (state) => {
      Vue.set(state, "lines", {});
      Vue.set(state, "loadingStates", {});
      Vue.set(state, "interactionStates", {});
    }
  },

  actions: {
    [types.actions.LineAddLine]({ commit, dispatch }, payload) {
      commit(types.mutations.LineSetLoadingState, {
        id: payload.id,
        loadingState: LoadingStates.LOADED
      });
      commit(types.mutations.LineAddLine, payload);
      if (payload?.createdBy) {
        dispatch(
          SessionTypes.actions.SessionAddUserDetails,
          payload?.createdBy
        );
      }
    },
    [types.actions.LineUpdate]({ commit }, payload) {
      commit(types.mutations.LineUpdate, payload);
    },
    [types.actions.LineDeleteLine]: ({ commit }, payload) => {
      commit(types.mutations.LineDeleteLine, payload);
    },
    [types.actions.LineSetLoadingState]({ commit }, payload) {
      commit(types.mutations.LineSetLoadingState, payload);
    },
    [types.actions.LineSetInteractionState]({ commit }, payload) {
      commit(types.mutations.LineSetInteractionState, payload);
    },
    [types.actions.LineResetState]({ commit }) {
      commit(types.mutations.LineResetState);
    }
  }
};
