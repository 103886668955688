export default {
  DEFAULT_COLORMAP: "viridis"
};

export const DATASET_TYPE = {
  VECTOR: "VECTOR",
  RASTER: "RASTER"
};

export const featureNameKey = "feat_name";

export const FROM_INGEST = "from_ingest";
