<template>
  <div class="pa-10">
    <v-row class="mb-8 ml-1">
      <v-icon class="material-icons-outlined ml-2" color="info" outline>
        info
      </v-icon>
      <span
        class="pl-1"
        :style="`color: ${this.$vuetify.theme.themes.light.info}; `"
      >
        Only administrators are able to add or remove project members
      </span>
    </v-row>
    <v-row class="ml-2">
      <v-col cols="2">
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          color="primary"
          data-cy="search-input"
          outlined
          filled
          dense
        />
      </v-col>
      <v-col cols="2" class="ml-3">
        <v-select
          v-model="selectedRoleForFilter"
          :items="rolesForFilter"
          data-cy="role-filter-select"
          hide-details
          dense
        >
          <template v-slot:item="{ item }">
            <v-list-item-content data-cy="role-filter-select-item">
              {{ item }}
            </v-list-item-content>
          </template>
        </v-select>
      </v-col>
      <div class="d-flex ml-auto">
        <PermissionGuard :permission="VERB.ADMIN">
          <template v-slot:default="permissionSlot">
            <DeleteDialog
              @deletionConfirmed="removeSelectedUsers"
              :key="`delete-${selectedUsers.length}-users-button`"
              :display-name="selectedUsersNames"
              :displayNameAsBullets="true"
              :item-id="selectedUsersIds"
              displayType="selected users"
              activatorType="customSlot"
              actionString="remove"
            >
              <template v-slot="customSlot">
                <v-btn
                  @click="customSlot.toggleDialog"
                  :disabled="!selectedUsers.length || !permissionSlot.hasAccess"
                  color="warning"
                  outlined
                >
                  <v-icon left> mdi-account-off </v-icon>
                  Remove Users
                  {{ selectedUsers.length ? `(${selectedUsers.length})` : "" }}
                </v-btn>
              </template>
            </DeleteDialog>
          </template>
        </PermissionGuard>
      </div>
    </v-row>
    <v-data-table
      v-model="selectedUsers"
      :headers="headers"
      :items="filteredUsersWithSelectableProperty"
      :no-data-text="noDataText"
      :loading="loading"
      item-key="id"
      height="50vh"
      loading-text="Loading users..."
      data-cy="users-table"
      show-select
    >
      <template v-slot:[`header.MFARequired`]>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              MFA
              <v-icon class="material-icons-outlined" outline>
                mdi-information-outline
              </v-icon>
            </span>
          </template>
          Multi Factor Authentication <br />
          <br />

          Please contact Continuum Industries to enable MFA for this project
        </v-tooltip>
      </template>

      <template v-slot:[`header.role`]>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              Role
              <v-icon class="material-icons-outlined" outline>
                mdi-information-outline
              </v-icon>
            </span>
          </template>
          Permission Roles <br />
          <br />
          <b>Read</b> - see everything in the project <br />
          <b>Write</b> - see and create, edit and delete any items in the
          project (but not the project itself)
          <br />
          <b>Execute</b> - grants all above permissions and the ability to run
          optimisations <br />
          <b>Admin</b> - grants all above permissions and the ability to manage
          users and the project
        </v-tooltip>
      </template>

      <template
        v-slot:[`item.data-table-select`]="{ isSelected, select, item }"
      >
        <v-tooltip
          :disabled="item.role !== permissionTypesForProject.ADMIN"
          open-delay="300"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-simple-checkbox
              v-bind="attrs"
              v-on="on"
              @input="select($event)"
              :value="isSelected"
              :ripple="false"
              :disabled="item.role === permissionTypesForProject.ADMIN"
              data-cy="userManagementTableRowCheckbox"
            />
          </template>
          <span>
            Admins cannot be removed from the project. Change their role and try
            again.</span
          >
        </v-tooltip>
      </template>

      <template v-slot:[`item.MFARequired`]="{ item }">
        <span v-if="item.MFARequired">
          <v-icon class="material-icons-outlined" outline>
            mdi-lock-outline
          </v-icon>
          enabled
        </span>
        <span v-else style="color: gray" outline> not enabled </span>
      </template>

      <template v-slot:[`item.role`]="{ item }">
        <PermissionGuard :permission="VERB.ADMIN">
          <template v-slot:default="permissionSlot">
            <v-tooltip
              :disabled="!permissionSlot.hasAccess"
              content-class="tooltip"
              top
            >
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-progress-circular
                    v-if="item.loading"
                    :width="2"
                    color="primary"
                    indeterminate
                  />
                  <v-select
                    v-else
                    @input="updateUserRole($event, item)"
                    @click="clearError(item)"
                    :value="item.role"
                    :items="roles"
                    :key="item.role"
                    :error="item.error"
                    :disabled="!permissionSlot.hasAccess"
                    :error-messages="item.errorMessage"
                    :hide-details="!item.errorMessage"
                    class="mt-1 mb-1"
                    data-cy="user-role-select"
                    outlined
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content data-cy="user-role-select-item">
                        {{ item }}
                      </v-list-item-content>
                    </template>
                  </v-select>
                </span>
              </template>
              <span>Choose a role for user</span>
            </v-tooltip>
          </template>
        </PermissionGuard>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { keyBy, uniq } from "lodash";

import { mapGetters } from "vuex";
import { getters as gettersFromSession } from "@S/session/types";

import { permissionTypesForProject } from "@/constants/PermissionsTypes";
import { getFeatureFlag } from "@/hooks/feature-flag-hook";
import fetchUsers from "@C/userManagement/helpers/fetchUsersByProjectId";

import PermissionGuard from "@C/PermissionGuard.vue";
import DeleteDialog from "@C/DeleteDialog.vue";

import removeUserMutation from "@/graphql/users/removeUser";
import { _axios } from "@/plugins/axios";
import { apolloClient } from "@/plugins/apolloPlugin";

const allRoles = "All roles";

export default {
  name: "UsersMgmtTable",
  components: {
    PermissionGuard,
    DeleteDialog
  },
  async mounted() {
    this.loading = true;
    this.userFetchError = false;

    if (await getFeatureFlag("AUTH0_SWITCH_OVER")) {
      this.headers.splice(2, 0, { text: "MFA Active", value: "MFARequired" });
    }
    await this.updateProjectUsers();

    this.loading = false;
  },
  data() {
    return {
      loading: false,
      userFetchError: "",
      search: "",
      rolesForFilter: [allRoles, ...Object.values(permissionTypesForProject)],
      selectedRoleForFilter: allRoles,
      roles: Object.values(permissionTypesForProject),
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "nickname"
        },
        { text: "Email", value: "email" },
        { text: "Role", value: "role", width: "25%" },
        { text: "", value: "actions" }
      ],
      users: {},
      selectedUsers: [],
      permissionTypesForProject
    };
  },
  methods: {
    async updateProjectUsers() {
      try {
        let usersFromGql = await fetchUsers(this.SessionGetActiveProjectId);

        //We convert role e.g. READ to read as frontend recognises lowercase role names
        usersFromGql = usersFromGql.map((user) => {
          return { ...user, role: user.role.toLowerCase() };
        });

        this.users = keyBy(usersFromGql, "id");
      } catch (error) {
        console.error("Error retrieving users: ", error);
        this.userFetchError = true;
      }
    },
    clearError(user) {
      const id = user?.id;
      this.users[id].error = false;
      this.users[id].errorMessage = "";
    },
    async updateUserRole(newRole, user) {
      const id = user?.id;
      this.users[id].loading = true;
      try {
        const response = await _axios.put("V2/user/role/", {
          project_id: this.SessionGetActiveProjectId,
          user_id: user.id,
          role: newRole?.toUpperCase()
        });

        if (!response?.data?.role) {
          throw new Error("Role not included in response");
        }

        const updatedUser = {
          ...this.users[id],
          role: response.data.role.toLowerCase(),
          loading: false,
          error: false,
          errorMessage: ""
        };

        Vue.set(this.users, id, updatedUser);
      } catch (error) {
        console.error("Error updating user role: ", error);
        const oldRole = user.role;
        const erroredUser = {
          ...this.users[id],
          role: oldRole,
          loading: false,
          error: true,
          errorMessage: "We encountered an error updating the role."
        };

        Vue.set(this.users, id, erroredUser);
      }
    },
    removeSelectedUsers() {
      this.selectedUsers.forEach((user) => {
        apolloClient
          .mutate(removeUserMutation(this.SessionGetActiveProjectId, user.id))
          .then(() => {
            Vue.delete(this.users, user.id);
          })
          .catch((error) => {
            console.error("error removing user", error);
          });
      });
      this.selectedUsers = [];
    }
  },
  computed: {
    ...mapGetters([gettersFromSession.SessionGetActiveProjectId]),
    noDataText() {
      if (this.userFetchError) {
        return "Something went wrong while loading users.";
      } else {
        return "No users found.";
      }
    },
    filteredUsersBySearch() {
      if (
        !this.users ||
        !Object.values(this.users) ||
        Object.values(this.users)?.length === 0
      ) {
        return [];
      }
      if (this.search?.length > 0) {
        const tempUsers = [];
        Object.values(this.users).forEach((userObject) => {
          const searchableValues = [
            userObject.email,
            userObject.nickname,
            userObject.role
          ];
          searchableValues.some((value) => {
            if (
              value
                ?.toString()
                .toLowerCase()
                .includes(this.search?.toLowerCase())
            ) {
              tempUsers.push(userObject);
            }
          });
        });

        return tempUsers;
      } else {
        return Object.values(this.users);
      }
    },
    filteredUsersBySearchAndRole() {
      if (this.selectedRoleForFilter !== allRoles) {
        return uniq(this.filteredUsersBySearch).filter(
          (item) => item.role === this.selectedRoleForFilter
        );
      } else {
        return uniq(this.filteredUsersBySearch);
      }
    },
    //Add 'isSelectable' property to 'filteredUsersBySearchAndRole' objects. True for all users except admins.
    filteredUsersWithSelectableProperty() {
      return this.filteredUsersBySearchAndRole.map((user) => {
        return {
          ...user,
          isSelectable: user.role !== permissionTypesForProject.ADMIN
        };
      });
    },
    selectedUsersNames() {
      return this.selectedUsers.map((user) => user.nickname);
    },
    selectedUsersIds() {
      return this.selectedUsers.map((user) => user.id);
    }
  }
};
</script>

<style scoped>
#input-usage .v-messages {
  border: 1px dashed rgba(0, 0, 0, 0.4);
}
</style>
