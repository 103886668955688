import LoadingStates from "@/constants/LoadingStates";
import { NewPolygonTypes as types } from "./types";
import SessionTypes from "@S/session/types";

import { InteractionStates } from "@/constants/InteractionStates";

import store from "@S/index";
import { getters as savingViewGetters } from "@S/savingView/types";
import { getters as categoryGetters } from "@S/projectFeatureCategories/types";

import {
  getFeatureColour,
  getFeatureOpacity,
  getFeatureOwnerName
} from "@S/helpers/featureGetterHelpers";

import Polygon from "@S/polygon/Polygon.js";
import LayersProperties from "@/constants/LayersProperties";

export default {
  state: {
    polygons: {},
    loadingStates: {},
    interactionStates: {}
  },

  getters: {
    [types.getters.GetAllPolygons](state) {
      return Object.values(state.polygons);
    },
    [types.getters.AllPolygonSets](state, getters) {
      const polygonIds = Object.keys(state.polygons);

      if (polygonIds.length == 0) {
        return [];
      }

      const polygonSets = [];

      polygonIds.forEach((polygonId) => {
        polygonSets.push(
          getters[types.getters.PolygonGetSet](Number(polygonId))
        );
      });
      return polygonSets;
    },
    [types.getters.PolygonGetSet]: (state, rootGetters) => (id) => {
      if (!state.polygons?.[id]) {
        return null;
      }

      //Polygon style from saved view
      const polygonStyle = store.getters[
        savingViewGetters.SavingViewsGetPolygonStyles
      ].find((style) => style.assetId == Number(id));

      const category = store.getters[categoryGetters.GetCategoryById](
        state.polygons[id]?.categoryId
      );

      const primaryColour = getFeatureColour(
        polygonStyle?.fillColour,
        category?.primaryColour,
        LayersProperties.CORRIDORS_COLOUR
      );

      const secondaryColour = getFeatureColour(
        polygonStyle?.outlineColour,
        category?.secondaryColour,
        LayersProperties.CORRIDORS_OUTLINE_COLOUR
      );

      const opacity = getFeatureOpacity(
        polygonStyle?.opacity,
        category?.masterOpacityPercentage,
        LayersProperties.CORRIDORS_FILL_OPACITY
      );

      const createdBy = state.polygons?.[id]?.createdBy;
      const users = rootGetters[SessionTypes.getters.SessionGetUsers];
      const ownerNickname = getFeatureOwnerName(createdBy, users);

      const polygon = new Polygon(
        state.polygons[id]?.id.toString(),
        state.polygons[id]?.name,
        state.polygons[id]?.categoryId,
        category?.name || null,
        state.polygons[id]?.description,
        state.polygons[id]?.coords,
        state.loadingStates[id],
        state.interactionStates[id] || InteractionStates.NORMAL,
        state.polygons[id]?.createdBy,
        ownerNickname,
        state.polygons[id]?.created,
        state.polygons[id]?.updatedAt,
        state.polygons[id]?.polygonType,
        primaryColour,
        secondaryColour,
        opacity
      );

      return polygon;
    },
    [types.getters.PolygonGetName]: (state) => (id) => {
      return state.polygons[id]?.name;
    },
    [types.getters.AnyPolygonIsLoading]: (state) => {
      return Object.values(state.loadingStates).includes(
        LoadingStates.INITIALISED || LoadingStates.LOADING
      );
    },

    [types.getters.PolygonGetLoadingState]: (state) => (id) => {
      return state.loadingStates[Number(id)];
    },

    [types.getters.PolygonGetInteractionState]: (state) => (id) => {
      return state.interactionStates[Number(id)] || InteractionStates.NORMAL;
    }
  },

  mutations: {
    [types.mutations.PolygonUpdate]: (state, payload) => {
      const id = payload.id;
      Vue.set(state.polygons[id], "name", payload.name);
      Vue.set(state.polygons[id], "categoryId", payload.categoryID);
      Vue.set(state.polygons[id], "description", payload.description);
      Vue.set(state.polygons[id], "coords", payload.coords);
      Vue.set(state.polygons[id], "polygonType", payload.polygonType);
      Vue.set(state.polygons[id], "updatedAt", payload.updatedAt);
    },
    [types.mutations.PolygonSetLoadingState]: (state, payload) => {
      //payload: {id, loadingState}
      Vue.set(state.loadingStates, Number(payload.id), payload.loadingState);
    },
    [types.mutations.PolygonSetInteractionState]: (state, payload) => {
      //payload: {id, interactionState}
      Vue.set(
        state.interactionStates,
        Number(payload.id),
        payload.interactionState
      );
    },
    [types.mutations.PolygonAddPolygon]: (state, payload) => {
      //convert categoryID property to categoryId
      const formatted = { ...payload, categoryId: payload.categoryID };
      delete formatted.categoryID;

      Vue.set(state.polygons, payload.id, formatted);
    },
    [types.mutations.PolygonResetState]: (state) => {
      Vue.set(state, "polygons", {});
      Vue.set(state, "loadingStates", {});
      Vue.set(state, "interactionStates", {});
    },
    [types.mutations.PolygonDeletePolygon]: (state, payload) => {
      Vue.delete(state.polygons, payload);
    }
  },

  actions: {
    [types.actions.PolygonUpdate]: ({ commit }, payload) => {
      commit(types.mutations.PolygonSetLoadingState, {
        id: payload.id,
        loadingState: LoadingStates.LOADED
      });
      commit(types.mutations.PolygonUpdate, payload);
    },
    [types.actions.PolygonSetLoadingState]({ commit }, payload) {
      commit(types.mutations.PolygonSetLoadingState, payload);
    },
    [types.actions.PolygonSetInteractionState]({ commit }, payload) {
      commit(types.mutations.PolygonSetInteractionState, payload);
    },
    [types.actions.PolygonAddPolygon]({ commit, dispatch }, payload) {
      commit(types.mutations.PolygonAddPolygon, payload);
      if (payload?.createdBy) {
        dispatch(
          SessionTypes.actions.SessionAddUserDetails,
          payload?.createdBy
        );
      }
    },
    [types.actions.PolygonResetState]({ commit }) {
      commit(types.mutations.PolygonResetState);
    },
    [types.actions.PolygonDeletePolygon]: ({ commit }, payload) => {
      commit(types.mutations.PolygonDeletePolygon, payload);
    }
  }
};
