<template>
  <v-list-item @click="logout" dense
    ><v-list-item-icon>
      <v-progress-circular
        v-if="authLoading"
        indeterminate
        class="mx-2"
      ></v-progress-circular>

      <v-icon v-else> mdi-logout-variant </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="buttonText"></v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { authState, logout } from "@/plugins/authPlugin";
export default {
  name: "LogoutButton",
  computed: {
    authLoading() {
      return authState?.value?.loading;
    },
    buttonText() {
      if (this.authLoading) {
        return "Loading";
      } else {
        return "Logout";
      }
    }
  },
  methods: {
    logout() {
      logout({ federated: true, returnTo: window.location.origin });
    }
  }
};
</script>
