const getters = {
  MultiPolygonAnalysisGetMaxPolygons: "MultiPolygonAnalysisGetMaxPolygons",
  MultiPolygonAnalysisGetPolygonIds: "MultiPolygonAnalysisGetPolygonIds",
  MultiPolygonAnalysisGetPointIds: "MultiPolygonAnalysisGetPointIds",
  MultiPolygonAnalysisGetLineIds: "MultiPolygonAnalysisGetLineIds",
  MultiPolygonAnalysisGetLayerIds: "MultiPolygonAnalysisGetLayerIds",
  MultiPolygonAnalysisGetLayerRowSelected:
    "MultiPolygonAnalysisGetLayerRowSelected",
  // deprecated: use MultiFeatureAnalysisGetSelectedFeatureTabInfo
  MultiFeatureAnalysisGetSelectedFeatureTab:
    "MultiFeatureAnalysisGetSelectedFeatureTab",
  MultiFeatureAnalysisGetSelectedFeatureTabInfo:
    "MultiFeatureAnalysisGetSelectedFeatureTabInfo",
  MultiPolygonAnalysisGetActiveFeatureSelection:
    "MultiPolygonAnalysisGetActiveFeatureSelection",
  MultiPolygonAnalysisGetBuffers: "MultiPolygonAnalysisGetBuffers",
  MultiPolygonAnalysisGetMaxBuffers: "MultiPolygonAnalysisGetMaxBuffers",
  MultiPolygonAnalysisGetSearchText: "MultiPolygonAnalysisGetSearchText",
  MultiPolygonAnalysisGetLayerFeatureSearchText:
    "MultiPolygonAnalysisGetLayerFeatureSearchText",
  MultiPolygonAnalysisGetSelectedFeatureParameters:
    "MultiPolygonAnalysisGetSelectedFeatureParameters",
  MultiPolygonAnalysisGetAllFeatureParameters:
    "MultiPolygonAnalysisGetAllFeatureParameters",
  MultiPolygonAnalysisGetSelectedGroupBy:
    "MultiPolygonAnalysisGetSelectedGroupBy"
};

const mutations = {
  MultiPolygonAnalysisOverwriteFeatureParameters:
    "MultiPolygonAnalysisOverwriteFeatureParameters",
  MultiPolygonAnalysisToggleFeatureId: "MultiPolygonAnalysisToggleFeatureId",
  MultiPolygonAnalysisSetLayerIds: "MultiPolygonAnalysisSetLayerIds",
  MultiPolygonAnalysisSetLayerRowSelected:
    "MultiPolygonAnalysisSetLayerRowSelected",
  MultiFeatureAnalysisSetSelectedFeatureTab:
    "MultiFeatureAnalysisSetSelectedFeatureTab",
  MultiPolygonAnalysisSetBuffers: "MultiPolygonAnalysisSetBuffers",
  MultiPolygonAnalysisResetState: "MultiPolygonAnalysisResetState",
  MultiPolygonAnalysisSetSearchText: "MultiPolygonAnalysisSetSearchText",
  MultiPolygonAnalysisSetLayerFeatureSearchText:
    "MultiPolygonAnalysisSetLayerFeatureSearchText",
  MultiPolygonAnalysisSetSelectedFeatureParameters:
    "MultiPolygonAnalysisSetSelectedFeatureParameters",
  MultiPolygonAnalysisSetSelectedGroupBy:
    "MultiPolygonAnalysisSetSelectedGroupBy"
};

const actions = {
  MultiPolygonAnalysisSetAnalysisReportProperties:
    "MultiPolygonAnalysisSetAnalysisReportProperties",
  MultiPolygonAnalysisToggleFeatureId: "MultiPolygonAnalysisToggleFeatureId",
  MultiPolygonAnalysisSetLayerIds: "MultiPolygonAnalysisSetLayerIds",
  MultiPolygonAnalysisSetLayerRowSelected:
    "MultiPolygonAnalysisSetLayerRowSelected",
  MultiFeatureAnalysisSetSelectedFeatureTab:
    "MultiFeatureAnalysisSetSelectedFeatureTab",
  MultiPolygonAnalysisSetBuffers: "MultiPolygonAnalysisSetBuffers",
  MultiPolygonAnalysisResetState: "MultiPolygonAnalysisResetState",
  MultiPolygonAnalysisSetSearchText: "MultiPolygonAnalysisSetSearchText",
  MultiPolygonAnalysisSetLayerFeatureSearchText:
    "MultiPolygonAnalysisSetLayerFeatureSearchText",
  MultiPolygonAnalysisSetSelectedFeatureParameters:
    "MultiPolygonAnalysisSetSelectedFeatureParameters",
  MultiPolygonAnalysisSetSelectedGroupBy:
    "MultiPolygonAnalysisSetSelectedGroupBy"
};

export { getters, mutations, actions };
const types = { getters, mutations, actions };
export default types;
