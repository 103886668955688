<template>
  <PermissionGuard :permission="VERB.WRITE">
    <template v-slot:default="permissionSlot">
      <div
        v-if="newViewMethod === SavingViewsNewViewMethod.NEW_FROM_EXISTING_VIEW"
      >
        <v-list-item
          @click="$emit('openDialog')"
          :disabled="!permissionSlot.hasAccess"
          data-cy="saveAsNewListItem"
        >
          <v-list-item-icon>
            <v-icon>mdi-content-save-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Save as new</v-list-item-content>
        </v-list-item>
      </div>
      <div v-else-if="newViewMethod === SavingViewsNewViewMethod.NEW_FROM_MENU">
        <v-btn
          @click="$emit('openDialog')"
          :disabled="!permissionSlot.hasAccess"
          data-cy="createViewButton"
          text
        >
          <v-icon large left> mdi-plus </v-icon> Create {{ viewType }} View
        </v-btn>
      </div>
      <div
        v-else-if="newViewMethod === SavingViewsNewViewMethod.NEW_FROM_BUTTON"
      >
        <v-btn
          @click="$emit('openDialog')"
          style="border-radius: 8px"
          data-cy="save-view-button"
          class="pa-3 pr-4 ma-0 mt-1"
          dark
          tile
          filled
          >Save as new</v-btn
        >
      </div>
    </template>
  </PermissionGuard>
</template>

<script>
import SavingViewsNewViewMethod from "@/constants/SavingViewsNewViewMethod";
import PermissionGuard from "@C/PermissionGuard.vue";

export default {
  name: "CreateSavedViewActivator",
  components: {
    PermissionGuard
  },
  data() {
    return {
      SavingViewsNewViewMethod
    };
  },
  emits: ["openDialog"], 
  props: {
    newViewMethod: {
      type: String,
      required: true
    },
    viewType: {
      type: String,
      required: true
    }
  }
};
</script>

<style></style>
