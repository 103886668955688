const getters = {
  ResultsTableGetAllSortedHeaders: "ResultsTableGetAllSortedHeaders",
  ResultsTableGetAdditionalHeaders: "ResultsTableGetAdditionalHeaders",
  ResultsTableGetSelectedHeaderIds: "ResultsTableGetSelectedHeaderIds",
  ResultsTableGetOrderedAdditionalHeaders:
    "ResultsTableGetOrderedAdditionalHeaders",
  ResultsTableGetOpenMetricTooltip: "ResultsTableGetOpenMetricTooltip"
};
const mutations = {
  ResultsTableSetAdditionalHeaders: "ResultsTableSetAdditionalHeaders",
  ResultsTableSetSelectedAdditionalHeaderIds:
    "ResultsTableSetSelectedAdditionalHeaderIds",
  ResetAvailableAndSelectedColumns: "ResetAvailableAndSelectedColumns",
  ResultsTableSetOpenMetricTooltip: "ResultsTableSetOpenMetricTooltip"
};
const actions = {
  ResultsTableAddAdditionalHeaders: "ResultsTableAddAdditionalHeaders",
  ResultsTableSetAdditionalHeaders: "ResultsTableSetAdditionalHeaders",
  ResultsTableSetSelectedAdditionalHeaderIds:
    "ResultsTableSetSelectedAdditionalHeaderIds",
  ResetAvailableAndSelectedColumns: "ResetAvailableAndSelectedColumns",
  ResultsTableSetOpenMetricTooltip: "ResultsTableSetOpenMetricTooltip"
};

export { getters, mutations, actions };

export default { getters, mutations, actions };
