import { getters as sessionGetters } from "@S/session/types";
import {
  getters as portfolioGetters,
  mutations as portfolioMutations
} from "@S/portfolio/types";

import { getters as projectGetters } from "@S/project/types";
import { attachmentTypes } from "@/constants/MilestoneTypes";
import { makeReadableDevelopmentAssetTypes } from "@/helpers/formatAssetTypeArray";
import {
  dashboardWidgetMappingKey,
  breakpointNames,
  localStorageLayoutKey,
  groupingKeys,
  widgetTypeKeys,
  widgetNameProp,
  selectedDeliverableProp,
  groupingFieldProp,
  datasetNameProp,
  widgetDatasetFiltersKey,
  TooltipSplittingFieldProp
} from "@C/projectHome/dashboard/dashboardConsts";
import {
  checkMilestoneMatchesSearch,
  checkMilestonePassesFilters
} from "./helpers";
import { v4 as uuidv4 } from "uuid";

const areLayoutsMissingBreakpoints = (layouts) => {
  const allBreakpoints = Object.values(breakpointNames);
  // Only accept new layout if it includes a layout for all breakpoints
  if (allBreakpoints.some((bp) => !layouts?.[bp])) {
    return;
  }
};

export default {
  state: {
    hoveredPortfolioProject: null,
    portfolioProjectsInViewPort: new Set(),
    portfolioSearch: "",
    portfolioStatusFilters: [],
    portfolioMilestoneNameFilters: [],
    portfolioDeliverableNameFilters: [],
    portfolioOptionsGeojson: {
      type: "FeatureCollection",
      features: []
    },
    dashboardWidgetMapping: {
      /*
      [widgetId]: {
        componentName/key: "",
        componentProps: {},
      }
      */
    },
    portfolioOptionsMap: {
      /*
      [id]: {
        id : 0,
        projectId: "",
        // use an array of deliverable IDs to account for possibly duplicated options across deliverables
        attachmentIds: [Number],
      }
      */
    },
    milestonesMap: {
      /*
      [milestoneId]: {
        id: 0,
        name: "",
        status: "",
        projectID: "",
        deliverableIds: []
      }
    */
    },
    deliverablesMap: {
      /*
      [deliverableId]: {
        id: 0,
        name: "",
        allowedAttachmentType: "",
        promoted: false,
        projectID: "",
        milestoneId: 0,
        attachmentIds: []
      }
    */
    },
    attachmentsMap: {
      /*
      [attachmentId]: {
        id: 0,
        value: "",
        type: "",
        projectID: "",
      }
    */
    },
    dashboardLayouts: {}
  },
  getters: {
    [portfolioGetters.GetHoveredPortfolioProjectId]: (state) => {
      return state.hoveredPortfolioProject;
    },
    [portfolioGetters.GetPortfolioProjectsInViewportSet]: (state) => {
      return state.portfolioProjectsInViewPort;
    },
    [portfolioGetters.GetPortfolioSearch]: (state) => {
      return state.portfolioSearch;
    },
    [portfolioGetters.GetPortfolioStatusFilters]: (state) => {
      return state.portfolioStatusFilters;
    },
    [portfolioGetters.GetPortfolioMilestoneNameFilters]: (state) => {
      return state.portfolioMilestoneNameFilters;
    },
    [portfolioGetters.GetPortfolioDeliverableNameFilters]: (state) => {
      return state.portfolioDeliverableNameFilters;
    },

    [portfolioGetters.GetProjectIdsThatMatchFilters]: (state, getters) => {
      // Match all projects by default unless we do some filtering later
      let projectIdsMatchingFilters = new Set(
        getters[sessionGetters.SessionGetProjectIds]
      );

      const statusFilters = getters[portfolioGetters.GetPortfolioStatusFilters];
      const milestoneNameFilters =
        getters[portfolioGetters.GetPortfolioMilestoneNameFilters];
      const deliverableNameFilters =
        getters[portfolioGetters.GetPortfolioDeliverableNameFilters];

      if (
        statusFilters?.length ||
        milestoneNameFilters?.length ||
        deliverableNameFilters?.length
      ) {
        projectIdsMatchingFilters = new Set();
        const milestones = Object.values(
          getters[portfolioGetters.GetPortfolioMilestonesMap]
        );

        milestones?.forEach((milestone) => {
          const milestoneProjectId = milestone?.projectID;
          if (projectIdsMatchingFilters?.has(milestoneProjectId)) {
            return;
          }
          const deliverablesGetter = (ms) => {
            return ms?.deliverableIds?.map(
              (deliverableId) =>
                getters[portfolioGetters.GetPortfolioDeliverablesMap]?.[
                  deliverableId
                ]
            );
          };
          const passesFilters = checkMilestonePassesFilters({
            milestone,
            statusFilters,
            milestoneNameFilters,
            deliverableNameFilters,
            deliverablesGetter
          });
          if (passesFilters) {
            projectIdsMatchingFilters.add(milestoneProjectId);
          }
        });
      }

      return projectIdsMatchingFilters;
    },
    [portfolioGetters.GetProjectIdsThatMatchSearch]: (state, getters) => {
      const search =
        getters[portfolioGetters.GetPortfolioSearch]?.toLowerCase();
      if (!search) {
        return new Set(getters[sessionGetters.SessionGetProjectIds]);
      }

      const milestones = Object.values(
        getters[portfolioGetters.GetPortfolioMilestonesMap]
      );
      const projectIdsMatchedThroughMilestones = new Set();

      milestones?.forEach((milestone) => {
        const milestoneProjectId = milestone?.projectID;
        if (projectIdsMatchedThroughMilestones?.has(milestoneProjectId)) {
          return;
        }
        const deliverablesGetter = (ms) => {
          return ms?.deliverableIds?.map(
            (deliverableId) =>
              getters[portfolioGetters.GetPortfolioDeliverablesMap]?.[
                deliverableId
              ]
          );
        };
        const matchesSearch = checkMilestoneMatchesSearch({
          milestone,
          deliverablesGetter,
          search
        });
        if (matchesSearch) {
          projectIdsMatchedThroughMilestones?.add(milestoneProjectId);
        }
      });

      const projectIdsMatchedThroughProjectFields = getters[
        sessionGetters.SessionGetProjectIds
      ]?.filter((id) => {
        const projectName =
          getters?.[projectGetters.ProjectGetName](id)?.toLowerCase();
        const projectDescription =
          getters?.[projectGetters.ProjectGetDescription](id)?.toLowerCase();

        const assetTypesString = makeReadableDevelopmentAssetTypes(
          getters?.[projectGetters.ProjectGetAssetTypes](id)
        )?.toLowerCase();

        const areaName =
          getters?.[projectGetters.ProjectGetAreaName](id)?.toLowerCase();

        if (
          projectName?.includes(search) ||
          projectDescription?.includes(search) ||
          assetTypesString?.includes(search) ||
          areaName?.includes(search)
        ) {
          return true;
        }
      });

      return new Set([
        ...projectIdsMatchedThroughMilestones,
        ...projectIdsMatchedThroughProjectFields
      ]);
    },
    [portfolioGetters.GetProjectIdsMatchingFiltersAndSearch]: (
      state,
      getters
    ) => {
      return new Set(
        getters[sessionGetters.SessionGetProjectIds]?.filter((id) => {
          const projectMatchesFilters =
            getters[portfolioGetters.GetProjectIdsThatMatchFilters]?.has(id);
          if (!projectMatchesFilters) {
            return false;
          }

          if (getters[portfolioGetters.GetProjectIdsThatMatchSearch]?.has(id)) {
            return true;
          }
        })
      );
    },
    [portfolioGetters.GetAllMatchedProjectIds]: (state, getters) => {
      const projectIdsFromSearchAndFilter = [
        ...getters[portfolioGetters.GetProjectIdsMatchingFiltersAndSearch]
      ];

      return new Set(
        projectIdsFromSearchAndFilter?.filter((id) => {
          const projectInViewport =
            getters[portfolioGetters.GetPortfolioProjectsInViewportSet]?.has(
              id
            );
          if (!projectInViewport) {
            return false;
          }

          return true;
        })
      );
    },
    [portfolioGetters.GetPortfolioOptionGeojson]: (state) => {
      return state.portfolioOptionsGeojson;
    },
    [portfolioGetters.GetPortfolioOptions]: (state) => {
      return state.portfolioOptionsMap;
    },
    [portfolioGetters.GetPortfolioOptionFields]: (state) => (optionId) => {
      return state.portfolioOptionsMap?.[optionId];
    },
    [portfolioGetters.GetPortfolioMilestonesMap]: (state) => {
      return state.milestonesMap;
    },
    [portfolioGetters.GetPortfolioDeliverablesMap]: (state) => {
      return state.deliverablesMap;
    },
    [portfolioGetters.GetPortfolioAttachmentsMap]: (state) => {
      return state.attachmentsMap;
    },
    [portfolioGetters.GetPromotedMetricAttachments]: (state, getters) => {
      return Object.values(state?.attachmentsMap)
        ?.filter((attachment) => {
          const name = attachment?.deliverableName;
          if (!attachment?.promoted || !name) {
            return;
          }
          if (
            attachment?.type !== attachmentTypes?.METRIC?.backendValue ||
            String(attachment?.value) === ""
          ) {
            return;
          }
          return true;
        })
        ?.map((attachment) => {
          const assetTypes =
            getters?.[projectGetters.ProjectGetAssetTypes](
              attachment?.projectID
            ) || [];
          const readableList = makeReadableDevelopmentAssetTypes(assetTypes);
          const areaName =
            getters?.[projectGetters.ProjectGetAreaName](
              attachment?.projectID
            ) || "";
          const projectName =
            getters?.[projectGetters.ProjectGetName](attachment?.projectID) ||
            "";
          return {
            ...attachment,
            [groupingKeys.assetTypesKey]: readableList,
            [groupingKeys.areaNameKey]: areaName,
            [groupingKeys.projectNameKey]: projectName
          };
        });
    },
    [portfolioGetters.GetAllMilestoneNames]: (state) => {
      const names = new Set();
      Object.values(state?.milestonesMap)?.forEach((m) => {
        if (!m?.name) return;
        names.add(m?.name);
      });
      return [...names];
    },
    [portfolioGetters.GetAllDeliverableNames]: (state) => {
      const names = new Set();
      Object.values(state?.deliverablesMap)?.forEach((d) => {
        if (!d?.name) return;
        names.add(d?.name);
      });
      return [...names];
    },
    [portfolioGetters.GetDashboardWidgetMapping]: (state) => {
      return state.dashboardWidgetMapping;
    },
    [portfolioGetters.GetDashboardLayouts]: (state) => {
      return state.dashboardLayouts;
    },
    [portfolioGetters.GetGroupedDeliverableData]:
      (state, getters) =>
      ({ widgetId }) => {
        const selectedGroupingField =
          getters.GetDashboardWidgetMapping?.[widgetId]?.props?.[
            groupingFieldProp
          ];

        // Don't bother calculating if we don't have a field to group on
        if (!selectedGroupingField) {
          return {};
        }

        const labelSplittingField =
          getters.GetDashboardWidgetMapping?.[widgetId]?.props?.[
            TooltipSplittingFieldProp
          ] || groupingKeys.projectNameKey;

        const countMapSplitByDataset = {};
        const datasetsInWidget = Object.values(
          getters.GetDashboardWidgetMapping?.[widgetId]?.datasets || {}
        );

        const tooltipLabelCountsAcrossDatasets = {};

        datasetsInWidget?.forEach((dataset) => {
          const targetDeliverableName = dataset?.[selectedDeliverableProp];
          const datasetId = dataset?.datasetId;

          tooltipLabelCountsAcrossDatasets[datasetId] = {};

          getters?.GetPromotedMetricAttachments?.forEach((attachment) => {
            const numericValue = Number(attachment?.value);
            const deliverableName = attachment?.deliverableName;

            // perform dataset based filtering
            if (deliverableName !== targetDeliverableName) {
              return;
            }
            const datasetFilterMap = dataset?.[widgetDatasetFiltersKey];
            if (datasetFilterMap && Object.keys(datasetFilterMap)?.length) {
              const passesFilters = Object.keys(datasetFilterMap).every(
                (filterGroupKey) => {
                  const filters = datasetFilterMap?.[filterGroupKey];
                  if (!filters?.length) {
                    return true;
                  }
                  const relevantAttachmentValue = attachment?.[filterGroupKey];
                  return filters.some(
                    (filterValue) => filterValue === relevantAttachmentValue
                  );
                }
              );
              if (!passesFilters) {
                return;
              }
            }

            const projectId = attachment?.projectID;
            const projectName =
              getters?.[projectGetters.ProjectGetName](projectId);
            const groupingValue = attachment?.[selectedGroupingField];

            // Grouping field value must have a name to be used
            if (!groupingValue || !projectId || !projectName) {
              return;
            }

            const groupCountsWithinDataset =
              countMapSplitByDataset?.[datasetId];
            if (groupCountsWithinDataset) {
              const countForGroupWithinDataset =
                groupCountsWithinDataset?.[groupingValue] || 0;
              const newCount = countForGroupWithinDataset + numericValue;
              countMapSplitByDataset[datasetId][groupingValue] = newCount;
            } else {
              countMapSplitByDataset[datasetId] = {};
              countMapSplitByDataset[datasetId][groupingValue] = numericValue;
            }

            const fieldForSplitTooltip = attachment?.[labelSplittingField];
            const datasetLabelCounts =
              tooltipLabelCountsAcrossDatasets?.[datasetId];
            const countForGroupedValue = datasetLabelCounts?.[groupingValue];
            const countForTooltipField =
              countForGroupedValue?.[fieldForSplitTooltip];
            if (countForTooltipField) {
              tooltipLabelCountsAcrossDatasets[datasetId][groupingValue][
                fieldForSplitTooltip
              ] = countForTooltipField + numericValue;
            } else {
              tooltipLabelCountsAcrossDatasets[datasetId] = {
                ...tooltipLabelCountsAcrossDatasets[datasetId],
                [groupingValue]: {
                  ...(tooltipLabelCountsAcrossDatasets?.[datasetId]?.[
                    groupingValue
                  ] || {}),
                  [fieldForSplitTooltip]: numericValue
                }
              };
            }
          });
        });

        return { countMapSplitByDataset, tooltipLabelCountsAcrossDatasets };
      },
    [portfolioGetters.GetWidgetDatasetName]:
      (state, getters) =>
      ({ widgetId, datasetId }) => {
        const dataset =
          getters.GetDashboardWidgetMapping?.[widgetId]?.datasets?.[datasetId];
        return (
          dataset?.[datasetNameProp] ||
          dataset?.[selectedDeliverableProp] ||
          "Unnamed dataset"
        );
      }
  },
  mutations: {
    [portfolioMutations.SetHoveredPortfolioProjectId]: (state, payload) => {
      if (!payload && payload !== null) return;
      Vue.set(state, "hoveredPortfolioProject", payload);
    },
    [portfolioMutations.SetPortfolioProjectsInViewport]: (state, newSet) => {
      if (!newSet) return;
      Vue.set(state, "portfolioProjectsInViewPort", newSet);
    },
    [portfolioMutations.SetPortfolioSearch]: (state, payload) => {
      Vue.set(state, "portfolioSearch", String(payload));
    },
    [portfolioMutations.SetPortfolioStatusFilters]: (state, payload) => {
      Vue.set(state, "portfolioStatusFilters", payload);
    },
    [portfolioMutations.SetPortfolioMilestoneNameFilters]: (state, payload) => {
      Vue.set(state, "portfolioMilestoneNameFilters", payload);
    },
    [portfolioMutations.SetPortfolioDeliverableNameFilters]: (
      state,
      payload
    ) => {
      Vue.set(state, "portfolioDeliverableNameFilters", payload);
    },

    [portfolioMutations.ResetPortfolioMilestonesState]: (state) => {
      Vue.set(state, "portfolioOptionsGeojson", {
        type: "FeatureCollection",
        features: []
      });
      Vue.set(state, "portfolioOptionsMap", {});
      Vue.set(state, "milestonesMap", {});
      Vue.set(state, "deliverablesMap", {});
      Vue.set(state, "attachmentsMap", {});
    },
    [portfolioMutations.AddPortfolioOption]: (state, payload) => {
      const optionId = payload?.optionId;
      if (!optionId) return;
      const attachmentId = payload?.attachmentId;
      const deliverableId = payload?.deliverableId;
      const milestoneId = payload?.milestoneId;
      const projectId = payload?.projectId;
      const alias = payload?.alias;
      const linkFieldsMissing = !deliverableId || !milestoneId || !attachmentId;

      // If option has already been fetched and added, just append to milestone links
      if (state.portfolioOptionsMap?.[optionId]) {
        if (linkFieldsMissing) {
          return;
        }
        state.portfolioOptionsMap[optionId]?.attachmentIds?.push(attachmentId);

        return;
      }

      Vue.set(state.portfolioOptionsMap, optionId, {
        id: optionId,
        projectId,
        attachmentIds: linkFieldsMissing ? [] : [attachmentId],
        alias
      });
      const geojson = payload?.geojson;
      if (
        !geojson ||
        !geojson?.type === "Feature" ||
        !geojson?.id ||
        !geojson?.geometry
      )
        return;
      state.portfolioOptionsGeojson.features.push(geojson);
    },
    [portfolioMutations.AddPortfolioMilestones]: (state, payload) => {
      const milestones = payload?.milestones;

      milestones?.forEach((milestone) => {
        const milestoneId = milestone?.id;
        if (!milestoneId) return;
        const milestoneName = milestone?.name || "";
        const projectID = milestone?.projectID || "";
        const status = milestone?.status || "";
        const deliverableIds = [];

        milestone?.deliverables?.forEach((deliverable) => {
          const deliverableId = deliverable?.id;
          if (!deliverableId) return;
          deliverableIds.push(deliverableId);
          const attachmentIds = [];

          deliverable?.attachments?.forEach((attachment) => {
            const attachmentId = attachment?.id;
            if (!attachmentId) return;
            attachmentIds.push(attachmentId);

            Vue.set(state.attachmentsMap, attachmentId, {
              id: attachmentId,
              type: attachment?.type || attachmentTypes?.ATTACHMENT_UNSET,
              value: attachment?.value || "",
              promoted: deliverable?.promoted || false,
              deliverableName: deliverable?.name || "",
              [groupingKeys.milestoneStatusKey]: status,
              [groupingKeys.milestoneNameKey]: milestoneName,
              deliverableId,
              milestoneId,
              projectID
            });
          });

          Vue.set(state.deliverablesMap, deliverableId, {
            id: deliverableId,
            name: deliverable?.name || "",
            allowedAttachmentType: deliverable?.allowedAttachmentType || "",
            promoted: deliverable?.promoted || false,
            projectID,
            milestoneId,
            [groupingKeys.milestoneNameKey]: milestoneName,
            attachmentIds
          });
        });
        Vue.set(state.milestonesMap, milestoneId, {
          id: milestoneId,
          name: milestoneName,
          projectID,
          status,
          deliverableIds
        });
      });
    },
    [portfolioMutations.SetDashboardWidgetMapping]: (state, payload) => {
      if (!payload) return;
      Vue.set(state, "dashboardWidgetMapping", payload);
      localStorage.setItem(
        dashboardWidgetMappingKey,
        JSON.stringify(state.dashboardWidgetMapping)
      );
    },

    [portfolioMutations.UpdateDashboardLayout]: (state, payload) => {
      // Only accept new layout if it includes a layout for all breakpoints
      if (areLayoutsMissingBreakpoints(payload)) {
        console.error("New layouts didn't include all breakpoints");
        return;
      }
      Vue.set(state, "dashboardLayouts", payload);
      localStorage.setItem(
        localStorageLayoutKey,
        JSON.stringify(state.dashboardLayouts)
      );
    }
  },
  actions: {
    [portfolioMutations.AddBlankDashboardWidget]: (
      { state, commit },
      { widgetId, layouts }
    ) => {
      if (!widgetId || !layouts) return;
      // Only accept new layout if it includes a layout for all breakpoints
      if (areLayoutsMissingBreakpoints(layouts)) {
        console.error(
          "New layouts didn't include all breakpoints when adding new widget"
        );
        return;
      }
      commit(portfolioMutations.SetDashboardWidgetMapping, {
        ...state.dashboardWidgetMapping,
        [widgetId]: {
          component: widgetTypeKeys.BlankWidget.key,
          datasets: {},
          props: { [widgetNameProp]: "", [groupingFieldProp]: "" }
        }
      });
      commit(portfolioMutations.UpdateDashboardLayout, layouts);
    },
    [portfolioMutations.UpdateDashboardWidgetComponent]: (
      { state, commit },
      payload
    ) => {
      const widgetId = payload?.widgetId;
      const component = payload?.component;
      if (!widgetId || !component) return;
      const newWidgetMapping = {
        ...state.dashboardWidgetMapping,
        [widgetId]: {
          ...state.dashboardWidgetMapping[widgetId],
          component: component
        }
      };
      commit(portfolioMutations.SetDashboardWidgetMapping, newWidgetMapping);
    },
    [portfolioMutations.UpdateDashboardWidgetProp]: (
      { state, commit },
      payload
    ) => {
      const widgetId = payload?.widgetId;
      const propName = payload?.propName;
      const propValue = payload?.propValue;
      if (!widgetId || !propName || propValue === undefined) return;
      const newWidgetMapping = {
        ...state.dashboardWidgetMapping,
        [widgetId]: {
          ...state.dashboardWidgetMapping?.[widgetId],
          props: {
            ...state.dashboardWidgetMapping?.[widgetId]?.props,
            [propName]: propValue
          }
        }
      };
      commit(portfolioMutations.SetDashboardWidgetMapping, newWidgetMapping);
    },
    [portfolioMutations.AddWidgetDataset]: ({ state, commit }, widgetId) => {
      if (!widgetId) {
        return;
      }
      const newDatasetId = uuidv4();
      const newWidgetMapping = {
        ...state.dashboardWidgetMapping,
        [widgetId]: {
          ...state.dashboardWidgetMapping?.[widgetId],
          datasets: {
            ...state.dashboardWidgetMapping?.[widgetId]?.datasets,
            [newDatasetId]: {
              datasetId: newDatasetId
            }
          }
        }
      };
      commit(portfolioMutations.SetDashboardWidgetMapping, newWidgetMapping);
    },
    [portfolioMutations.UpdateWidgetDataset]: ({ state, commit }, payload) => {
      const widgetId = payload?.widgetId;
      const propName = payload?.propName;
      const propValue = payload?.propValue;
      const datasetId = payload?.datasetId;
      if (!widgetId || !propName || !datasetId || propValue === undefined)
        return;
      const newWidgetMapping = {
        ...state.dashboardWidgetMapping,
        [widgetId]: {
          ...state.dashboardWidgetMapping?.[widgetId],
          datasets: {
            ...state.dashboardWidgetMapping?.[widgetId]?.datasets,
            [datasetId]: {
              ...state.dashboardWidgetMapping?.[widgetId]?.datasets?.[
                datasetId
              ],
              datasetId,
              [propName]: propValue
            }
          }
        }
      };
      commit(portfolioMutations.SetDashboardWidgetMapping, newWidgetMapping);
    },
    [portfolioMutations.DeleteWidgetDataset]: ({ state, commit }, payload) => {
      const widgetId = payload?.widgetId;
      const datasetId = payload?.datasetId;
      if (!widgetId || !datasetId) return;
      const newWidgetMapping = {
        ...state.dashboardWidgetMapping
      };
      Vue.delete(newWidgetMapping[widgetId].datasets, datasetId);
      commit(portfolioMutations.SetDashboardWidgetMapping, newWidgetMapping);
    },
    [portfolioMutations.RemoveDashboardWidget]: (
      { state, commit },
      widgetId
    ) => {
      if (!widgetId) {
        return;
      }
      const widgetPropMapping = { ...state.dashboardWidgetMapping };
      Vue.delete(widgetPropMapping, widgetId);
      commit(portfolioMutations.SetDashboardWidgetMapping, widgetPropMapping);

      const layouts = { ...state.dashboardLayouts };
      Object.keys(layouts).forEach((breakpoint) => {
        const layout = layouts?.[breakpoint]?.filter(
          (item) => item.i !== widgetId
        );
        layouts[breakpoint] = layout;
      });
      commit(portfolioMutations.UpdateDashboardLayout, layouts);
    }
  }
};
