//GraphQL version of src/components/userManagement/helpers/fetchUsers.js
import getUsersByProjectId from "@/graphql/users/getUsersByProjectId";
import { apolloClient } from "@/plugins/apolloPlugin";

const fetchUsers = async (projectId) => {
  const response = await apolloClient.query(getUsersByProjectId(projectId));

  if (response.errors) {
    return;
  }
  return response.data.users.users;
};

export default fetchUsers;
