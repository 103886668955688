const FILE_UPLOAD_STATES = Object.freeze({
  IDLE: 0,
  IN_PROGRESS: 1,
  FILE_SELECTED: 2,
  COMPLETED: 3,
  FAILED: -1,
  PARTIAL_SUCCESS: 4
});

export const projectFeaturesUploadKey = "projectFeaturesUpload";
export const datasetFileUploadKey = "fileUpload";
export const ellipsisIngestKey = "ellipsisIngest";
export default FILE_UPLOAD_STATES;
