//plugin used to determine if a user has permissions to do a certain action.
//by passing it the required permissions, it will return a boolean value
import { permissionTypesForProject as ProjectPermissions } from "@/constants/PermissionsTypes";
import { mapGetters } from "vuex";
import { getters as sessionGetters } from "@/store/session/types";

function allowedProjectAction(verb) {
  if (this.SessionWaitingForPermissions) {
    return false;
  }
  try {
    if (!Object.values(ProjectPermissions).includes(verb)) {
      console.log(
        "Invalid permission verb: " +
          verb +
          "options are: " +
          Object.values(ProjectPermissions)
      );
      throw "Invalid permission verb";
    }
    const projectId = this.$route?.params?.projectId;
    const accessAllowed = this.SessionCheckAuthorised(projectId, verb);
    return accessAllowed;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

const mixin = {
  computed: {
    ...mapGetters([
      sessionGetters.SessionCheckAuthorised,
      sessionGetters.SessionWaitingForPermissions
    ])
  },
  methods: {
    userCan: allowedProjectAction
  },
  beforeCreate() {
    this.VERB = ProjectPermissions;
  }
};

export default mixin;
