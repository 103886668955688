import { InteractionStates } from "@/constants/InteractionStates";
import { hashCode } from "@/hooks/hash-code";

export default class Line {
  horizontalProfileId = null;
  name = null;
  description = null;
  categoryId = 0;
  categoryName = null;
  coords = null;
  interactionState = InteractionStates.NORMAL;
  createdBy = null;
  owner_nickname = null;
  created = null;
  activeOutlineColour = "";
  activeOpacity = "";

  toGeoJsonFeature() {
    return {
      type: "Feature",
      id: hashCode(`${this.horizontalProfileId}`),
      geometry: {
        type: "LineString",
        coordinates: this.coords
          ? this.coords?.map((coord) => [coord.lng, coord.lat])
          : []
      },
      properties: {
        horizontalProfileId: this.horizontalProfileId,
        name: this.name,
        categoryId: this.categoryId,
        categoryName: this.categoryName,
        description: this.description,
        interactionState: this.interactionState,
        owner_nickname: this.owner_nickname,
        created: this.created,
        activeOutlineColour: this.activeOutlineColour,
        activeOpacity: this.activeOpacity
      }
    };
  }

  createGeoJsonForExport() {
    return {
      type: "Feature",
      id: hashCode(`${this.horizontalProfileId}`),
      geometry: {
        type: "LineString",
        coordinates: this.coords
          ? this.coords?.map((coord) => [coord.lng, coord.lat])
          : []
      },
      properties: {
        horizontalProfileId: this.horizontalProfileId,
        name: this.name,
        description: this.description,
        created: this.created,
        owner_nickname: this.owner_nickname
      }
    };
  }
  constructor(
    horizontalProfileId,
    _name,
    categoryId,
    categoryName,
    description,
    coords,
    loadingState,
    interactionState,
    createdBy,
    owner_nickname,
    created,
    activeOutlineColour,
    activeOpacity
  ) {
    this.horizontalProfileId = horizontalProfileId;
    this.name = _name;
    this.categoryId = categoryId;
    this.categoryName = categoryName;
    this.description = description;
    this.coords = coords;
    this.loadingState = loadingState;
    this.interactionState = interactionState;
    this.createdBy = createdBy;
    this.owner_nickname = owner_nickname;
    this.created = created;
    this.activeOutlineColour = activeOutlineColour;
    this.activeOpacity = activeOpacity;
  }
}
