import { routes as routeConsts } from "@/constants/routes";

const handlerAssetOnClick = async (
  router,
  routeName,
  callBackFuncPayload,
  defaultCallbackFunc,
  destinationRouteName
) => {
  if (routeName === routeConsts.projectDetails) {
    await goToPage(
      router,
      callBackFuncPayload,
      defaultCallbackFunc,
      destinationRouteName
    );
  } else {
    defaultCallbackFunc(callBackFuncPayload);
  }
};

const goToPage = async (
  router,
  callBackFuncPayload,
  successCallbackFunc,
  destinationRouteName
) => {
  try {
    await router.push(destinationRouteName);
    successCallbackFunc(callBackFuncPayload);
  } catch (error) {
    console.error("error in goToPage: ", error);
  }
};

export default handlerAssetOnClick;
