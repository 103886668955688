const getters = {
  SetupGetSetupMode: "SetupGetSetupMode"
};

const mutations = {
  SetupEnableCorridorGenerationMode: "SetupEnableCorridorGenerationMode",
  SetupEnableSiteSelectionMode: "SetupEnableSiteSelectionMode",
  SetupEnableGenerationMode: "SetupEnableGenerationMode",
  SetupEnableEvaluationMode: "SetupEnableEvaluationMode",
  SetupResetMode: "SetupResetMode"
};

const actions = {
  SetupEnableCorridorGenerationMode: "SetupEnableCorridorGenerationMode",
  SetupEnableSiteSelectionMode: "SetupEnableSiteSelectionMode",
  SetupEnableGenerationMode: "SetupEnableGenerationMode",
  SetupEnableEvaluationMode: "SetupEnableEvaluationMode",
  SetupResetMode: "SetupResetMode"
};

export { getters, mutations, actions };

export default { getters, mutations, actions };
