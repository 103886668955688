import types from "./types";

import { createViewObject, newEmptyViewsState } from "@/hooks/createViewObject";
import { cloneDeep, isEqual } from "lodash";
import sessionTypes from "@S/session/types";
import layerTypes from "@S/layer/types";
import updateOrAddProjectFeatureStyle from "@/hooks/updateOrAddProjectFeatureStyle";
import getVisibileFeaturesIds from "@/hooks/getVisibleFeaturesIds";
import { POLYGON_RESULT_ID_PREFIX } from "@/constants/Prefixes";

export default {
  state: newEmptyViewsState(),
  getters: {
    [types.getters.SavingViewsGetSavedVersionUpdatedAt]: (state) => {
      return state.savedVersionUpdatedAt || null;
    },
    [types.getters.SavingViewsGetAvailableViews]: (state) =>
      state.availableViews,
    [types.getters.SavingViewsGetActiveViewName]: (state) =>
      state.activeView.name,
    [types.getters.SavingViewsGetActiveViewId]: (state) => state.activeView.id,
    [types.getters.SavingViewsGetSavedGisPanelSwitches]: (state) =>
      state.activeView.savedVersion.mapStyle.showLayers,
    [types.getters.SavingViewsGetGisPanelSwitches]: (state) =>
      state.activeView.workingVersion.mapStyle.showLayers,
    [types.getters.SavingViewsGetVisibleVectorLayers]: (state) =>
      state.activeView.savedVersion?.mapStyle?.datasetLayerStyles?.map(
        (item) => {
          return Number(item.assetId);
        }
      ),

    [types.getters.SavingViewsGetGroupVectorLayersBy]: (state) =>
      state.activeView.workingVersion.mapStyle.groupBy,
    [types.getters.SavingViewsGetBasemapStyle](state) {
      return state.activeView.workingVersion.mapStyle.baseMapStyle;
    },
    [types.getters.SavingViewsGetIsThreeDimensional]: (state) =>
      state.activeView.workingVersion.mapStyle.threeD.active,
    [types.getters.SavingViewsGetThreeDimensionalExaggerationFactor]: (state) =>
      state.activeView.workingVersion.mapStyle.threeD.exaggerationFactor,
    [types.getters.SavingViewsGetWorkingVersion](state, getters) {
      const workingVersion = cloneDeep(state.activeView.workingVersion);
      workingVersion.mapStyle.datasetLayerStyles =
        getters[types.getters.SavingViewsGetDataLayers];

      return { ...workingVersion };
    },
    [types.getters.SavingViewsGetSavedVersion](state) {
      return state.activeView.savedVersion;
    },
    [types.getters.SavingViewsGetOptionStyles](state) {
      return state.activeView.workingVersion.optionsState;
    },
    [types.getters.SavingViewsGetSavedDataLayers](state) {
      return (
        state?.activeView?.savedVersion?.mapStyle?.datasetLayerStyles || []
      );
    },
    [types.getters.SavingViewsGetWorkingVersionActiveRasterLayer](state) {
      return state.activeView.workingVersion.mapStyle.activeRasterDatasetID;
    },
    [types.getters.SavingViewsGetSavedVersionActiveRasterLayer](state) {
      return state.activeView.savedVersion.mapStyle.activeRasterDatasetID;
    },
    [types.getters.SavingViewsGetDataLayers](_, getters) {
      const visibleLayers =
        getters[sessionTypes.getters.SessionGetVisibleVectorLayerIds];
      const layerSummaries = getters[layerTypes.getters.LayersGetAllSummaries];

      const layerStyles =
        visibleLayers?.map((id) => {
          const style = layerSummaries[id]?.style || {};
          return {
            assetId: id.toString(),
            asset: "VECTOR_DATASET",
            isVisible: true,
            opacity: style.opacity,
            outlineColour: style.outlineColour,
            fillColour: style.fillColour
          };
        }) || [];
      return layerStyles;
    },
    [types.getters.SavingViewsGetViewPort]: (state) =>
      state.activeView.workingVersion.mapStyle.viewPort,
    [types.getters.SavingViewsGetSavedViewPort]: (state) =>
      state.activeView.savedVersion.mapStyle.viewPort,

    [types.getters.SavingViewsGetRoutePointStyles]: (state) =>
      state.activeView.workingVersion.mapStyle.routePointStyles,
    [types.getters.SavingViewsGetLineStyles]: (state) =>
      state.activeView.workingVersion.mapStyle.lineStyles,
    [types.getters.SavingViewsGetPolygonStyles]: (state) =>
      state.activeView.workingVersion.mapStyle.polygonStyles,
    [types.getters.SavingViewsGetVisiblePointsIds]: (state) =>
      getVisibileFeaturesIds(
        state?.activeView?.workingVersion?.mapStyle?.routePointStyles
      ),
    [types.getters.SavingViewsGetVisibleLinesIds]: (state) =>
      getVisibileFeaturesIds(
        state?.activeView?.workingVersion?.mapStyle?.lineStyles
      ),
    [types.getters.SavingViewsGetVisiblePolygonsIds]: (state) =>
      getVisibileFeaturesIds(
        state?.activeView?.workingVersion?.mapStyle?.polygonStyles
      ),
    [types.getters.SavingViewGetVisiblePolygonResultsIdSet]: (state) => {
      const visibleResultIds = new Set();
      state?.activeView?.workingVersion?.polygonResultsState.styles?.forEach(
        (resultState) => {
          if (!resultState?.isVisible || !resultState?.assetId) return;
          visibleResultIds.add(resultState?.assetId);
        }
      );
      return visibleResultIds;
    },
    [types.getters.SavingViewGetHeatmapVisibility]: (state) => (caseID) => {
      const heatmapState =
        state?.activeView?.workingVersion?.heatmapsState.styles[caseID];
      return heatmapState?.isVisible || false;
    }
  },
  mutations: {
    [types.mutations.SavingViewsSetPolygonStyle](state, payload) {
      updateOrAddProjectFeatureStyle(
        state,
        payload,
        (payload) => ({
          assetId: payload.id,
          isVisible: true,
          fillColour: payload.fillColour,
          outlineColour: payload.outlineColour,
          opacity: payload.opacity
        }),
        "polygonStyles"
      );
    },
    [types.mutations.SavingViewsSetPolygonVisibility](state, payload) {
      updateOrAddProjectFeatureStyle(
        state,
        { id: payload },
        (payload, existingItem) => {
          return {
            ...existingItem,
            isVisible: !existingItem.isVisible
          };
        },
        "polygonStyles"
      );
    },

    [types.mutations.SavingViewsSetLinesStyle](state, payload) {
      updateOrAddProjectFeatureStyle(
        state,
        payload,
        (payload) => ({
          assetId: payload.id,
          isVisible: true,
          fillColour: payload.fillColour,
          outlineColour: payload.outlineColour,
          opacity: payload.opacity
        }),
        "lineStyles"
      );
    },
    [types.mutations.SavingViewsSetLinesVisibility](state, payload) {
      updateOrAddProjectFeatureStyle(
        state,
        { id: payload },
        (payload, existingItem) => {
          return {
            ...existingItem,
            isVisible: !existingItem.isVisible
          };
        },
        "lineStyles"
      );
    },
    [types.mutations.SavingViewsSetRoutePointStyle](state, payload) {
      updateOrAddProjectFeatureStyle(
        state,
        payload,
        (payload) => ({
          assetId: payload.id,
          isVisible: true,
          fillColour: payload.fillColour,
          outlineColour: payload.outlineColour,
          opacity: payload.opacity
        }),
        "routePointStyles"
      );
    },
    [types.mutations.SavingViewsSetRoutePointVisibility](state, payload) {
      updateOrAddProjectFeatureStyle(
        state,
        { id: payload },
        (payload, existingItem) => {
          return {
            ...existingItem,
            isVisible: !existingItem.isVisible
          };
        },
        "routePointStyles"
      );
    },
    [types.mutations.SavingViewsSetOptionsState](state, payload) {
      const newVersion = {
        activeId: payload.activeId ? payload.activeId : null,
        selected: payload.selected,
        styles: payload.styles
      };
      if (isEqual(state.activeView.workingVersion.optionsState, newVersion))
        return; // don't update if the same to prevent circular updates
      Vue.set(state.activeView.workingVersion, "optionsState", newVersion);
    },
    [types.mutations.SavingViewSetHeatmapVisibility](state, payload) {
      Vue.set(
        state?.activeView?.workingVersion?.heatmapsState?.styles,
        payload.caseID,
        {
          caseID: payload.caseID,
          isVisible: payload.isVisible
        }
      );
    },
    [types.mutations.SavingViewSetPolygonResultVisibility](state, payload) {
      const polygonId = payload.polygonResultId;
      const isVisible = payload.isVisible;
      const polygonStateIndex =
        state?.activeView?.workingVersion?.polygonResultsState.styles.findIndex(
          (polygonState) => polygonState.assetId === polygonId
        );
      if (polygonStateIndex !== -1) {
        state?.activeView?.workingVersion?.polygonResultsState.styles.splice(
          polygonStateIndex,
          1
        );
        console.log("Removed previous entry");
      }
      Vue.set(
        state?.activeView?.workingVersion?.polygonResultsState,
        "styles",
        [
          ...state?.activeView?.workingVersion?.polygonResultsState.styles,
          {
            assetId: polygonId,
            isVisible: isVisible,
            asset: "POLYGON_RESULT"
          }
        ]
      );
    },
    [types.mutations.SavingViewsSetActiveOption](state, optionId) {
      if (!state.activeView.workingVersion.optionsState) {
        Vue.set(state.activeView.workingVersion, "optionsState", {
          activeId: optionId
        });
        return;
      }
      Vue.set(
        state.activeView.workingVersion.optionsState,
        "activeId",
        optionId
      );
    },

    [types.mutations.SavingViewsSetViewPort]: (state, payload) => {
      Vue.set(
        state.activeView.workingVersion.mapStyle.viewPort,
        payload.name,
        payload.value
      );
    },
    [types.mutations.SavingViewsSetActiveView](state, payload) {
      const { name, id } = payload;

      const getState = (payload) => {
        const payloadObject = cloneDeep(payload);

        const threeD = payloadObject.mapStyle.threeD;
        const tempShowLayers = payloadObject.mapStyle.showLayers.reduce(
          (acc, { layer, show }) => {
            acc[layer] = show;
            return acc;
          },
          {}
        );
        const tempDatasetLayers =
          payloadObject?.mapStyle?.datasetLayerStyles?.reduce((acc, layer) => {
            acc.push({ ...layer, assetId: layer?.assetId?.toString() });
            return acc;
          }, []);

        const optionsStyles = payloadObject?.optionsState?.styles?.reduce(
          (acc, oS) => {
            acc.push({ ...oS, assetId: Number(oS?.assetId) });
            return acc;
          },
          []
        );

        const tempViewPort = {
          ...payloadObject.mapStyle.viewPort,
          lat: parseFloat(payloadObject?.mapStyle?.viewPort?.lat?.toFixed(6)),
          lng: parseFloat(payloadObject?.mapStyle?.viewPort?.lng?.toFixed(6)),
          zoomLevel: parseFloat(
            payloadObject?.mapStyle?.viewPort?.zoomLevel?.toFixed(6)
          ),
          pitch: parseFloat(
            payloadObject?.mapStyle?.viewPort?.pitch?.toFixed(6)
          ),
          bearing: parseFloat(
            payloadObject?.mapStyle?.viewPort?.bearing?.toFixed(6)
          )
        };
        const heatmapStyles = (payloadObject?.heatmapsState?.styles || []).map(
          (style) => {
            return [style.caseID, style];
          }
        );
        return {
          mapStyle: {
            ...payloadObject.mapStyle,
            viewPort: tempViewPort,
            showLayers: { ...tempShowLayers },
            datasetLayerStyles: tempDatasetLayers,
            threeD: {
              active: threeD.active,
              exaggerationFactor: threeD.exaggerationFactor
            }
          },
          polygonResultsState: {
            styles: (payloadObject?.polygonResultsState?.styles || []).map(
              (style) => {
                return {
                  ...style,
                  assetId: `${POLYGON_RESULT_ID_PREFIX}${style.assetId}`
                };
              }
            )
          },
          heatmapsState: {
            styles: Object.fromEntries(heatmapStyles)
          },
          optionsState: {
            ...payloadObject.optionsState,
            styles: optionsStyles,
            activeId: payloadObject?.optionsState?.activeId
              ? Number(payloadObject?.optionsState?.activeId)
              : payloadObject?.optionsState?.activeId
          }
        };
      };
      const newState = getState(payload);

      state.activeView = {
        savedVersion: cloneDeep(newState),
        workingVersion: cloneDeep(newState),
        id,
        name
      };

      const index = state.availableViews.findIndex((view) => view.id === id);
      Vue.set(state.availableViews, index, {
        name: payload.name,
        id: payload.id,
        createdAt: payload.createdAt,
        updatedAt: payload.updatedAt
      });

      state.savedVersionUpdatedAt = Date.now();
    },
    [types.mutations.SavingViewsRenameView]: (state, payload) => {
      // Find the view with the matching id
      const viewToUpdate = state.availableViews.find(
        (view) => view.id === payload.id
      );

      if (viewToUpdate) {
        // Update the name property of the matching view
        Vue.set(viewToUpdate, "name", payload.name);
      }

      // Update activeView name if the id matches
      if (state.activeView.id === payload.id) {
        Vue.set(state.activeView, "name", payload.name);
      }
    },
    [types.mutations.SavingViewsSetAvailableViews](state, payload) {
      state.availableViews = payload;
    },
    [types.mutations.SavingViewsRemoveAvailableView](state, payload) {
      state.availableViews = state.availableViews.filter(
        (item) => String(item.id) !== String(payload)
      );

      if (state.availableViews.length === 0) {
        Vue.set(state, "activeView", createViewObject({ name: "" }));
        state.savedVersionUpdatedAt = Date.now();
      }
    },
    [types.mutations.SavingViewsSetActiveViewName](state, payload) {
      state.activeView.name = payload;
    },
    [types.mutations.SavingViewsResetState](state) {
      Object.assign(state, newEmptyViewsState());
    },

    [types.mutations.SavingViewsSetGisPanelSwitch](state, payload) {
      Vue.set(state.activeView.workingVersion.mapStyle, "showLayers", {
        ...state.activeView.workingVersion.mapStyle.showLayers,
        [payload.name]: payload.value
      });
    },

    [types.mutations.SavingViewsSetGroupVectorLayersBy](state, payload) {
      state.activeView.workingVersion.mapStyle.groupBy = payload;
    },
    [types.mutations.SavingViewsSetBasemapStyle](state, payload) {
      Vue.set(
        state.activeView.workingVersion.mapStyle,
        "baseMapStyle",
        payload
      );
    },
    [types.mutations.SavingViewsSetIsThreeDimensional](state, payload) {
      state.activeView.workingVersion.mapStyle.threeD.active = payload;
    },
    [types.mutations.SavingViewsSetThreeDimensionalExaggerationFactor](
      state,
      payload
    ) {
      state.activeView.workingVersion.mapStyle.threeD.exaggerationFactor =
        payload;
    },
    [types.mutations.SavingViewsAddAvailableView](state, payload) {
      state.availableViews.push(payload);
    },
    [types.mutations.SavingViewsDiscardChanges](state) {
      //cloneDeep twice to ensure we have different objects between saved and working versions
      Vue.set(
        state.activeView,
        "workingVersion",
        cloneDeep(state.activeView.savedVersion)
      );
      Vue.set(
        state.activeView,
        "savedVersion",
        cloneDeep(state.activeView.savedVersion)
      );
      state.savedVersionUpdatedAt = Date.now();
    },
    [types.mutations.SavingViewsUpdateSavedVersion](
      state,
      { optionsState, mapStyle }
    ) {
      Vue.set(state.activeView, "savedVersion", { optionsState, mapStyle });
      state.savedVersionUpdatedAt = Date.now();
    },
    [types.mutations.SavingViewsSetActiveRasterLayer]: (state, payload) => {
      Vue.set(
        state.activeView.workingVersion.mapStyle,
        "activeRasterDatasetID",
        payload
      );
    }
  },
  actions: {
    [types.actions.SavingViewSetHeatmapVisibility]({ commit }, payload) {
      commit(types.mutations.SavingViewSetHeatmapVisibility, payload);
    },
    [types.actions.SavingViewSetPolygonResultVisibility]({ commit }, payload) {
      commit(types.mutations.SavingViewSetPolygonResultVisibility, payload);
    },
    [types.actions.SavingViewsSetActiveRasterLayer]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetActiveRasterLayer, payload);
    },
    [types.actions.SavingViewsSetPolygonVisibility]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetPolygonVisibility, payload);
    },
    [types.actions.SavingViewsSetPolygonStyle]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetPolygonStyle, payload);
    },
    [types.actions.SavingViewsSetLinesStyle]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetLinesStyle, payload);
    },
    [types.actions.SavingViewsSetLinesVisibility]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetLinesVisibility, payload);
    },
    [types.actions.SavingViewsSetRoutePointVisibility]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetRoutePointVisibility, payload);
    },
    [types.actions.SavingViewsSetRoutePointStyle]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetRoutePointStyle, payload);
    },
    [types.actions.SavingViewsSetViewPort]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetViewPort, payload);
    },
    [types.actions.SavingViewsUpdateSavedVersion]({ commit }, payload) {
      commit(types.mutations.SavingViewsUpdateSavedVersion, payload);
    },
    [types.actions.SavingViewsRemoveAvailableView]({ commit }, payload) {
      commit(types.mutations.SavingViewsRemoveAvailableView, payload);
    },
    [types.actions.SavingViewsDiscardChanges]({ commit }) {
      commit(types.mutations.SavingViewsDiscardChanges);
    },
    [types.actions.SavingViewsSetActiveView]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetActiveView, payload);
    },
    [types.actions.SavingViewsSetAvailableViews]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetAvailableViews, payload);
    },
    [types.actions.SavingViewsAddAvailableView]({ commit }, payload) {
      commit(types.mutations.SavingViewsAddAvailableView, payload);
    },
    [types.actions.SavingViewsSetAvailableViews]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetAvailableViews, payload);
    },
    [types.actions.SavingViewsSetActiveViewName]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetActiveViewName, payload);
    },
    [types.actions.SavingViewsResetState]({ commit }) {
      commit(types.mutations.SavingViewsResetState);
    },
    [types.actions.SavingViewsSetGisPanelSwitch]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetGisPanelSwitch, payload);
    },

    [types.actions.SavingViewsSetGroupVectorLayersBy]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetGroupVectorLayersBy, payload);
    },
    [types.actions.SavingViewsSetBasemapStyle]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetBasemapStyle, payload);
    },
    [types.actions.SavingViewsSetIsThreeDimensional]({ commit }, payload) {
      commit(types.mutations.SavingViewsSetIsThreeDimensional, payload);
    },
    [types.actions.SavingViewsSetThreeDimensionalExaggerationFactor](
      { commit },
      payload
    ) {
      commit(
        types.mutations.SavingViewsSetThreeDimensionalExaggerationFactor,
        payload
      );
    }
  }
};
