const getters = {
  AlertExists: "AlertExists",
  AlertGetMessage: "AlertGetMessage",
  AlertGetType: "AlertGetType"
};
const mutations = {
  AlertUpdate: "AlertUpdate"
};
const actions = {
  AlertUpdate: "AlertUpdate"
};

export { getters, mutations, actions };

export default { getters, mutations, actions };
