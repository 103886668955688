import gqlCreateRAG from "@/graphql/relativeRAG/gqlCreateRAG";
import gqlUpdateRAG from "@/graphql/relativeRAG/gqlUpdateRAG";
import gqlDeleteRAG from "@/graphql/relativeRAG/gqlDeleteRAG";
import gqlGetAllRAGs from "@/graphql/relativeRAG/gqlGetAllRAGs";
import gqlEvaluateRAG from "@/graphql/relativeRAG/gqlEvaluateRAG";
import { apolloClient } from "@/plugins/apolloPlugin";

// Encapsulates GQL enddpoints for Relative RAG CRUD and evaluation

export async function createRAG(payload) {
  try {
    const response = await apolloClient.mutate(gqlCreateRAG(payload));
    return {
      result: response?.data?.createRAG
    };
  } catch (error) {
    return {
      error: error
    };
  }
}

export async function updateRAG(payload) {
  try {
    const response = await apolloClient.mutate(gqlUpdateRAG(payload));
    return {
      result: response?.data?.updateRAG
    };
  } catch (error) {
    return {
      error: error
    };
  }
}

export async function deleteRAG(ragId) {
  try {
    const response = await apolloClient.mutate(gqlDeleteRAG(ragId));
    return {
      // Return the deleted Id from the response
      // {"data":{"deleteRAG":51}}
      result: response?.data?.deleteRAG
    };
  } catch (error) {
    return {
      error: error
    };
  }
}

export async function getAllRAGs( projectID) {
  try {
    const response = await apolloClient.query(gqlGetAllRAGs(projectID));
    return {
      result: response?.data?.rags
    };
  } catch (error) {
    return {
      error: error
    };
  }
}

export function createEvaluateRAGBoundsPayload(
  projectID,
  optionIds,
  metricNames
) {
  return {
    projectID: projectID,
    option_ids: optionIds,
    metric_names: metricNames
  };
}

export async function evaluateRAGBounds(payload) {
  try {
    const response = await apolloClient.query(gqlEvaluateRAG(payload));
    return {
      result: response?.data?.evaluateRAGBounds.bounds
    };
  } catch (error) {
    return {
      error: error
    };
  }
}
