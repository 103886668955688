<template>
  <v-row
    :data-cy="`${dataCy}-row`"
    class="pa-0 ma-0"
    align-content="center"
    justify="end"
  >
    <template>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :close-on-click="true"
        direction="bottom"
        bottom
        dense
        eager
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :color="colour"
            :style="
              !isWorkingVersionSaved
                ? `background-color: ${colourOpaque}; border-radius: 4px; height: 42px`
                : `border-radius: 4px; height: 42px`
            "
            :disabled="isWorkingVersionSaved"
            :data-cy="`${dataCy}-button`"
            class="pa-3 ma-0"
            outlined
            tile
            small
          >
            Save {{ viewType }}
            <v-icon class="pa-0 ma-0" light>{{ menuIcon }}</v-icon>
          </v-btn>
        </template>

        <v-list data-cy="saved-view-edited-menu-options-list" dense>
          <v-list-item-group color="primary">
            <!-- Save as new -->
            <CreateMapViewDialogue
              v-if="viewType === SavedViewTypes.MAP"
              :newViewMethod="SavingViewsNewViewMethod.NEW_FROM_EXISTING_VIEW"
            />

            <CreateMetricsViewDialogue
              v-else-if="viewType === SavedViewTypes.METRICS"
              :newViewMethod="SavingViewsNewViewMethod.NEW_FROM_EXISTING_VIEW"
            />

            <!-- Overwrite current -->
            <PermissionGuard :permission="VERB.WRITE">
              <template v-slot:default="permissionSlot">
                <v-tooltip
                  :disabled="!permissionSlot.hasAccess"
                  content-class="tooltip"
                  left
                >
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-list-item
                        @click="saveView"
                        :disabled="!selectedViewId || !permissionSlot.hasAccess"
                        data-cy="override-save-view-button"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-content-save-edit-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content
                          >Overwrite current view</v-list-item-content
                        >
                      </v-list-item>
                    </div>
                  </template>
                  <span v-if="!selectedViewId"
                    >You are not in an active view.</span
                  >
                </v-tooltip>
              </template>
            </PermissionGuard>

            <!-- Discard -->
            <v-list-item
              @click="discardChanges"
              data-cy="saved-view-discard-changes-button"
            >
              <v-list-item-icon>
                <v-icon>mdi-autorenew</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Discard changes</v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </template>
  </v-row>
</template>
<script>
import CreateMapViewDialogue from "@/components/savingView/CreateViewDialogue/CreateMapViewDialogue.vue";
import CreateMetricsViewDialogue from "@/components/savingView/CreateViewDialogue/CreateMetricsViewDialogue.vue";
import SavingViewsNewViewMethod from "@/constants/SavingViewsNewViewMethod";

import SavedViewTypes from "@/constants/SavedViewTypes";

import PermissionGuard from "@C/PermissionGuard.vue";

export default {
  name: "SavedViewEditedMenu",
  components: {
    CreateMapViewDialogue,
    CreateMetricsViewDialogue,
    PermissionGuard
  },
  data: () => ({
    menu: false,
    SavingViewsNewViewMethod,
    SavedViewTypes
  }),
  emits: ["discardChanges", "saveView"], 
  props: {
    selectedViewId: {
      type: [String, Number],
      default: ""
    },
    isWorkingVersionSaved: {
      type: Boolean,
      required: true
    },
    colour: {
      type: String,
      required: true
    },
    viewType: {
      type: String,
      required: true
    },

    dataCy: {
      type: String,
      required: true
    }
  },
  computed: {
    menuIcon() {
      return this.menu ? "mdi-menu-up" : "mdi-menu-down";
    },
    colourOpaque() {
      //Append a 15% opacity in the alpha channel. 26 is it's hex equivalent.
      return `${this.colour}26`;
    }
  },
  methods: {
    discardChanges() {
      this.$emit("discardChanges");
      this.menu = false;
    },
    saveView() {
      this.$emit("saveView");
      this.menu = false;
    }
  }
};
</script>
