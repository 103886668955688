<template>
  <v-dialog
    v-model="dialog"
    data-cy="deleteDialogModal"
    max-width="30%"
    transition="dialog-bottom-transition"
    overlay-opacity="0.70"
  >
    <template v-slot:activator="{ on: onModal }">
      <slot
        v-if="activatorType === 'customSlot'"
        :toggleDialog="openDeleteDialog"
      />

      <v-tooltip
        v-if="activatorType === 'buttonIcon'"
        :disabled="disabled && !disabledTooltipText"
        open-delay="300"
        content-class="tooltip"
        right
      >
        <template v-slot:activator="{ on: onTooltip }">
          <div v-on="{ ...onTooltip }">
            <v-btn
              v-on="{ ...onModal }"
              :disabled="disabled || !userCan(VERB.WRITE)"
              :data-cy="iconActivatorTag"
              icon
            >
              <v-icon
                :class="
                  activatorIconUseOutline
                    ? 'material-icons-outlined'
                    : 'material-icons'
                "
                medium
              >
                {{ actionString }}
              </v-icon>
            </v-btn>
          </div>
        </template>

        <span v-if="!disabled && userCan(VERB.WRITE)">{{ tooltipText }}</span>
        <span v-else> {{ disabledTooltipText }} </span>
      </v-tooltip>
      <v-list-item
        v-if="activatorType == `listItem`"
        v-on="onModal"
        :data-cy="listItemActivatorTag"
        :disabled="disabled"
      >
        <v-list-item-content
          :style="!disabled ? 'color: red' : 'opacity: 0.7'"
          >{{ capitalizedActionString }}</v-list-item-content
        >
      </v-list-item>

      <v-icon
        v-else-if="activatorType == 'rowItem'"
        v-on="onModal"
        :disabled="disabled"
        :data-cy="rowActivatorTag"
      >
        {{ actionString }}
      </v-icon>

      <v-btn
        v-else-if="activatorType == `button`"
        v-on="onModal"
        :disabled="!userCan(VERB.WRITE)"
        :data-cy="textButtonActivator"
        color="warning"
        text
      >
        {{ callToActionText }}
      </v-btn>
    </template>
    <v-card class="pa-3" style="background-color: white">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card-title class="primary--text title">
              <h4>{{ capitalizedActionString }}</h4>
              <v-btn @click="closeDialog" right absolute icon large>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="text--primary">
                {{ warningText }}
                <!-- Use normal display format if the displayName if we are not using bullet points -->
                <template v-if="!displayNameAsBullets">
                  <b>{{ displayName }}.</b>
                </template>
                <!-- Else use bullet points, must be an array -->
                <template
                  v-else-if="displayNameAsBullets && Array.isArray(displayName)"
                >
                  <ul>
                    <li
                      v-for="(name, index) in displayName"
                      :key="`deletion_item_${name}_${index}`"
                    >
                      {{ name }}
                    </li>
                  </ul>
                </template>
              </div>
              <div v-if="isHighImpact" class="text--primary">
                To confirm, please type its name in the box below
              </div>
              <div v-if="!isHighImpact" class="text--primary">
                Do you want to proceed?
              </div>
            </v-card-text>
            <v-text-field
              v-if="isHighImpact"
              v-model="confirmationInput"
              :label="`${capitalizedActionString} Name`"
              class="pa-4"
              data-cy="configurationNameTextField"
              outlined
              autofocus
            />

            <v-card-actions v-if="isHighImpact">
              <v-spacer />
              <v-btn
                @click="triggerDelete"
                :disabled="!(displayName === confirmationInput)"
                data-cy="buttonForSavingTheForm"
                plain
                outlined
              >
                {{ capitalizedActionString }}
              </v-btn>
              <v-btn
                @click="closeDialog"
                data-cy="buttonForCancellingTheForm"
                color="warning"
              >
                Cancel
              </v-btn>
            </v-card-actions>
            <v-card-actions v-if="!isHighImpact">
              <v-spacer />

              <v-btn
                @click="closeDialog"
                data-cy="buttonForCancellingTheForm"
                plain
                depressed
              >
                Cancel
              </v-btn>
              <v-btn
                @click="triggerDelete"
                data-cy="deleteDialogConfirmationButton"
                color="warning"
              >
                {{ capitalizedActionString }}
              </v-btn>
            </v-card-actions>
          </v-col></v-row
        ></v-container
      >
    </v-card>
    <DeletionSnackbar
      @deletionConfirmed="deletionConfirmed"
      @deletionCancelled="deletionCancelled"
      :showSnackbar="deleteTriggered"
      data-cy="deleteConfigSnackbar"
    >
      {{ confirmationText }}
    </DeletionSnackbar>
  </v-dialog>
</template>

<script>
import DeletionSnackbar from "@C/DeletionSnackbar.vue";
import { capitalize } from "lodash";

export default {
  name: "DeleteDialog",
  components: { DeletionSnackbar },
  emits: ["deletionCancelled", "deletionConfirmed", "deletionTriggered"], 
  props: {
    displayName: {
      required: true
    },
    displayNameAsBullets: {
      required: false,
      default: false
    },
    displayType: {
      required: true
    },
    isHighImpact: {
      required: false,
      type: Boolean,
      default: false
    },
    itemId: {
      required: true,
      type: [String, Array, Number]
    },
    activatorType: {
      type: String,
      validator: (value) => {
        console.log("validator value", value);
        return [
          "listItem",
          "button",
          "rowItem",
          "buttonIcon",
          "customSlot"
        ]?.includes(value);
      }
    },
    actionString: {
      type: String,
      default: "delete",
      required: false
    },
    tooltipText: {
      type: String,
      default: "Delete selected items"
    },
    disabledTooltipText: {
      type: String,
      required: false,
      default: null
    },
    disabled: { type: Boolean, default: false },
    activatorIconUseOutline: { type: Boolean, default: true, required: false },
    activatorTag: { type: String, required: false }
  },
  data() {
    return {
      dialog: false,
      deleteTriggered: false,
      confirmationInput: ""
    };
  },

  computed: {
    iconActivatorTag() {
      return this.activatorTag || "deleteDialogButtonIconActivator";
    },
    listItemActivatorTag() {
      return this.activatorTag || "deleteDialogListItemActivator";
    },
    rowActivatorTag() {
      return this.activatorTag || "deleteDialogRowItemActivator";
    },
    textButtonActivator() {
      return this.activatorTag || "deleteDialogTextButtonActivator";
    },
    capitalizedActionString() {
      return capitalize(this.actionString);
    },
    callToActionText() {
      return `${this.capitalizedActionString} ${this.displayType}`;
    },
    warningText() {
      return `You're about to permanently ${this.actionString} the ${this.displayType}:`;
    },
    confirmationText() {
      return `You have ${this.actionString}d a ${this.displayType}`;
    }
  },

  methods: {
    openDeleteDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    triggerDelete() {
      this.dialog = false;
      this.deleteTriggered = true;
      this.$emit("deletionTriggered");
    },
    deletionConfirmed() {
      this.deleteTriggered = false;
      this.$emit("deletionConfirmed", this.itemId);
    },
    deletionCancelled() {
      this.deleteTriggered = false;
      this.$emit("deletionCancelled", this.itemId);
    }
  }
};
</script>

<style></style>
