import { RoutePointTypes as types } from "@S/routePoints/types";

import LoadingStates from "@/constants/LoadingStates";
import { InteractionStates } from "@/constants/InteractionStates";
import LayersProperties from "@/constants/LayersProperties";
import store from "@S/index";
import { getters as savingViewGetters } from "@S/savingView/types";
import { getters as categoryGetters } from "@S/projectFeatureCategories/types";

import {
  getFeatureColour,
  getFeatureOpacity
} from "@S/helpers/featureGetterHelpers";

export class RoutePoint {
  id = null;
  name = null;
  categoryId = 0;
  categoryName = null;
  description = null;
  owner_nickname = null;
  owner_id = null;
  created = null;
  coords = null;
  loadingState = LoadingStates.INITIALISED;
  interactionState = InteractionStates.NORMAL;
  editMode = 0; //Store whether the point is currently being edited
  activeColour = "";
  activeOutlineColour = "";
  activeOpacity = "";

  toGeoJsonFeature() {
    return {
      type: "Feature",
      id: this.id,
      geometry: {
        type: "Point",
        coordinates: [this.coords.lng, this.coords.lat]
      },
      properties: {
        id: this.id,
        name: this.name,
        categoryId: this.categoryId,
        categoryName: this.categoryName,
        description: this.description,
        owner_nickname: this.owner_nickname,
        owner_id: this.owner_id,
        created: this.created,
        interactionState: this.interactionState,
        editMode: this.editMode,
        activeColour: this.activeColour,
        activeOutlineColour: this.activeOutlineColour,
        activeOpacity: this.activeOpacity
      }
    };
  }

  createGeoJsonForExport() {
    return {
      type: "Feature",
      id: this.id,
      geometry: {
        type: "Point",
        coordinates: [this.coords.lng, this.coords.lat]
      },
      properties: {
        id: this.id,
        name: this.name,
        description: this.description,
        owner_nickname: this.owner_nickname,
        owner_id: this.owner_id,
        created: this.created
      }
    };
  }

  constructor(
    id,
    _name,
    categoryId,
    categoryName,
    description,
    owner_nickname,
    owner_id,
    created,
    coords,
    loadingState,
    interactionState,
    editMode,
    activeColour,
    activeOutlineColour,
    activeOpacity
  ) {
    this.id = id;
    this.name = _name;
    this.categoryId = categoryId;
    this.categoryName = categoryName;
    this.description = description;
    this.owner_nickname = owner_nickname;
    this.owner_id = owner_id;
    this.created = created;
    this.coords = coords;
    this.loadingState = loadingState;
    this.interactionState = interactionState;
    this.editMode = editMode;
    this.activeColour = activeColour;
    this.activeOutlineColour = activeOutlineColour;
    this.activeOpacity = activeOpacity;
  }
}

export default {
  state: {
    routePoints: {}
    // id: identifier,
    // name: null,
    // categoryId: 0
    // description: null,
    // owner_nickname: null,
    // owner_id: null,
    // created: new Date(0),
    // coords: null,
    // loadingState: LoadingStates.INITIALISED,
    // interactionState: InteractionStates.NORMAL,
    // editMode: 0
  },

  getters: {
    [types.getters.RoutePointsAreLoading](state) {
      return Object.values(state.routePoints).some((routePoint) => {
        const isLoading =
          routePoint.loadingState === LoadingStates.INITIALISED ||
          routePoint.loadingState === LoadingStates.LOADING;

        if (isLoading) {
          return true;
        }
      });
    },

    [types.getters.RoutePointGetName]: (state) => (id) => {
      return state.routePoints[id]?.name;
    },
    [types.getters.AllRoutePointSets](state, getters) {
      const routePointIds = Object.keys(state.routePoints);

      if (routePointIds.length == 0) {
        return [];
      }

      const routePointSets = [];

      routePointIds.forEach((routePointId) => {
        routePointSets.push(
          getters[types.getters.RoutePointGetSet](routePointId)
        );
      });

      return routePointSets;
    },

    [types.getters.RoutePointGetSet]: (state) => (id) => {
      if (!state.routePoints?.[id]) {
        return null;
      }

      const routePointStyle = store.getters[
        savingViewGetters.SavingViewsGetRoutePointStyles
      ].find((style) => style.assetId == id);

      const category = store.getters[categoryGetters.GetCategoryById](
        state.routePoints[id]?.categoryId
      );

      const primaryColour = getFeatureColour(
        routePointStyle?.fillColour,
        category?.primaryColour,
        LayersProperties.POINTS_DEFAULT_FILL_COLOUR
      );

      const secondaryColour = getFeatureColour(
        routePointStyle?.outlineColour,
        category?.secondaryColour,
        LayersProperties.POINTS_DEFAULT_OUTLINE_COLOUR
      );

      const opacity = getFeatureOpacity(
        routePointStyle?.opacity,
        category?.masterOpacityPercentage,
        LayersProperties.POINTS_DEFAULT_OPACITY
      );

      return new RoutePoint(
        state.routePoints[id]?.id,
        state.routePoints[id]?.name,
        state.routePoints[id]?.categoryId,
        category?.name || null,
        state.routePoints[id]?.description,
        state.routePoints[id]?.owner_nickname,
        state.routePoints[id]?.owner_id,
        state.routePoints[id]?.created,
        state.routePoints[id]?.coords,
        state.routePoints[id]?.loadingState,
        state.routePoints[id]?.interactionState,
        state.routePoints[id]?.editMode,
        primaryColour,
        secondaryColour,
        opacity
      );
    },
    [types.getters.GetAllRoutePointsAsArray](state) {
      return Object.values(state.routePoints);
    }
  },

  mutations: {
    [types.mutations.RoutePointUpdate]: (state, payload) => {
      Vue.set(state.routePoints[payload.id], "name", payload.name);
      Vue.set(state.routePoints[payload.id], "categoryId", payload.categoryID);
      Vue.set(
        state.routePoints[payload.id],
        "description",
        payload.description
      );

      Vue.set(
        state.routePoints[payload.id],
        "owner_nickname",
        payload.owner_nickname
      );
      Vue.set(
        state.routePoints[payload.id],
        "created",
        new Date(payload.created)
      );
      Vue.set(state.routePoints[payload.id], "coords", payload.coords);
    },
    [types.mutations.RoutePointSetLoadingState]: (state, payload) => {
      Vue.set(state.routePoints[payload.id], "loadingState", payload.state);
    },
    [types.mutations.RoutePointSetInteractionState]: (state, payload) => {
      Vue.set(
        state.routePoints[payload.id],
        "interactionState",
        payload.interactionState
      );
    },
    [types.mutations.RoutePointSetEditMode]: (state, payload) => {
      Vue.set(state.routePoints[payload.id], "editMode", payload.mode);
    },
    [types.mutations.RoutePointDelete]: (state, id) => {
      Vue.delete(state.routePoints, id);
    },
    [types.mutations.RoutePointResetState]: (state) => {
      Vue.set(state, "routePoints", {});
    },
    [types.mutations.RoutePointAddRoutePoint]: (state, payload) => {
      //convert categoryID property to categoryId
      const formatted = { ...payload, categoryId: payload.categoryID };
      delete formatted.categoryID;

      Vue.set(state.routePoints, payload.id, formatted);
    }
  },

  actions: {
    [types.actions.RoutePointUpdate]: ({ commit }, payload) => {
      commit(types.mutations.RoutePointSetLoadingState, {
        id: payload.id,
        state: LoadingStates.LOADED
      });
      commit(types.mutations.RoutePointUpdate, payload);
    },
    [types.actions.RoutePointDelete]: ({ commit }, payload) => {
      commit(types.mutations.RoutePointDelete, payload);
    },
    [types.actions.RoutePointUpdateLoadingState]({ commit }, payload) {
      commit(types.mutations.RoutePointSetLoadingState, {
        id: payload.id,
        state: payload.state
      });
    },

    [types.actions.RoutePointSetLoadingState]({ commit }, payload) {
      commit(types.mutations.RoutePointSetLoadingState, payload);
    },

    [types.actions.RoutePointSetInteractionState]({ commit }, payload) {
      commit(types.mutations.RoutePointSetInteractionState, payload);
    },
    [types.actions.RoutePointSetEditMode]({ commit }, payload) {
      commit(types.mutations.RoutePointSetEditMode, payload);
    },
    [types.actions.RoutePointResetState]({ commit }) {
      commit(types.mutations.RoutePointResetState);
    },
    [types.actions.RoutePointAddRoutePoint]({ commit }, payload) {
      commit(types.mutations.RoutePointAddRoutePoint, payload);
    }
  }
};
