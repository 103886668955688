import VueRouter from "vue-router";

import store from "@/store/index";

import * as sessionModuleTypes from "@/store/session/types";

import { authGuard } from "@/plugins/authPlugin";
import { routes as routeConsts } from "@/constants/routes";
import { getFeatureFlag } from "@/hooks/feature-flag-hook";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    props: true,
    component: () =>
      import(/* webpackChunkName: "projectshome" */ "@/pages/Home.vue")
  },
  {
    path: "/" + routeConsts.portfolio,
    name: routeConsts.portfolio,
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "portfolio" */ "@/components/projectHome/PortfolioLayout"
      ),
    beforeEnter: async (to, from, next) => {
      authGuard(to, from, next);
      const portfoliosActive = await getFeatureFlag("PORTFOLIOS");

      if (portfoliosActive) {
        next();
      } else {
        next({ name: "home" });
      }
    },
    children: [
      {
        path: routeConsts.portfolioMap,
        name: routeConsts.portfolioMap,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "portfolio" */ "@/components/projectHome/portfolioMap/PortfolioMapPage"
          )
      },
      {
        path: routeConsts.portfolioDashboard,
        name: routeConsts.portfolioDashboard,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "portfolio" */ "@/components/projectHome/dashboard/PortfolioDashboard"
          )
      }
    ]
  },
  {
    path: "/project/:projectId",
    name: "project",
    props: true,
    component: () =>
      import(/* webpackChunkName: "project" */ "@/pages/project/Project.vue"),
    beforeEnter: (to, from, next) => {
      authGuard(to, from, next);
      store.commit(
        sessionModuleTypes.mutations.SessionSelectProjectById,
        to.params.projectId
      );
      const activeProjectId =
        store.getters[sessionModuleTypes.getters.SessionGetActiveProjectId];
      if (activeProjectId === 0) {
        next({ name: "home" });
      } else {
        next();
      }
    },
    children: [
      {
        path: "",
        name: routeConsts.projectDetails,
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "projectshome" */
            "@/pages/project/ProjectDetails.vue"
          )
      },

      {
        path: routeConsts.parameters,
        name: routeConsts.parameters,
        props: { default: true, sidebar: true },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "parameters" */
              "@/pages/project/model/ParameterPage.vue"
            ),
          sidebar: () =>
            import(
              /* webpackChunkName: "parameterssidebar" */
              "@/components/configuration/ParametersSidebar.vue"
            )
        }
      },
      {
        path: routeConsts.data,
        name: routeConsts.data,
        props: { default: true, sidebar: true, quickview: true },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "mappage" */
              "@/pages/project/map/MapPage.vue"
            ),
          sidebar: () =>
            import(
              /* webpackChunkName: "datasidebar" */
              "@/components/data/DataSideBar.vue"
            ),
          quickview: () =>
            import(
              /* webpackChunkName: "dataeditspace" */
              "@/components/data/DataEditSpace.vue"
            )
        }
      },
      {
        path: routeConsts.setup,
        name: routeConsts.setup,
        props: { default: true, sidebar: true, quickview: true },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "mappage" */
              "@/pages/project/map/MapPage.vue"
            ),
          sidebar: () =>
            import(
              /* webpackChunkName: "setupsidebar" */
              "@/components/setup/SetupSideBar.vue"
            ),
          quickview: () =>
            import(
              /* webpackChunkName: "setupcomponent" */
              "@/components/setup/CaseSetupWizard.vue"
            )
        }
      },
      {
        path: `${routeConsts.analyze}/:optionIds?`,
        name: routeConsts.analyze,
        props: { default: true, sidebar: true, quickview: true },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "mappage" */
              "@/pages/project/map/MapPage.vue"
            ),
          sidebar: () =>
            import(
              /* webpackChunkName: "analysesidebar" */
              "@/components/analysis/AnalyseSideBar.vue"
            ),
          quickview: () =>
            import(
              /* webpackChunkName: "analyseeditspace" */
              "@/components/analysis/AnalyseEditSpace.vue"
            )
        }
      },
      {
        path: `${routeConsts.mapExport}`,
        name: routeConsts.mapExport,
        props: { default: true, sidebar: true },
        components: {
          default: () =>
            import(
              /* webpackChunkName: "mappage" */
              "@/pages/project/map/MapPage.vue"
            ),
          sidebar: () =>
            import(
              /* webpackChunkName: "analysesidebar" */
              "@/components/analysis/AnalyseSideBar.vue"
            ),
          quickview: () =>
            import(
              /* webpackChunkName: "mapreporteditspace" */
              "@/components/map/mapReport/MapReportEditSpace.vue"
            )
        }
      }
    ]
  },
  { path: "*", redirect: "/home" }
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition;
      // For anchors
    } else if (to.hash) {
      return { selector: to.hash };
      // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    } else if (from.path === to.path) {
      return {};
    }

    // Scroll to top
    return { x: 0, y: 0 };
  }
});

const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    console.log(err);
  });
};

const originalReplace = router.replace;
router.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => {
    console.log(err);
  });
};

router.onError((error) => {
  console.error(error);
  Vue?.prototype?.$log?.error("Failure Reason: ", error.message, error);
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    Vue?.prototype?.$log?.error("Reloading Window 1");
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    Vue?.prototype?.$log?.error("Reloading Window 2");
    window.location.reload();
  }
});

router.beforeEach((to, from, next) => {
  const path = to.path;
  const routeConstsValues = Object.values(routeConsts);
  // Check if the path does not end with a slash and is not the root path and does not end with any of the routeConsts values
  if (
    !path.endsWith("/") &&
    path !== "/" &&
    !routeConstsValues.some((value) => path.includes(value))
  ) {
    next({ path: `${path}/`, query: to.query });
    return;
  }

  next();
});

export default router;
