import { Config } from "./plugins/config-importer";
import { makeServer } from "../mirage/server";
import posthog from "posthog-js";
import { usingCognito } from "@/plugins/authHelpers";

if (
  process.env.NODE_ENV === "development" &&
  Config.host === "api.optioneer.local"
) {
  makeServer();
}

import vuetify from "./plugins/vuetify";
import App from "./App.vue";

// Component Plugins
import Fragment from "vue-fragment";
import PortalVue, { Wormhole } from "portal-vue";
Wormhole.trackInstances = false;

import draggable from "vuedraggable";

// Functional Plugins
import { AuthPlugin } from "./plugins/authPlugin";
import router from "./router";

const domain = window.config.DOMAIN;
const clientId = window.config.CLIENT_ID;

Vue.use(PortalVue);
Vue.use(AuthPlugin, {
  domain,
  clientId,
  onRedirectCallback: (appState) => {
    router
      .push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      )
      .catch((error) => {
        console.log(error);
      });
  }
});

Vue.config.errorHandler = function (err, vm, info) {
  console.warn(
    `Error in Vue component: ${vm?.$options?._componentTag} \n It happened while processing: ${info} \n Error Details:`,
    err,
    vm
  );
};

import "@/scss/main.scss";
import "@/scss/z-index.scss";
import "mapbox-gl/dist/mapbox-gl.css";

import permissions from "@/mixins/permissions";
Vue.mixin(permissions);

Vue.config.productionTip = false;

if (process.env.NODE_ENV === "development") {
  Vue.config.devtools = true;
} else {
  Vue.config.devtools = false;
}

Vue.use(Fragment.Plugin);
Vue.component("draggable", draggable);

// Call after auth plugin
import "./plugins/axios";

// Store last so dependencies get built
import store from "./store";

// Sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  Vue: Vue,
  dsn: "https://b86f2be98b184d12a8796f085c074d1c@o268087.ingest.sentry.io/5507286",
  environment: window.config.VUE_APP_ENVIRONMENT,
  release: process.env.VUE_APP_SENTRY_RELEASE,
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["localhost", window.config.VUE_APP_API_HOSTNAME, /^\//],
      beforeNavigate: (context) => {
        return {
          ...context,
          name: location.pathname.replace(/case\/[a-zA-Z0-9]{7}/g, "/<case_id>")
        };
      }
    })
  ],
  trackComponents: true,
  tracesSampleRate: 0.05
});

import panZoom from "vue-panzoom";
Vue.use(panZoom);

import Vue from "vue";

import { instantiateApolloClient } from "@/plugins/apolloPlugin";
instantiateApolloClient();

const posthogHost = usingCognito() ? "https://us.i.posthog.com" : "https://eu.i.posthog.com"; 
posthog.init(
  window.config?.POSTHOG_PROD_ACCESS_KEY,
  { api_host:posthogHost }
);

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");

if (window.Cypress) {
  // only available during E2E tests
  window.app = app;
}
