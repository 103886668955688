import { LAYERS } from "@/behaviours/map/features/feature-names";
import { actions, mutations, getters } from "./types";
import SetupFeatureTypes from "@/constants/SetupFeatureTypes";
import SetupRoutePointTypes from "@/constants/SetupRoutePointTypes";

export default {
  state: {
    selectedCases: [],
    entities: {},
    setupPoints: [],
    horizontalProfilePoints: [],
    clickOutsideProjectBoundries: false,
    moveOutsideProjectBoundries: false,
    selectedMapPoint: {},
    selectedMapFeature: {},
    editSpaceCollapsed: false,
    // DATA PAGE
    currentDataRoutePoint: {},
    currentHorizontalProfilePoints: [],
    pointLocationData: {},
    areRoutePointLabelsVisible: LAYERS.ROUTE_POINTS_LABELS?.onByDefault,
    areLineLabelsVisible: LAYERS.LINE_LABELS?.onByDefault,
    arePolygonLabelsVisible: LAYERS.POLYGON_LABELS?.onByDefault,
    mapBeingExported: false,
    mapExportScaleBar: { width: 0, text: "" },
    mapExportViewportGeojson: "",
    googleMaps3DTilesAttribution: new Set()
  },
  getters: {
    [getters.MapGetPointLocationData](state) {
      return state.pointLocationData;
    },
    [getters.MapGetEditSpaceCollapsed](state) {
      return state.editSpaceCollapsed;
    },
    [getters.MapGetSelectedCases](state) {
      return state.selectedCases;
    },

    [getters.MapGetClickOutsideProjectBoundries](state) {
      return state.clickOutsideProjectBoundries;
    },
    [getters.MapGetMoveOutsideProjectBoundries](state) {
      return state.moveOutsideProjectBoundries;
    },

    [getters.MapGetSetupPointByName]: (state) => (pointName) => {
      return state.setupPoints?.find((point) => point?.name == pointName);
    },
    [getters.MapGetSetupPointById]: (state) => (id) => {
      return state.setupPoints?.find((point) => point?.id === id);
    },

    [getters.MapGetSetupPoints]: (state) => {
      const pointsToBeAddAsLayer = [];

      state.setupPoints?.forEach((point) => {
        const waypoint = {
          type: "Feature",
          properties: { id: point?.lng + "_" + point?.lat, type: point?.type },
          id: point?.name,
          geometry: {
            type: "Point",
            coordinates: [point?.lng, point?.lat]
          }
        };

        pointsToBeAddAsLayer.push(waypoint);
      });

      const featuresCollection = {
        type: "FeatureCollection",
        features: pointsToBeAddAsLayer
      };
      return featuresCollection;
    },
    [getters.MapGetSetupPointsAsArray]: (state) => {
      return state.setupPoints;
    },
    [getters.MapGetEntities](state) {
      return state.entities;
    },

    [getters.MapGetSelectedMapPoint](state) {
      return state.selectedMapPoint;
    },

    [getters.AreRoutePointLabelsVisible](state) {
      return state.areRoutePointLabelsVisible;
    },
    [getters.AreLineLabelsVisible](state) {
      return state.areLineLabelsVisible;
    },
    [getters.ArePolygonLabelsVisible](state) {
      return state.arePolygonLabelsVisible;
    },
    [getters.IsMapBeingExported](state) {
      return state.mapBeingExported;
    },
    [getters.GetExportMapScaleBar](state) {
      return state.mapExportScaleBar;
    },
    [getters.GetMapExportViewportGeojson](state) {
      return state.mapExportViewportGeojson;
    },
    [getters.GetGoogleMaps3DTilesAttribution](state) {
      return state.googleMaps3DTilesAttribution;
    }
  },
  mutations: {
    [mutations.MapSetPointLocationData](state, payload) {
      state.pointLocationData = payload;
    },
    [mutations.MapSetEditSpaceCollapsed]: (state, payload) => {
      state.editSpaceCollapsed = payload;
    },
    [mutations.MapSetSelectedCases]: (state, payload) => {
      state.selectedCases = payload;
    },
    [mutations.MapSetClickOutsideProjectBoundries]: (state, payload) => {
      state.clickOutsideProjectBoundries = payload;
    },
    [mutations.MapSetMoveOutsideProjectBoundries]: (state, payload) => {
      state.moveOutsideProjectBoundries = payload;
    },
    [mutations.MapCreateSetupPoint]: (state, payload) => {
      //If we are in GENERATION mode:
      if (payload.featureType === SetupFeatureTypes.POINT) {
        // Point already exists so just return
        if (state.setupPoints?.some((_point) => _point?.id == payload?.id)) {
          return;
        }

        //If there is more than 1 setup point, and there is not an existing point which is a startpont
        if (
          state.setupPoints.length > 1 &&
          !state.setupPoints?.find(
            (_point) => _point.type === SetupRoutePointTypes.STARTPOINT
          )
        ) {
          //Then add the point this point to the start of the array
          state.setupPoints = [payload, ...state.setupPoints];
        } else {
          //otherwise add it to the end of the array
          state.setupPoints = [...state.setupPoints, payload];
        }

        //Loop over all points. Make the first index a start pont, and the last index an endpoint. In-between are waypoints.
        state.setupPoints?.map((element, index) => {
          if (index === 0) {
            element.type = SetupRoutePointTypes.STARTPOINT;
          } else if (index === state.setupPoints.length - 1) {
            element.type = SetupRoutePointTypes.ENDPOINT;
          } else {
            element.type = SetupRoutePointTypes.WAYPOINT;
          }
        });
      } else if (
        payload.featureType === SetupFeatureTypes.LINE ||
        payload.featureType == SetupFeatureTypes.POLYGON
      ) {
        //If there is already an element in the array with the same parentGeometryId && id
        if (
          state.setupPoints?.some(
            (_point) =>
              _point?.parentGeometryId === payload?.parentGeometryId &&
              _point.id === payload?.id
          )
        ) {
          // Line already added so just return
          return;
        }
        state.setupPoints = [...state.setupPoints, payload];
      }
    },
    [mutations.MapUpdateSetupPoint]: (state, payload) => {
      const indexOfPointToBeEdited = state.setupPoints?.findIndex(
        (point) => point?.name === payload?.name
      );

      const pointToBeEdited = state.setupPoints[indexOfPointToBeEdited];

      pointToBeEdited.elevation = payload?.elevation;
      pointToBeEdited.lat = payload?.lat;
      pointToBeEdited.lng = payload?.lng;
      pointToBeEdited.id = payload?.id;

      const newSetupPointsArray = [...state.setupPoints];

      newSetupPointsArray[indexOfPointToBeEdited] = pointToBeEdited;

      state.setupPoints = newSetupPointsArray;
    },
    [mutations.MapUpdateSetupPointArrayByDragging]: (state, payload) => {
      payload
        .filter((waypoint) => waypoint.featureType === SetupFeatureTypes.POINT)
        .forEach((element, index) => {
          if (index === 0) {
            element.type = SetupRoutePointTypes.STARTPOINT;
          } else if (index === payload.length - 1) {
            element.type = SetupRoutePointTypes.ENDPOINT;
          } else {
            element.type = SetupRoutePointTypes.WAYPOINT;
          }
        });

      state.setupPoints.forEach((element) => {
        if (element.type === "point") {
          payload.push(element);
        }
      });

      state.setupPoints = payload;
    },
    [mutations.MapUpdateSetupPointType]: (state, payload) => {
      const indexOfPointToBeEdited = state.setupPoints?.findIndex(
        (point) => point?.id === payload?.id
      );

      const pointToBeEdited = state.setupPoints[indexOfPointToBeEdited];

      pointToBeEdited.type = payload?.type;

      const newSetupPointsArray = [...state?.setupPoints];

      newSetupPointsArray[indexOfPointToBeEdited] = pointToBeEdited;

      if (pointToBeEdited.type === SetupRoutePointTypes.STARTPOINT) {
        newSetupPointsArray?.splice(indexOfPointToBeEdited, 1);
        newSetupPointsArray?.unshift(pointToBeEdited);
      } else if (pointToBeEdited.type === SetupRoutePointTypes.ENDPOINT) {
        newSetupPointsArray.push(
          newSetupPointsArray?.splice(indexOfPointToBeEdited, 1)[0]
        );
      } else {
        //find endpoint and move it to the end of the array
        const endpointIndex = state.setupPoints?.findIndex(
          (point) => point.type === SetupRoutePointTypes.ENDPOINT
        );
        newSetupPointsArray.push(
          newSetupPointsArray?.splice(endpointIndex, 1)[0]
        );
      }

      state.setupPoints = newSetupPointsArray;
    },
    [mutations.MapRemoveSetupPointById]: (state, id) => {
      const currentPoints = state?.setupPoints?.filter(
        (point) => point?.id != id
      );
      currentPoints
        ?.filter((waypoint) => waypoint.featureType === SetupFeatureTypes.POINT)
        .forEach((point, index, arr) => {
          if (index === 0) {
            point.type = SetupRoutePointTypes.STARTPOINT;
          } else if (index === arr?.length - 1) {
            point.type = SetupRoutePointTypes.ENDPOINT;
          } else {
            point.type = SetupRoutePointTypes.WAYPOINT;
          }
        });
      state.setupPoints = currentPoints;
    },
    [mutations.MapRemoveHorizontalProfilePoints]: (
      state,
      horizontalProfileId
    ) => {
      const currentPoints = state?.setupPoints?.filter(
        (point) => point.parentGeometryId != horizontalProfileId
      );

      state.setupPoints = currentPoints;
    },
    [mutations.ClearSetupPoints]: (state) => {
      state.setupPoints = [];
    },
    [mutations.MapAddEntity]: (state, payload) => {
      state.entities = { ...state.entities, [payload?.id]: payload };
    },
    [mutations.MapUpdateEntity]: (state, payload) => {
      if (state.entities[payload?.id]) {
        state.entities = { ...state.entities, [payload?.id]: payload };
      }
    },
    [mutations.MapDeleteEntity]: (state, payload) => {
      delete state.entities[payload];
    },
    [mutations.MapUpdateSelectedMapPoint]: (state, payload) => {
      state.selectedMapPoint = payload;
    },
    [mutations.MapUpdateSelectedMapFeature]: (state, payload) => {
      state.selectedMapFeature = payload;
    },
    [mutations.SetRoutePointLabelsVis]: (state, payload) => {
      state.areRoutePointLabelsVisible = payload;
    },
    [mutations.SetLineLabelsVis]: (state, payload) => {
      state.areLineLabelsVisible = payload;
    },
    [mutations.SetPolygonLabelsVis]: (state, payload) => {
      state.arePolygonLabelsVisible = payload;
    },
    [mutations.SetMapBeingExported]: (state, payload) => {
      state.mapBeingExported = payload;
    },
    [mutations.SetMapExportScaleBar]: (state, payload) => {
      state.mapExportScaleBar = { width: payload?.width, text: payload?.text };
    },
    [mutations.SetMapExportViewportGeojson]: (state, payload) => {
      state.mapExportViewportGeojson = payload;
    },
    [mutations.SetGoogleMaps3DTilesAttribution]: (state, payload) => {
      state.googleMaps3DTilesAttribution = payload;
    }
  },
  actions: {
    [actions.MapSetPointLocationData]: ({ commit }, payload) => {
      commit(mutations.MapSetPointLocationData, payload);
    },
    [actions.MapSetEditSpaceCollapsed]: ({ commit }, payload) => {
      commit(mutations.MapSetEditSpaceCollapsed, payload);
    },
    [actions.MapSetSelectedCases]: ({ commit }, payload) => {
      commit(mutations.MapSetSelectedCases, payload);
    },
    [actions.MapSetClickOutsideProjectBoundries]: ({ commit }, payload) => {
      commit(mutations.MapSetClickOutsideProjectBoundries, payload);
    },
    [actions.MapSetMoveOutsideProjectBoundries]: ({ commit }, payload) => {
      commit(mutations.MapSetMoveOutsideProjectBoundries, payload);
    },
    [actions.MapAddSetupPoint]: ({ commit }, payload) => {
      commit(mutations.MapCreateSetupPoint, payload);
    },
    [actions.MapUpdateSetupPoint]: ({ commit }, payload) => {
      commit(mutations.MapUpdateSetupPoint, payload);
    },
    [actions.MapUpdateSetupPointType]: ({ commit }, payload) => {
      commit(mutations.MapUpdateSetupPointType, payload);
    },
    [actions.MapUpdateSetupPointArrayByDragging]: ({ commit }, payload) => {
      commit(mutations.MapUpdateSetupPointArrayByDragging, payload);
    },
    [actions.MapRemoveSetupPointById]: ({ commit }, payload) => {
      commit(mutations.MapRemoveSetupPointById, payload);
    },
    [actions.MapRemoveHorizontalProfilePoints]: ({ commit }, payload) => {
      commit(mutations.MapRemoveHorizontalProfilePoints, payload);
    },
    [actions.ClearSetupPoints]: ({ commit }) => {
      commit(mutations.ClearSetupPoints);
    },
    [actions.MapAddDrawnObject]: ({ commit }, payload) => {
      commit(mutations.MapAddEntity, payload);
    },
    [actions.MapUpdateDrawnObject]: ({ commit }, payload) => {
      commit(mutations.MapUpdateEntity, payload);
    },
    [actions.MapDeleteDrawnObjectById]: ({ commit }, payload) => {
      commit(mutations.MapDeleteEntity, payload);
    },
    [actions.MapUpdateSelectedMapPoint]: ({ commit }, payload) => {
      commit(mutations.MapUpdateSelectedMapPoint, payload);
    },
    [actions.MapUpdateSelectedMapFeature]: ({ commit }, payload) => {
      commit(mutations.MapUpdateSelectedMapFeature, payload);
    },
    [actions.SetGoogleMaps3DTilesAttribution]: ({ commit }, payload) => {
      commit(mutations.SetGoogleMaps3DTilesAttribution, payload);
    }
  }
};
