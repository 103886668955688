<template>
  <div
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    class="recent-views-container pa-0 ma-0 ml-3 align-center"
  >
    <div
      :class="{
        'recent-views-title-content-colour': !hover,
        'recent-views-title-content-hovered-colour': hover
      }"
      class="recent-views-title-content"
    >
      RecentViews
    </div>
    <div class="clickable rectangle-border rectangle"></div>
    <div class="clickable rectangle-border rectangle middle-rectangle"></div>
    <div class="clickable rectangle-border rectangle"></div>
  </div>
</template>

<script>
export default {
  name: "RecentViews",
  data() {
    return {
      hover: false
    };
  }
};
</script>

<style scoped lang="scss">
.recent-views-title-content {
  font-size: 10px;
  position: absolute;
  margin-top: -38px;
}

.recent-views-title-content-colour {
  color: var(--ciLightGray);
}

.recent-views-title-content-hovered-colour {
  color: #e69f00;
}
.recent-views-container {
  width: 200px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.rectangle {
  width: 60px;
  height: 34px;
  margin-top: 14px;
  border-radius: 8px;
}

.rectangle-border {
  border: solid var(--ciLightGray) 1px !important;
}

.rectangle-border:hover {
  border: solid #e69f00 1px !important;
}

.middle-rectangle {
  margin-left: 8px;
  margin-right: 8px;
}
</style>
