// Changing these keys will cause layouts to be "forgotten" on user devices
export const dashboardWidgetMappingKey = "portfolioWidgetMapping";
export const localStorageLayoutKey = "portfolioLayouts";
export const selectedDeliverableProp = "selectedDeliverableName";
export const groupingFieldProp = "groupingField";
export const TooltipSplittingFieldProp = "TooltipSplittingFieldProp";
export const widgetNameProp = "widgetName";
export const datasetNameProp = "datasetName";
export const widgetDatasetFiltersKey = "widgetDatasetFiltersKey";

export const groupingKeys = {
  areaNameKey: "areaNameKey",
  projectNameKey: "projectNameKey",
  milestoneNameKey: "milestoneNameKey",
  milestoneStatusKey: "milestoneStatusKey",
  assetTypesKey: "assetTypesKey"
};
export const chartGroupingOptions = [
  { text: "Project name", value: groupingKeys.projectNameKey },
  { text: "Project area", value: groupingKeys.areaNameKey },
  { text: "Milestone name", value: groupingKeys.milestoneNameKey },
  { text: "Milestone status", value: groupingKeys.milestoneStatusKey },
  { text: "Asset types", value: groupingKeys.assetTypesKey }
];

export const widgetTypeKeys = {
  DeliverablePieChart: {
    key: "DeliverablePieChart",
    text: "Deliverable pie chart"
  },
  DeliverableBarChart: {
    key: "DeliverableBarChart",
    text: "Deliverable bar chart"
  },
  ProjectCountPieChart: {
    key: "ProjectCountPieChart",
    text: "Project pie"
  },
  ProjectSummariesTable: {
    key: "ProjectSummariesTable",
    text: "Project summary table"
  },
  ProjectCountStatsCard: {
    key: "ProjectCountStatsCard",
    text: "Number of projects stat card"
  },
  StatsCardKey: { key: "StatsCardKey" },
  BlankWidget: { key: "BlankWidget" }
};

export const breakpointNames = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl"
};
