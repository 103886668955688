import { actions, mutations, getters } from "@/store/setupPage/types";

import SetupModes from "@/constants/SetupModes";

export default {
  state: {
    setupMode: SetupModes.NONE
  },
  getters: {
    [getters.SetupGetSetupMode](state) {
      return state.setupMode;
    }
  },
  mutations: {
    [mutations.SetupEnableSiteSelectionMode]: (state) => {
      state.setupMode = SetupModes.SITE_SELECTION;
    },
    [mutations.SetupEnableCorridorGenerationMode]: (state) => {
      state.setupMode = SetupModes.CORRIDOR_GENERATION;
    },
    [mutations.SetupEnableGenerationMode]: (state) => {
      state.setupMode = SetupModes.GENERATION;
    },
    [mutations.SetupEnableEvaluationMode]: (state) => {
      state.setupMode = SetupModes.EVALUATION;
    },
    [mutations.SetupResetMode]: (state) => {
      state.setupMode = SetupModes.NONE;
    }
  },
  actions: {
    [actions.SetupEnableSiteSelectionMode]: ({ commit }) => {
      commit(mutations.SetupEnableSiteSelectionMode);
    },
    [actions.SetupEnableCorridorGenerationMode]: ({ commit }) => {
      commit(mutations.SetupEnableCorridorGenerationMode);
    },
    [actions.SetupEnableGenerationMode]: ({ commit }) => {
      commit(mutations.SetupEnableGenerationMode);
    },
    [actions.SetupEnableEvaluationMode]: ({ commit }) => {
      commit(mutations.SetupEnableEvaluationMode);
    },
    [actions.SetupResetMode]: ({ commit }) => {
      commit(mutations.SetupResetMode);
    }
  }
};
