import Options from "@/constants/Options";

function getCaseSummaries(
  GetSummariesOfSelectedOptions,
  CaseGetName,
  CaseGetDateCreated
) {
  const caseIdsToNames = new Map();
  return GetSummariesOfSelectedOptions.reduce((sortedCases, op) => {
    const id = op.caseId;
    if (!id || caseIdsToNames.has(id)) return sortedCases;

    const caseName = CaseGetName(id) || "";
    const dateCreated = new Date(CaseGetDateCreated(id));
    caseIdsToNames.set(id, caseName);

    return [...sortedCases, { caseName, dateCreated, id }];
  }, []);
}

function sortCases(
  sortedCases,
  GetOptionDateSort,
  GetOptionsAreGroupedByCase,
  GetOptionSort
) {
  const sortField = GetOptionSort?.sortField;
  const isDateSort =
    GetOptionDateSort || (GetOptionsAreGroupedByCase && sortField);

  return sortedCases.sort((a, b) => {
    if (isDateSort) return b.dateCreated - a.dateCreated;
    return b.caseName?.localeCompare(a.caseName);
  });
}

function reverseIfDescending(sortedCases, GetOptionSort) {
  if (GetOptionSort.direction === Options.sorters.directions.descending) {
    return [...sortedCases].reverse();
  }
  return sortedCases;
}

function attachCaseOptions(sortedCases, GetSummariesOfSelectedOptions) {
  const alphabetField = Options.sorters.sortFields.alphabetical.field;
  return sortedCases.map((caseSummary) => {
    const caseOptions = GetSummariesOfSelectedOptions.filter(
      (op) => op?.caseId === caseSummary?.id
    );
    caseOptions.sort((a, b) =>
      a[alphabetField]?.localeCompare(b[alphabetField])
    );

    return { ...caseSummary, caseOptions };
  });
}

function sortedCases(props) {
  const {
    GetSummariesOfSelectedOptions,
    CaseGetName,
    CaseGetDateCreated,
    GetOptionsAreGroupedByCase,
    GetOptionDateSort,
    GetOptionSort
  } = props;

  let sortedCases = getCaseSummaries(
    GetSummariesOfSelectedOptions,
    CaseGetName,
    CaseGetDateCreated
  );
  sortedCases = sortCases(
    sortedCases,
    GetOptionDateSort,
    GetOptionsAreGroupedByCase,
    GetOptionSort
  );
  sortedCases = reverseIfDescending(sortedCases, GetOptionSort);
  return attachCaseOptions(sortedCases, GetSummariesOfSelectedOptions);
}

export default sortedCases;
