import { extend } from "lodash";

function setCategories(state, configId, componentSettings) {
  if (!componentSettings) {
    return;
  }

  if (componentSettings?.design_rules?.length > 0) {
    // categoriesForConfig contains the titles and descriptions for each category. Each
    // category also have a `values` attribute which contain the titles and descriptions
    // for each value. In the future this will come straight from the backend
    const categoriesForConfig = {};

    componentSettings?.design_rules.forEach((element) => {
      element.required_geodata.sort();
      element.required_by.sort();
      element.requires.sort();
      // Each design rule will have a "simpler" mapping of category names to value names
      element.categoriesToValuesMapping = {};

      for (const [categoryName, categoryData] of Object.entries(
        element.categories
      )) {
        if (!(categoryName in categoriesForConfig)) {
          categoriesForConfig[categoryName] = categoryData;
        }
        extend(
          categoriesForConfig[categoryName]["values"],
          categoryData["values"]
        );

        const categoryValues = [];
        for (const key in categoryData["values"]) {
          categoryValues.push(key);
        }
        element.categoriesToValuesMapping[categoryName] = categoryValues;
      }
    });
    Vue.set(
      state.configs[configId],
      "categoriesForConfig",
      categoriesForConfig
    );
  }
}

export { setCategories };
