<template>
  <div>
    <CreateSavedViewActivator
      @openDialog="openDialog"
      :newViewMethod="newViewMethod"
      :viewType="viewType"
    />

    <v-dialog
      v-model="dialog"
      max-width="30%"
      transition="dialog-bottom-transition"
      overlay-opacity="0.70"
    >
      <v-card
        class="pa-3"
        style="background-color: white"
        data-cy="createSavedViewDialog"
      >
        <v-form v-model="isValid" @submit.prevent>
          <v-card-title class="primary--text title">
            <h4>Create {{ viewType }} View</h4>
            <v-btn
              @click="closeDialog"
              data-cy="closeDialogButton"
              right
              absolute
              icon
              large
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-text-field
            v-model="savedViewName"
            :rules="savedViewNameValidationRules"
            class="pa-4"
            data-cy="newViewNameTextField"
            label="View Name"
            hint="Please enter the new name"
            outlined
            filled
            autofocus
          />

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              @click="closeDialog"
              data-cy="buttonForCancellingTheForm"
              plain
              depressed
              >Cancel</v-btn
            >
            <v-btn
              @click="save"
              :disabled="!isValid"
              :loading="saving"
              data-cy="buttonForSavingTheForm"
              color="primary"
            >
              Create {{ viewType }} View
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SavingViewsNewViewMethod from "@/constants/SavingViewsNewViewMethod";
import CreateSavedViewActivator from "@C/savingView/CreateViewDialogue/CreateSavedViewActivator.vue";

export default {
  name: "CreateSavedViewDialogue",
  data() {
    return {
      savedViewName: "",
      isValid: false,
      dialog: false,
      SavingViewsNewViewMethod
    };
  },
  components: {
    CreateSavedViewActivator
  },
  emits: ["save"], 
  props: {
    newViewMethod: {
      type: String,
      required: true
    },
    viewType: {
      type: String,
      required: true
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    savedViewNameValidationRules() {
      return [
        (v) => !!v || "Name is required",
        (v) => v.length <= 50 || "Name must be less than 50 characters"
      ];
    }
  },
  methods: {
    openDialog() {
      this.savedViewName = "Unnamed view";
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    save() {
      this.$emit("save", this.savedViewName);
      this.closeDialog();
    }
  }
};
</script>

<style></style>
