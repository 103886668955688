<template>
  <v-container class="fill-height" fluid pa-0>
    <v-row class="fill-height">
      <v-col
        :style="{ backgroundColor: primaryThemeColor }"
        class="d-flex align-center justify-center fill-height leftColumn"
      >
        <ContinuumLogoWhite />
      </v-col>

      <v-col class="d-flex align-center justify-center fill-height rightColumn">
        <v-col>
          <v-row lign="center" justify="center">
            <LogoOptioneer />
          </v-row>
          <v-row align="center" justify="center">
            <LoginButton />
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LogoOptioneer from "@/components/logos/LogoOptioneer.vue";
import ContinuumLogoWhite from "@/components/logos/ContinuumLogoWhite.vue";
import LoginButton from "@/components/auth/LoginButton.vue";

export default {
  name: "Login",

  components: { ContinuumLogoWhite, LogoOptioneer, LoginButton },
  computed: {
    primaryThemeColor() {
      return this.$vuetify.theme.defaults.light.primaryVariant;
    }
  }
};
</script>

<style scoped>
.rightColumn {
  background-color: white;
}
</style>
