export const truncate = (text, stop, clamp) => {
  if (stop === 0) {
    // if stop is 0, don't truncate the string
    return text ?? "";
  }
  if (typeof text == "number") {
    text = text.toString();
  }

  clamp = clamp == null || clamp == undefined ? "..." : clamp;

  return text?.slice(0, stop) + (stop < text?.length ? clamp : "") ?? "";
};
