export default {
  POINT: "Point",
  POLYGON: "Polygon",
  LINE_STRING: "LineString"
};

export const categoryFeatureTypes = {
  POINT: "POINT",
  POLYGON: "POLYGON",
  LINE: "LINE",
  COMMENT: "COMMENT"
};

export const newCategoryIdentifier = "newCategoryIdentifier";
