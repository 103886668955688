/**
 * Base Types is used to provide validation on checks for Vuex type definition classes that extend it.
 * @abstract
 */
export class BaseTypes {
  /**
   * Static bool to check if init has been called before fetching any types.
   * @type {Boolean} _intialised
   * @private
   */
  static _intialised;

  /**
   * @static
   * @private
   */
  static _getters;

  static get getters() {
    this.init();
    return this._getters;
  }

  static set getters(val) {
    this._getters = val;
  }

  /**
   * @static
   * @private
   */
  static _mutations;

  static get mutations() {
    this.init();
    return this._mutations;
  }

  static set mutations(val) {
    this._mutations = val;
  }

  /**
   * @static
   * @private
   * */
  static _actions;

  static get actions() {
    this.init();
    return this._actions;
  }

  static set actions(val) {
    this._actions = val;
  }

  /**
   * @summary Assigns a proxy getter handler to throw a ReferenceError when trying to access a non-existant member.
   * @private
   */
  static typeValidationHandler(type, base) {
    return {
      get: (target, name, receiver) => {
        const rv = Reflect.get(target, name, receiver);
        if (["string", "symbol"].includes(typeof rv)) {
          return rv;
        }
        // throw new ReferenceError(
        //   `Non existant ${type} "${name}" in ${this.name} ${base} class called`
        // );
        console.warn(
          `Non existant ${type} "${name}" in ${this.name} ${base} class called`
        );
      }
    };
  }

  /**
   * @summary Initialises all the proxy handlers for the type validation.
   * @private
   */
  static init() {
    if (this._intialised) {
      return;
    }
    this._intialised = true;
    this._getters = new Proxy(
      this._getters,
      this.typeValidationHandler("getter", this.name)
    );
    this.mutations = new Proxy(
      this.mutations,
      this.typeValidationHandler("mutation", this.name)
    );
    this._actions = new Proxy(
      this._actions,
      this.typeValidationHandler("action", this.name)
    );
  }
}
