const names = {
  None: "None",
  RasterLegend: "RasterLegend",
  HeatmapSliders: "HeatmapSliders",
  CommentsControl: "CommentsControl",
  PointTooltipPanel: "PointTooltipPanel"
};

// MapLegendsNames const to reference the map legends within the template
export const MapLegendsNamesMixin = {
  data() {
    return {
      mapLegendsNames: names
    };
  }
};

export default Object.freeze(names);
