<template>
  <v-app-bar
    v-if="isAuthenticated && !isFullScreen"
    :color="isScopingProject ? 'secondary' : 'primary'"
    :key="SessionGetActiveProjectId"
    :height="appbarHeight"
    data-cy="header"
    class="header side-panel-z-index"
    clipped-left
    app
    fixed
    flat
  >
    <v-tabs
      v-model="tabValue"
      :color="isScopingProject ? 'primary' : 'secondary'"
      :dark="isScopingProject ? false : true"
      :hide-slider="isScopingProject ? false : true"
      :key="projectStage"
      data-cy="headerTabs"
      optional
    >
      <v-tab
        :to="{ name: 'home' }"
        data-cy="headerTabHome"
        class="customHomeTab tab text-none"
        eager
        exact
      >
        <LogoOptioneerWhite :isScopingProject="isScopingProject" />
      </v-tab>

      <MainNavBarProjectItems
        v-if="!authLoading && SessionHasActiveProject"
        @projectStageValue="setProjectStage"
        :key="`main-nav-bar-project-items-${SessionGetActiveProjectId}`"
        :projectId="SessionGetActiveProjectId"
      />
      <v-spacer />
      <Alert class="ma-0" />

      <span v-if="isScopingProject" class="scopingVersionText"
        >scoping version</span
      >
      <v-row v-if="SessionGetActiveProjectId" class="ma-0 mr-4 pa-0">
        <SaveMapViewMenu class="mr-2" />
        <SelectMapView
          class="d-flex align-center"
          dataCy="map-saved-view-name-and-menu-container"
        />
        <RecentViews v-if="false" />
      </v-row>

      <v-card
        max-height="46"
        class="navBarIconAndButtonCard rounded-bl-xl"
        color="white"
        elevation="4"
        tile
      >
        <FeatureFlag
          :projectId="SessionGetActiveProjectId"
          featureFlag="LET_IT_SNOW"
        >
          <LetItSnow />
        </FeatureFlag>
        <FeatureFlag
          :projectId="SessionGetActiveProjectId"
          featureFlag="BIRTHDAY"
        >
          <ConfettiCannon />
        </FeatureFlag>
        <v-tooltip bottom content-class="tooltip">
          <template v-slot:activator="{ on }">
            <v-hover>
              <v-btn color="primary" icon>
                <v-icon @click="goToHelpDocumentation" v-on="on">help</v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span data-cy="navBarhelpIconButton">Read documentation</span>
        </v-tooltip>
        <LowBandwidthMode />
        <v-tooltip bottom content-class="tooltip">
          <template v-slot:activator="{ on }">
            <v-hover>
              <v-btn data-cy="intercomHelp" color="primary" icon>
                <v-icon v-on="on">feedback</v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span data-cy="navBarFeedbackIconButton">Leave us some feedback</span>
        </v-tooltip>
        <DropDownMenu :projectId="SessionGetActiveProjectId" />
      </v-card>
    </v-tabs>
    <portal-target name="linearLoader" />
  </v-app-bar>
</template>

<script>
import RecentViews from "@/components/savingView/views/RecentViews.vue";
import SelectMapView from "@/components/savingView/SelectView/SelectMapView.vue";
import MainNavBarProjectItems from "@/components/MainNavBarProjectItems.vue";
import DropDownMenu from "@/components/userManagement/DropDownMenu.vue";
import LogoOptioneerWhite from "@/components/logos/LogoOptioneerWhite.vue";
import { mapGetters, mapActions } from "vuex";
import { getters, actions } from "@/store/session/types";
import ProjectStages from "@/constants/ProjectStages";
import LetItSnow from "@C/snowstorm.vue";
import FeatureFlag from "@/components/FeatureFlag.vue";
import SaveMapViewMenu from "@C/savingView/SaveViewMenu/SaveMapViewMenu.vue";
import ConfettiCannon from "@/components/ConfettiCannon.vue";
import LowBandwidthMode from "@/components/LowBandwidthMode.vue";
import { appbarHeight } from "@/constants/LayoutConsts";
import Alert from "@/components/Alert.vue";
import fullscreenMixin from "@/mixins/fullscreenMixin";
import { authState } from "@/plugins/authPlugin";

export default {
  name: "MainNavBar",
  components: {
    MainNavBarProjectItems,
    DropDownMenu,
    LogoOptioneerWhite,
    LetItSnow,
    SelectMapView,
    RecentViews,
    FeatureFlag,
    ConfettiCannon,
    SaveMapViewMenu,
    LowBandwidthMode,
    Alert
  },
  mixins: [fullscreenMixin],
  data() {
    return {
      tabValue: window.location.href,
      projectStage: ProjectStages.OPTION_SELECTION,
      appbarHeight
    };
  },

  computed: {
    ...mapGetters([
      getters.SessionGetActiveProjectId,
      getters.SessionHasActiveProject
    ]),
    isAuthenticated() {
      return authState?.value?.isAuthenticated;
    },
    authLoading() {
      return authState?.value?.loading;
    },
    isScopingProject() {
      return this.projectStage == ProjectStages.SCOPING;
    }
  },
  methods: {
    ...mapActions([actions.SessionSelectProjectById]),
    goToHelpDocumentation() {
      window.open("http://help.optioneer.ai/en", "_blank");
    },
    setProjectStage(stage) {
      this.projectStage = stage;
    }
  }
};
</script>

<style lang="scss">
.v-toolbar__content {
  padding-right: 0px !important;
}

.headerIcon {
  width: 24px;
  height: 24px;
}

.v-application .white {
  border-bottom: 0px !important;
}

.header {
  width: 100%;

  .scopingVersionText {
    margin-right: 24px;
    align-self: center;
    color: white;
    font-style: italic;
  }

  .v-tabs {
    width: auto;
  }

  .tabs {
    font-size: 14px !important;
  }

  .v-tab {
    min-width: auto !important;
    margin-left: 0;
    margin-right: 0px;
    padding-right: 13px;
    padding-left: 13px;
  }

  .customHomeTab.v-tab {
    min-width: 56px;
    padding-right: 19px;
    padding-left: 19px;
  }

  .navBarIconAndButtonCard {
    display: flex;
    align-items: center;
  }
}
</style>
