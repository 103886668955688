import { createDataForVerticalChart3dDataLine } from "@/../worker-api";

import VerticalProfileChartTypes from "@/constants/VerticalProfileChartTypes";

import { format, select, axisBottom, axisLeft, line, area, min, max } from "d3";
import { ELEVATION_PLOT_COLOUR } from "@C/analysis/charts/MapChart/mapChartConsts";
import { plotDiscreteItems } from "@C/analysis/charts/MapChart/PlotDiscreteItems";

function performSvgCleanup(svg, chartType, compositionSubselection, isIndex) {
  /** Perform SVG cleanup */
  svg.selectAll(".line-comparison1").attr("d", "");
  svg.selectAll(".line-comparison2").attr("d", "");
  svg.selectAll(".point-comparison1").selectAll("circle").remove();
  svg.selectAll(".point-comparison2").selectAll("circle").remove();
  svg.selectAll(".rightAxisTitle").selectAll("text").remove();

  svg.selectAll(".discrete-item").remove();
  if (
    chartType !== VerticalProfileChartTypes?.composition?.id ||
    compositionSubselection.length < 1
  ) {
    svg.selectAll(".layer").remove();
  }
  if (chartType === VerticalProfileChartTypes?.composition?.id) {
    svg.selectAll(".selected3dOption").attr("d", "");
    svg.selectAll(".selection-option-point").remove();
  }
  if (chartType !== VerticalProfileChartTypes?.vProfile?.id) {
    if (!isIndex) {
      svg.select(".area-ground-level").attr("d", ""); //Remove Elevation
    }
    svg.select(".selected3dOption").html("");
  }
}

/**
 * @param {SVGElement} svg
 * @param {Array} groundLevel
 * @param {Function} colours
 * @param {String} chartType
 * @param {Array<Number>} chainage
 * @param {Function} xScale
 * @param {Function} elevationScale
 * @param {Array<Number>} dimensions
 */

function plotElevation(svg, chartData, xScale, elevationScale, dimensions) {
  /** Plot area - ground level */
  svg
    .selectAll(".area-ground-level")
    .data([chartData])
    .join("path")
    .attr("class", "area-ground-level")
    .attr("fill-opacity", "0.2")
    .attr("fill", ELEVATION_PLOT_COLOUR)
    .attr("stroke", "none")
    .attr(
      "d",
      area()
        .x((d) => {
          return xScale(d?.chainage);
        })
        .y0((d) => elevationScale(d?.ground_level))
        .y1(dimensions[0])
    );
}

async function plot3dDataLine(svg, chartData, xScale, elevationScale) {
  const newline = line()
    .defined((d) => !isNaN(d["centerline_elevation"]))
    .x((d) => xScale(d["chainage"]))
    .y((d) => elevationScale(d["centerline_elevation"]));

  const newData = await createDataForVerticalChart3dDataLine(chartData);
  svg
    .selectAll(".selected3dOption")
    .join("path")
    .attr("class", "selected3dOption")
    .data([null])
    .join("g")
    .attr("class", "selected3dOption")
    .selectAll("path")
    .data(newData)
    .join("path")
    .attr("class", `vertical-profile-point`)
    .attr("fill", "none")
    .attr("stroke", "#000")
    .attr("stroke-width", 2)
    .attr("d", (segement) => newline(segement));
}

/**
 * Construct and call X-Axis
 */
function constructAxisX(svg, chartType, xScale, dimensions) {
  const xAxis = axisBottom(xScale).tickSizeOuter(0);
  if (chartType === VerticalProfileChartTypes.composition.id)
    xAxis
      .tickValues(
        xScale
          .domain()
          .filter((_, i) => !(i % Math.floor(xScale.domain().length / 7)))
      )
      .tickFormat((d) =>
        Math.floor(d).toLocaleString(undefined, {
          maximumFractionDigits: 2
        })
      );

  svg
    .selectAll(".x-axis")
    .data([null])
    .join("g")
    .attr("class", "x-axis")
    .attr("transform", `translate(0, ${dimensions[0]})`)
    .call(xAxis);
}

/**
 * Construct and call Y-Axis
 */
function constructAxisY(svg, scale, dimensions) {
  const yAxis = axisLeft(scale).tickFormat((d) => {
    const minValue = min(scale.domain());
    const maxValue = max(scale.domain());
    if (Math.abs(minValue - maxValue) > 1000) {
      return format(".1e")(d);
    } else {
      return Math.floor(d).toLocaleString(undefined, {
        maximumFractionDigits: 2
      });
    }
  });

  const strokeWidth = scale.domain()[0] < 0 ? 1 : 0;
  svg
    .selectAll(".ground-level-group")
    .data([null])
    .join("g")
    .attr("class", "ground-level-group")
    .selectAll("line")
    .data([null])
    .join("line")
    .attr("x1", 0)
    .attr("y1", scale(0))
    .attr("x2", dimensions[1])
    .attr("y2", scale(0))
    .attr("stroke-width", strokeWidth)
    .attr("stroke", "#333333");

  svg
    .selectAll(".y-axis")
    .data([null])
    .join("g")
    .attr("class", "y-axis")
    .call(yAxis);
}

/**
 * Generate titles
 */

export const addXAxisTitle = ({ svg, dimensions, svgMargin }) => {
  // Bottom axis title
  const chainageText = "Chainage";
  svg
    .selectAll(".bottomAxisTitle")
    .data([null])
    .join("g")
    .attr("class", "bottomAxisTitle")
    .attr(
      "transform",
      `translate(${dimensions[1] / 2 + svgMargin.left}, ${
        +dimensions[0] + svgMargin.bottom + 10
      })`
    )
    .selectAll("text")
    .data([null])
    .join("text")
    .attr("text-anchor", "middle")
    .attr("text-rendering", "optimizeLegibility")
    .attr("font-weight", 700)
    .attr("font-size", 15)
    .text(chainageText);
};
async function addYAxisTitle({ svg, dimensions, yAxisLabel, svgMargin }) {
  svg
    .selectAll(".leftAxisTitle")
    .data([null])
    .join("g")
    .attr("class", "leftAxisTitle")
    .attr(
      "transform",
      `rotate(-90) translate(-${dimensions[0] / 2 + svgMargin.top / 2}, ${20})`
    )
    .selectAll("text")
    .data([null])
    .join("text")
    .attr("fill", ELEVATION_PLOT_COLOUR)
    .attr("text-anchor", "middle")
    .attr("text-rendering", "optimizeLegibility")
    .attr("font-weight", 700)
    .attr("font-size", 15)
    .text(yAxisLabel);
}

/**
 * Generate and create top axis
 */
function constructTopAxis(svg, scale, chartType) {
  const topAxis = axisBottom(scale).tickSizeOuter(0);
  if (chartType === VerticalProfileChartTypes?.composition?.id) {
    topAxis
      .tickValues(scale.domain().filter((_, i) => !(i % 3)))
      .tickFormat((d) =>
        Math.floor(d).toLocaleString(undefined, {
          maximumFractionDigits: 2
        })
      );
  }
  svg
    .selectAll(".top-axis")
    .data([null])
    .join("g")
    .attr("class", "top-axis")
    .call(topAxis);
  svg.selectAll(".top-axis").selectAll(".domain").remove();
  svg.selectAll(".top-axis").selectAll("line").attr("stroke", "steelblue");
  svg.selectAll(".top-axis").selectAll("text").attr("color", "steelblue");
}

function plotStacks(svg, isIndex, layers, stackColour, xScale, yScale) {
  if (isIndex) {
    return;
  }

  svg
    .selectAll(".layergroup")
    .data([null])
    .join("g")
    .attr("class", "layergroup")
    .selectAll(".layer")
    .data(layers)
    .join("g")
    .attr("class", "layer")
    .attr("fill", (layer) => {
      return stackColour(layer["key"]);
    })
    .attr("stroke-width", 0)
    .selectAll("rect")
    .data((layer) => {
      return layer;
    })
    .join("rect")
    .attr("class", "stack-rect")
    .attr("x", (seq) => {
      return xScale(seq?.data?.chainage);
    })
    .attr("cursor", "pointer")
    .attr("width", xScale?.bandwidth())
    .attr("height", (seq) => {
      const height = yScale(seq[0]) - yScale(seq[1]);
      if (height >= 0) {
        return height;
      } else {
        return yScale(seq[1]) - yScale(seq[0]);
      }
    })
    .attr("y", (seq) => {
      const height = yScale(seq[0]) - yScale(seq[1]);
      if (height >= 0) {
        return yScale(seq[1]);
      } else {
        return yScale(seq[0]);
      }
    });
}

function callDataTooltip(
  hideMarker,
  svg,
  selector,
  isIndex,
  setTooltipActivator
) {
  svg
    .selectAll(selector)
    .on("mouseover", (e) => {
      /** Calling toltip */
      if (!isIndex) {
        if (setTooltipActivator) {
          setTooltipActivator(e);
        }
        if (e.target.classList[0] != "vertical-profile-point") {
          select(e.target).attr("stroke", "#000000").attr("stroke-width", "2");
        }
      }
    })
    .on("mouseout", (e) => {
      if (!isIndex) {
        if (e?.target?.classList[0] != "vertical-profile-point") {
          select(e.target).attr("stroke", "none").attr("stroke-width", "0");
        }
      }
      hideMarker();
    });
}

function convertSpanMetricsToPerPointQuantities(spanMetrics, length) {
  const unpacked = [];
  spanMetrics.forEach((metric) => {
    const ppqValues = new Array(length).fill(0);
    metric.values.forEach((value) => {
      for (let i = value.start; i < value.start + value.length; i++) {
        ppqValues[i] = value.value;
      }
    });
    unpacked.push({
      name: metric.name,
      values: ppqValues,
      unit: metric.unit
    });
  });
  return unpacked;
}

export {
  performSvgCleanup,
  constructAxisX,
  constructAxisY,
  constructTopAxis,
  addYAxisTitle,
  plotElevation,
  plot3dDataLine,
  plotDiscreteItems,
  plotStacks,
  callDataTooltip,
  convertSpanMetricsToPerPointQuantities
};
