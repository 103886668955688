<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="150"
    height="150"
    viewBox="0 0 713.2 130"
  >
    <defs>
      <clipPath id="a">
        <rect width="713.2" height="130" fill="#fff" />
      </clipPath>
    </defs>
    <g clip-path="url(#a)">
      <path
        d="M60,25H20A20.059,20.059,0,0,0,0,45V85a20.059,20.059,0,0,0,20,20H60A20.059,20.059,0,0,0,80,85V45A20.059,20.059,0,0,0,60,25ZM55,85H25a4.951,4.951,0,0,1-5-5V50a4.951,4.951,0,0,1,5-5H55a4.951,4.951,0,0,1,5,5V80A4.951,4.951,0,0,1,55,85Z"
        fill="#fff"
      />
      <path d="M263.2,25h-20v80h20Z" fill="#fff" />
      <path
        d="M263.2,0h-20V20h20Z"
        :fill="isScopingProject ? 'var(--ciRed)' : 'var(--ciLightBlue)'"
      />
      <path
        d="M341.2,25h-40a20.059,20.059,0,0,0-20,20V85a20.059,20.059,0,0,0,20,20h40a20.059,20.059,0,0,0,20-20V45A19.994,19.994,0,0,0,341.2,25Zm-5,60h-30a4.951,4.951,0,0,1-5-5V50a4.951,4.951,0,0,1,5-5h30a4.951,4.951,0,0,1,5,5V80A4.951,4.951,0,0,1,336.2,85Z"
        fill="#fff"
      />
      <path
        d="M673.2,25a20.059,20.059,0,0,0-20,20v60h20V50a4.951,4.951,0,0,1,5-5h35V25Z"
        fill="#fff"
      />
      <path
        d="M528.5,25h-40a20.059,20.059,0,0,0-20,20V85a20.059,20.059,0,0,0,20,20h60V85h-55a4.951,4.951,0,0,1-5-5V78.3h40a20.059,20.059,0,0,0,20-20V45A19.994,19.994,0,0,0,528.5,25Zm0,28.3a4.951,4.951,0,0,1-5,5h-35V50a4.951,4.951,0,0,1,5-5h30a4.951,4.951,0,0,1,5,5v3.3Z"
        fill="#fff"
      />
      <path
        d="M620.3,25h-40a20.059,20.059,0,0,0-20,20V85a20.059,20.059,0,0,0,20,20h60V85h-55a4.951,4.951,0,0,1-5-5V78.3h40a20.059,20.059,0,0,0,20-20V45A19.994,19.994,0,0,0,620.3,25Zm0,28.3a4.951,4.951,0,0,1-5,5h-35V50a4.951,4.951,0,0,1,5-5h30a4.951,4.951,0,0,1,5,5v3.3Z"
        fill="#fff"
      />
      <path
        d="M435.2,25h-40a20.059,20.059,0,0,0-20,20v60h20V50a4.951,4.951,0,0,1,5-5h30a4.951,4.951,0,0,1,5,5v55h20V45A19.994,19.994,0,0,0,435.2,25Z"
        fill="#fff"
      />
      <path
        d="M152.9,25H113.1A19.888,19.888,0,0,0,93.2,44.9V130h19.9V104.5h39.8a19.888,19.888,0,0,0,19.9-19.9V44.9A19.888,19.888,0,0,0,152.9,25Zm0,54.7a5.015,5.015,0,0,1-5,5H118.1a5.015,5.015,0,0,1-5-5V49.8a5.015,5.015,0,0,1,5-5h29.8a5.015,5.015,0,0,1,5,5Z"
        fill="#fff"
      />
      <path
        d="M205.8,25V0h-20V85a20.059,20.059,0,0,0,20,20h20V85h-15a4.951,4.951,0,0,1-5-5V45h20V25Z"
        fill="#fff"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "LogoOptioneerWhite",
  props: ["isScopingProject"]
};
</script>
