import { actions, mutations, getters } from "./types";
import { _axios } from "@/plugins/axios";

export default {
  state: {
    collections: []
  },
  getters: {
    [getters.CollectionGetCollections](state) {
      return state.collections;
    }
  },
  mutations: {
    [mutations.CollectionSetCollections](state, payload) {
      state.collections = payload;
    }
  },
  actions: {
    [actions.CollectionFetchCollections]: async ({ commit }, payload) => {
      try {
        const collectionsResponse = await _axios.get(
          `V2/collections/${payload.projectId}`
        );

        const collections = collectionsResponse.data.collections;
        commit(mutations.CollectionSetCollections, collections);
      } catch (error) {
        console.error("Error fetching collections: ", error);
      }
    }
  }
};
