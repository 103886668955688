<template>
  <div>
    <v-btn
      v-if="value === null"
      @click="initSort"
      :data-cy="dataCy"
      style="opacity: 0.6"
      icon
    >
      <v-icon :small="small"> {{ icon }} </v-icon>
    </v-btn>
    <v-btn
      v-else
      @click="toggle"
      :class="{ rotate: value }"
      :style="activeColour ? `color: ${activeColour}` : ''"
      :data-cy="dataCy"
      class="toggleUpDown"
      icon
    >
      <v-icon :small="small"> {{ icon }} </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SortOrderToggle",
  emits: ["input"], 
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-arrow-up"
    },
    activeColour: {
      type: String,
      required: false,
      default: null
    },
    allowNoSortOrder: {
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    dataCy: {
      type: String,
      required: false,
      default: "sort-order-toggle"
    }
  },
  methods: {
    toggle() {
      //Check if it is already descending and if we allow no sort order, then turn off (emit null)
      if (this.value && this.allowNoSortOrder) {
        this.$emit("input", null);
        return;
      }

      this.$emit("input", !this.value);
    },
    //Called when the sorting is null and we then apply an ascending order
    initSort() {
      this.$emit("input", false);
    }
  }
};
</script>
<style scoped>
.toggleUpDown {
  transition: transform 0.3s ease-in-out !important;
}

.toggleUpDown.rotate {
  transform: rotate(180deg);
}
</style>
