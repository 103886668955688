import ProjectTypes from "@S/project/types";
import LoadingStates from "@/constants/LoadingStates";

import { fetchProjectDataById } from "@/store/project/helpers";
import { mutations as datasetMutations } from "@S/dataset/types";
import toolNames from "@/behaviours/map/toolNames";
import mapLegendsNames from "@/behaviours/map/mapLegendsNames";
import { _axios } from "@/plugins/axios";

const createEmptyState = () => {
  // There is always just one active project so no need to index by id
  return {
    loadingMap: {},

    activeTool: toolNames.None,
    activeMapLegend: mapLegendsNames.None,
    projectSummaries: {
      // [id]: {
      //   name: null,
      //      description: null,
      //      notice: null,
      //      areaName: null,
      //      boundaryNorth: null,
      //      boundaryEast: null,
      //      boundarySouth: null,
      //      boundaryWest: null,
      //   projectData: null,
      //   projectDataWasFetched: false,
      // }
    }
  };
};

export default {
  ProjectTypes: ProjectTypes,
  state: createEmptyState(),
  getters: {
    [ProjectTypes.getters.ProjectGetProjectSummary]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetProjectSummary called with no project id");
        return null;
      }
      return state?.projectSummaries?.[id] || null;
    },
    [ProjectTypes.getters.ProjectGetCaseIdsForFetching]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetCaseIdsForFetching called with no project id");
        return [];
      }
      return state?.projectSummaries?.[id]?.projectData?.case_ids || [];
    },
    [ProjectTypes.getters.ProjectGetConfigIds]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetConfigIds called with no project id");
        return [];
      }
      return state?.projectSummaries?.[id]?.projectData?.config_ids || [];
    },
    [ProjectTypes.getters.ProjectGetBoundariesAsGeoJson]:
      (state, getters) => (id) => {
        if (!id) {
          console.error(
            "ProjectGetBoundariesAsGeoJson called with no project id"
          );
          return;
        }
        const bound = getters[ProjectTypes.getters.ProjectGetBoundaries](id);
        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [bound.west, bound.north],
                [bound.west, bound.south],
                [bound.east, bound.south],
                [bound.east, bound.north],
                [bound.west, bound.north]
              ]
            ]
          }
        };
      },
    [ProjectTypes.getters.ProjectGetBoundaries]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetBoundaries called with no project id");
        return;
      }
      return {
        north: state?.projectSummaries?.[id]?.boundaryNorth,
        east: state?.projectSummaries?.[id]?.boundaryEast,
        south: state?.projectSummaries?.[id]?.boundarySouth,
        west: state?.projectSummaries?.[id]?.boundaryWest
      };
    },
    [ProjectTypes.getters.ProjectGetAreaName]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetAreaName called with no project id");
        return;
      }
      return state?.projectSummaries?.[id]?.areaName;
    },

    [ProjectTypes.getters.ProjectGetActiveTool](state) {
      return state.activeTool;
    },
    [ProjectTypes.getters.ProjectGetLoadingState]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetLoadingState called with no project id");
        return;
      }
      return state.loadingMap?.[id];
    },
    [ProjectTypes.getters.ProjectIsLoading]: (state, getters) => (id) => {
      if (!id) {
        console.error("ProjectIsLoading called with no project id");
        return;
      }
      return [LoadingStates.INITIALISED, LoadingStates.LOADING].includes(
        getters?.[ProjectTypes.getters.ProjectGetLoadingState](id)
      );
    },
    [ProjectTypes.getters.ProjectGetActiveMapLegend](state) {
      return state.activeMapLegend;
    },

    [ProjectTypes.getters.ProjectGetName]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetName called with no project id");
        return;
      }
      return state?.projectSummaries?.[id]?.name;
    },
    [ProjectTypes.getters.ProjectGetDescription]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetDescription called with no project id");
        return;
      }
      return state?.projectSummaries?.[id]?.description;
    },
    [ProjectTypes.getters.ProjectGetStage]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetStage called with no project id");
        return;
      }
      return state?.projectSummaries?.[id]?.stage;
    },
    [ProjectTypes.getters.ProjectGetNotice]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetNotice called with no project id");
        return;
      }
      return state?.projectSummaries?.[id]?.notice;
    },
    [ProjectTypes.getters.ProjectGetAssetTypes]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetAssetTypes called with no project id");
        return [];
      }
      return state?.projectSummaries?.[id]?.assetTypes || [];
    },

    [ProjectTypes.getters.GetProjectDataWasFetched]: (state) => (id) => {
      if (!id) {
        console.error("GetProjectDataWasFetched called with no project id");
        return;
      }
      return state?.projectSummaries?.[id]?.projectDataWasFetched;
    },

    [ProjectTypes.getters.ProjectGetCategoryIds]: (state) => (id) => {
      if (!id) {
        console.error("ProjectGetCategoryIds called with no project id");
        return;
      }
      return state?.projectSummaries?.[id]?.projectData
        ?.drawn_asset_category_ids;
    }
  },
  mutations: {
    [ProjectTypes.mutations.ProjectSetActiveMapLegend]: (state, payload) => {
      state.activeMapLegend = payload || mapLegendsNames.None;
    },

    [ProjectTypes.mutations.ProjectSetActiveTool]: (state, payload) => {
      state.activeTool = payload || toolNames.None;
    },

    [ProjectTypes.mutations.ProjectSetProjectDataWasFetched]: (
      state,
      { projectId, dataWasFetched }
    ) => {
      if (!projectId) {
        console.error("ProjectSetProjectDataWasFetched called with no id");
        return;
      }
      if (!state.projectSummaries[projectId]) {
        Vue.set(state.projectSummaries, projectId, {});
      }

      Vue.set(
        state.projectSummaries?.[projectId],
        "projectDataWasFetched",
        dataWasFetched
      );
    },
    [ProjectTypes.mutations.ProjectUpdate]: (
      state,
      { projectId, projectData }
    ) => {
      if (!projectId) {
        console.error("ProjectUpdate called with no id");
        return;
      }
      Vue.set(state.projectSummaries, projectId, {
        ...state.projectSummaries[projectId],
        name: projectData?.name,
        description: projectData?.description,
        areaName: projectData?.area_name,
        assetTypes: projectData?.asset_types,
        boundaryNorth: projectData?.bounding_box.boundaries.north,
        boundaryEast: projectData?.bounding_box.boundaries.east,
        boundarySouth: projectData?.bounding_box.boundaries.south,
        boundaryWest: projectData?.bounding_box.boundaries.west,
        stage: projectData?.stage,
        projectData: projectData?.project_data,
        notice: projectData?.notice
      });
    },
    [ProjectTypes.mutations.ProjectSetLoadingState]: (
      state,
      { loadingState, id }
    ) => {
      if (!id) {
        console.error("ProjectSetLoadingState called with no id");
        return;
      }
      Vue.set(state.loadingMap, id, loadingState);
    },
    [ProjectTypes.mutations.ProjectSetCategoryIds]: (
      state,
      { projectId, categoryIds }
    ) => {
      if (!projectId) {
        console.error("ProjectSetCategoryIds called with no id");
        return;
      }
      if (!state.projectSummaries[projectId]) {
        Vue.set(state.projectSummaries, projectId, {});
      }

      Vue.set(state.projectSummaries?.[projectId], "categoryIds", categoryIds);
    },
    [ProjectTypes.mutations.ProjectRemoveCategoryId]: (
      state,
      { projectId, idToBeRemoved }
    ) => {
      if (!projectId) {
        console.error("ProjectRemoveCategoryId called with no project id");
        return;
      }
      const tempCategoryIds = state.projectSummaries[
        projectId
      ].categoryIds.filter((id) => id !== idToBeRemoved);

      Vue.set(
        state.projectSummaries[projectId],
        "categoryIds",
        tempCategoryIds
      );
    },
    [ProjectTypes.mutations.ChangeActiveProject]: (state) => {
      Vue.set(state, "loadingMap", {});

      Vue.set(state, "activeTool", toolNames.None);
      Vue.set(state, "activeMapLegend", mapLegendsNames.None);
    }
  },
  actions: {
    [ProjectTypes.actions.ProjectSetActiveMapLegend]: ({ commit }, payload) => {
      commit(ProjectTypes.mutations.ProjectSetActiveMapLegend, payload);
    },
    [ProjectTypes.actions.ProjectSetActiveTool]: ({ commit }, payload) => {
      commit(ProjectTypes.mutations.ProjectSetActiveTool, payload);
    },
    [ProjectTypes.actions.ProjectUpdate]: (
      { state, commit, dispatch },
      { projectId, project }
    ) => {
      if (!projectId) {
        console.error("ProjectUpdate called with no project id");
        return;
      }
      const pythonSafePayload = {
        id: projectId,
        name:
          project?.name !== undefined
            ? project?.name
            : state.projectSummaries[projectId].name,
        description:
          project?.description !== undefined
            ? project?.description
            : state.projectSummaries[projectId].description,
        area_name:
          project?.area_name !== undefined
            ? project?.area_name
            : state.projectSummaries[projectId].areaName,
        boundary_north:
          project?.boundary_north !== undefined
            ? project?.boundary_north
            : state.projectSummaries[projectId].boundaryNorth,
        boundary_east:
          project?.boundary_east !== undefined
            ? project?.boundary_east
            : state.projectSummaries[projectId].boundaryEast,
        boundary_south:
          project?.boundary_south !== undefined
            ? project?.boundary_south
            : state.projectSummaries[projectId].boundarySouth,
        boundary_west:
          project?.boundary_west !== undefined
            ? project?.boundary_west
            : state.projectSummaries[projectId].boundaryWest,
        asset_types:
          project?.asset_types !== undefined
            ? project?.asset_types
            : state.projectSummaries[projectId].assetTypes,
        stage:
          project?.stage !== undefined
            ? project?.stage
            : state.projectSummaries[projectId].stage,
        notice:
          project?.notice !== undefined
            ? project?.notice
            : state.projectSummaries[projectId].notice
      };
      commit(ProjectTypes.mutations.ProjectSetLoadingState, {
        id: projectId,
        loadingState: LoadingStates.LOADING
      });

      const onSuccess = function () {
        dispatch(ProjectTypes.actions.ProjectFetchProjectDataById, projectId);
      };
      const onFailure = function (error) {
        commit(ProjectTypes.mutations.ProjectSetLoadingState, {
          id: projectId,
          loadingState: LoadingStates.ERRORED
        });
        return Promise.reject(error);
      };

      return _axios
        .put(`V2/project/${projectId}`, pythonSafePayload)
        .then(onSuccess, onFailure);
    },
    [ProjectTypes.actions.UpdateProjectChildIds]: (
      { commit, state },
      projectId
    ) => {
      if (!projectId) {
        console.error("UpdateProjectChildIds called with no project id");
        return;
      }
      //dispatch
      try {
        commit(ProjectTypes.mutations.ProjectSetLoadingState, {
          loadingState: LoadingStates.LOADING,
          id: projectId
        });

        if (
          state.projectSummaries[projectId].projectData.drawn_asset_category_ids
        ) {
          commit(ProjectTypes.mutations.ProjectSetCategoryIds, {
            categoryIds:
              state.projectSummaries[projectId].projectData
                .drawn_asset_category_ids,
            projectId
          });
        }

        const datasetIds =
          state.projectSummaries?.[projectId]?.projectData
            ?.datasetIdsForFetching;

        if (datasetIds) {
          commit(datasetMutations.SetDatasetIdsForInitialFetch, datasetIds, {
            root: true
          });
        }

        commit(ProjectTypes.mutations.ProjectSetLoadingState, {
          loadingState: LoadingStates.LOADED,
          id: projectId
        });
      } catch (error) {
        console.error(error);
        commit(ProjectTypes.mutations.ProjectSetLoadingState, {
          loadingState: LoadingStates.ERRORED,
          id: projectId
        });
      }
    },

    [ProjectTypes.actions.ProjectRemoveCategoryId]: (
      { commit },
      { projectId, categoryId }
    ) => {
      commit(ProjectTypes.mutations.ProjectRemoveCategoryId, {
        projectId,
        categoryId
      });
    },
    [ProjectTypes.actions.ProjectFetchProjectDataById]: (
      { commit },
      projectId
    ) => {
      if (!projectId) {
        console.error("ProjectFetchProjectDataById called with no project id");
        return;
      }
      fetchProjectDataById({
        projectId,
        setLoadingState: ({ projectId, loadingState }) => {
          commit(ProjectTypes.mutations.ProjectSetLoadingState, {
            id: projectId,
            loadingState
          });
        },
        setDataWasFetched: ({ projectId, dataWasFetched }) => {
          commit(ProjectTypes.mutations.ProjectSetProjectDataWasFetched, {
            projectId: projectId,
            dataWasFetched
          });
        },
        setProjectData: ({ projectId, projectData }) => {
          commit(ProjectTypes.mutations.ProjectUpdate, {
            projectId: projectId,
            projectData
          });
        }
      });
    }
  }
};
