<template>
  <div>
    <SavedView2OptionState
      @OptionsToggleInteractionState="OptionsToggleInteractionState"
      @OptionsToggleVisibility="updateOptionVisibility"
      @SetAllOptionColours="SetAllOptionColours"
      :OptionsGetActiveId="OptionsGetActiveId"
      :OptionsGetSelectedIds="OptionsGetSelectedIds"
      :SavingViewsGetOptionStyles="SavingViewsGetOptionStyles"
    />
    <OptionState2SavedView
      @SavingViewsSetOptionsState="SavingViewsSetOptionsState"
      :OptionsGetVisibleIds="OptionsGetVisibleIds"
      :OptionsGetActiveId="OptionsGetActiveId"
      :OptionsGetSelectedIds="OptionsGetSelectedIds"
      :GetOptionColour="GetOptionColour"
      :OptionsGetAllColours="OptionsGetAllColours"
    />
  </div>
</template>

<script>
import { isEqual } from "lodash";

import { mapGetters, mapActions, mapMutations } from "vuex";
import { getters, mutations } from "@S/savingView/types";
import { NewOptionTypes as OptionTypes } from "@S/option/types";

import SavedView2OptionState from "./SavedView2OptionState.vue";
import OptionState2SavedView from "./OptionState2SavedView.vue";

export default {
  components: { SavedView2OptionState, OptionState2SavedView },
  render() {
    return null;
  },
  props: {},
  computed: {
    ...mapGetters([
      getters.SavingViewsGetOptionStyles,
      OptionTypes.getters.OptionsGetActiveId,
      OptionTypes.getters.OptionsGetSelectedIds,
      OptionTypes.getters.OptionsGetAllColours,
      OptionTypes.getters.GetOptionColour,
      OptionTypes.getters.OptionsGetVisibleIds
    ])
  },
  methods: {
    ...mapMutations([mutations.SavingViewsSetOptionsState]),
    ...mapActions([
      OptionTypes.actions.OptionsToggleInteractionState,
      OptionTypes.actions.OptionsToggleVisibility,
      OptionTypes.actions.SetAllOptionColours
    ]),
    updateOptionVisibility(payload) {
      const newVisibleIds = payload?.optionIds;
      const currentVisibleIds = this.OptionsGetVisibleIds;
      if (isEqual(newVisibleIds, currentVisibleIds)) return;
      this.OptionsToggleVisibility(payload);
    }
  }
};
</script>

<style></style>
