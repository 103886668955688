import { InteractionStates } from "@/constants/InteractionStates";
import { negate, isEmpty, isNil, toLower, includes, isEqual } from "lodash";

/** @typedef {import('@/models').Models} Models */

export const IsNotEmpty = negate(isEmpty);
export const IsNotNil = negate(isNil);

export const Has = new Proxy(
  {},
  {
    get(_target, name) {
      return (obj) => {
        if (Object.keys(obj)?.includes(name)) {
          return true;
        }

        const possibleMatch = Object.keys(obj)?.find((key) => {
          return toLower(key) == toLower(name);
        });
        console.warn(
          `Couldn't find ${name}`,
          possibleMatch && `but found similar prop: ${possibleMatch}`
        );

        return false;
      };
    },
    call(target, name) {
      return (obj) => {
        if (Object.keys(obj).includes(name)) {
          return true;
        }

        const possibleMatch = Object.keys(obj)?.find((key) => {
          return toLower(key) == toLower(name);
        });
        console.warn(
          `Couldn't find ${name}`,
          possibleMatch && `but found similar prop: ${possibleMatch}`
        );

        return false;
      };
    }
  }
);

export const To = new Proxy(
  {},
  {
    get: (target, name) => {
      return (obj) => {
        return obj?.[name];
      };
    }
  }
);

export function HasId(obj) {
  return Object.keys(obj).includes("id");
}

export function ToArrayAsPair(propKey = "key", valKey = "values") {
  return ([key, value]) => ({ [propKey]: key, [valKey]: value });
}

export function SafeAssign(obj) {
  return (item) => {
    const fn = typeof obj === "function";
    return { ...item, ...(fn ? obj.call({}, item) : obj) };
  };
}

export function SafelyToIds(obj) {
  return DivertWhen(HasId, ToId)(obj);
}

/**
 * @param {Models.IIdentifiable} obj
 * @return {Models.Identifier}
 * */
export function ToId(obj) {
  return `${obj?.id}`;
}
export function ToType(obj) {
  return obj?.type;
}
export function ToName(obj) {
  return obj?.name;
}

export function Call(obj, args) {
  return obj?.call(this, args);
}
export function CallWith(args) {
  return (obj) => Call(obj, args);
}

export function IsDatasetType(...types) {
  return (obj) => [].concat(types)?.includes(ToType(obj));
}

export function IsUnique(value, index, self) {
  return self?.findIndex(isEqual(value)) === index;
}

/**
 * Divert filter to `divert` param when `exp` returns true.
 * @param {(obj) => boolean} exp
 * @param {(obj) => any} divert
 * @param {(obj) => any} otherwise
 */
export function DivertWhen(exp, divert, otherwise) {
  return (obj) => {
    return exp(obj) ? divert(obj) : otherwise?.(obj) ?? obj;
  };
}

export function FilterWhenGivenValues(vals, filter) {
  return DivertWhen(
    () => IsNotNil(vals),
    (obj) => filter(vals, obj)
  );
}

export function ImposeIncludes(values) {
  return FilterWhenGivenValues(values, includes);
}

export function ImposeIncludesId(values) {
  return (obj) => ImposeIncludes(values)(SafelyToIds(obj));
}

export const InteractionFilters = {
  /**
   * @param {Models.IInteractable} item
   * @returns {boolean}
   */
  IsActive: (item) => {
    return item?.interactionState == InteractionStates.ACTIVE;
  },
  IsSelected: (item) => {
    const set = [InteractionStates.ACTIVE, InteractionStates.SELECTED];

    return item?.interactionState
      ? set.includes(item?.interactionState)
      : false;
  },
  /**
   * @param {Models.IInteractable} item
   * @returns {boolean}
   */
  IsHidden: (item) => {
    return item?.interactionState === InteractionStates.HIDDEN;
  }
};
