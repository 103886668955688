import { BaseTypes } from "@S/base_types";

class PolygonTypes extends BaseTypes {
  static getters = {};

  static mutations = {};

  static actions = {};
}

const NewPolygonTypes = {
  getters: {
    GetAllPolygons: "GetAllPolygons",
    AllPolygonSets: "AllPolygonSets",
    PolygonGetSet: "PolygonGetSet",
    PolygonGetName: "PolygonGetName",
    AnyPolygonIsLoading: "AnyPolygonIsLoading",
    PolygonGetLoadingState: "PolygonGetLoadingState",
    PolygonGetInteractionState: "PolygonGetInteractionState"
  },
  mutations: {
    PolygonUpdate: "PolygonUpdate",
    PolygonSetLoadingState: "PolygonSetLoadingState",
    PolygonSetInteractionState: "PolygonSetInteractionState",
    PolygonAddPolygon: "PolygonAddPolygon",
    PolygonResetState: "PolygonResetState",
    PolygonDeletePolygon: "PolygonDeletePolygon"
  },
  actions: {
    PolygonUpdate: "PolygonUpdate",
    PolygonSetLoadingState: "PolygonSetLoadingState",
    PolygonSetInteractionState: "PolygonSetInteractionState",
    PolygonAddPolygon: "PolygonAddPolygon",
    PolygonResetState: "PolygonResetState",
    PolygonDeletePolygon: "PolygonDeletePolygon"
  }
};

export { PolygonTypes };
export { NewPolygonTypes };
