<template>
  <div>
    <v-dialog
      v-model="dialog"
      :key="dialog"
      max-width="90%"
      transition="dialog-top-transition"
    >
      <template v-slot:activator="slotData">
        <slot
          v-if="$scopedSlots.activator"
          v-bind="slotData"
          :openToInvites="openToInvites"
          name="activator"
        />
        <v-list-item
          v-else
          v-on="slotData.on"
          @click="toggleDialog"
          data-cy="team-mgmt-modal-button"
          dense
          ><v-list-item-icon>
            <v-icon> mdi-account-multiple </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Team management</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-card height="90%" color="white">
        <v-row class="pa-0 ma-0">
          <v-col
            cols="3"
            class="pa-3"
            style="background: var(--ciBackgroundGray)"
          >
            <v-row class="fill-height" dense>
              <v-col cols="12">
                <h6 class="pl-4">{{ projectName }}</h6>
                <v-list
                  class="user-mgmt-list"
                  style="background: var(--ciBackgroundGray)"
                >
                  <v-subheader class="body-2 font-weight-bold"
                    >USER MANAGEMENT</v-subheader
                  >

                  <v-list-item-group v-model="selectedPage">
                    <v-list-item
                      :value="pages.users"
                      data-cy="team-mgtm-modal-users-table-tab"
                      dense
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ pages.users }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      :value="pages.invites"
                      data-cy="team-mgmt-modal-invite-project-members"
                      dense
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          pages.invites
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list></v-col
              >
              <v-col cols="12" class="text-center" align-self="end">
                <LogoOptioneer width="200" height="50" />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="9">
            <div
              v-if="selectedPage == pages.users"
              :key="`${selectedPage}Users`"
            >
              <UsersMgmtTable />
            </div>

            <div
              v-if="selectedPage == pages.invites"
              :key="`${selectedPage}Sharing`"
            >
              <InviteProjectMembers />
            </div>
          </v-col>
          <v-btn
            @click="dialog = false"
            data-cy="team-mgmt-modal-close-button"
            color="black"
            class="mt-1"
            right
            absolute
            icon
            large
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LogoOptioneer from "@/components/logos/LogoOptioneer.vue";
import UsersMgmtTable from "@C/userManagement/UsersMgmtTable.vue";
import InviteProjectMembers from "@C/userManagement/InviteProjectMembers.vue";

const pages = {
  users: "Users",
  invites: "Invites and Sharing"
};
export default {
  name: "TeamMgmtModal",
  components: { LogoOptioneer, UsersMgmtTable, InviteProjectMembers },
  props: {
    projectName: { default: "", type: String }
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    openToInvites() {
      this.dialog = true;
      this.selectedPage = pages.invites;
    }
  },
  data() {
    return {
      dialog: false,
      pages,
      selectedPage: pages.users
    };
  }
};
</script>

<style scoped>
.user-mgmt-list .v-list-item-group .v-list-item--active {
  color: white;
  background-color: white;
}
.user-mgmt-list .v-list-item-group .v-list-item--active .v-list-item__title {
  color: var(--v-primaryVariant2-base);
  background-color: white;
  font-weight: bold;
}
</style>
