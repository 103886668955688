<template>
  <v-app 
    id="main-app-container"
    :data-ph-capture-attribute-project-id="projectId"
  >
    <MainNavBar />
    <v-main>
      <SessionTimeoutDialog
        v-if="isAuthenticated"
        :timeFromWarningToLogoutInMinutes="15"
        :idleTimeBeforeLogoutInMinutes="120"
      />
      <DatasetUploadTracker />
      <!-- If using vue-router -->
      <portal-target name="DeletionSnackbar" />
      <portal-target name="saving-views-error" />
      <portal-target name="layer-buffering-error" />
      <portal-target name="define-custom-metric-error" />
      <portal-target name="relative-rag-editor-error" />
      <router-view v-if="!authLoading && isAuthenticated" />
      <Login v-else />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { actions } from "@S/session/types";
import { updateAccessTokenExpiryTime, authReady } from "@/plugins/authPlugin";
import SessionTimeoutDialog from "@C/generic/SessionTimeoutDialog.vue";
import DatasetUploadTracker from "@/components/data/DatasetUploadTracker.vue";
import { authState } from "@/plugins/authPlugin";

import MainNavBar from "@/components/MainNavBar.vue";
import Login from "@/pages/Login.vue";
import { _axios } from "./plugins/axios";

const intercomUserHashUrl = "/V2/intercom/hash";

export default {
  name: "App",
  components: {
    MainNavBar,
    Login,
    SessionTimeoutDialog,
    DatasetUploadTracker
  },
  async created() {
    await authReady();
    this.SessionFetchProjectIdsForUser();
    _axios
      .get(intercomUserHashUrl, {})
      .then((response) => {
        const userHash = response.data.hmac;
        const user = authState?.value?.user;
        window.Intercom("update", {
          user_id: user?.sub,
          name: user?.name,
          email: user?.email,
          user_hash: userHash
        });
      })
      .catch((error) => {
        console.error("error grabbing intercom hash", error);
      });
    updateAccessTokenExpiryTime();
  },
  computed: {
    isAuthenticated() {
      return authState?.value?.isAuthenticated;
    },
    authLoading() {
      return authState?.value?.loading;
    },
    projectId() {
      return this.$route.params?.projectId ?? "no-project-id";
    }
  },

  methods: {
    ...mapActions([actions.SessionFetchProjectIdsForUser])
  },
  watch: {
    "$route.params.projectId": {
      handler: function (projectId, oldProjectId) {
        if (projectId === oldProjectId) {
          return;
        }

        if (oldProjectId && projectId !== oldProjectId) {
          // Reloading page to reset the state. Unclear if we still need this.
          location.reload();
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style>
@import url("https://use.typekit.net/lsb5uyk.css");
html,
body {
  font-family: "nunito-sans";
  overflow: hidden;
  height: 100vh;
}
#app {
  font-family: "nunito-sans";
}
</style>
