import types from "./types";

export function createEmptyState() {
  return {
    savedVersion: {
      // id
      // createdAt
      // updatedAt
      // createdBy
      // updatedBy
      // name
      // description
      // layerIDs //Used in MultiPolygonAnalysis
      // buffers //Used in MultiPolygonAnalysis
      // layerParams //Used in MultiPolygonAnalysis
      // categoryID
    },
    analysisReports: [],
    loading: false
  };
}

export default {
  state: createEmptyState(),
  actions: {},
  mutations: {
    [types.mutations.AnalysisReportSetSavedVersion](state, savedVersion) {
      let layerParamsAsObject = {};
      if (savedVersion.layerParams) {
        layerParamsAsObject = savedVersion.layerParams.reduce(
          (acc, { layerID, featurePropertyHeaders }) => {
            acc[layerID] = featurePropertyHeaders;
            return acc;
          },
          {}
        );
      }

      Vue.set(state, "savedVersion", {
        ...savedVersion,
        layerParams: { ...layerParamsAsObject }
      });
    },
    [types.mutations.AnalysisReportResetState](state) {
      Object.assign(state, createEmptyState());
    },
    [types.mutations.AnalysisReportSetAnalysisReports](state, analysisReports) {
      Vue.set(state, "analysisReports", analysisReports);
    },
    [types.mutations.AddAnalysisReport](state, analysisReport) {
      Vue.set(state, "analysisReports", [
        analysisReport,
        ...state.analysisReports
      ]);
    },
    [types.mutations.SetLoadingState](state, loadingState) {
      state.loading = loadingState;
    }
  },
  getters: {
    [types.getters.AnalysisReportWorkingVersionLayerParamsAsArray](
      _state,
      getters
    ) {
      if (!getters.AnalysisReportWorkingVersion.layerParams) {
        return [];
      }
      return Object.entries(
        getters.AnalysisReportWorkingVersion.layerParams
      ).reduce((acc, [layerID, featurePropertyHeaders]) => {
        acc.push({ layerID, featurePropertyHeaders });
        return acc;
      }, []);
    },
    [types.getters.SavedAnalysisReportCategoryId]: (state) => {
      return state.savedVersion ? state.savedVersion.categoryID : null;
    },
    [types.getters.SavedAnalysisReportDescription]: (state) => {
      return state.savedVersion ? state.savedVersion.description : null;
    },
    [types.getters.SavedAnalysisReportId]: (state) => {
      return state.savedVersion ? state.savedVersion.id : null;
    },
    [types.getters.AnalysisReportSavedVersion]: (state) => {
      return state.savedVersion;
    },
    [types.getters.AnalysisReportSavedVersionModalProperties](state) {
      return {
        layerIDs: state.savedVersion.layerIDs,
        buffers: state.savedVersion.buffers,
        layerParams: state.savedVersion.layerParams
      };
    },
    [types.getters.AnalysisReportGetAnalysisReports](state) {
      return state.analysisReports;
    },
    [types.getters.GetLoadingState]: (state) => {
      return state.loading;
    },
    [types.getters.AnalysisReportWorkingVersion](
      _state,
      _getters,
      _rootState,
      rootGetters
    ) {
      const currentReport = {
        layerIDs: rootGetters.MultiPolygonAnalysisGetLayerIds,
        buffers: rootGetters.MultiPolygonAnalysisGetBuffers,
        layerParams: rootGetters.MultiPolygonAnalysisGetAllFeatureParameters
      };

      return currentReport;
    }
  }
};
