import Vuetify from "vuetify/lib";

import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  defaultAssets: {
    font: true,
    icons: "md"
  },
  icons: {
    iconfont: "md"
  },
  options: {
    customProperties: true
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#29194F",
        primaryVariant: "#330084",
        primaryVariant2: "#3E0CBA",
        secondary: "#1CCDD8",
        accent: "#F43212",
        error: "#D40D0D",
        info: "#7E49FF",
        success: "#099268",
        warning: "#E8590C",
        ciBackgroundGray: "#f2f2f2",
        ciPink: "#ae0049",
        ciOrange: "#e69f00"
      }
    }
  }
});

export default vuetify;
