import {
  defaultSelectedResultTableColumns,
  defaultAvailableResultTableColumns,
  mandatoryResultsTableHeaders,
  rankColumn
} from "@/constants/ResultTypes";
import { ResultsLibraryAnalysisModes } from "@/constants/ResultsLibraryAnalysisModes";

import { actions, mutations, getters as resultTableGetters } from "./types";
import {
  actions as savedMetricsViewActions,
  getters as savedMetricsViewGetters
} from "@S/savedMetricsView/types";

import { uniq, startCase } from "lodash";

const defaultSelectedAdditionalHeaderIds = Object.keys(
  defaultSelectedResultTableColumns
).map((id) => id);

const defaultState = {
  availableAdditionalColumns: { ...defaultAvailableResultTableColumns },
  selectedAdditionalColumnIds: defaultSelectedAdditionalHeaderIds,
  openMetricTooltip: null
};

export default {
  state: { ...defaultState },
  getters: {
    [resultTableGetters.ResultsTableGetOrderedAdditionalHeaders](
      state,
      getters
    ) {
      const sortedHeaders = [];
      // Iterate over selected additional header ids in the correct order
      // Grab relevant headers and return them
      getters[resultTableGetters.ResultsTableGetSelectedHeaderIds].forEach(
        (id) => {
          const header = state.availableAdditionalColumns[id];
          if (!header) {
            return;
          }

          //If the header found is an option metric, add its weight
          if (header.isOptionMetric) {
            header["weight"] =
              getters[savedMetricsViewGetters.MetricsViewGetMetricsByName]()[
                header.value
              ]?.weight;
          }
          sortedHeaders.push(header);
        }
      );

      return sortedHeaders;
    },

    [resultTableGetters.ResultsTableGetAllSortedHeaders]:
      (state, getters) =>
      (analysisMode = null) => {
        const sortedHeaders = [...mandatoryResultsTableHeaders];

        //additionalHeaders: all additional headers, non-metric and metric ones.
        let additionalHeaders =
          getters[resultTableGetters.ResultsTableGetOrderedAdditionalHeaders];

        //If we are in analysis mode, add the rank column and filter out non-metric columns
        if (analysisMode === ResultsLibraryAnalysisModes.ANALYSIS) {
          sortedHeaders.push(rankColumn);

          additionalHeaders = additionalHeaders.filter(
            (header) => header.isOptionMetric
          );
        }

        sortedHeaders.push(...additionalHeaders);

        return sortedHeaders;
      },
    [resultTableGetters.ResultsTableGetAdditionalHeaders](state) {
      return state.availableAdditionalColumns;
    },
    [resultTableGetters.ResultsTableGetSelectedHeaderIds](
      state,
      getters,
      rootState,
      rootGetters
    ) {
      const workingMetrics =
        rootGetters[savedMetricsViewGetters.MetricsViewGetWorkingMetricNames];

      return uniq([...state.selectedAdditionalColumnIds, ...workingMetrics]);
    },
    [resultTableGetters.ResultsTableGetOpenMetricTooltip](state) {
      return state.openMetricTooltip;
    }
  },
  mutations: {
    [mutations.ResultsTableSetAdditionalHeaders]: (state, payload) => {
      Vue.set(state, "availableAdditionalColumns", payload);
    },
    [mutations.ResultsTableSetSelectedAdditionalHeaderIds]: (
      state,
      payload
    ) => {
      Vue.set(state, "selectedAdditionalColumnIds", payload);
    },
    [mutations.ResetAvailableAndSelectedColumns]: (state) => {
      state.availableAdditionalColumns =
        defaultState.availableAdditionalColumns;
      state.selectedAdditionalColumnIds =
        defaultState.selectedAdditionalColumnIds;
    },
    [mutations.ResultsTableSetOpenMetricTooltip]: (state, payload) => {
      state.openMetricTooltip = payload;
    }
  },
  actions: {
    [actions.ResultsTableSetSelectedAdditionalHeaderIds]: (
      { commit, dispatch, getters },
      payload
    ) => {
      const selectedDefaults = payload.filter(
        (header) => defaultAvailableResultTableColumns?.[header]
      );

      const metrics = payload.filter(
        (header) => !defaultAvailableResultTableColumns?.[header]
      );

      const metricsAndWeights = metrics.map((metric) => {
        const weight =
          getters[savedMetricsViewGetters.MetricsViewGetMetricsByName]()[metric]
            ?.weight;

        return {
          name: metric,
          weight: weight
        };
      });

      dispatch(
        savedMetricsViewActions.MetricsViewSetWorkingMetrics,
        metricsAndWeights
      );

      commit(
        mutations.ResultsTableSetSelectedAdditionalHeaderIds,
        selectedDefaults
      );
    },
    [actions.ResultsTableAddAdditionalHeaders]: (
      { commit, getters },
      payload
    ) => {
      const newAdditionalColumns = {
        ...getters[resultTableGetters.ResultsTableGetAdditionalHeaders]
      };

      payload.forEach((newColumn) => {
        const column_name = newColumn.name;
        if (!column_name) {
          throw `column name must not be undefined, received: ${newColumn}`;
        }
        // Check whether header has already been added to additional headers
        // and whether it is already in the table but not the additional headers
        if (
          column_name in
            getters[resultTableGetters.ResultsTableGetAdditionalHeaders] ||
          mandatoryResultsTableHeaders.some(
            (currentColumn) => currentColumn.value === column_name
          )
        ) {
          // Abort if column has already been added
          return;
        }

        newAdditionalColumns[column_name] = {
          text: newColumn.title || startCase(column_name),
          description: newColumn?.description,
          value: column_name,
          draggable: true,
          metricMetadata: newColumn.metricMetadata || [],
          isOptionMetric: !!newColumn.isOptionMetric
        };
      });

      commit(mutations.ResultsTableSetAdditionalHeaders, newAdditionalColumns);
    },
    [actions.ResultsTableSetAdditionalHeaders]: ({ commit }, payload) => {
      commit(mutations.ResultsTableSetAdditionalHeaders, payload);
    },
    [actions.ResetAvailableAndSelectedColumns]: ({ commit }) => {
      commit(mutations.ResetAvailableAndSelectedColumns);
    },
    [actions.ResultsTableSetOpenMetricTooltip]: ({ commit }, payload) => {
      commit(mutations.ResultsTableSetOpenMetricTooltip, payload);
    }
  }
};
