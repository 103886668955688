const getters = {
  DatasetGetName: "DatasetGetName",
  DatasetGetType: "DatasetGetType",
  DatasetGetLayerNamesAndIds: "DatasetGetLayerNamesAndIds",
  DatasetGetIsLoaded: "DatasetGetIsLoaded",
  DatasetGetAsGeoJson: "DatasetGetAsGeoJson",
  GetAllDatasetsAsGeojson: "GetAllDatasetsAsGeojson",
  GetDatasetIdsForInitialFetch: "GetDatasetIdsForInitialFetch",
  AnyDatasetsLoading: "AnyDatasetsLoading",
  DatasetGetAllIds: "DatasetGetAllIds",
  GetDatasetsAsArray: "GetDatasetsAsArray",
  GetDatasetSummaries: "GetDatasetSummaries",
  GetRasterLegendById: "GetRasterLegendById"
};

const mutations = {
  DatasetSetData: "DatasetSetData",
  DatasetSetName: "DatasetSetName",
  DatasetSetType: "DatasetSetType",
  DatasetSetLoadingState: "DatasetSetLoadingState",
  DatasetSetLayerNameIds: "DatasetSetLayerNameIds",
  DatasetSetVectorTiledStatus: "DatasetSetVectorTiledStatus",
  DatasetDelete: "DatasetDelete",
  SetDatasetIdsForInitialFetch: "SetDatasetIdsForInitialFetch",
  UpdateDatasetsGeojson: "UpdateDatasetsGeojson",
  ResetDatasets: "ResetDatasets",
  SetRasterLegend: "SetRasterLegend"
};
const actions = {
  DatasetUpdate: "DatasetUpdate",
  DatasetDelete: "DatasetDelete",
  UpdateDatasetsGeojson: "UpdateDatasetsGeojson",

  FetchGeoJSON: "FetchGeoJSON"
};

export { getters, mutations, actions };
