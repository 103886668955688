export default {
  sorters: {
    directions: {
      ascending: "ascending",
      descending: "descending"
    },
    sortFields: {
      alphabetical: {
        field: "name",
        label: "Alphabetical"
      }
    }
  }
};
