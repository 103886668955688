<template>
  <CreateSavedViewDialogue
    @save="save"
    :newViewMethod="newViewMethod"
    :viewType="SavedViewTypes.MAP"
    :saving="saving"
  />
</template>

<script>
import createViewReturnStyle from "@/graphql/views/createViewReturnStyle";
import { createDefaultViewInputObject } from "@/hooks/createViewObject";
import { convertToViewInputWithProjectId } from "@/hooks/convertToViewInput";
import { mapActions, mapGetters } from "vuex";
import { actions, getters } from "@S/savingView/types";

import SavingViewsNewViewMethod from "@/constants/SavingViewsNewViewMethod";

import CreateSavedViewDialogue from "@C/savingView/CreateViewDialogue/CreateSavedViewDialogue.vue";

import SavedViewTypes from "@/constants/SavedViewTypes";
import { apolloClient } from "@/plugins/apolloPlugin";

export default {
  name: "CreateMapViewDialogue",
  components: {
    CreateSavedViewDialogue
  },
  data() {
    return {
      saving: false,
      SavedViewTypes
    };
  },
  props: {
    newViewMethod: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      getters.SavingViewsGetWorkingVersion,
      getters.SavingViewsGetSavedVersion
    ])
  },
  methods: {
    ...mapActions([
      actions.SavingViewsAddAvailableView,
      actions.SavingViewsSetActiveView
    ]),
    save(savedViewName) {
      this.saving = true;

      const currentViewport =
        this.SavingViewsGetWorkingVersion.mapStyle.viewPort;

      let input = null;

      if (
        this.newViewMethod ===
          SavingViewsNewViewMethod.NEW_FROM_EXISTING_VIEW ||
        this.newViewMethod === SavingViewsNewViewMethod.NEW_FROM_BUTTON
      ) {
        input = convertToViewInputWithProjectId({
          obj: this.SavingViewsGetWorkingVersion,
          name: savedViewName,
          projectID: this.$route.params.projectId
        });
      } else {
        input = {
          ...createDefaultViewInputObject({
            name: savedViewName,
            projectId: this.$route.params.projectId,
            currentViewport
          })
        };
      }
      apolloClient
        .mutate({
          mutation: createViewReturnStyle,
          variables: {
            input: input
          }
        })
        .then((response) => {
          if (response.errors) {
            console.error("error", response.errors);
            throw new Error("failed to create view");
          }
          this.SavingViewsAddAvailableView(response.data.createView);
          this.SavingViewsSetActiveView({
            ...response.data.createView
          });
        })
        .catch((error) => {
          console.error("error", error);
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
};
</script>

<style></style>
