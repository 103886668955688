export const THEME_COLOURS = [
  "rgba(230, 159, 0, 1)",
  "rgba(0, 150, 255, 1)",
  "rgba(179, 0, 179, 1)",
  "rgba(100, 200, 50, 1)",
  "rgba(255, 128, 0, 1)",
  "rgba(240, 228, 66, 1)",
  "rgba(51, 204, 204, 1)",
  "rgba(128, 0, 128, 1)",
  "rgba(255, 102, 0, 1)",
  "rgba(180, 90, 0, 1)",
  "rgba(0, 158, 115, 1)",
  "rgba(204, 51, 255, 1)",
  "rgba(153, 0, 153, 1)",
  "rgba(255, 153, 0, 1)",
  "rgba(90, 180, 0, 1)",
  "rgba(86, 180, 233, 1)",
  "rgba(255, 0, 0, 1)",
  "rgba(102, 0, 102, 1)",
  "rgba(255, 204, 0, 1)",
  "rgba(0, 153, 76, 1)",
  "rgba(37, 61, 161, 1)",
  "rgba(255, 255, 0, 1)",
  "rgba(76, 0, 153, 1)",
  "rgba(255, 0, 255, 1)",
  "rgba(0, 102, 204, 1)",
  "rgba(178, 0, 237, 1)",
  "rgba(0, 255, 255, 1)",
  "rgba(102, 0, 51, 1)",
  "rgba(255, 0, 102, 1)",
  "rgba(51, 153, 255, 1)",
  "rgba(204, 121, 167, 1)",
  "rgba(0, 255, 128, 1)",
  "rgba(102, 51, 0, 1)",
  "rgba(255, 51, 0, 1)",
  "rgba(128, 204, 255, 1)",
  "rgba(213, 94, 0, 1)",
  "rgba(0, 204, 102, 1)",
  "rgba(153, 51, 0, 1)",
  "rgba(255, 51, 153, 1)",
  "rgba(51, 255, 204, 1)"
];

export default {
  data() {
    return {
      themeColours: THEME_COLOURS
    };
  }
};
