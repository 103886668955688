<template>
  <v-dialog
    v-model="dialog"
    max-width="30%"
    transition="dialog-bottom-transition"
    overlay-opacity="0.70"
  >
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on" :disabled="disabled">
        <v-list-item-content>Rename</v-list-item-content>
      </v-list-item>
    </template>
    <v-card class="pa-3" style="background-color: white">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-form v-model="isValid" @submit.prevent>
              <v-card-title class="primary--text title">
                <h4>Rename</h4>
                <v-btn @click="closeDialog" right absolute icon large>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div
                  data-cy="saved-view-rename-dialog-text"
                  class="text--primary"
                >
                  You're about to rename {{ title }} <br />
                  Please enter a new name
                </div>
              </v-card-text>
              <v-text-field
                v-model="newSavedViewName"
                :rules="[
                  ...savedViewNameValidationRules,
                  checkDuplicateName,
                  checkEmptySpaces
                ]"
                class="pa-4"
                data-cy="savedViewNameTextField"
                label="Saved View Name"
                hint="Please enter the new name"
                outlined
                autofocus
              />

              <v-card-actions>
                <v-spacer />

                <v-btn
                  @click="closeDialog"
                  data-cy="buttonForCancellingTheForm"
                  plain
                  depressed
                  >Cancel</v-btn
                >
                <v-btn
                  @click="save"
                  :disabled="!isValid"
                  data-cy="buttonForSavingTheForm"
                  color="primary"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import updateViewName from "@/graphql/views/updateViewName";
import { mapMutations } from "vuex";
import { mutations } from "@/store/savingView/types";
import { mutations as metricsViewsMutations } from "@S/savedMetricsView/types";
import { apolloClient } from "@/plugins/apolloPlugin";

export default {
  name: "RenameSavedViewItemDialog",

  emits: ["refreshMetricsViewList"], 
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isMapView: {
      type: Boolean,
      required: true
    },
    availableViews: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialog: false,
      isValid: false,
      newSavedViewName: ""
    };
  },
  computed: {
    savedViewNameValidationRules() {
      return [
        (v) => !!v || "Name is required",
        (v) => v.length <= 50 || "Name must be less than 50 characters"
      ];
    },

    checkDuplicateName() {
      return (v) => {
        const duplicateName = this.availableViews.some(
          (view) => view.name === v
        );
        return !duplicateName || "Name already exists";
      };
    },
    checkEmptySpaces() {
      return (v) => {
        return v.trim().length > 0 || "Name cannot be just empty spaces";
      };
    }
  },

  methods: {
    ...mapMutations([
      mutations.SavingViewsRenameView,
      metricsViewsMutations.MetricsViewRenameView
    ]),
    closeDialog() {
      this.dialog = false;
    },
    save() {
      const input = {
        id: this.id,
        name: this.newSavedViewName
      };
      this.dialog = false;
      apolloClient
        .mutate({
          mutation: updateViewName,
          variables: {
            input: input
          }
        })
        .then((response) => {
          if (response.errors) {
            throw response.errors;
          }
          const updatedView = response.data.updateView;
          if (this.isMapView) {
            this.SavingViewsRenameView(updatedView);
          } else {
            this.MetricsViewRenameView(updatedView);
            this.$emit("refreshMetricsViewList");
          }
        })
        .catch((error) => {
          console.error(
            "update mutation failed with errors: ",
            error.graphQLErrors || error
          );
          console.error("input was: ", input);
          alert("Error saving view");
        });
    }
  }
};
</script>

<style scoped></style>
