<template>
  <v-tooltip content-class="tooltip" bottom>
    <template v-slot:activator="{ on }">
      <v-badge
        :value="SessionLowbandWithModeActive"
        icon="priority_high"
        color="info"
        offset-x="16"
        offset-y="24"
        overlap
        top
        right
      >
        <v-btn v-on="on" @click="toggleLowBandwithMode" color="primary" icon>
          <v-icon :style="iconStyle" data-cy="toggle-low-data-mode">
            {{ lowBandwithIcon }}
          </v-icon>
        </v-btn>
      </v-badge>
    </template>
    <span data-cy="lowBandwidthModeTooltip">
      Slow Bandwitdh mode will make things load one at the time, significantly
      improving loading performance when loading very large projects or when
      working on a low bandwidth
    </span>
  </v-tooltip>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getters, actions } from "@/store/session/types";

const localstoreKey = "lowBandwidthMode";

export default {
  name: "LowBandwidthMode",
  computed: {
    ...mapGetters([getters.SessionLowbandWithModeActive]),
    lowBandwithIcon() {
      return this.SessionLowbandWithModeActive
        ? "mdi-speedometer-slow"
        : "mdi-speedometer";
    },
    iconStyle() {
      return { opacity: this.SessionLowbandWithModeActive ? 1 : 0.7 };
    }
  },
  mounted() {
    const lowBandwidthMode = localStorage.getItem(localstoreKey);
    this.SessionSetLowBandwidthMode(lowBandwidthMode === "true");
  },
  methods: {
    ...mapActions([actions.SessionSetLowBandwidthMode]),
    toggleLowBandwithMode() {
      localStorage.setItem(localstoreKey, !this.SessionLowbandWithModeActive);
      this.SessionSetLowBandwidthMode(!this.SessionLowbandWithModeActive);
    }
  }
};
</script>

<style></style>
