import { BaseTypes } from "@S/base_types";

const getters = {
  GetAllCases: "GetAllCases",
  GetAllCaseIds: "GetAllCaseIds",
  GetAllCaseLabels: "GetAllCaseLabels",
  CaseGetName: "CaseGetName",
  CaseGetCreatedBy: "CaseGetCreatedBy",
  CaseGetDateCreated: "CaseGetDateCreated",
  CaseGetDetails: "CaseGetDetails",
  CaseGetLazilyLoadedOptions: "CaseGetLazilyLoadedOptions",

  CaseHasVisibleResults: "CaseHasVisibleResults",
  CaseGetAllOptionsIds: "CaseGetAllOptionsIds",
  GetOptionCaseNameAndId: "GetOptionCaseNameAndId",
  CaseGetOptionsObjectives: "CaseGetOptionsObjectives",
  CaseGetTopObjectiveOptions: "CaseGetTopObjectiveOptions",
  CaseGetAllVisibleOptionsIds: "CaseGetAllVisibleOptionsIds",
  CaseGetAllOptionsStates: "CaseGetAllOptionsStates",

  CaseGetResultPropertiesById: "CaseGetResultPropertiesById",
  GetAllProjectResults: "GetAllProjectResults",

  AllVisibleHeatmaps: "AllVisibleHeatmaps",
  CaseGetHeatmap: "CaseGetHeatmap",
  CaseHasHeatmap: "CaseHasHeatmap",
  CaseGetHeatmapSize: "CaseGetHeatmapSize",
  CaseGetHeatmapVisibility: "CaseGetHeatmapVisibility",
  GetLabelsByCaseId: "GetLabelsByCaseId",

  CaseGetAggregateResults: "CaseGetAggregateResults",

  AnyCasesLoading: "AnyCasesLoading",
  CaseGetFailureReason: "CaseGetFailureReason",

  CaseGetPolygonResultSummaries: "CaseGetPolygonResultSummaries",
  IsOptionOrPolygonResultVisible: "IsOptionOrPolygonResultVisible",

  GetSortedCasesWithSummariesOfSelectedOptions:
    "GetSortedCasesWithSummariesOfSelectedOptions",

  CaseGetOptionsWithObjective: "CaseGetOptionsWithObjective",
  CaseGetOptionsConstraintViolation: "CaseGetOptionsConstraintViolation"
};

const mutations = {
  AddCase: "AddCase",
  RemoveCase: "RemoveCase",

  CaseDeleteOption: "CaseDeleteOption",
  RemovePolygonResult: "RemovePolygonResult",

  CaseSetLoadingState: "CaseSetLoadingState",

  ResetCasesState: "ResetCasesState",
  CaseAddOption: "CaseAddOption",
  CaseSetLazilyLoadedOptions: "CaseSetLazilyLoadedOptions",
  CaseSetOptionsObjectives: "CaseSetOptionsObjectives",
  CaseSetTopObjectiveOptions: "CaseSetTopObjectiveOptions",
  RemoveHeatmapByCaseId: "RemoveHeatmapByCaseId"
};

const actions = {
  CaseDelete: "CaseDelete",
  CaseToggleHeatmapVisibility: "CaseToggleHeatmapVisibility",

  CaseSetResultVisibility: "CaseSetResultVisibility",
  CaseSetAllResultsVisibility: "CaseSetAllResultsVisibility",

  AddCase: "AddCase",
  RemoveCase: "RemoveCase",
  RemovePolygonResult: "RemovePolygonResult",
  CaseAddOption: "CaseAddOption",
  CaseDeleteOption: "CaseDeleteOption",
  CaseUpdateOptionsLazilyLoaded: "CaseUpdateOptionsLazilyLoaded",
  CaseSetLazilyLoadedOptions: "CaseSetLazilyLoadedOptions",
  CaseSetOptionsObjectives: "CaseSetOptionsObjectives",
  CaseSetTopObjectiveOptions: "CaseSetTopObjectiveOptions"
};

/**
 * @augments BaseTypes
 */
export class CaseTypes extends BaseTypes {}
CaseTypes.getters = getters;
CaseTypes.mutations = mutations;
CaseTypes.actions = actions;

export { getters, mutations, actions };
