import { actions, mutations, getters } from "@S/linkAnythingEverywhere/types";

export default {
  state: {
    isMentionListOpen: false,
    configSelectedInComment: null
  },
  getters: {
    [getters.IsMentionListOpen]: (state) => state.isMentionListOpen,
    [getters.ConfigSelectedInComment]: (state) => state.configSelectedInComment
  },
  mutations: {
    [mutations.SetIsMentionListOpen]: (state, payload) => {
      Vue.set(state, "isMentionListOpen", payload);
    },
    [mutations.SetConfigSelectedInComment]: (state, payload) => {
      Vue.set(state, "configSelectedInComment", payload);
    }
  },
  actions: {
    [actions.SetIsMentionListOpen]: ({ commit }, payload) => {
      commit(mutations.SetIsMentionListOpen, payload);
    },
    [actions.SetConfigSelectedInComment]: ({ commit }, payload) => {
      commit(mutations.SetConfigSelectedInComment, payload);
    }
  }
};
