const cableImage = "cable.svg";
const crossingArrowsImage = "crossing-arrows.svg";
const alertImage = "alert.svg";
const commentImage = "comment.svg";
const recordCircleOutlineImage = "record-circle-outline.svg";
const currencyMntImage = "currency-mnt.svg";
const currencyKztImage = "currency-kzt.svg";
const towerTerminalImage = "tower-terminal.svg";
const formatWrapTopBottomRotatedImage = "format-wrap-top-bottom-rotated.svg";
const formatWrapTopBottomImage = "format-wrap-top-bottom.svg";
const fenceImage = "fence.svg";
const roadImage = "road.svg";
const wavesImage = "waves.svg";
const lightningBoltImage = "lightning-bolt.svg";
const pipeImage = "pipe.svg";

const iconsExcludedFromIconLegend = ["comment"];

/**
 * Search terms/keywords
 *
 * This array contains objects of keys and aliases
 * This is used in order to lookup a key for an image based on an alias or the key iteself
 * The match is found when the input CONTAINS the alias.
 * If more than one is found then ONLY the LAST result is returned, so make sure your aliases are as unique as possible also and not matching more than one key (icon).
 * These are NOT case sensitive.
 *
 * One object should contain a default: true property which will point to a fallback key (icon) if no matches are found.
 */
const keywords = [
  {
    key: "TelecomCablesInUse",
    aliases: ["telecom", "fibre", "fiber", "phone", "data", "pilot", "cable"]
  },
  {
    key: "crossing",
    aliases: [
      "crossing_indices",
      "crossing_index",
      "infrastructure_crossing_indices",
      "crossing_default"
    ]
  },
  {
    key: "tower_angle",
    aliases: ["angle", "deviation", "tension"],
    blocked_words: ["rectangle"]
  },
  {
    key: "tower_suspension",
    aliases: ["standard", "support", "suspension", "intermediate"]
  },
  {
    key: "tower_terminal",
    aliases: ["terminal"]
  },
  {
    key: "water_asset_washout",
    aliases: ["wash", "washout", "blow", "washout_position"]
  },
  {
    key: "water_asset_air_valve",
    aliases: ["air", "release", "vacuum", "position_airvalve"]
  },
  {
    key: "crossing_rail",
    aliases: ["rail", "train"],
    blocked_words: ["trail"]
  },
  {
    key: "crossing_road",
    aliases: ["road", "street", "access", "track"],
    blocked_words: ["rail", "train"]
  },
  {
    key: "crossing_water",
    aliases: ["course", "river", "surface", "stream", "burn", "waterline"],
    blocked_words: ["pipe"]
  },
  {
    key: "crossing_electricity_line",
    aliases: ["electric", "transmission", "ETL", "power", "kV", "export"]
  },
  {
    key: "crossing_gas_water_pipeline",
    aliases: ["pipe", "gas", "water", "main", "trunk"],
    blocked_words: ["stream", "river", "burn", "course", "road", "waterlines"]
  },
  {
    key: "proximity_building",
    aliases: ["proximity", "residence", "residential", "dwellings"]
  },
  {
    key: "comment",
    aliases: []
  },
  { key: "default", default: true }
];

/**
 * Icons object
 * This object points a key to an icon file
 */
const itemsAndIcons = {
  TelecomCablesInUse: cableImage,
  crossing: crossingArrowsImage,
  tower_angle: currencyMntImage,
  tower_suspension: currencyKztImage,
  tower_terminal: towerTerminalImage,
  water_asset_washout: formatWrapTopBottomRotatedImage,
  water_asset_air_valve: formatWrapTopBottomImage,
  crossing_rail: fenceImage,
  crossing_road: roadImage,
  crossing_water: wavesImage,
  crossing_electricity_line: lightningBoltImage,
  crossing_gas_water_pipeline: pipeImage,
  proximity_building: alertImage,
  comment: commentImage,
  default: recordCircleOutlineImage
};

// TODO: Remove this singleton and use a store instead?
const iconsInUse = [
  { key: "default", aliases: ["default"] }
  //{key: 'crossing_water', aliasUsed: 'river'}
];

export function getIconKey(keyword) {
  //Hold the key that we find in the iteration
  let matchedKeyword = "default";

  //If the specified keyword matches the key, then
  if (keywords?.find((kw) => kw.key.toLowerCase() === keyword.toLowerCase())) {
    //Set the matched keyword
    matchedKeyword = keyword;
  }

  //If the specified keyword is not an exact key, search the aliases...
  if (matchedKeyword === "default") {
    //For each object in the keywords array
    keywords.forEach((kw) => {
      //If the object has an aliases array
      if (kw.aliases && kw.aliases.length > 0) {
        //For each alias in the keyword's aliases
        kw.aliases.forEach((alias) => {
          //If the specified keyword has the alias within it,
          if (keyword.toLowerCase().includes(alias.toLowerCase())) {
            //If the object has a blocked_words list
            if (kw.blocked_words) {
              //If the blocked words list has less than one (ZERO) words which are contained in the specified keyword
              if (
                !kw.blocked_words.some((bw) =>
                  keyword.toLowerCase().includes(bw.toLowerCase())
                )
              ) {
                //Set the matched keyword
                matchedKeyword = kw.key;
              }
            } else {
              //Else there was no blocked words so set the matched keyword
              matchedKeyword = kw.key;
            }
          }
        });
      }
    });
  }

  //If the used icons array already has this icon key inside
  if (iconsInUse.some((i) => i.key === matchedKeyword)) {
    //Then add the alias to its list of aliases if that is not already inside

    if (
      !iconsInUse
        ?.find((i) => i.key === matchedKeyword)
        ?.aliases?.includes(keyword)
    ) {
      iconsInUse?.find((i) => i.key === matchedKeyword)?.aliases?.push(keyword);
    }
  } else {
    //Else the array does not have an entry for an icon of this key, so add it and the first alias.
    if (!iconsExcludedFromIconLegend.includes(matchedKeyword)) {
      iconsInUse.push({ key: matchedKeyword, aliases: [keyword] });
    }
  }

  return matchedKeyword;
}

export function toDiscreteIconImage(inputKey) {
  const base = "/images/icons/";
  const key = getIconKey(inputKey);

  return base.concat(itemsAndIcons[key]);
}

export function getIconsInUse() {
  return iconsInUse;
}

export function formatIconDisplayName(iconName) {
  let i = iconName?.split("_")?.split("-").length;
  const parts = iconName?.split("_")?.split("-");

  for (i = 0; i < parts.length; i++) {
    parts[i] = parts[i]?.charAt(0)?.toUpperCase() + parts[i]?.slice(1);
  }

  return parts.join(" ");
}

export const getArrayOfKeywords = () => {
  return keywords.map((kw) => {
    return kw.key;
  });
};

export default itemsAndIcons;
