import axios from "axios";
import axiosRetry from "axios-retry";

// Copy of the SAFE_HTTP_METHODS const in axios-retry to
// filter on GET requests only
const SAFE_HTTP_METHODS = ["get", "head", "options"];
/**
 * @param  {Error}  error
 * @return {boolean}
 */
export function isSafeRequestError(error) {
  if (!error?.config) {
    // Cannot determine if the request can be retried
    return false;
  }

  const noRepeatStatusCodes = [400, 401, 403, 404, 413];

  if (noRepeatStatusCodes?.indexOf(error?.response?.status) != -1) {
    console.log("failing request with no-retry status code");
    return false;
  }

  const isSafe = SAFE_HTTP_METHODS?.indexOf(error?.config?.method) !== -1;

  if (isSafe) {
    console.info("Repeating request", error?.request?.responseURL);
  }

  return isSafe;
}

const axiosRetryConfig = {
  retries: 10,
  retryDelay: axiosRetry.exponentialDelay,
  shouldResetTimeout: true,
  retryCondition: isSafeRequestError
};

import store from "@/store";
import { mutations } from "@/store/alert/types";
import { Config } from "./config-importer";
import { addAuthHeaders } from "@/helpers/addAuthHeaders";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const axiosConfig = {
  baseURL: Config.apiBaseAddress,
  timeout: 180 * 1000 // Timeout
};

export const _axios = axios.create(axiosConfig);

axiosRetry(_axios, axiosRetryConfig);

_axios.defaults.headers = {
  "Content-Type": "application/json"
};

// Add a response interceptor
_axios.interceptors.request.use(
  async (config) => {
    config = await addAuthHeaders(config);
    return config;
  },
  function (error) {
    // Do something with request error
    store.dispatch(mutations.AlertUpdate, {
      message: "Something went wrong, please contact us via Intercom below",
      type: "error"
    });
    return Promise.reject(error);
  }
);
// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);
