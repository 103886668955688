const getters = {
  DerivedMetricGetRagAnalysisNameObject: "DerivedMetricGetRagAnalysisNameObject"
};
const mutations = {
  DerivedMetricSetRagAnalysisNameObject:
    "DerivedMetricSetRagAnalysisNameObject",
  DerivedMetricResetRagAnalysisNameObject:
    "DerivedMetricResetRagAnalysisNameObject"
};
const actions = {
  DerivedMetricSetRagAnalysisNameObject:
    "DerivedMetricSetRagAnalysisNameObject",
  DerivedMetricResetRagAnalysisNameObject:
    "DerivedMetricResetRagAnalysisNameObject"
};

export { getters, mutations, actions };

export default { getters, mutations, actions };
