class PolygonAnalysisTypes {
    static getters = {
        GetAnalysisForPolygon: "GetAnalysisForPolygon",
        GetAnalysisForAllPolygons: "GetAnalysisForAllPolygons",
    }
    static mutations = {
        SetPolygonAnalysisResult: "SetPolygonAnalysisResult",
        ClearPolygonAnalysisResult: "ClearPolygonAnalysisResult",
    }
    static actions = {
        SetPolygonAnalysisResult: "SetPolygonAnalysisResult",
        ClearPolygonAnalysisResult: "ClearPolygonAnalysisResult",
    }
}

export { PolygonAnalysisTypes }
