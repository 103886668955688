import { POLYGON_RESULT_ID_PREFIX } from "@/constants/Prefixes";

function filterFalsy(obj) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] || key === "isVisible") {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

const createMapStyle = (obj) => {
  const showLayers = Object.entries(obj.mapStyle.showLayers).map(
    ([layer, show]) => ({
      layer,
      show
    })
  );

  const mapStyleInput = {
    showLayers,
    viewPort: obj.mapStyle.viewPort,
    baseMapStyle: obj.mapStyle.baseMapStyle,
    threeD: obj.mapStyle.threeD,
    groupBy: obj.mapStyle.groupBy,
    datasetLayerStyles: obj.mapStyle.datasetLayerStyles,
    activeRasterDatasetID: obj.mapStyle.activeRasterDatasetID,
    routePointStyles: obj.mapStyle.routePointStyles.map((item) => {
      const obj = filterFalsy(item);
      obj.asset = "ROUTE_POINT";
      return obj;
    }),
    lineStyles: obj.mapStyle.lineStyles.map((item) => {
      const obj = filterFalsy(item);
      obj.asset = "HORIZONTAL_PROFILE";
      return obj;
    }),
    polygonStyles: obj.mapStyle.polygonStyles.map((item) => {
      const obj = filterFalsy(item);
      obj.asset = "POLYGON";
      return obj;
    })
  };

  return mapStyleInput;
};

function makeOptionsState(optionsState) {
  const obj = {
    selected: optionsState?.selected || [],
    styles: optionsState?.styles?.map((item) => filterFalsy(item)) || []
  };

  if (optionsState.activeId) {
    obj.activeId = optionsState.activeId;
  }

  return obj;
}

function makePolygonResultsState(polygonResultsState) {
  const obj = {
    styles:
      polygonResultsState?.styles.map((style) => {
        return {
          ...style,
          assetId: parseInt(style.assetId.replace(POLYGON_RESULT_ID_PREFIX, ""))
        };
      }) || []
  };

  return obj;
}

function makeHeatmapsState(heatmapsState) {
  return {
    styles: Object.entries(heatmapsState?.styles || {}).map(([key, value]) => {
      return { caseID: key, ...value };
    })
  };
}

const convertToViewInputWithId = ({ obj, name, id }) => {
  return {
    id,
    name,
    mapStyle: createMapStyle(obj),
    optionsState: makeOptionsState(obj.optionsState),
    polygonResultsState: makePolygonResultsState(obj.polygonResultsState),
    heatmapsState: makeHeatmapsState(obj.heatmapsState)
  };
};
const convertToViewInputWithProjectId = ({ obj, name, projectID }) => {
  return {
    name,
    projectID,
    mapStyle: createMapStyle(obj),
    optionsState: makeOptionsState(obj.optionsState),
    polygonResultsState: makePolygonResultsState(obj.polygonResultsState),
    heatmapsState: makeHeatmapsState(obj.heatmapsState)
  };
};

export { convertToViewInputWithProjectId, convertToViewInputWithId };
