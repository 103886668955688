import { isEqual } from "lodash";

export const DatasetConverter = {
  combineFeatureGeometries(featuresArray) {
    const returnFeature = {
      type: "Feature",
      geometry: {
        type: "GeometryCollection",
        geometries: featuresArray
          ?.map((feature) => feature?.geometry?.geometries)
          ?.flat()
      }
    };

    return returnFeature;
  },

  flattenSimilarPropertyFeaturesToGeometryCollection(featuresArray) {
    if (!featuresArray) {
      return;
    }

    const propMap = new Map();

    featuresArray.forEach((feature) => {
      propMap.set(feature?.properties?.layer_name, {
        ...feature?.properties,
        layerName: feature?.properties?.layer_name
      });
    });

    const grouped = [];
    for (const [layerName, props] of propMap) {
      const featuresForProps = this.combineFeatureGeometries(
        featuresArray?.filter((feature) =>
          isEqual(feature?.properties?.layer_name, layerName)
        )
      );

      featuresForProps.properties = props;
      delete featuresForProps?.properties?.layer_name;
      grouped?.push(featuresForProps);
    }

    if (grouped?.length > 0 && !isNaN(grouped[0]?.layerName)) {
      grouped?.sort((a, b) => a.layerName - b.layerName);
    }

    return grouped;
  },

  applyPropertyAcrossFeatures(featuresArray, properties, layerNameIdMap) {
    return featuresArray?.map((feature) => {
      const mergedProperties = { ...feature?.properties, ...properties };
      const layerName = feature?.properties?.layerName;
      const layerId = layerName ? layerNameIdMap[layerName] : null;

      return {
        ...feature,
        id: layerId,
        properties: mergedProperties
      };
    });
  },

  convertFeatures(featuresArray, properties, layerNameIdMap) {
    if (!featuresArray) {
      return;
    }

    let flattenedFeatures =
      this.flattenSimilarPropertyFeaturesToGeometryCollection(featuresArray);

    if (properties) {
      flattenedFeatures = this.applyPropertyAcrossFeatures(
        flattenedFeatures,
        properties,
        layerNameIdMap
      );
    }

    return flattenedFeatures;
  },

  convertDataset(dataset, properties, layerNameIdMap) {
    if (!dataset?.features) {
      return;
    }

    const convertedFeatures = {
      features: this.convertFeatures(
        dataset.features,
        properties,
        layerNameIdMap
      )
    };

    return { ...dataset, ...convertedFeatures };
  },

  combineDatasets(datasets, baseFeatureCollection) {
    return {
      ...baseFeatureCollection,
      features: datasets
        ?.map((dataset) => dataset.features)
        ?.reduce((prev, curr) => [...prev, ...curr], [])
    };
  }
};

export const DatasetExpressionBuilder = {
  generateColoursLookupExpression(styleProperty) {
    const mapboxExpression = ["feature-state", styleProperty];
    return mapboxExpression;
  },

  generateVisibilityExpression(defaultOpacity) {
    const define = [
      "case",
      [
        "coalesce",
        ["feature-state", "visible"],
        ["feature-state", "selected"],
        false
      ],
      ["coalesce", ["feature-state", "opacity"], defaultOpacity],
      0
    ];
    return define;
  }
};
