import store from "@/store";
import { mutations } from "@/store/alert/types";
import jwt_decode from "jwt-decode";
import {
  mutations as sessionMutations,
  getters as sessionGetters
} from "@S/session/types";
import { ref, watch } from "vue";

import {
  signInWithRedirect,
  fetchAuthSession,
  signOut,
  fetchUserAttributes
} from "aws-amplify/auth";
import createAuth0Client from "@auth0/auth0-spa-js";
import { usingCognito } from "@/plugins/authHelpers";

import { Amplify } from "aws-amplify";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import posthog from "posthog-js";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: window.config?.COGNITO_USER_POOL_CLIENT_ID,
      userPoolId: window.config?.COGNITO_USER_POOL_ID,
      loginWith: {
        oauth: {
          domain: window.config?.COGNITO_USER_POOL_DOMAIN,
          scopes: ["aws.cognito.signin.user.admin openid phone email profile"],
          redirectSignIn: [`${window.location.origin}/home`],
          redirectSignOut: [`${window.location.origin}/home`],
          responseType: "code"
        }
      }
    }
  }
});

const authCacheLocation = window?.config?.AUTH_CACHE_LOCATION;
if (authCacheLocation !== "localstorage") {
  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());
}

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const authState = ref({
  loading: true,
  isAuthenticated: false,
  user: {},
  error: null
});

const auth0Client = ref(null);

export async function authReady() {
  if (authState?.value?.loading === false) {
    return;
  } else {
    await new Promise((resolve) => {
      watch(authState?.value, (value) => {
        const loading = value?.loading;
        if (loading === false) {
          resolve();
        }
      });
    });
  }
}

export const executeOnAuthReady = async (fn) => {
  await authReady();
  await fn();
};

const prodAPIAudience = "api.optioneer.ai";

export async function loginWithPopup(o) {
  try {
    await auth0Client.value.loginWithPopup(o);
    authState.value.user = await auth0Client.value.getUser();
    authState.value.isAuthenticated = await auth0Client.value.isAuthenticated();
    authState.value.error = null;
    await updateAccessTokenExpiryTime();
  } catch (e) {
    store.dispatch(mutations.AlertUpdate, {
      message: "Something went wrong, please contact us via Intercom below",
      type: "error"
    });
    console.error("error logging in with popup", e);
    authState.value.error = e;
  }
}

export async function handleRedirectCallback() {
  authState.value.loading = true;
  try {
    await auth0Client.value.handleRedirectCallback();
    authState.value.user = await auth0Client.value.getUser();
    authState.value.isAuthenticated = true;
    authState.value.error = null;
  } catch (e) {
    store.dispatch(mutations.AlertUpdate, {
      message: "Something went wrong, please contact us via Intercom below",
      type: "error"
    });
    authState.value.error = e;
  } finally {
    authState.value.loading = false;
  }
}

export function loginWithRedirect(o) {
  if (usingCognito()) {
    console.log("logging in with redirect");
    return signInWithRedirect();
  }
  return auth0Client.value.loginWithRedirect(o);
}

export function getIdTokenClaims(o) {
  return auth0Client.value.getIdTokenClaims(o);
}

export async function getTokenSilently(o) {
  await authReady();
  if (usingCognito()) {
    const session = await fetchAuthSession();
    const { accessToken } = session?.tokens || {};
    const rawAccessToken = accessToken?.toString();
    return rawAccessToken;
  }
  try {
    return await auth0Client.value?.getTokenSilently(o);
  } catch (err) {
    if (err.error !== "login_required") {
      throw err;
    }

    if (store.getters?.[sessionGetters.GetSessionAccessExpired]) {
      return;
    }

    const now = Date.now();
    const fiveMinutesMs = 5 * 1000 * 60;
    store.commit(sessionMutations.SetSessionAccessExpired, true);
    store.commit(sessionMutations.SetSessionExpiry, now + fiveMinutesMs);
  }
}

export function getTokenWithPopup(o) {
  return auth0Client.value.getTokenWithPopup(o);
}

export function logout(o) {
  window.Intercom("shutdown", {});
  window.Intercom("boot", {});
  if (usingCognito()) {
    return signOut();
  }
  posthog.reset();
  return auth0Client.value.logout(o);
}

export async function updateAccessTokenExpiryTime() {
  if (usingCognito()) {
    return;
  }
  try {
    const accessToken = await getTokenSilently({
      audience: window.config?.VUE_APP_API_AUDIENCE || prodAPIAudience,
      ignoreCache: true
    });
    const parsedToken = jwt_decode(accessToken);
    const expiryTime = parsedToken?.exp;

    if (expiryTime) {
      const expiryTimeInMilliseconds = expiryTime * 1000;

      console.log("session expiry time: ", new Date(expiryTimeInMilliseconds));
      store.commit(sessionMutations.SetSessionExpiry, expiryTimeInMilliseconds);
    } else {
      throw Error("No expiry time found in token", JSON.stringify(parsedToken));
    }
  } catch (err) {
    console.error("error grabbing session expiry time: \n", err);
  }
}

export async function initialiseAuth0Client({
  options,
  redirectUri,
  onRedirectCallback
}) {
  auth0Client.value = await createAuth0Client({
    domain: options.domain,
    client_id: options.clientId,
    audience: options.audience,
    redirect_uri: redirectUri,
    cacheLocation: window.config.AUTH_CACHE_LOCATION || "memory"
  });

  try {
    // This block runs after a normal login from login page
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      const { appState } = await auth0Client.value.handleRedirectCallback();
      authState.value.error = null;
      onRedirectCallback(appState);
    }
  } catch (e) {
    store.dispatch(mutations.AlertUpdate, {
      message: "Something went wrong, please contact us via Intercom below",
      type: "error"
    });
    authState.value.error = e;
  } finally {
    authState.value.isAuthenticated = await auth0Client.value.isAuthenticated();
    authState.value.user = await auth0Client.value.getUser();
  }
}

export async function initAuthPlugin({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) {
  if (usingCognito()) {
    console.log("checking auth session");
    try {
      const session = await fetchAuthSession();
      console.log("session", session);

      const { accessToken, idToken } = session?.tokens || {};
      if (!accessToken || !idToken) {
        console.error("no access token or id token", session);
        authState.value.loading = false;
        return;
      }
      authState.value.isAuthenticated = true;
      authState.value.user = await fetchUserAttributes();
      // authState.value.user.picture = "https://ui-avatars.com/api/?name=J+M&background=random"
    } catch (err) {
      console.error("error fetching auth session", err);
    }
  } else {
    await initialiseAuth0Client({
      options,
      redirectUri,
      onRedirectCallback
    });
  }

  authState.value.loading = false;

  const userEmail = authState.value.user?.email;
  const username = authState.value.user?.name;

  if (userEmail) {
    window.heap?.addUserProperties({ email: userEmail, name: username });
    posthog.identify(
      authState.value.user?.sub,
      { email: userEmail }
    );
  }
}

export const authGuard = (to) => {
  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authState.value.isAuthenticated) {
      return true;
    }

    console.log("User not authenticated");

    // Otherwise, log in
    loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  executeOnAuthReady(fn);
};

export const AuthPlugin = {
  async install(Vue, options) {
    await initAuthPlugin(options);
  }
};
