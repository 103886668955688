import { getTokenSilently } from "@/plugins/authPlugin";

export const addAuthHeaders = async (config) => {
  try {
    const token = await getTokenSilently();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    console.error(
      "error grabbing access token for interceptor headers\n",
      error
    );
  }

  return config;
};
