function randomInteger(max) {
  return Math.floor(Math.random() * (max + 1));
}

export const generateRandomRgbaString = (opacity) => {
  const r = randomInteger(255);
  const g = randomInteger(255);
  const b = randomInteger(255);
  const a = opacity || 1;
  return `rgba(${r},${g},${b},${a})`;
};

export default generateRandomRgbaString;
