const getters = {
  SavedAnalysisReportCategoryId: "SavedAnalysisReportCategoryId",
  SavedAnalysisReportDescription: "SavedAnalysisReportDescription",
  AnalysisReportSavedVersionModalProperties:
    "AnalysisReportSavedVersionModalProperties",
  AnalysisReportSavedVersion: "AnalysisReportSavedVersion",
  AnalysisReportGetAnalysisReports: "AnalysisReportGetAnalysisReports",
  GetLoadingState: "GetLoadingState",
  AnalysisReportWorkingVersion: "AnalysisReportWorkingVersion",
  SavedAnalysisReportId: "SavedAnalysisReportId",
  AnalysisReportWorkingVersionLayerParamsAsArray:
    "AnalysisReportWorkingVersionLayerParamsAsArray"
};
const mutations = {
  AnalysisReportSetSavedVersion: "AnalysisReportSetSavedVersion",
  AnalysisReportResetState: "AnalysisReportResetState",
  AnalysisReportSetAnalysisReports: "AnalysisReportSetAnalysisReports",
  SetLoadingState: "SetLoadingState",
  AddAnalysisReport: "AddAnalysisReport"
};
const actions = {};

export { getters, mutations, actions };
const types = { getters, mutations, actions };
export default types;
