<template>
  <portal to="DeletionSnackbar">
    <v-snackbar
      :value="showSnackbar"
      :timeout="timeout"
      data-cy="snackbar"
      width="2000"
      left
      bottom
      dark
    >
      <slot>You have deleted an item.</slot>
      <template v-slot:action="{ attrs }">
        <v-container pa-0>
          <v-row>
            <v-col>
              <v-btn
                v-bind="attrs"
                @click="cancel"
                color="white"
                outlined
                dense
              >
                Undo
              </v-btn>
              <v-btn
                v-bind="attrs"
                @click="close"
                data-cy="deletionSnackbarCloseButton"
                color="white"
                text
                dense
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-snackbar>
  </portal>
</template>

<script>
export default {
  name: "DeletionSnackbar",
  emits: ["deletionCancelled", "deletionConfirmed"], 
  props: ["showSnackbar"],

  data() {
    return {
      timeout: 5000,
      deletionCancelled: false,
      delectionConfirmedEmitted: false,
      timeoutToken: ""
    };
  },

  methods: {
    cancel() {
      this.deletionCancelled = true;
      this.delectionConfirmedEmitted = false;
      this.$emit("deletionCancelled");
    },
    close() {
      this.emitDeletionConfirmed();
    },
    removeTimeout() {
      clearTimeout(this.timeoutToken);
      this.timeoutToken = "";
    },
    emitDeletionConfirmed() {
      if (!this.delectionConfirmedEmitted) {
        this.removeTimeout();
        this.$emit("deletionConfirmed");
        this.delectionConfirmedEmitted = true;
      }
    }
  },
  watch: {
    showSnackbar: function (newValue) {
      if (newValue === true) {
        //Remove any previous state of deletionCancelled from preivous deletes
        this.deletionCancelled = false;

        this.timeoutToken = setTimeout(() => {
          if (!this.deletionCancelled) {
            this.emitDeletionConfirmed();
          }
        }, this.timeout);
      }

      //Reset the deletion confirmed to false, so that we can delete again and this isn't still true
      this.delectionConfirmedEmitted = false;
    }
  }
};
</script>

<style scoped></style>
