import ParametersTypes from "@/constants/ParametersTypes";
import { actions, mutations, getters } from "./types";

export default {
  state: {
    selectedParameter: {},
    configDrChangeMap: {},
    anyDatasetHasErrors: false,
    drToBeCompared: null,
    primarySetting: null
  },
  getters: {
    [getters.ConfigurationParameterConfigDrChangeMap](state) {
      return state.configDrChangeMap;
    },
    [getters.ConfigurationParameterDrToBeCompared](state) {
      return state.drToBeCompared;
    },
    [getters.ConfigurationParameterSelectedParameter](state) {
      return state.selectedParameter;
    },
    [getters.ConfigurationParameterPrimarySetting](state) {
      return state.primarySetting;
    },

    [getters.ConfigurationParameterDatasetHasErrors](state) {
      return state.anyDatasetHasErrors;
    },
    [getters.ConfigurationParameterDesignRuleGotActivatedOrDeactivated]:
      (state) => (configId) => {
        if (state.configDrChangeMap?.[configId]) {
          const isChanged = (el) => {
            return el.changed;
          };

          return Object.values(state.configDrChangeMap?.[configId]).some(
            isChanged
          );
        } else {
          return false;
        }
      },
    [getters.IsDesignRuleSelected]: (state, getters) => {
      return (
        getters.ConfigurationParameterSelectedParameter?.value &&
        getters.ConfigurationParameterSelectedParameter?.type ==
          ParametersTypes.DESIGN_RULE
      );
    }
  },
  mutations: {
    [mutations.ConfigurationParameterSetPrimarySetting]: (state, payload) => {
      state.primarySetting = payload;
    },
    [mutations.ConfigurationParameterSetSelectedParameter]: (
      state,
      payload
    ) => {
      state.selectedParameter = payload;
    },
    [mutations.ConfigurationParameterSetDrToBeCompared]: (state, payload) => {
      state.drToBeCompared = payload;
    },
    [mutations.ConfigurationParameterSetDatasetHasError]: (state, payload) => {
      state.anyDatasetHasErrors = payload;
    },
    [mutations.ConfigurationParameterResetDesignRuleActivityMapping]: (
      state,
      payload
    ) => {
      if (!payload || !state.configDrChangeMap?.[payload]) return; // if payload is falsy, do nothing
      const keys = Object.keys(state.configDrChangeMap?.[payload]);

      if (keys.length > 0) {
        //The object has at least one key.
        for (const key of keys) {
          state.configDrChangeMap[payload][key].changed = false;
        }
      }
    },

    [mutations.ConfigurationParameterSetConfigDrChangeMap]: (
      state,
      payload
    ) => {
      const mainObject = state.configDrChangeMap[payload.configId];
      let subObject;
      if (mainObject) {
        subObject = mainObject[payload.designRuleId];
      }

      state.configDrChangeMap = {
        ...state.configDrChangeMap,
        [payload.configId]: {
          ...state.configDrChangeMap[payload.configId],
          [payload.designRuleId]: { changed: subObject?.changed ? false : true }
        }
      };
    }
  },
  actions: {
    [actions.ConfigurationParameterSetPrimarySetting]: (
      { commit },
      payload
    ) => {
      commit(mutations.ConfigurationParameterSetPrimarySetting, payload);
    },
    [actions.ConfigurationParameterSetConfigDrChangeMap]: (
      { commit },
      payload
    ) => {
      commit(mutations.ConfigurationParameterSetConfigDrChangeMap, payload);
    },
    [actions.ConfigurationParameterSetSelectedParameter]: (
      { commit },
      payload
    ) => {
      commit(mutations.ConfigurationParameterSetSelectedParameter, payload);
    },
    [actions.ConfigurationParameterResetDesignRuleActivityMapping]: (
      { commit },
      payload
    ) => {
      commit(
        mutations.ConfigurationParameterResetDesignRuleActivityMapping,
        payload
      );
    },

    [actions.ConfigurationParameterSetDatasetHasError]: (
      { commit },
      payload
    ) => {
      commit(mutations.ConfigurationParameterSetDatasetHasError, payload);
    },
    [actions.ConfigurationParameterSetDrToBeCompared]: (
      { commit },
      payload
    ) => {
      commit(mutations.ConfigurationParameterSetDrToBeCompared, payload);
    }
  }
};
