import types from "./types";
import { getters as resultsTableGetters } from "@S/resultsTable/types";
import {
  getters as mapChartGetters,
  mutations as mapChartMutations
} from "@S/mapChart/types";
import { Has } from "@/hooks/declarative-filters";
import { cloneDeep, isEqual } from "lodash";

export function createEmptyState() {
  //No need for a list of available views, we will fetch that in the component via graphql and run SaveMetricView with the payload.
  return {
    id: null, //Null when no view is selected
    name: null, //Null when no view is selected
    workingMetrics: [], //The user's current metrics, regardless if they are saved or not
    savedMetrics: [] //The metrics at the time of last save.
  };
}

export default {
  state: createEmptyState(),
  getters: {
    [types.getters.MetricsViewGetName]: (state) => {
      return state.name;
    },
    [types.getters.MetricsViewGetId]: (state) => {
      return state.id;
    },

    [types.getters.MetricsViewGetSavedMetrics]: (state) => {
      return state.savedMetrics;
    },
    [types.getters.MetricsViewGetWorkingMetrics]: (state) => {
      return state.workingMetrics;
    },
    [types.getters.MetricsViewGetWorkingMetricNames]: (state) => {
      return state.workingMetrics.map((metric) => metric.name);
    },
    [types.getters.MetricsViewGetMetricsByName]:
      (state, getters) =>
      (omitMetrics = []) => {
        //'omitMetrics' takes a list of metrics to show 0% in the fractionOfTotal output. Usually used when options are missing a value for it.
        const metricsByName = {};
        let sum = 0;

        state.workingMetrics.forEach((metric) => {
          if (metric.weight && !omitMetrics.includes(metric.name)) {
            //Also need to ensure that metrics which are exclusive to opportunity maps are not included in the sum
            if (
              getters?.[resultsTableGetters.ResultsTableGetAdditionalHeaders]?.[
                metric.name
              ]?.isOptionMetric
            ) {
              sum += metric.weight;
            }
          }
        });

        //Clone the working metrics state before looping so we don't mutate it
        cloneDeep(state.workingMetrics).forEach((metric) => {
          metricsByName[metric.name] = metric;

          let fractionOfTotal = 0;

          //If the metric is not included in the omitted ones, calculate the fraction of total
          if (!omitMetrics.length || !omitMetrics.includes(metric.name)) {
            fractionOfTotal = metric.weight / sum;
          }

          if (isNaN(fractionOfTotal) || fractionOfTotal === undefined) {
            fractionOfTotal = null;
          }
          metricsByName[metric.name]["fractionOfTotal"] = fractionOfTotal;
        });

        return metricsByName;
      },
    [types.getters.MetricsViewGetIsDirty]: (state, getters) => {
      //Dirty if the working metrics do not match the saved metrics, or there is no working metrics. Also dirty if a metrics' weight does not match the saved version.
      return (
        !isEqual(state.workingMetrics, state.savedMetrics) ||
        !state.workingMetrics.length ||
        getters?.[mapChartGetters.IsOptionComparisonViewDirty]
      );
    }
  },
  mutations: {
    [types.mutations.MetricsViewRenameView]: (state, payload) => {
      // Update the name property of the state if the id matches
      if (state.id === payload.id) {
        Vue.set(state, "name", payload.name);
      }
    },
    [types.mutations.SaveMetricView](state, payload) {
      Vue.set(state, "id", payload.id);
      Vue.set(state, "name", payload.name);
      Vue.set(state, "savedMetrics", payload.selectedOptionMetrics);
    },
    [types.mutations.MetricsViewSetWorkingMetrics](state, payload) {
      //When the payload of metrics/weights comes in, make sure we are setting weight to 1 if undefined.
      const payloadMappedWeights = payload.map((metric) => {
        let weight = metric.weight;
        if (weight === undefined) {
          weight = 1;
        }

        return { name: metric.name, weight: weight };
      });

      Vue.set(state, "workingMetrics", payloadMappedWeights);
    },
    [types.mutations.MetricsViewDiscardChanges](state) {
      Vue.set(state, "workingMetrics", state.savedMetrics);
    },
    [types.mutations.MetricsViewResetState](state) {
      Object.assign(state, createEmptyState());
    }
  },
  actions: {
    [types.actions.SaveMetricView]({ commit }, payload) {
      commit(types.mutations.SaveMetricView, payload);
      commit(
        types.mutations.MetricsViewSetWorkingMetrics,
        payload.selectedOptionMetrics
      );
      commit(mapChartMutations.SetComparisonView, payload);
    },
    [types.actions.MetricsViewComputeAndSetWorkingMetrics](
      { commit },
      payload
    ) {
      const metricsWithName = payload.filter(Has.name);
      const metrics = metricsWithName.map((metric) => {
        return { name: metric.name, weight: metric.weight };
      });
      commit(types.mutations.MetricsViewSetWorkingMetrics, metrics);
    },
    [types.actions.MetricsViewSetWorkingMetrics]({ commit }, payload) {
      commit(types.mutations.MetricsViewSetWorkingMetrics, payload);
    },
    [types.actions.MetricsViewDiscardChanges]({ commit }) {
      commit(types.mutations.MetricsViewDiscardChanges);
    },
    [types.actions.MetricsViewResetState]({ commit }) {
      commit(types.mutations.MetricsViewResetState);
    }
  }
};
