import types from "@S/optionVisualisation/types";
import optionTypes from "@S/option/types";

export class OptionSelectionGrouping {
  static CASE = "case";
  static SCENARIO = "scenario";
  static NONE = "none";

  static isValid(value) {
    return (
      value === this.CASE || value === this.SCENARIO || value === this.NONE
    );
  }
  static fromString(value) {
    if (!this.isValid(value)) {
      throw new Error(
        `Invalid option selection grouping: ${value}. Valid values are: ${Object.values(
          this
        )}`
      );
    }
    return value;
  }
}

function newEmptyState() {
  return {
    optionSelectionGrouping: OptionSelectionGrouping.CASE,
    iconVisibility: {
      //optionId: {discreteItemName: bool}
    },
    counterVisibilty: {
      //optionId: {discreteItemName: bool}
    },
    polygonVisibility: {
      //optionId: {PolygonMetricName: bool}
    }
  };
}

export default {
  types: types,
  newEmptyState, //also included here to be able to use it in tests
  state: newEmptyState(),
  getters: {
    [types.getters.OptionVisGetSelectionGrouping]: (state) =>
      state.optionSelectionGrouping,
    [types.getters.OptionVisGetIconVisibility]:
      (state) =>
      ({ optionId, discreteItemName }) => {
        if (!optionId) {
          throw new Error(
            `Invalid parameters for getting icon visibility: optionId: ${optionId}, discreteItemName: ${discreteItemName}`
          );
        }
        if (!discreteItemName) {
          return state.iconVisibility[Number(optionId)] || {};
        }
        return (
          state.iconVisibility[Number(optionId)]?.[discreteItemName] || false
        );
      },
    [types.getters.OptionVisGetVisibleIconCombinations]: (state, getters) => {
      //returns a list of combinations of visible icons and option ids in the format
      //[{optionId: N, discreteItemName: String}, ...]
      const visibleOptionIds =
        getters[optionTypes.getters.OptionsGetVisibleIds];
      const ret = [];
      visibleOptionIds.forEach((optionId) => {
        const iconsInOption = state.iconVisibility?.[optionId] || [];
        for (const [discreteItemName, visible] of Object.entries(
          iconsInOption
        )) {
          if (visible) {
            ret.push({ optionId, discreteItemName });
          }
        }
      });
      return ret;
    },
    [types.getters.OptionVisGetCounterVisibility]:
      (state) =>
      ({ optionId, discreteItemName }) => {
        if (!(optionId && discreteItemName)) {
          throw new Error(
            `Invalid parameters for getting counter visibility: optionId: ${optionId}, discreteItemName: ${discreteItemName}`
          );
        }
        return (
          state.counterVisibilty[Number(optionId)]?.[discreteItemName] || false
        );
      },
    [types.getters.OptionVisGetVisibleCounterCombinations]: (
      state,
      getters
    ) => {
      const visibleOptionIds =
        getters[optionTypes.getters.OptionsGetVisibleIds];
      const ret = [];

      visibleOptionIds.forEach((optionId) => {
        const countersInOption = state.counterVisibilty?.[optionId] || [];
        for (const [discreteItemName, visible] of Object.entries(
          countersInOption
        )) {
          if (
            visible &&
            getters[types.getters.OptionVisGetIconVisibility]({
              optionId,
              discreteItemName
            })
          ) {
            ret.push({ optionId, discreteItemName });
          }
        }
      });
      return ret;
    },
    [types.getters.OptionVisGetPolygonVisibility]:
      (state) =>
      ({ optionId, polygonMetricName }) => {
        if (!(optionId && polygonMetricName)) {
          throw new Error(
            `Invalid parameters for getting polygon visibility: optionId: ${optionId}, polygonMetricName: ${polygonMetricName}`
          );
        }
        return (
          state.polygonVisibility[Number(optionId)]?.[polygonMetricName] ||
          false
        );
      },
    [types.getters.OptionVisGetPolygonVisibilityCombinations]: (
      state,
      getters
    ) => {
      //returns a list of combinations of visible polygons and option ids in the format
      //[{optionId: N, polygonMetricName: String}, ...]
      const activeOptionId = getters[optionTypes.getters.OptionsGetActiveId];
      if (
        !getters[optionTypes.getters.OptionsGetVisibleIdsAsSet].has(
          activeOptionId
        )
      ) {
        return []; // only show the polygons for the active options and if the active option is visible
      }
      const ret = [];

      const polygonsInOption = state.polygonVisibility?.[activeOptionId] || [];
      for (const [polygonMetricName, visible] of Object.entries(
        polygonsInOption
      )) {
        if (visible) {
          ret.push({ optionId: activeOptionId, polygonMetricName });
        }
      }

      return ret;
    }
  },
  actions: {
    [types.actions.OptionVisSetSelectionGrouping]: ({ commit }, payload) => {
      commit(types.mutations.OptionVisSetSelectionGrouping, payload);
    },
    [types.actions.OptionVisSetCounterVisibility]: (
      { commit, getters },
      { optionId, discreteItemName, value }
    ) => {
      const iconVisibility = getters[types.getters.OptionVisGetIconVisibility]({
        optionId,
        discreteItemName
      });
      if (!iconVisibility && value) {
        commit(types.mutations.OptionVisSetIconVisibility, {
          optionId,
          discreteItemName,
          value: true
        });
      }
      commit(types.mutations.OptionVisSetCounterVisibility, {
        optionId,
        discreteItemName,
        value
      });
    }
  },
  mutations: {
    [types.mutations.OptionVisSetSelectionGrouping]: (state, payload) => {
      if (!OptionSelectionGrouping.isValid(payload)) {
        throw new Error(
          `Invalid option selection grouping: ${payload}. Valid values are: ${Object.values(
            OptionSelectionGrouping
          )}`
        );
      }
      state.optionSelectionGrouping = payload;
    },
    [types.mutations.OptionVisSetIconVisibility]: (
      state,
      { optionId, discreteItemName, value }
    ) => {
      if (!(optionId && discreteItemName && value !== undefined)) {
        throw new Error(
          `Invalid parameters for setting icon visibility: optionId: ${optionId}, discreteItemName: ${discreteItemName}, value: ${value}`
        );
      }
      optionId = Number(optionId);
      if (!state.iconVisibility[optionId]) {
        Vue.set(state.iconVisibility, optionId, { [discreteItemName]: value });
      } else {
        Vue.set(state.iconVisibility[optionId], discreteItemName, value);
      }
    },
    [types.mutations.OptionVisSetCounterVisibility]: (
      state,
      { optionId, discreteItemName, value }
    ) => {
      if (!(optionId && discreteItemName && value !== undefined)) {
        throw new Error(
          `Invalid parameters for setting counter visibility: optionId: ${optionId}, discreteItemName: ${discreteItemName}, value: ${value}`
        );
      }
      value = Boolean(value);
      optionId = Number(optionId);
      if (!state.counterVisibilty[optionId]) {
        Vue.set(state.counterVisibilty, optionId, {
          [discreteItemName]: value
        });
      } else {
        Vue.set(state.counterVisibilty[optionId], discreteItemName, value);
      }
    },
    [types.mutations.OptionVisSetPolygonVisibility]: (
      state,
      { optionId, polygonMetricName, value }
    ) => {
      if (!(optionId && polygonMetricName && value !== undefined)) {
        throw new Error(
          `Invalid parameters for setting polygon visibility: optionId: ${optionId}, polygonMetricName: ${polygonMetricName}, value: ${value}`
        );
      }
      optionId = Number(optionId);
      if (!state.polygonVisibility[optionId]) {
        Vue.set(state.polygonVisibility, optionId, {
          [polygonMetricName]: value
        });
      } else {
        Vue.set(state.polygonVisibility[optionId], polygonMetricName, value);
      }
    }
  }
};
