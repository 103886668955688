export { status, getDisplayStatus };

const status = {
  PENDING: "PENDING",
  RUNNING: "RUNNING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

function getDisplayStatus(uploadStatus) {
  switch (uploadStatus) {
    case status.PENDING:
      return "Uploading";
    case status.RUNNING:
      return "Processing";
    case status.SUCCESS:
      return "Done";
    case status.ERROR:
      return "Something went wrong";
    default:
      console.error(
        `unexpected status returned from the backend: ${uploadStatus}`
      );
      return "Error";
  }
}
