class NewOptionTypes {
  static getters = {
    GetArchivedOptions: "GetArchivedOptions",

    OptionGetAllIds: "OptionGetAllIds",

    //option state
    OptionsGetActiveId: "OptionsGetActiveId",
    OptionsGetVisibleIds: "OptionsGetVisibleIds",
    OptionsGetVisibleIdsAsSet: "OptionsGetVisibleIdsAsSet",
    OptionsGetSelectedIds: "OptionsGetSelectedIds",
    OptionsGetHovered: "OptionsGetHovered",

    GetRouteIconsCountersVisibility: "GetRouteIconsCountersVisibility",
    GetSelectedOptionsMetricItems: "GetSelectedOptionsMetricItems",


    //single option breakdown
    GetOptionSummaryById: "GetOptionSummaryById",
    OptionGetLength: "OptionGetLength",
    OptionGetCaseId: "OptionGetCaseId",
    OptionsGetVisiblePolygonOutputNames: "OptionsGetVisiblePolygonOutputNames",
    GetOptionConstraintViolation: "GetOptionConstraintViolation",
    OptionsGetAllColours: "OptionsGetAllColours",
    OptionGetAlias: "OptionGetAlias",
    GetOptionColour: "GetOptionColour",
    OptionGetOptionMetrics: "OptionGetOptionMetrics",
    OptionGetDiscreteItems: "OptionGetDiscreteItems",

    OptionGetScenarios: "OptionGetScenarios",
    OptionGetCaseName: "OptionGetCaseName",
    OptionGetObjectives: "OptionGetObjectives",
    OptionGetTopObjective: "OptionGetTopObjective",
    GetOptionDisplayValue: "GetOptionDisplayValue",
    AnyOptionsLoading: "AnyOptionsLoading",
    GetOptionLoadingState: "GetOptionLoadingState",
    GetPolygonOutputsForOption: "GetPolygonOutputsForOption",
    GetOptionSort: "GetOptionSort",
    GetSortedSelectedOptionsForAutocompleteList:
      "GetSortedSelectedOptionsForAutocompleteList",
    GetOptionsAreGroupedByCase: "GetOptionsAreGroupedByCase",
    GetActiveOptionSummary: "GetActiveOptionSummary",
    GetSummariesOfSelectedOptions: "GetSummariesOfSelectedOptions",
    GetOptionDateSort: "GetOptionDateSort",

    //Aggregators
    GetAllOptionSummaries: "GetAllOptionSummaries",
    GetAllOptionsSummariesSorted: "GetAllOptionsSummariesSorted",
    OptionsGetPolygonOutputsForAllOptions:
      "OptionsGetPolygonOutputsForAllOptions",

    GetAmountOfOptionsUserCanSelect: "GetAmountOfOptionsUserCanSelect",
    GetGroupedMetricItemsOfSelectedOptions:
      "GetGroupedMetricItemsOfSelectedOptions",

    GetLazilyLoadedHoveredOptionGeojson: "GetLazilyLoadedHoveredOptionGeojson",
    GetLazilyLoadedActiveOptionGeojson: "GetLazilyLoadedActiveOptionGeojson"
  };
  static mutations = {
    SetArchivedOptions: "SetArchivedOptions",
    SetLazilyLoadedHoveredOptionGeojson: "SetLazilyLoadedHoveredOptionGeojson",
    SetLazilyLoadedActiveOptionGeojson: "SetLazilyLoadedActiveOptionGeojson",
    SetRouteIconsCountersVisibility: "SetRouteIconsCountersVisibility",
    OptionsUpdateOptionMetrics: "OptionsUpdateOptionMetrics",
    OptionsSetCaseName: "OptionsSetCaseName",
    OptionsSetCaseId: "OptionsSetCaseId",
    OptionsAdd: "OptionsAdd",
    OptionUpdate: "OptionUpdate",
    OptionsToggleVisibility: "OptionsToggleVisibility",
    OptionsSetPolygonOutputVis: "OptionsSetPolygonOutputVis",
    OptionsToggleInteractionState: "OptionsToggleInteractionState",
    OptionToggleHover: "OptionToggleHover",
    RemoveOption: "RemoveOption",
    SetOptionColour: "SetOptionColour",
    OptionsResetState: "OptionsResetState",
    SetAllOptionColours: "SetAllOptionColours",
    SetOptionLoadingState: "SetOptionLoadingState",
    OptionSetPolygonOutputsForOption: "OptionSetPolygonOutputsForOption",
    SetOptionSort: "SetOptionSort",
    OptionSetLength: "OptionSetLength"
  };
  static actions = {
    SetLazilyLoadedActiveOptionGeojson: "SetLazilyLoadedActiveOptionGeojson",
    SetLazilyLoadedHoveredOptionGeojson: "SetLazilyLoadedHoveredOptionGeojson",
    SetRouteIconsCountersVisibility: "SetRouteIconsCountersVisibility",
    OptionsUpdateOptionMetrics: "OptionsUpdateOptionMetrics",
    OptionSetPolygonOutputsForOption: "OptionSetPolygonOutputsForOption",
    OptionsSetCaseName: "OptionsSetCaseName",
    OptionsSetCaseId: "OptionsSetCaseId",
    OptionsAdd: "OptionsAdd",
    OptionUpdate: "OptionUpdate",
    OptionsToggleVisibility: "OptionsToggleVisibility",
    OptionsSetPolygonOutputVis: "OptionsSetPolygonOutputVis",
    OptionsToggleInteractionState: "OptionsToggleInteractionState",
    OptionToggleHover: "OptionToggleHover",
    RemoveOption: "RemoveOption",
    SetOptionColour: "SetOptionColour",
    OptionsResetState: "OptionsResetState",
    SetAllOptionColours: "SetAllOptionColours",
    SetOptionSort: "SetOptionSort",
    OptionSetHover: "OptionSetHover"
  };
}

export { NewOptionTypes };
export { NewOptionTypes as OptionTypes };
export default NewOptionTypes;
