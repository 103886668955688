<template>
  <fragment>
    <v-tab
      :to="{ name: 'projectDetails', params: { projectId: projectId } }"
      style="max-width: 100%"
      data-cy="headerTabProjectName"
      class="tab text-none"
      eager
      exact
    >
      <v-col class="pa-0 ma-0">
        <v-row
          class="projectTitle pa-0 ma-0 align-center"
          :class="{ scoping: isScopingProject }"
        >
          <v-col>
            <span class="projectTitleText"> {{ activeProjectName }} </span>
          </v-col>
          <v-col>
            <v-icon
              style="margin-left: 5px !important"
              data-cy="projectNameIcon"
              class="headerIcon"
              :class="{ scoping: isScopingProject }"
              outline
            >
              timeline
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-tab>

    <ScopingDivider v-if="isScopingProject" />
    <DefaultDivider v-else />
    <v-tab
      data-cy="headerTabModel"
      class="tab text-none"
      :to="{ name: routes.parameters, params: { projectId: projectId } }"
      >Parameters</v-tab
    >
    <v-tab
      data-cy="headerTabData"
      class="tab text-none"
      :to="{ name: routes.data, params: { projectId: projectId } }"
      >Data</v-tab
    >
    <ScopingDivider v-if="isScopingProject" />
    <DefaultDivider v-else />
    <fragment v-if="allowedSetupAccess">
      <v-tab
        :to="{ name: routes.setup, params: { projectId: projectId } }"
        data-cy="headerTabSetup"
        class="tab text-none"
        >Setup</v-tab
      >
    </fragment>
    <v-tab
      data-cy="headerTabAnalyze"
      class="tab text-none"
      :to="{ name: routes.analyze, params: { projectId: projectId } }"
      :style="analyseTabStyle"
    >
      Analysis
    </v-tab>
  </fragment>
</template>

<script>
import { getters } from "@/store/project/types";
import ProjectStages from "@/constants/ProjectStages";

import DefaultDivider from "@/components/DefaultDivider.vue";
import ScopingDivider from "@/components/scopingVersion/ScopingDivider.vue";

import { mapGetters } from "vuex";
import { getters as gettersFromSession } from "@S/session/types";
import accessAllowed from "@/mixins/accessAllowed";
import PermissionsTypes from "@/constants/PermissionsTypes";
import { routes } from "@/constants/routes";

export default {
  name: "MainNavBarProjectItems",
  mixins: [accessAllowed],

  components: { DefaultDivider, ScopingDivider },
  emits: ["projectStageValue"], 
  props: ["projectId"],
  mounted() {
    if (!this.SessionWaitingForPermissions && this.projectId) {
      this.allowedSetupAccess = this.accessAllowed(
        this.projectId,
        PermissionsTypes.EXECUTE
      );
    }
  },
  data() {
    return {
      allowedSetupAccess: false,
      routes
    };
  },
  computed: {
    ...mapGetters([
      gettersFromSession.SessionWaitingForPermissions,
      gettersFromSession.SessionCheckAuthorised,
      gettersFromSession.SessionGetActiveProjectId,
      getters.ProjectGetName,
      getters.ProjectGetStage
    ]),
    activeProjectName() {
      return this.ProjectGetName(this.projectId);
    },
    activeProjectStage() {
      return this.ProjectGetStage(this.projectId);
    },

    analyseTabStyle() {
      if (this.$route.name == "optionanalysis") {
        return "color: #1ccdd8;";
      } else {
        return "";
      }
    },
    isScopingProject() {
      return this.activeProjectStage == ProjectStages.SCOPING;
    }
  },

  watch: {
    activeProjectStage: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          // It has to be emitted from here to the parent component
          // as the parent component of MainNavBar does not have access
          // to the store
          this.$emit("projectStageValue", newValue);
        }
      }
    },
    SessionWaitingForPermissions(newValue) {
      if (!newValue) {
        this.allowedSetupAccess = this.accessAllowed(
          this.projectId,
          PermissionsTypes.EXECUTE
        );
      }
    },
    SessionGetActiveProjectId(newValue) {
      if (newValue) {
        this.allowedSetupAccess = this.accessAllowed(
          this.projectId,
          PermissionsTypes.EXECUTE
        );
      }
    }
  }
};
</script>
<style lang="scss">
.projectTitle::before {
  content: "Project";
  position: absolute;
  left: 13px;
  margin-top: -25px;

  font-size: 10px;
}

.projectTitleText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.projectTitle.scoping::before {
  color: white;
}

.headerIcon {
  opacity: 0.6;

  &.scoping {
    opacity: 1 !important;
  }
}
</style>
