import { actions, mutations, getters } from "./types";
import VerticalProfileChartTypes from "@/constants/VerticalProfileChartTypes";
import { fetchDiscreteItemsForOption } from "@/graphql/options/getOptionById";
import { isEqual, cloneDeep } from "lodash";

const defaultMapLinkage = true;
const createEmptyOptionComparisonState = () => {
  return {
    verticalProfile: {
      selectedQuantityNames: [],
      chartType: VerticalProfileChartTypes.vProfile.id,
      mapLinkActive: defaultMapLinkage
    }
  };
};

export const convertOptionComparisonToGraphQLInput = (
  workingOptionComparison
) => {
  const stateVerticalProfileView = workingOptionComparison?.verticalProfile;
  const graphQLInput = {
    verticalProfileView: {
      selectedPPQs: [
        ...(stateVerticalProfileView?.selectedQuantityNames || [])
      ],
      selectedChartType: getViewVerticalProfileChartId(
        stateVerticalProfileView?.chartType
      ),
      mapLinked: stateVerticalProfileView?.mapLinkActive
    }
  };
  return graphQLInput;
};

const getViewVerticalProfileChartId = (chartId) => {
  const viewId = Object.values(VerticalProfileChartTypes).find(
    (chartType) => chartType?.id === chartId
  )?.viewId;
  return viewId;
};

export default {
  state: {
    discreteItems: [],
    paneExpanded: false,
    workingOptionComparison: createEmptyOptionComparisonState(),
    savedOptionComparison: createEmptyOptionComparisonState()
  },
  getters: {
    [getters.MapChartGetPaneExpanded](state) {
      return state.paneExpanded;
    },
    [getters.GetSelectedMapChartTypeId](state) {
      return state.workingOptionComparison?.verticalProfile?.chartType;
    },
    [getters.GetActiveOptionDiscreteItems](state) {
      return state.discreteItems;
    },
    [getters.GetSavedOptionComparisonView]: (state) => {
      return state.savedOptionComparison;
    },
    [getters.GetWorkingOptionComparisonView]: (state) => {
      return state.workingOptionComparison;
    },
    [getters.IsOptionComparisonViewDirty]: (state) => {
      const selectedQuantitiesChanged = !isEqual(
        state?.savedOptionComparison?.verticalProfile?.selectedQuantityNames,
        state?.workingOptionComparison?.verticalProfile?.selectedQuantityNames
      );
      const chartTypeChanged =
        state?.savedOptionComparison?.verticalProfile?.chartType !==
        state?.workingOptionComparison?.verticalProfile?.chartType;
      const mapLinkageChanged =
        state?.savedOptionComparison?.verticalProfile?.mapLinkActive !==
        state?.workingOptionComparison?.verticalProfile?.mapLinkActive;
      return selectedQuantitiesChanged || chartTypeChanged || mapLinkageChanged;
    },
    [getters.IsVerticalProfileLinkedToMap]: (state) => {
      return state.workingOptionComparison?.verticalProfile?.mapLinkActive;
    },
    [getters.GetVerticalProfileSelectedQuantities]: (state) => {
      return (
        state.workingOptionComparison?.verticalProfile?.selectedQuantityNames ||
        []
      );
    }
  },
  mutations: {
    [mutations.MapChartSetPaneExpanded]: (state, payload) => {
      state.paneExpanded = payload;
    },
    [mutations.SetVerticalProfileMapLink]: (state, payload) => {
      Vue.set(
        state.workingOptionComparison.verticalProfile,
        "mapLinkActive",
        payload
      );
    },
    [mutations.SetVerticalProfileSelectedQuantities]: (state, payload) => {
      Vue.set(
        state.workingOptionComparison.verticalProfile,
        "selectedQuantityNames",
        payload
      );
    },
    [mutations.MapChartSetSelectedMapChartTypeId]: (state, chartId) => {
      const availableChartTypes = Object.values(VerticalProfileChartTypes).map(
        (chartType) => chartType.id
      );
      if (!availableChartTypes.includes(chartId)) {
        console.error(
          "Invalid chart type id: ",
          chartId,
          "\n Available chart types: ",
          availableChartTypes
        );
        return;
      }
      Vue.set(
        state.workingOptionComparison.verticalProfile,
        "chartType",
        chartId
      );
    },
    [mutations.SetActiveOptionDiscreteItems]: (state, payload) => {
      state.discreteItems = payload;
    },
    [mutations.SetComparisonView]: (state, payload) => {
      const optionComparisonPayload = payload?.optionComparisonView || {};
      const verticalProfPayload =
        optionComparisonPayload?.verticalProfileView || {};
      const defaults = createEmptyOptionComparisonState();

      const selectedVerticalProfileChartId = Object.values(
        VerticalProfileChartTypes
      ).find(
        (chartType) =>
          chartType?.viewId === verticalProfPayload?.selectedChartType
      )?.id;
      const mapLinkage =
        verticalProfPayload?.mapLinked !== undefined
          ? verticalProfPayload?.mapLinked
          : defaults?.verticalProfile?.mapLinkActive;

      const newOptionComparison = {
        verticalProfile: {
          selectedQuantityNames:
            verticalProfPayload?.selectedPPQs ||
            defaults?.verticalProfile?.selectedQuantityNames,
          chartType:
            selectedVerticalProfileChartId ||
            defaults?.verticalProfile?.chartType,
          mapLinkActive: mapLinkage
        }
      };
      Vue.set(state, "savedOptionComparison", cloneDeep(newOptionComparison));
      Vue.set(state, "workingOptionComparison", cloneDeep(newOptionComparison));
    }
  },
  actions: {
    [actions.MapChartSetPaneExpanded]: ({ commit }, payload) => {
      commit(mutations.MapChartSetPaneExpanded, payload);
    },
    [actions.FetchActiveOptionDiscreteItems]: async ({ commit }, payload) => {
      const { activeOptionId } = payload;

      if (!activeOptionId) {
        return;
      }

      const discreteItems = await fetchDiscreteItemsForOption({
        optionId: activeOptionId
      });

      if (discreteItems) {
        commit(mutations.SetActiveOptionDiscreteItems, discreteItems);
      }
    }
  }
};
