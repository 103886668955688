import gql from "graphql-tag";

export default gql`
  query getViewById($id: ID!) {
    view(id: $id) {
      id
      projectID
      name
      createdAt
      updatedAt

      mapStyle {
        viewPort {
          lat
          lng
          pitch
          bearing
          zoomLevel
        }
        showLayers {
          layer
          show
        }
        baseMapStyle
        threeD {
          active
          exaggerationFactor
        }
        groupBy
        activeRasterDatasetID
        datasetLayerStyles {
          assetId
          asset
          isVisible
          opacity
          outlineColour
          fillColour
        }
        routePointStyles {
          assetId
          asset
          isVisible
          opacity
          outlineColour
          fillColour
        }
        lineStyles {
          assetId
          asset
          isVisible
          opacity
          outlineColour
        }
        polygonStyles {
          assetId
          asset
          isVisible
          opacity
          outlineColour
          fillColour
        }
      }
      optionsState {
        selected
        styles {
          assetId
          asset
          isVisible
          fillColour
        }
        activeId
      }
      polygonResultsState {
        styles {
          assetId
          asset
          isVisible
        }
      }
      heatmapsState {
        styles {
          caseID
          isVisible
        }
      }
    }
  }
`;
