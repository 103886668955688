export const configAssetTypes = {
  OFFSHORE_CABLE: "OFFSHORE_CABLE",
  OFFSHORE_PIPELINE: "OFFSHORE_PIPELINE",
  ONSHORE_CABLE: "ONSHORE_CABLE",
  ONSHORE_PIPELINE: "ONSHORE_PIPELINE",
  PIPELINE: "PIPELINE",
  TRANSMISSION_LINE: "TRANSMISSION_LINE",
  WATER_PIPELINE: "WATER_PIPELINE",
  SITE_SELECTION: "SITE_SELECTION",
  ACCESS_TRACK: "ACCESS_TRACK",
  ELECTRICITY: "ELECTRICITY",
  ONSHORE_PIPELINE_2: "ONSHORE_PIPELINE_2"
};

export const defaultEvaluatorVersionForAssetType = {
  [configAssetTypes.OFFSHORE_CABLE]: "TWO",
  [configAssetTypes.OFFSHORE_PIPELINE]: "ONE",
  [configAssetTypes.ONSHORE_CABLE]: "TWO",
  [configAssetTypes.ONSHORE_PIPELINE]: "TWO",
  [configAssetTypes.PIPELINE]: "ONE",
  [configAssetTypes.TRANSMISSION_LINE]: "TWO",
  [configAssetTypes.WATER_PIPELINE]: "ONE",
  [configAssetTypes.SITE_SELECTION]: "TWO",
  [configAssetTypes.ACCESS_TRACK]: "TWO",
  [configAssetTypes.ELECTRICITY]: "TWO",
  [configAssetTypes.ONSHORE_PIPELINE_2]: "TWO"
};

export default {
  NOT_SET: "Not set",
  [configAssetTypes.PIPELINE]: "Pipeline (Legacy)",
  [configAssetTypes.OFFSHORE_CABLE]: "Offshore Cable",
  [configAssetTypes.ONSHORE_CABLE]: "Onshore Cable (Legacy)",
  [configAssetTypes.OFFSHORE_PIPELINE]: "Offshore Pipeline (Legacy)",
  [configAssetTypes.ONSHORE_PIPELINE]: "Onshore Pipeline (Legacy)",
  [configAssetTypes.TRANSMISSION_LINE]: "Transmission Line (Legacy)",
  [configAssetTypes.WATER_PIPELINE]: "Water Pipeline (Legacy)",
  [configAssetTypes.SITE_SELECTION]: "Site Selection",
  [configAssetTypes.ACCESS_TRACK]: "Access Track",
  [configAssetTypes.ELECTRICITY]: "Electricity",
  [configAssetTypes.ONSHORE_PIPELINE_2]: "Onshore Pipeline"
};
