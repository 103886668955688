import { BaseTypes } from "@S/base_types";

const getters = {
  RoutePointGetName: "RoutePointGetName",
  RoutePointGetSet: "RoutePointGetSet",
  RoutePointsAreLoading: "RoutePointsAreLoading",
  AllRoutePointSets: "AllRoutePointSets",
  GetAllRoutePointsAsArray: "GetAllRoutePointsAsArray"
};

const mutations = {
  RoutePointUpdate: "RoutePointUpdate",
  RoutePointDelete: "RoutePointDelete",
  RoutePointSetLoadingState: "RoutePointSetLoadingState",
  RoutePointSetInteractionState: "RoutePointSetInteractionState",
  RoutePointSetEditMode: "RoutePointSetEditMode",
  RoutePointResetState: "RoutePointResetState",
  RoutePointAddRoutePoint: "RoutePointAddRoutePoint"
};
const actions = {
  RoutePointUpdate: "RoutePointUpdate",
  RoutePointDelete: "RoutePointDelete",
  RoutePointSetLoadingState: "RoutePointSetLoadingState",
  RoutePointUpdateLoadingState: "RoutePointUpdateLoadingState",
  RoutePointSetInteractionState: "RoutePointSetInteractionState",
  RoutePointSetEditMode: "RoutePointSetEditMode",
  RoutePointResetState: "RoutePointResetState",
  RoutePointAddRoutePoint: "RoutePointAddRoutePoint"
};

export class RoutePointTypes extends BaseTypes {}
RoutePointTypes.getters = getters;
RoutePointTypes.mutations = mutations;
RoutePointTypes.actions = actions;

export { getters, mutations, actions };
