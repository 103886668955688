const getters = {
  MetricsViewGetName: "MetricsViewGetName",
  MetricsViewGetId: "MetricsViewGetId",

  MetricsViewGetSavedMetrics: "MetricsViewGetSavedMetrics",
  MetricsViewGetWorkingMetricNames: "MetricsViewGetWorkingMetricNames",
  MetricsViewGetWorkingMetrics: "MetricsViewGetWorkingMetrics",
  MetricsViewGetMetricsByName: "MetricsViewGetMetricsByName",

  MetricsViewGetIsDirty: "MetricsViewGetIsDirty"
};

const mutations = {
  MetricsViewRenameView: "MetricsViewRenameView",
  SaveMetricView: "SaveMetricView",
  MetricsViewSetWorkingMetrics: "MetricsViewSetWorkingMetrics",
  MetricsViewDiscardChanges: "MetricsViewDiscardChanges",
  MetricsViewResetState: "MetricsViewResetState"
};

const actions = {
  SaveMetricView: "SaveMetricView",
  MetricsViewSetWorkingMetrics: "MetricsViewSetWorkingMetrics",
  MetricsViewDiscardChanges: "MetricsViewDiscardChanges",
  MetricsViewResetState: "MetricsViewResetState",
  MetricsViewComputeAndSetWorkingMetrics:
    "MetricsViewComputeAndSetWorkingMetrics"
};

export { getters, mutations, actions };
const types = { getters, mutations, actions };
export default types;
