const getters = {
  LayersTreeGetDisplayMode: "LayersTreeGetDisplayMode",
  LayersTreeGetGroupByProperty: "LayersTreeGetGroupByProperty",
  LayersTreeGetShowProperty: "LayersTreeGetShowProperty",
  LayersTreeGetSearchFilter: "LayersTreeGetSearchFilter",
  LayersTreeGetShowAliasOrName: "LayersTreeGetShowAliasOrName"
};

const mutations = {
  LayersTreeSetDisplayMode: "LayersTreeSetDisplayMode",
  LayersTreeSetGroupByProperty: "LayersTreeSetGroupByProperty",
  LayersTreeSetShowProperty: "LayersTreeSetShowProperty",
  LayersTreeSetSearchFilter: "LayersTreeSetSearchFilter",
  LayersTreeSetShowAliasOrName: "LayersTreeSetShowAliasOrName",
  LayersTreeResetState: "LayersTreeResetState"
};

const actions = {
  LayersTreeSetDisplayMode: "LayersTreeSetDisplayMode",
  LayersTreeSetGroupByProperty: "LayersTreeSetGroupByProperty",
  LayersTreeSetShowProperty: "LayersTreeSetShowProperty",
  LayersTreeSetSearchFilter: "LayersTreeSetSearchFilter",
  LayersTreeSetShowAliasOrName: "LayersTreeSetShowAliasOrName",
  LayersTreeResetState: "LayersTreeResetState"
};

export { getters, mutations, actions };
const types = { getters, mutations, actions };
export default types;
