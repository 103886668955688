const getters = {
  CommentGetAllAsObject: "CommentGetAllAsObject",
  CommentGetAll: "CommentGetAll",
  CommentGetAttachmentsOnMap: "CommentGetAttachmentsOnMap",
  CommentGetComment: "CommentGetComment",
  CommentGetNumberOfComments: "CommentGetNumberOfComments",
  CommentGetMessage: "CommentGetMessage",
  CommentGetCreatedBy: "CommentGetCreatedBy",
  CommentGetCreatedDate: "CommentGetCreatedDate",
  CommentGetEditedDate: "CommentGetEditedDate",
  CommentGetCoords: "CommentGetCoords",
  CommentGetTempCoords: "CommentGetTempCoords",
  CommentGetParentId: "CommentGetParentId",
  CommentIsLoading: "CommentIsLoading",
  CommentErrored: "CommentErrored",
  CommentGetReplyCount: "CommentGetReplyCount",
  CommentGetNumberOfCommentsLoading: "CommentGetNumberOfCommentsLoading",
  CommentGetUser: "CommentGetUser",
  CommentGetOptionIds: "CommentGetOptionIds",
  CommentGetPointIds: "CommentGetPointIds",
  CommentGetLineIds: "CommentGetLineIds",
  CommentGetPolygonIds: "CommentGetPolygonIds",
  CommentGetAttachments: "CommentGetAttachments",

  GetCommentCategory: "GetCommentCategory",
  GetCommentColour: "GetCommentColour",
  GetCommentCategories: "GetCommentCategories",
  GetCategoryIdForComment: "GetCategoryIdForComment",

  PaginatedCommentsAreLoading: "PaginatedCommentsAreLoading",
  GetOpenCommentId: "GetOpenCommentId",
  GetCommentSort: "GetCommentSort",
  GetEditingCommentId: "GetEditingCommentId"
};
const mutations = {
  CommentSetPagginatedCommentsAreLoading:
    "CommentSetPagginatedCommentsAreLoading",
  CommentAddAttachmentToMap: "CommentAddAttachmentToMap",
  CommentRemoveAttachmentFromMap: "CommentRemoveAttachmentFromMap",
  CommentRemoveAllAttachmentsFromMap: "CommentRemoveAllAttachmentsFromMap",
  CommentResetState: "CommentResetState",
  CommentAddComment: "CommentAddComment",
  CommentUpdateComment: "CommentUpdateComment",
  CommentDeleteComment: "CommentDeleteComment",
  CommentDelete: "CommentDelete",
  CommentSetLoadingState: "CommentSetLoadingState",
  CommentSetInteractionState: "CommentSetInteractionState",
  CommentSetCoords: "CommentSetCoords",
  CommentSetTempCoords: "CommentSetTempCoords",
  CommentSetMostRecentReplyDate: "CommentSetMostRecentReplyDate",
  CommentSetAttachmentUploadState: "CommentSetAttachmentUploadState",

  SetCommentCategory: "SetCommentCategory",
  AddCommentCategories: "AddCommentCategories",
  UpdateCommentCategoryLoading: "UpdateCommentCategoryLoading",
  DeleteCommentCategory: "DeleteCommentCategory",
  SetOpenCommentId: "SetOpenCommentId",
  SetCommentSort: "SetCommentSort",
  SetEditingCommentId: "SetEditingCommentId"
};
const actions = {
  CommentSetPagginatedCommentsAreLoading:
    "CommentSetPagginatedCommentsAreLoading",
  CommentAddAttachmentToMap: "CommentAddAttachmentToMap",
  CommentRemoveAttachmentFromMap: "CommentRemoveAttachmentFromMap",
  CommentRemoveAllAttachmentsFromMap: "CommentRemoveAllAttachmentsFromMap",
  CommentResetState: "CommentResetState",
  CommentAddComment: "CommentAddComment",
  CommentUpdateComment: "CommentUpdateComment",
  CommentUpdateLoadingState: "CommentUpdateLoadingState",
  CommentSetInteractionState: "CommentSetInteractionState",
  CommentSetMostRecentReplyDate: "CommentSetMostRecentReplyDate",
  CommentSetCoords: "CommentSetCoords",
  CommentSetTempCoords: "CommentSetTempCoords",
  CommentDeleteComment: "CommentDeleteComment",
  CommentSetAttachmentUploadState: "CommentSetAttachmentUploadState"
};

export { getters, mutations, actions };
