/* eslint-disable no-prototype-builtins */
export function areViewportsDifferent(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return true;
  }

  for (const key of keys1) {
    if (
      !obj2.hasOwnProperty(key) ||
      obj1[key].toFixed(6) !== obj2[key].toFixed(6)
    ) {
      return true;
    }
  }

  return false;
}

export function areSelectedOptionsDifferent(array1, array2) {
  if (array1.length !== array2.length) {
    return true;
  }

  const set2 = new Set(array2.map(Number));
  for (const option of array1) {
    if (!set2.has(Number(option))) {
      return true;
    }
  }

  return false;
}
