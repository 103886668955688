<template>
  <v-btn
    @click="tabOnClickHandler"
    data-cy="loginButtonOnLoginPage"
    color="primaryVariant"
    text
    dark
  >
    <v-progress-circular v-if="authLoading" indeterminate class="mx-2" />
    {{ buttonText }}
  </v-btn>
</template>

<script>
import { authState, loginWithRedirect } from "@/plugins/authPlugin";
export default {
  name: "LoginButton",
  computed: {
    buttonText() {
      if (this.authLoading) {
        return "Loading";
      } else {
        return "Login";
      }
    },
    authLoading() {
      return authState?.value?.loading;
    }
  },
  methods: {
    tabOnClickHandler() {
      if (!authState?.value.isAuthenticated) {
        this.login();
      }
    },
    login() {
      loginWithRedirect();
    }
  }
};
</script>
