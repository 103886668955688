const getters = {
  ConfigurationParameterConfigDrChangeMap:
    "ConfigurationParameterConfigDrChangeMap",
  ConfigurationParameterSelectedParameter:
    "ConfigurationParameterSelectedParameter",
  ConfigurationParameterDatasetHasErrors:
    "ConfigurationParameterDatasetHasErrors",
  ConfigurationParameterDesignRuleGotActivatedOrDeactivated:
    "ConfigurationParameterDesignRuleGotActivatedOrDeactivated",
  ConfigurationParameterDrToBeCompared: "ConfigurationParameterDrToBeCompared",
  ConfigurationParameterPrimarySetting: "ConfigurationParameterPrimarySetting",
  IsDesignRuleSelected: "IsDesignRuleSelected"
};

const mutations = {
  ConfigurationParameterSetPrimarySetting:
    "ConfigurationParameterSetPrimarySetting",
  ConfigurationParameterSetConfigDrChangeMap:
    "ConfigurationParameterSetConfigDrChangeMap",
  ConfigurationParameterSetSelectedParameter:
    "ConfigurationParameterSetSelectedParameter",
  ConfigurationParameterResetDesignRuleActivityMapping:
    "ConfigurationParameterResetDesignRuleActivityMapping",
  ConfigurationParameterSetDatasetHasError:
    "ConfigurationParameterSetDatasetHasError",
  ConfigurationParameterSetDrToBeCompared:
    "ConfigurationParameterSetDrToBeCompared"
};

const actions = {
  ConfigurationParameterSetPrimarySetting:
    "ConfigurationParameterSetPrimarySetting",
  ConfigurationParameterSetConfigDrChangeMap:
    "ConfigurationParameterSetConfigDrChangeMap",
  ConfigurationParameterSetSelectedParameter:
    "ConfigurationParameterSetSelectedParameter",
  ConfigurationParameterResetDesignRuleActivityMapping:
    "ConfigurationParameterResetDesignRuleActivityMapping",
  ConfigurationParameterSetDatasetHasError:
    "ConfigurationParameterSetDatasetHasError",
  ConfigurationParameterSetDrToBeCompared:
    "ConfigurationParameterSetDrToBeCompared"
};

export { getters, mutations, actions };
