import Worker from "worker-loader!./worker";
import * as Comlink from "comlink";

// A word of caution: the worker-loader seems to not resolve circular dependencies
// in the same way as default webpack, so if you make a change in here and find
// that your build is hanging infinitely, you may have to hunt down the dependency

const worker = Comlink.wrap(new Worker());

export const transformVerticalChartData = worker.transformVerticalChartData;

export const transformOptionResultsToOldFormat =
  worker.transformOptionResultsToOldFormat;

export const transformDataAttributes = worker.transformDataAttributes;

export const extentOfVerticalChartDataByChainage =
  worker.extentOfVerticalChartDataByChainage;

export const createExtentFromChainage = worker.createExtentFromChainage;

export const getChainageOutOfChartData = worker.getChainageOutOfChartData;

export const filterDiscreteItemsForUndefined =
  worker.filterDiscreteItemsForUndefined;

export const createTheArraysForTheGraphFromChartData =
  worker.createTheArraysForTheGraphFromChartData;

export const getMinMaxForElevationScale = worker.getMinMaxForElevationScale;

export const createDataForVerticalChart3dDataLine =
  worker.createDataForVerticalChart3dDataLine;