import gql from "graphql-tag";
import { convertSpanMetricsToPerPointQuantities } from "@/components/analysis/charts/MapChart/GraphFunctions";
import { apolloClient } from "@/plugins/apolloPlugin";

const FETCH_DISCRETE_ITEMS_QUERY = gql`
  query getDiscreteItemsByOptionId($optionId: ID!) {
    option(optionID: $optionId) {
      id
      discreteItems {
        name
        indices
      }
    }
  }
`;

const FETCH_PER_POINT_QUANTITIES_QUERY = gql`
  query GetFullPerPointQuantities(
    $optionId: ID!
    $perPointQuantitiesFilters: PPQRequestFilters!
  ) {
    option(optionID: $optionId) {
      id
      perPointQuantities(filters: $perPointQuantitiesFilters) {
        values
        name
        unit {
          displayUnit
        }
      }
    }
  }
`;

const FETCH_PER_POINT_QUANTITIES_METADATA_QUERY = gql`
  query GetPerPointQuanitityMetadata($optionId: ID!) {
    option(optionID: $optionId) {
      id
      perPointQuantities {
        name
        unit {
          displayUnit
        }
      }
    }
  }
`;

const FETCH_PER_POINT_QUANTITIES_AND_SPAN_METRICS_QUERY = gql`
  query getOptionSpanMetricsAndPerPointQuantities(
    $optionId: ID!
    $perPointQuantitiesFilters: PPQRequestFilters!
  ) {
    option(optionID: $optionId) {
      id
      spanMetrics {
        name
        title
        unit {
          displayUnit
        }
        values {
          start
          length
          value
        }
      }
      perPointQuantities(filters: $perPointQuantitiesFilters) {
        values
        name
        unit {
          displayUnit
        }
      }
    }
  }
`;

export const getAllOptionIds = gql`
  query getAllOptionIds($input: ListOptionsInput!) {
    options(input: $input) {
      options {
        id
      }
    }
  }
`;

export const getOptionsPaginatedWithoutMetrics = gql`
  query InitialPaginatedOptionsFetch($input: ListOptionsInput!) {
    options(input: $input) {
      options {
        id
        alias
        caseId
        constraintViolation
        objectiveMetrics {
          name
          value
        }
        discreteItems {
          name
        }
        polygonOutputs {
          name
          title
          description
        }
        scenarios {
          id
          name
          colour
        }
      }
      pageInfo {
        after
        before
        totalCount
      }
    }
  }
`;

export const getOptionGeojson = (optionId) => ({
  query: gql`
    query getOptionGeojson($optionID: ID!) {
      option(optionID: $optionID) {
        id
        geojson
        alias
      }
    }
  `,
  variables: { optionID: Number(optionId) }
});

export const fetchOptionGeojson = async (optionId, retries) => {
  retries = retries || 0;
  if (retries > 5) {
    throw new Error("Failed to fetch option geojson");
  }
  try {
    const resp = await apolloClient.query(getOptionGeojson(optionId));
    return JSON.parse(resp.data.option.geojson);
  } catch (e) {
    console.warn(e);
    return await fetchOptionGeojson(optionId, retries + 1);
  }
};

export const getOptionObjectiveMetrics = gql`
  query getOptionObjectiveMetrics($optionID: ID!) {
    option(optionID: $optionID) {
      id
      constraintViolation
      objectiveMetrics {
        name
        value
      }
    }
  }
`;

export const fetchDiscreteItemsForOption = ({ optionId }) => {
  return apolloClient
    .query({
      query: FETCH_DISCRETE_ITEMS_QUERY,
      variables: {
        optionId: Number(optionId)
      }
    })
    .then((response) => {
      return response.data.option.discreteItems;
    })
    .catch((err) => {
      console.error("Error returned getting discrete items for option: ", err);

      return;
    });
};

export const fetchPerPointQuantitiesAndSpanMetricsForOption = ({
  optionId,
  requiredPerPointQuantityNames
}) => {
  return apolloClient
    .query({
      query: FETCH_PER_POINT_QUANTITIES_AND_SPAN_METRICS_QUERY,
      variables: {
        optionId: Number(optionId),
        perPointQuantitiesFilters: {
          filterNames: requiredPerPointQuantityNames
        }
      }
    })
    .then(async (response) => {
      if (!response.data) return [];

      const quantities = response?.data?.option?.perPointQuantities ?? [];

      quantities.push(
        ...convertSpanMetricsToPerPointQuantities(
          response.data.option.spanMetrics,
          quantities[0].values.length
        )
      );
      return quantities;
    })
    .catch((err) => {
      console.error(
        "Error returned getting ppq and span metrics for option: ",
        err
      );

      return [];
    });
};

export const fetchPerPointQuantitiesForOption = ({
  optionId,
  requiredPerPointQuantityNames
}) => {
  return apolloClient
    .query({
      query: FETCH_PER_POINT_QUANTITIES_QUERY,
      variables: {
        optionId: Number(optionId),
        perPointQuantitiesFilters: {
          filterNames: requiredPerPointQuantityNames
        }
      }
    })
    .then(async (response) => {
      const quantities = response.data.option.perPointQuantities;
      return quantities;
    })
    .catch((err) => {
      console.error(
        "Error returned getting per point quanaities for option: ",
        err
      );

      return;
    });
};

export const fetchPerPointQuantitiesMetadataForOption = ({ optionId }) => {
  return apolloClient
    .query({
      query: FETCH_PER_POINT_QUANTITIES_METADATA_QUERY,
      variables: {
        optionId: Number(optionId)
      }
    })
    .then((response) => {
      return response.data.option.perPointQuantities;
    })
    .catch((err) => {
      console.error(
        "Error returned getting per point quantities metadata for option: ",
        err
      );

      return;
    });
};
