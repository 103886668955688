import { PolygonAnalysisTypes as types } from "@S/polygonAnalysis/types";

export default {
  state: {
    analysedPolygons: {
      //id: {
      //  layerId: {
      //bufferMeters: someValue
      //}
      //}
    }
  },
  types: types,
  getters: {
    [types.getters.GetAnalysisForPolygon]: (state) => (polygonId) => {
      return state.analysedPolygons?.[polygonId] || {};
    },
    [types.getters.GetAnalysisForAllPolygons]: (state) => () => {
      return state.analysedPolygons || {};
    }
  },
  mutations: {
    [types.mutations.ClearPolygonAnalysisResult]: function (state, payload) {
      const { polygonId } = payload;
      Vue.set(state.analysedPolygons, polygonId, {});
    },
    [types.mutations.SetPolygonAnalysisResult]: function (state, payload) {
      const { polygonId, layerId, buffer, data } = payload;
      if (!(polygonId in state.analysedPolygons)) {
        Vue.set(state.analysedPolygons, polygonId, {});
      }
      if (!(layerId in state.analysedPolygons[polygonId])) {
        Vue.set(state.analysedPolygons[polygonId], layerId, {});
      }
      Vue.set(state.analysedPolygons[polygonId][layerId], buffer, data);
    }
  },
  actions: {
    [types.actions.ClearPolygonAnalysisResult]: ({ commit }, payload) => {
      commit(types.mutations.ClearPolygonAnalysisResult, payload);
    },
    [types.actions.SetPolygonAnalysisResult]: ({ commit }, payload) => {
      commit(types.mutations.SetPolygonAnalysisResult, payload);
    }
  }
};
