<template>
  <v-alert
    @input="closeAlert"
    :value="AlertExists"
    :type="AlertGetType"
    class="text-center"
    border="left"
    height="60"
    text
    dismissible
  >
    {{ AlertGetMessage }}
  </v-alert>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getters, actions } from "@/store/alert/types";

export default {
  computed: {
    ...mapGetters([
      getters.AlertExists,
      getters.AlertGetMessage,
      getters.AlertGetType
    ])
  },

  methods: {
    ...mapActions([actions.AlertUpdate]),
    closeAlert() {
      this.AlertUpdate(null);
    }
  }
};
</script>
