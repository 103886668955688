import gql from "graphql-tag";

/*
input UpdateRAGInput {
  projectID: ProjectID!
  id: ID!
  BaseExpressionType: BaseExpressionType
  greenBound: String
  redBound: String
}
*/
export default (input) => {
  return {
    mutation: gql`
    mutation updateRAG($input: UpdateRAGInput!) {
      updateRAG(input: $input) {
        id
        createdAt
        updatedAt
        createdBy
        updatedBy
        metricName
        expressionType
        greenBound
        redBound
      }
    }
    `,
    variables: { input: input }
  };
};