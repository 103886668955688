export const RAGAnalysisFunctionTypes = {
  CONSTANT: {
    name: "Absolute",
    editorPrefix: "Abs",
    value: "CONSTANT"
  },
  MIN: {
    name: "Min",
    editorPrefix: "Min",
    value: "MIN"
  },
  AVG: {
    name: "Average",
    editorPrefix: "Avg",
    value: "AVG"
  },
  MAX: {
    name: "Max",
    editorPrefix: "Max",
    value: "MAX"
  },
  TYPE_UNSET: {
    name: "Unset",
    editorPrefix: "-----",
    value: null
  }
};

export const RAGAnalysisStatuses = {
  CREATING: "CREATING",
  DELETING: "DELETING",
  LOADING: "LOADING",
  UPDATING: "UPDATING",
  OK: "OK",
  ERROR: "ERROR"
};

export const RAGHexColours = {
  RED: "#FFCBA3",
  AMBER: "#F2EC9B",
  GREEN: "#7AC9B4"
};
